import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory , Link} from 'react-router-dom';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Card, Form, Button, Table, Modal } from 'react-bootstrap';
import { MDBInput } from 'mdb-react-ui-kit';
import PsContext from '../../context';
import API from '../../utils/api';
import {baseUrl} from '../../utils';
import axios from "axios";
import moment from "moment";

const AttendancePage = (props) =>{
	
	const context = useContext(PsContext);
	const history = useHistory();
	const [viewModal, setViewModal] = useState(false);
	const [fieldValue, setFieldValue] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const [latitude, setLatitude] = useState('');
	const [longitude, setLongitude] = useState('');
	
	useEffect(() => {
        listdata(); 
    } , [] );

	const listdata = () => {
		try {
            setIsLoading(true);
			var form = new FormData();
			form.append('userid',props.match.params.userid);
			form.append('month',props.match.params.month);
			API.post('v1/user/listattendanceformob',form).then(res => {
			if (res['data'].status == '1') {
                var d = res['data'].data;
                setData(d);
                 setIsLoading(false);
			}
			else {
				setIsLoading(false);
				}
			});
		}
		catch (error) {
            setIsLoading(false);
		}
	};
	
	const viewReport = (lat,lon) => {
		setLatitude(lat);
		setLongitude(lon);
		setViewModal(true);
	}

	return(
		<>
			<div className="container" >
				{data.length > 0 ?
				<Row className="mt-2" >
					<Col md={12}>
						<Table bordered responsive className="table table-bordered">
							<thead className="text-center">
								<tr>
								  <th width="30%"><b>Date</b></th>
								  <th width="20%"><b>IN</b></th>
								  <th width="20%"><b>OUT</b></th>
								  <th width="30%"><b>Location</b></th>
								</tr>
							</thead>
							<tbody className="text-center">
								{(data.map((item, id ) => {
									const str = item.location.split(",");
									const lat = str[0];
									const lon = str[1];
									return <tr>
										  <td>{moment(item.att_date).format("DD-MM-YYYY")}</td>
										  <td>{item.att_time}</td>
										  <td>{item.out_time}</td>
											<td>
											{/*<Button type="submit" size="sm" onClick={(e) => {viewReport(lat,lon)}}>View</Button>*/}
												<a href={"https://maps.google.com/?q="+lat+","+lon} >Link</a>
											</td>
									</tr>
								}))} 																		
							</tbody>
						</Table>
					</Col>
				</Row> : 
				<center>No Data Found...</center>
				}
				<Modal show={viewModal} size="lg" onHide={()=> {setViewModal(false) }} backdrop="static"> 
					<Modal.Header closeButton >
						<Modal.Title>View</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<iframe src={"https://maps.google.com/?q="+latitude+","+longitude} title="description"></iframe>
					</Modal.Body>
				</Modal>
			</div>
		</>
	);
};
export default withRouter(AttendancePage);