import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { integerIndMobile, lettersOnly, baseUrl } from '../../utils';
import axios from 'axios';
import ReactFileReader from 'react-file-reader';
import moment from 'moment';

const AddStaff = (props) => {

	const context = useContext(PsContext);
	const [submit, sethandleSubmit] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [file, setFile] = useState('');
	const [fileName, setFileName] = useState('');
	const [dob, setDob] = useState('');
	const [doa, setDoa] = useState('');
	const [data, changeData] = useState([]);

	useEffect(() => {
		var date = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + (new Date().getDate() - 1);
		setDob(date);
		setDoa(date);
	}, []);

	const handleFiles = (files) => {
		setFile(files.base64);
		setFileName(files.fileList[0].name);
	}

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		if (!form.checkValidity() === true) {
			event.stopPropagation();
			setValidated(true);
			return;
		}
		try {
			setBtnLoading(true);
			API.post('v1/user/add', $("#addform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Staff Added Successfully..!", "success");
					document.getElementById("addform").reset();
					setFile('');
					setFileName('');
					const vrl = baseUrl + 'v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'User Added Staff Member');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
						method: 'post',
						url: vrl,
						data: formData
					};

					axios(config)
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
					setBtnLoading(false);
				}
				else {
					toast.error(res['data'].message, "danger");
					setBtnLoading(false);
				}
				setIsLoading(false);
			});
		}
		catch (error) {

		}
	};

	return (
		<>
			<div className="container" style={{ backgroundColor: 'white' }}>
				<Navbar bg="white" expand="lg">
					<Container>
						<Navbar.Brand >Add Staff</Navbar.Brand>
						<Nav className="justify-content-end">
							<Link to={{
								pathname: '/staff'
							}}>Manage</Link>
						</Nav>
					</Container>
				</Navbar>

				<Row>
					<Col md={2}>
					</Col>
					<Col md={8}>
						<Card>
							<Card.Body>
								<h3>Add Staff</h3><hr />
								<Form action="" method="post" id="addform" noValidate validated={validated} onSubmit={handleSubmit} >
									<input type="hidden" name="file" value={JSON.stringify(file)} />
									<input type="hidden" name="fileName" value={fileName} />
									<Row>
										<Col md={6}>
											<Col>
												<MDBInput
													label="Full Name"
													type="text"
													name="name"
													size="lg"
													onKeyPress={lettersOnly}
													required
												/>
											</Col>

											<Col className="mt-3">
												<MDBInput
													label="Email"
													type="email"
													name="email"
													size="lg"
													required
												/>
											</Col>

											<Col className="mt-3">
												<MDBInput
													label="Mobile"
													type="text"
													name="mobile"
													size="lg"
													onKeyPress={integerIndMobile}
													required
												/>
											</Col>

											<Col className="mt-3">
												<MDBInput
													label="Address"
													type="text"
													name="address"
													size="lg"
													required
												/>
											</Col>

											<Col className="mt-3">
												<MDBInput
													label="DOB"
													type="date"
													name="dob"
													size="lg"
													required
													defaultValue={dob}
													max={moment().subtract(1, "days").format("YYYY-MM-DD")}
												/>
											</Col>

											<Col className="mt-3">
												<MDBInput
													label="Education Qualification"
													type="text"
													name="edu_qualification"
													size="lg"
													onKeyPress={lettersOnly}
													required
												/>
											</Col>
										</Col>
										<Col md={6}>
											<Col className="mt-0">
												<MDBInput
													label="Year of Experience"
													type="number"
													name="year_ex"
													size="lg"
													required
												/>
											</Col>

											{/*<Col className="mt-3">
												<MDBInput
													label="Upload CV"
													type="text"
													name="upload_cv"
													size="lg"
													onKeyPress={lettersOnly}
													required
												/>
												<ReactFileReader base64={true} handleFiles={handleFiles}>
													<button type="button" className='btn btn-block'>Upload CV</button>
												</ReactFileReader>
						</Col>*/}

											<Col className="mt-3">
												<MDBInput
													label="Date Of Appointment"
													type="date"
													name="doa"
													size="lg"
													required
													defaultValue={doa}
												/>
											</Col>
											<Col className="mt-3">
												<MDBInput
													label="Designation"
													type="text"
													name="designation"
													size="lg"
													onKeyPress={lettersOnly}
													required
												/>
											</Col>
											<Col className="mt-3">
												<select className="form-select form-select-lg" name="staff_type" requried>
													<option value="" disabled selected>Staff Type</option>
													<option value="0">Project Staff</option>
													<option value="1">Admin Staff</option>
												</select>
											</Col>
											<Col className="mt-3">
												<MDBInput
													label="Order"
													type="number"
													name="ordered"
													size="lg"
													required
												/>
											</Col>
											<Col className="mt-3">
												<Row>
													<Col md={5}>
														<label>Mobile APP Access:</label><br />
														<div className="mt-2">
															<div className="form-check form-check-inline">
																<input type="radio" name="mobileAccess" value="Yes" className="form-check-input" />
																<label for="mobileAccess">
																	Yes
																</label>
															</div>
															<div className="form-check form-check-inline">
																<input type="radio" name="mobileAccess" value="No" className="form-check-input" />
																<label for="mobileAccess">
																	No
																</label>
															</div>
														</div>
													</Col>
													<Col md={7}>
														<label>Gender:</label><br />
														<div className="mt-2">
															<div className="form-check form-check-inline">
																<input type="radio" name="gender" value="Male" className="form-check-input" required />
																<label for="gender">
																	Male
																</label>
															</div>
															<div className="form-check form-check-inline">
																<input type="radio" name="gender" value="Female" className="form-check-input" required />
																<label for="gender">
																	Female
																</label>
															</div>
															<div className="form-check form-check-inline">
																<input type="radio" name="gender" value="TG" className="form-check-input" required />
																<label for="gender">
																	TransGender
																</label>
															</div>
														</div>
													</Col>

												</Row>
											</Col>
										</Col>
									</Row>
									<Col className="mt-3">
										<button class="btn btn-success " style={{ float: 'right' }} type="submit" disabled={btnLoading}>
											{btnLoading ? 'Please wait..' : 'Save'}</button>
									</Col>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default AddStaff;