import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col,  Modal , Form} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import moment from 'moment';
import ReactFileReader from 'react-file-reader';
import {baseUrl} from '../../utils';

const EditAccountingYear = (props) =>{

	const context = useContext(PsContext);
	const [btnLoading, setBtnLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = (event) =>{
		event.preventDefault();
		try{
			setIsLoading(true);
			API.post('v1/user/editaccountingyear', $("#editform").serialize()).then(res =>{
			if(res['data'].status == '1'){
				toast.success("Accounting Year Updated Successfully","success");
				setIsLoading(false);
				const vrl=baseUrl+'v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Accounting Year Updated Successfully');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
					  method: 'post',
					  url: vrl,
					  data : formData
					};
					axios(config)
					.then(function (response) {
					  console.log(JSON.stringify(response.data));
					})
					.catch(function (error) {
					  console.log(error);
					});	
				props.afterFinish();
				props.afterList();
			}
			else{
				toast.error("Something Went Wrong","danger");
				setIsLoading(false);
			}
			});
		}
		catch(error){
			
		}
	}
	
	const onFromChange = (event) => {
	
		var fromdate = $("#fromdate1").val();
		var toMinDate = moment(fromdate).format("YYYY-MM-DD");
		if(fromdate!=''){
			$("#todate1").attr('min',toMinDate);
		}
	};

	return(
		<>
			<div className="container">
				<Form action="" method="post" id="editform" validated={validated} onSubmit={handleSubmit} >
					<input type="hidden" name="id" value={props.edit.id} />
					<Row>
						<Col md={12}>
							<MDBInput 
								label="Start Date"
								type="date"
								name="start_date"
								required
								size="md"
								id="fromdate1"
								defaultValue={props.edit.start_date}
								onChange={onFromChange}
							 />
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput 
								label="End Date"
								type="date"
								name="end_date"
								required
								size="md"
								id="todate1"
								defaultValue={props.edit.end_date}
							 />
						</Col>
					</Row>
                <Col className="mt-3">
					<button type="button" className="btn btn-outline-black btn-block"  type="submit" disabled={btnLoading}>
					{btnLoading ? 'Please wait..' : 'Update'}</button>
				</Col>
			</Form>
			</div>
		</>
	);
};

export default EditAccountingYear; 