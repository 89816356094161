import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Table, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import { integerIndMobile, baseUrl, decimalKeyPress } from '../../utils';
import moment from 'moment';

const EditSaless = (props) => {

	const context = useContext(PsContext);
	const [btnLoading, setBtnLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [plasticData, setPlasticData] = useState([]);
	const [materialData, setMaterialData] = useState([]);
	const [data, setData] = useState([]);
	const [edit, setEdit] = useState([]);



	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			setBtnLoading(true);
			console.log($("#addform").serialize());
			API.post('v1/plastic/editplasticsales', $("#addform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Updated Successfully..!", "success");
					//document.getElementById("addform").reset();
					setBtnLoading(false);
					props.afterFinish();
					props.afterList();
				}
				else {
					toast.error(res['data'].message, "danger");
					setBtnLoading(false);
				}
				setIsLoading(false);
			});
		}
		catch (error) {

		}
	};

	console.log(props.data);
	let totalAmount = 0;
	let total = 0;
	let btotal = 0;

	return (
		<>
			<div className="container">
				<Form action="" method="post" id="addform" validated={validated} onSubmit={handleSubmit} >
					<input type="hidden" name="id" value={props.data.id} />
					<Row>
						<Col md={3}>
							<MDBInput
								label="Date"
								type="date"
								name="sales_date"
								size="sm"
								defaultValue={props.data.sales_date}
							/>
						</Col>
						<Col md={3}>
							<MDBInput
								label="Material Type"
								type="text"
								name="pname"
								size="sm"
								disabled
								defaultValue={props.data.pname}
							/>
						</Col>
						<Col md={3}>
							<MDBInput
								label={props.type == 'others pcs' ? "Pieces" : "KG"}
								type="text"
								name="selling_kg"
								size="sm"
								defaultValue={props.data.selling_kg}
							/>
						</Col>
						<Col md={3}>
							<MDBInput
								label="Amount"
								type="text"
								name="total_amount"
								size="sm"
								defaultValue={props.data.total_amount}
							/>
						</Col>
						{/* <Col md={3}>
							<MDBInput
								label="Plastic Amount"
								type="text"
								name="total_amount"
								size="sm"
								className='mt-2'
								defaultValue={props.data.plastic_amount}
							/>
						</Col> */}
					</Row>
					<button type="submit" style={{ float: 'right' }} className="btn btn-success mt-2" disabled={btnLoading}><i className='fa fa-check'></i>&emsp;
						{btnLoading ? 'Please wait..' : 'Update'}</button>
				</Form>
			</div>
		</>
	);
};
export default EditSaless; 