import Home from "../pages/home";
import MyHome from "../pages/home/myHome";
import AddStaff from "../pages/staffManagement/addStaff";
import staffManagement from "../pages/staffManagement/staffManagement";
import AttendanceReport from "../pages/staffManagement/reportPage";
import StaffAttendance from "../pages/staffManagement/staffAttendance";
import EditStaffAttendance from "../pages/staffManagement/editStaffAttendance";
import EditAttendance from "../pages/staffManagement/editAttendance";
import AddGroup from "../pages/groupManagement/addGroup";
import GroupManagement from "../pages/groupManagement/groupManagement";
import GroupSettings from "../pages/groupManagement/groupSettings";
import GroupMeeting from "../pages/groupManagement/groupMeeting";
import EventManagement from "../pages/eventManagement/eventManagement";
import EventReport from "../pages/eventManagement/eventReport";
import EventReportDate from "../pages/eventManagement/eventReportDate";
import EventType from "../pages/eventManagement/eventType";
import EventList from "../pages/eventManagement/eventList";
import ViewEventList from "../pages/eventManagement/viewEventList";
import Vendor from "../pages/vendor/vendor";
import AddVendor from "../pages/vendor/addVendor";
import AddTrack from "../pages/staffManagement/addTrack";
import AddEvent from "../pages/eventManagement/addEvent";
import Announcement from "../pages/manage/announcements";
import AdminPanel from "../pages/setting/adminpanel";
import UpdateCompany from "../pages/company/updateCompany";
import PlasticType from "../pages/plasticManagement/plasticType/plasticType";
import AddType from "../pages/plasticManagement/plasticType/addType";
import PlasticPriceSetup from "../pages/plasticManagement/plasticPrice/plasticPrice";
import PlasticPriceChart from "../pages/plasticManagement/plasticChart";
import PlasticCurrentStock from "../pages/plasticManagement/plasticStock";
import PlasticSales from "../pages/plasticManagement/plasticSales";
import PlasticSalesReport from "../pages/plasticManagement/plasticSalesReport";
import PlasticCollectionSourceReport from "../pages/plasticManagement/plasticCollectionSourceReport";
import PlasticSalesGroupWiseReport from "../pages/plasticManagement/plasticSalesGroupWise";
import MaterialCollection from "../pages/plasticManagement/materialCollection";
import MaterialCollectionReport from "../pages/plasticManagement/materialCollectionReport";
import TargetSetup from "../pages/plasticManagement/targetSetup";
import CollectionReportStaffWise from "../pages/plasticManagement/collectionReportStaffWise";
import userManagement from "../pages/userManagement/userManagement";
import AccountingYear from "../pages/setting/accountingyear";
import GroupUserManagement from "../pages/userManagement/addUser";
import AccountsLedgerHead from "../pages/ledgerhead";
import BankAccounts from "../pages/bank";
import Payments from "../pages/payments";
import FinancialSummary from "../pages/finance";
import FinancialStatement from "../pages/finance/financialStatement";
import AddVillage from "../pages/setting/addVillage";
import AddHouse from "../pages/house/addHouse";
import EditHouse from "../pages/house/editHouse";
import Indicators from "../pages/house/indicators";
import HouseManagement from "../pages/house/houseManagement";
import IndicatorReport from "../pages/house/indicatorReport";
import LeaveEntry from "../pages/setting/leaveEntry";
import AddLeave from "../pages/setting/addLeave";
import GroupMeetingReport from "../pages/groupManagement/groupMeetingReport";
import AddGroupMeetingReport from "../pages/groupManagement/addGroupMeetingReport";
import EditGroupMeetingReport from "../pages/groupManagement/editGroupMeetingReport";
import HbcReport from "../pages/reportPage/hbcReport";
import QrlReport from "../pages/reportPage/qrlReport";
import HbcgSurvey from "../pages/reportPage/hbcgSurvey";
import financialStatementPb from "../pages/finance/financialStatementPb";
import financialSummaryPb from "../pages/finance/financialSummaryPb";
import HBCOutcome from "../pages/house/HBCOutcome";
import AllHBCOutcome from "../pages/house/allHBCOutcome";

const pages = [
  { name: "Dashboard", path: "/home", component: MyHome },
  { name: "Dashboard", path: "/myhome", component: MyHome },
  { name: "Add House", path: "/addhouse", component: AddHouse },
  { name: "Staff Management", path: "/staff", component: staffManagement },
  { name: "Group Management", path: "/group", component: GroupManagement },
  { name: "Group Settings", path: "/groupsettings", component: GroupSettings },
  { name: "Group Meeting", path: "/staff-report", component: GroupMeeting },
  {
    name: "Group User Management",
    path: "/groupusermanagement",
    component: GroupUserManagement,
  },

  { name: "Event Management", path: "/event", component: EventManagement },
  { name: "Event Report", path: "/eventreport", component: EventReport },
  {
    name: "EventReportDate",
    path: "/eventreportDate",
    component: EventReportDate,
  },
  { name: "EventType", path: "/eventtype", component: EventType },
  { name: "EventList", path: "/eventlist", component: EventList },
  { name: "ViewEventList", path: "/vieweventlist", component: ViewEventList },

  { name: "Vendor", path: "/vendor", component: Vendor },
  { name: "Add Vendor", path: "/addvendor", component: AddVendor },
  { name: "Add Event", path: "/addevent", component: AddEvent },
  { name: "Add Staff", path: "/addstaff", component: AddStaff },
  { name: "Add Track", path: "/addtrack", component: AddTrack },
  { name: "Add Group", path: "/addgroup", component: AddGroup },
  { name: "Announcement", path: "/announcement", component: Announcement },
  { name: "Admin Panel", path: "/adminpanel", component: AdminPanel },
  {
    name: "Update Company",
    path: "/settings/update",
    component: UpdateCompany,
  },
  {
    name: "Plastic Type Management",
    path: "/typemanagement",
    component: PlasticType,
  },
  { name: "Add Type", path: "/addtype", component: AddType },
  {
    name: "Plastic Price Setup",
    path: "/pricesetup",
    component: PlasticPriceSetup,
  },
  {
    name: "Plastic Price Chart",
    path: "/pricechart",
    component: PlasticPriceChart,
  },
  {
    name: "Plastic Current Stock",
    path: "/currentstock",
    component: PlasticCurrentStock,
  },
  { name: "Plastic Sales", path: "/plasticsales", component: PlasticSales },
  {
    name: "Plastic Sales Report",
    path: "/salesreport",
    component: PlasticSalesReport,
  },
  {
    name: "Plastic Collection Source Report",
    path: "/srcreport",
    component: PlasticCollectionSourceReport,
  },
  {
    name: "Plastic Sales Group Wise Report",
    path: "/salesgroupwise",
    component: PlasticSalesGroupWiseReport,
  },
  { name: "Target Setup", path: "/target-setup", component: TargetSetup },
  {
    name: "Staff Wise Collection Report",
    path: "/staffwise-report",
    component: CollectionReportStaffWise,
  },
  {
    name: "Attendance Report",
    path: "/attendance-report",
    component: AttendanceReport,
  },
  { name: "Staff Attendance", path: "/attendance", component: StaffAttendance },
  {
    name: "Edit Staff Attendance",
    path: "/editstaffattendance",
    component: EditStaffAttendance,
  },
  {
    name: "Edit  Attendance",
    path: "/editattendance",
    component: EditAttendance,
  },

  {
    name: "Material Collection",
    path: "/material-collection",
    component: MaterialCollection,
  },
  {
    name: "Material Collection Report",
    path: "/material-collection-report",
    component: MaterialCollectionReport,
  },
  { name: "User Management", path: "/users", component: userManagement },
  {
    name: "Accounting Year",
    path: "/accountingyear",
    component: AccountingYear,
  },

  { name: "Payments", path: "/payments", component: Payments },
  {
    name: "Accounts Ledger Head Year",
    path: "/ledgerhead",
    component: AccountsLedgerHead,
  },
  { name: "Bank Accounts", path: "/bankaccounts", component: BankAccounts },
  {
    name: "Financial Summary",
    path: "/financial-statement",
    component: FinancialStatement,
  },
  {
    name: "Financial Statement",
    path: "/financial-summary",
    component: FinancialSummary,
  },

  {
    name: "Plastic Bank Financial Summary",
    path: "/plastic-bank-financial-summary",
    component: financialSummaryPb,
  },
  {
    name: "Plastic Bank Financial Statement",
    path: "/plastic-bank-financial-statement",
    component: financialStatementPb,
  },

  { name: "Add Village", path: "/addvillage", component: AddVillage },
  { name: "House Management", path: "/house", component: HouseManagement },
  { name: "Edit House", path: "/edithouse", component: EditHouse },
  { name: "Indicators", path: "/indicators", component: Indicators },
  {
    name: "Indicator Report",
    path: "/indicatorreport",
    component: IndicatorReport,
  },
  {
    name: "Indicator Report",
    path: "/indicatorreport",
    component: IndicatorReport,
  },
  { name: "Leave Entry", path: "/leaveentry", component: LeaveEntry },
  { name: "Add Leave", path: "/addleave", component: AddLeave },
  {
    name: "Group Meeting Report",
    path: "/groupmeetingreport",
    component: GroupMeetingReport,
  },
  {
    name: "Add Group Meeting Report",
    path: "/addgroupmeetingreport",
    component: AddGroupMeetingReport,
  },
  {
    name: "Edit Group Meeting Report",
    path: "/editgroupmeetingreport",
    component: EditGroupMeetingReport,
  },

  { name: "HBCG Report", path: "/hbcg-report", component: HbcReport },
  { name: "HBCG Question Report", path: "/qrl-report", component: QrlReport },
  { name: "HBCG Survey", path: "/hbcg-survey", component: HbcgSurvey },
  { name: "HBC Outcome", path: "/hbcoutcome", component: HBCOutcome },
  { name: "HBC Outcome ", path: "/allhbcoutcome", component: AllHBCOutcome },
];
export default pages;
