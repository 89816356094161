import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Modal,
  Form,
  Table,
  Navbar,
  Nav,
  Container,
  Button,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { MDBInput } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import API from "../../utils/api";
import $ from "jquery";
import PsContext from "../../context";
import { PSListItem } from "../../comp";
import {
  integerIndMobile,
  lettersOnly,
  integerIndGst,
  integerKeyPress,
} from "../../utils";
import { baseUrl } from "../../utils";
import axios from "axios";
import { Rating } from "react-simple-star-rating";

const EditStar1 = (props) => {
  const [data, changeData] = useState([]);
  const [ratingResponse, setRatingResponse] = useState([]);
  const [questionData, setQuestionData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");

  const handleRating = (rate, itemId) => {
    try {
      var form = new FormData();
      form.append("id", itemId);
      form.append("rating", rate);
      API.post("v1/group/editratingscore2", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Score Updated", "success");
        }
      });
    } catch (error) {}
  };

  const handleRating2 = (rate, itemId) => {
    try {
      var form = new FormData();
      form.append("id", itemId);
      form.append("isyes", rate);
      API.post("v1/group/editratingscore2", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Score Updated", "success");
        }
      });
    } catch (error) {}
  };

  const handleValue = (rate, indecator) => {
    console.log("rate" + rate + " indecator " + indecator);

    var newResponse = ratingResponse;

    newResponse.forEach((item) => {
      console.log("loop item", item.indicator, indecator);
      if (item.indicator == indecator) {
        item.rating = rate;
        // console.log("current item", item);
      }
      return item;
    });
    console.log("res", newResponse);
    setRatingResponse(newResponse);
  };

  const handleValue2 = (rate, indecator) => {
    console.log("rate" + rate + " indecator " + indecator);

    var newResponse = ratingResponse;

    newResponse.forEach((item) => {
      console.log("loop item", item.indicator, indecator);
      if (item.indicator == indecator) {
        item.isyes = rate;
        // console.log("current item", item);
      }
      return item;
    });
    console.log("res", newResponse);
    setRatingResponse(newResponse);
  };

  const getLastValue = (itemid, lab) => {
    var d = questionData;
    console.log("question dddd data is" + itemid + " label is " + lab);
    console.log(d);
    if (lab === "rate") return 0;
    else if (lab === "isyes") return 1;
    else return 2;
  };

  const handleSubmit = () => {
    try {
      console.log(ratingResponse);
      var form = new FormData();
      form.append("house_id", props.user.id);
      form.append("value", JSON.stringify(ratingResponse));
      API.post("v1/group/addquestionrateing2", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Score Added", "success");
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    listdata();
    const rRes = [
      { indicator: 6, isyes: -1, rating: 0 },
      { indicator: 7, isyes: -1, rating: 0 },
      { indicator: 8, isyes: -1, rating: 0 },
      { indicator: 9, isyes: -1, rating: 0 },
      { indicator: 10, isyes: -1, rating: 0 },
      { indicator: 11, isyes: -1, rating: 0 },
    ];
    setRatingResponse(rRes);
  }, []);

  const listdata = () => {
    try {
      API.get("v1/group/listindicator").then((res) => {
        if (res.data.data2) {
          var d = res.data.data2;
          changeData(d);
        } else {
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    listQuestionReport(props.user.id);
  }, [props.user]);

  const listQuestionReport = (id) => {
    try {
      setIsLoading(true);
      API.get("v1/group/listquestionsdata2?hid=" + id).then((res) => {
        if (res["data"].status == "1") {
          var d = res.data.data;
          console.log("data", res["data"].data);
          setQuestionData(d);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  console.log("questions Data", questionData);
  console.log(props.user);
  console.log(props.data);
  return (
    <>
      <h5>Family Details </h5>
      <Table bordered responsive className="table">
        <tbody>
          <tr>
            <th>Name</th>
            <td>{props.user.fam_head_name}</td>
            <th>Mobile</th>
            <td>{props.user.mobile}</td>
          </tr>
          <tr>
            <th>Block Name</th>
            <td>{props.user.bname}</td>
            <th>Village Name</th>
            <td>{props.user.vname}</td>
          </tr>
        </tbody>
      </Table>
      <h5>Indicators Rating Score :</h5>
      {questionData.length > 0 ? (
        <Table bordered responsive className="table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Indicators</th>
              <th>Rating</th>
            </tr>
          </thead>
          <tbody>
            {questionData.map((item, i) => {
              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>{item.indicators}</td>
                  <td>
                    <Row>
                      <Col md="4">
                        <Form.Control
                          as="select"
                          size="sm"
                          className="fw-bold form-select form-select-sm"
                          onChange={(e) =>
                            handleRating2(e.target.value, item.id)
                          }
                          defaultValue={item.isyes}
                        >
                          <option value="-1">Select</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </Form.Control>
                      </Col>
                      <Col md="4">
                        <Form.Control
                          type="text"
                          size="sm"
                          className="fw-bold"
                          defaultValue={item.rateing}
                          onChange={(e) =>
                            handleRating(e.target.value, item.id)
                          }
                        />
                      </Col>
                    </Row>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <Table bordered responsive className="table">
          <thead>
            <tr>
              <th>#S.No</th>
              <th>Indicators</th>
              <th>Rating</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => {
              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>{item.indicators}</td>
                  <td>
                    <Row>
                      <Col md="4">
                        <Form.Control
                          as="select"
                          size="sm"
                          className="fw-bold form-select form-select-sm"
                          onChange={(e) =>
                            handleValue2(e.target.value, item.id)
                          }
                          defaultValue={() => getLastValue(item.id, "isyes")}
                        >
                          <option value="-1">Select</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </Form.Control>
                      </Col>
                      <Col md="4">
                        <MDBInput
                          type="number"
                          name={"tt" + i}
                          size="sm"
                          required
                          onKeyPress={integerKeyPress}
                          onChange={(e) => handleValue(e.target.value, item.id)}
                          defaultValue={() => getLastValue(item.id, "rate")}
                        />
                      </Col>
                    </Row>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan="3">
                <Button
                  type="button"
                  size="sm"
                  className="mt-1"
                  style={{ float: "right" }}
                  variant="success"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};
export default EditStar1;
