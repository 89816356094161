import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { Link } from 'react-router-dom';


const EventList = () => {

	const context = useContext(PsContext);
	const [AddUser, setAddUser] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [ViewData, setViewData] = useState(false);
	const [user, setUser] = useState([]);
	const [view, setView] = useState([]);
	const [data, setData] = useState([]);
	const [stData, stChangeData] = useState([]);
	const [searchData, setSearchData] = useState([]);
	const [loading, setIsLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);

	useEffect(() => {
		listdata();
	}, []);

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/event/listeventtype').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setData(d);
					setSearchData(d);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const viewModal = (item, id) => {
		//alert('hi');
	}

	const getAchivedEvents = () => {

		let total = 0;
		searchData.map(item => total = parseFloat(total) + parseFloat(item.event_achived));
		return parseFloat(total).toFixed(0);
	};

	const getTargetTotal = () => {
		let total = 0;
		searchData.map(item => total = parseFloat(total) + parseFloat(item.noofevent));
		return parseFloat(total).toFixed(0);
	};

	const getPendingTotal = () => {
		let total = 0;
		searchData.map(item => total = parseFloat(total) + (parseFloat(item.noofevent) - parseFloat(item.event_achived)));
		return parseFloat(total).toFixed(0);
	};

	return (
		<>

			<div className="container" >
				<Navbar bg="white" expand="lg">
					<Container>
						<Navbar.Brand >Events Summary - ({searchData.length})</Navbar.Brand>
						<Nav className="justify-content-end">
						</Nav>
					</Container>
				</Navbar>
				<Card>
					<Card.Body>
						<Row>
							<Col md={12}>
								<Table bordered className="mt-10">
									<thead className="text-center">
										<tr>
											<th width="5%">S.No</th>
											<th width="10%"><b>Name</b></th>
											<th width="10%"><b>Target Events<br />({getTargetTotal()})</b></th>
											<th width="10%"><b>Achieved <br />({getAchivedEvents()})</b></th>
											<th width="10%"><b>Target of Participants </b></th>
											<th width="10%"><b>Achieved</b></th>
											<th width="10%"><b>Pending Events <br />({getPendingTotal()})</b></th>
											<th width="10%"><b>Details</b></th>
										</tr>
									</thead>
									<tbody className="text-center">
										{(searchData.map((item, id) => {
											let d = searchData.length;
											return <tr>
												<td>{id +1}</td>
												<td align="left">{item.ename}</td>
												<td>{item.noofevent}</td>
												<td>{item.event_achived}{' '}
													({(100 * item.event_achived / item.noofevent).toFixed(1)}%)
												</td>
												<td>{item.noofevent * item.pname} ({item.pname})</td>
												<b><td style={{ color: (item.achived1 > item.event_achived * item.pname || item.achived1 == item.event_achived * item.pname ? 'green' : 'red') }}>{item.event_achived > 0 && item.noofevent > 0 ? (item.achived1) + ' / ' + (item.event_achived * item.pname) : '0'}</td></b>
												<td>{item.noofevent - item.event_achived < 0 ? '0' : item.noofevent - item.event_achived}</td>
												<td><Button variant='white' onClick={() => viewModal(item, item.id)}><Link to={{
													pathname: '/vieweventlist',
													state: { eventtypeid: item.id }
												}}>View</Link></Button></td>
											</tr>
										}))}
									</tbody>
								</Table>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</div>
		</>
	);
};
export default EventList;