import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Modal,
  Form,
  Navbar,
  Container,
  Nav,
  Button,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { MDBInput } from "mdb-react-ui-kit";
import API from "../../utils/api";
import $ from "jquery";
import { Link } from "react-router-dom";
import PsContext from "../../context";
import { PSListItem } from "../../comp";
import { lettersOnly, integerIndMobile, decimalKeyPress } from "../../utils";
import { baseUrl } from "../../utils";
import axios from "axios";
import Select from "react-select";

const AddGroup = (props) => {
  const context = useContext(PsContext);
  const [submit, sethandleSubmit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, changeData] = useState([]);
  const [block, setBlock] = useState([]);
  const [villageData, setVillageData] = useState([]);
  const [villageName, setVillageName] = useState([]);
  const [dob, setDob] = useState("");

  useEffect(() => {
    var date =
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      (new Date().getDate() - 1);
    setDob(date);
    listdata();
    listVillage();
    listBlock();
  }, []);

  const listdata = () => {
    try {
      API.get("v1/user/list").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
        } else {
        }
      });
    } catch (error) {}
  };

  const listVillage = () => {
    try {
      API.get("v1/group/village").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setVillageData(d);
        } else {
        }
      });
    } catch (error) {}
  };

  const listBlock = () => {
    try {
      API.get("v1/group/block").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setBlock(d);
        } else {
        }
      });
    } catch (error) {}
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      console.log($("#addform").serialize());
      API.post("v1/group/addgroup", $("#addform").serialize()).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Group Added Successfully..!", "success");
          document.getElementById("addform").reset();
          setBtnLoading(false);
          const vrl = baseUrl + "/v1/log/addlog";
          let formData = new FormData();
          formData.append("createon", "2021/12/10"); //append the values with key, value pair
          formData.append("context", "Add Group");
          formData.append("e_type", "User_log");
          formData.append("action_by", "admin");
          formData.append("addby", 1);
          formData.append("addip", "localhost");

          var config = {
            method: "post",
            url: vrl,
            data: formData,
          };

          axios(config)
            .then(function (response) {
              console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          toast.error(res["data"].message, "danger");
          setBtnLoading(false);
        }
        setIsLoading(false);
      });
    } catch (error) {}
  };

  const getVillage = (e) => {
    if (e.target.value != "") {
      var d = villageData.filter(function (item, i) {
        return item.block_id == e.target.value;
      });
      setVillageName(d);
    }
  };

  return (
    <>
      <div className="container">
        <Navbar bg="white" expand="lg">
          <Container>
            <Navbar.Brand>Add Groups</Navbar.Brand>
            <Nav className="justify-content-end">
              <Link
                to={{
                  pathname: "/group",
                }}
              >
                Manage
              </Link>
            </Nav>
          </Container>
        </Navbar>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <Form
                  action=""
                  method="post"
                  id="addform"
                  validated={validated}
                  onSubmit={handleSubmit}
                >
                  <Row>
                    <Col md={6}>
                      <Row className="mt-3">
                        <Col md={12}>
                          <MDBInput
                            label="Group Name"
                            type="text"
                            name="grp_name"
                            size="sm"
                            onKeyPress={lettersOnly}
                          />
                        </Col>
                      </Row>

                      {/*<Row className="mt-3">
												<Col md={12}>
													<select className="form-control form-control-sm" name="app_access1" id="app_access1">
														<option value="">App Access 1</option>
														<option value="yes">Yes</option>
														<option value="no">No</option>
													</select>
												</Col>
						</Row>*/}
                      <Row className="mt-3">
                        <Col md={12}>
                          <MDBInput
                            label="President Name"
                            type="text"
                            name="pre_name"
                            size="sm"
                            onKeyPress={lettersOnly}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={12}>
                          <MDBInput
                            label="Secretary Name"
                            type="text"
                            name="sec_name"
                            size="sm"
                            onKeyPress={lettersOnly}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={12}>
                          <MDBInput
                            label="Treasurer Name"
                            type="text"
                            name="trusure_name"
                            size="sm"
                            onKeyPress={lettersOnly}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={12}>
                          <MDBInput
                            label="Bank Name"
                            type="text"
                            name="bank_name"
                            size="sm"
                            onKeyPress={lettersOnly}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={4}>
                          <label>APP Access:</label>
                        </Col>
                        <Col md={8}>
                          <div>
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                name="app_access1"
                                value="Yes"
                                className="form-check-input"
                                required
                              />
                              <label>Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                name="app_access1"
                                value="No"
                                className="form-check-input"
                                required
                              />
                              <label>No</label>
                            </div>
                          </div>
                          {/*<select className="form-control form-control-sm" name="app_access2" id="app_access2">
														<option value="">App Accesss 2</option>
														<option value="yes">Yes</option>
														<option value="no">No</option>
													</select>*/}
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={4}>
                          <label>Plastic Bank Activate:</label>
                        </Col>
                        <Col md={8}>
                          <div>
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                name="bank_activate"
                                value="Yes"
                                className="form-check-input"
                                required
                              />
                              <label>Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                name="bank_activate"
                                value="No"
                                className="form-check-input"
                                required
                              />
                              <label>No</label>
                            </div>
                          </div>
                          {/*<select className="form-control form-control-sm" name="app_access2" id="app_access2">
														<option value="">App Accesss 2</option>
														<option value="yes">Yes</option>
														<option value="no">No</option>
													</select>*/}
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={2} className="pt-1">
                          <label>Block :</label>
                        </Col>
                        <Col md={10}>
                          <select
                            className="form-control"
                            name="block"
                            size="sm"
                            onChange={getVillage}
                            required
                          >
                            <option value="">Select Block</option>
                            {block.map((user, i) => {
                              return (
                                <option value={user.id}>{user.block}</option>
                              );
                            })}
                          </select>
                        </Col>
                      </Row>

                      {/*<Row className="mt-3">
												<Col md={12}>
													{/*<select className="form-control form-control-sm" name="incharge1" id="incharge1" required>
														<option value="">Incharge Person 1</option>
														{data.map((user, i) => {
														return <option value={user.id}>{user.name}</option>
														})}
													</select>
													<MDBInput
														label="Incharge Person 1"
														type="text"
														name="incharge1"
														size="sm"
														onKeyPress={lettersOnly}
														required
													/>
												</Col>
											</Row>
											<Row className="mt-3">
												<Col md={12}>
													{/*<select className="form-control form-control-sm" name="incharge2" id="incharge2" required>
														<option value="">Incharge Person 2</option>
														{data.map((user, i) => {
														return <option value={user.id}>{user.name}</option>
														})}
													</select>
													<MDBInput
														label="Incharge Person 2"
														type="text"
														name="incharge2"
														size="sm"
														onKeyPress={lettersOnly}
														required
													/>
												</Col>
											</Row>*/}
                    </Col>
                    <Col md={6}>
                      <Row className="mt-3">
                        <Col md={12}>
                          <MDBInput
                            label="Total Members"
                            type="number"
                            name="total_members"
                            size="sm"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={12}>
                          <MDBInput
                            label="Date of Etd"
                            type="date"
                            name="date_of_start"
                            size="sm"
                            required
                            defaultValue={dob}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md={12}>
                          <MDBInput
                            label="President Mobile"
                            type="text"
                            name="pre_mobile"
                            size="sm"
                            onKeyPress={integerIndMobile}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={12}>
                          <MDBInput
                            label="Secretary  Mobile"
                            type="text"
                            name="sec_mobile"
                            size="sm"
                            onKeyPress={integerIndMobile}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={12}>
                          <MDBInput
                            label="Treasurer Mobile"
                            type="text"
                            name="trusure_mobile"
                            size="sm"
                            onKeyPress={integerIndMobile}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={12}>
                          <MDBInput
                            label="Bank Account No"
                            type="text"
                            name="bank_ac_no"
                            size="sm"
                            onKeyPress={decimalKeyPress}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md={12}>
                          <MDBInput
                            label="Address"
                            type="text"
                            name="address"
                            size="md"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={2} className="pt-1">
                          <label>Village :</label>
                        </Col>
                        <Col md={10}>
                          <select
                            className="form-control"
                            name="village"
                            size="sm"
                            required
                          >
                            <option value="" draggable>
                              Select Village
                            </option>
                            {villageName.map((user, i) => {
                              return (
                                <option value={user.id}>
                                  {user.villagename}
                                </option>
                              );
                            })}
                          </select>
                        </Col>
                      </Row>

                      {/*<Row className="mt-3">
												<Col md={12}>
													<MDBInput
														label="Mobile Number"
														type="text"
														name="mobileno1"
														size="sm"
														required
														onKeyPress={integerIndMobile}
													/>
												</Col>
											</Row>
											<Row className="mt-3">
												<Col md={12}>
													<MDBInput
														label="Mobile Number"
														type="text"
														name="mobileno2"
														size="sm"
														required
														onKeyPress={integerIndMobile}
													/>
												</Col>
										</Row>*/}
                    </Col>
                    <Col md={6}></Col>
                    <Col md={6}></Col>
                    {/*<Col md={5} className="mt-3">
											<MDBInput
												label="Address"
												type="text"
												name="address"
												size="md"
											/>
										</Col>
										<Col md={5} className="mt-3">
											<select className="form-control" name="village" size="sm">
												<option value="">Select Village</option>
												{villageData.map((user, i) => {
													return <option value={user.id}>{user.villagename}</option>
												})}
											</select>
											</Col>&nbsp;*/}
                    <Button
                      type="submit"
                      variant="success"
                      className="mt-2"
                      size="md"
                      block
                    >
                      Add
                    </Button>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AddGroup;
