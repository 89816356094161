import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col,  Modal , Form, Navbar,Container,Nav, Button, Table} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { lettersOnly,integerIndMobile, GOOGLE_API_KEY } from '../../utils';

const tdStyle = {border : '1px solid grey', borderCollapse : 'collapse', padding : '3px'}

const Location = (props) =>{
	
	const { values } = props;
	
	return(
		<>
			<Row>
				<Col md={6}>
					<h3>IN</h3>
					<iframe
						src={`https://maps.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${values.lat},${values.long}`}
					>
					
					</iframe>
				</Col>
				<Col md={6}>
					<h3>OUT</h3>
					<a href="#"></a>
				</Col>
			</Row>
		</>
	);
};
export default Location;