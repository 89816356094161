import React, { useState, useContext } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Navbar,
  Container,
  Nav,
} from "react-bootstrap";
import PsContext from "../../context";

const Layout = (props) => {
  const context = useContext(PsContext);

  const getPageTitle = () => {
    return props.page == "home"
      ? "Pro-Vision"
      : props.page == "group_accounts"
      ? "Group Accounts"
      : props.page == "plastic_bank_accounts"
      ? "Plastic Bank Accounts"
      : "Pro-Vision";
  };
  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />
      <div className="px-3 w-100">
        <Navbar bg="dark" fixed="top">
          <div className="px-3 w-100">
            <Navbar.Brand className="text-white">{getPageTitle()}</Navbar.Brand>
            {props.page != "home" && (
              <a
                style={{
                  position: "absolute",
                  top: "7px",
                  right: "20px",
                  color: "#fff",
                  fontSize: "20px",
                }}
                onClick={(e) => props.onAction("home")}
              >
                <i className="fa fa-home"></i>
              </a>
            )}
          </div>
        </Navbar>
      </div>

      <div className="container bg-transparent" style={{ marginTop: "70px" }}>
        {props.children}
      </div>
    </>
  );
};
export default Layout;
