import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col,  Modal , Form} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { integerIndMobile, lettersOnly, integerIndGst } from '../../utils';
import {baseUrl} from '../../utils';
import axios from 'axios';

const EditVendor = (props) =>{
	
	const context = useContext(PsContext);
	const [submit, sethandleSubmit] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [data, changeData] = useState([]);
	
	const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		if (!form.checkValidity() === true) {
		  event.stopPropagation();
		  setValidated(true);
		  return;
		}
		try{
			setBtnLoading(true);
			API.post('v1/vendor/editvendor',$("#editform").serialize()).then(res=>{
				if (res['data'].status == '1') {
				  toast.success("Vendor Updated Successfully..!", "success");
				  document.getElementById("editform").reset();
				  setBtnLoading(false);
				  props.afterFinish();
				  props.afterList();
				  const vrl=baseUrl+'/v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Add Vendor');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
					  method: 'post',
					  url: vrl,
					  data : formData
					};

					axios(config)
					.then(function (response) {
					  console.log(JSON.stringify(response.data));
					})
					.catch(function (error) {
					  console.log(error);
					});	
				}
				else {
				  toast.error("Something Went Wrong..!", "danger");
				  document.getElementById("editform").reset();
				}
				setIsLoading(false);
			});
		}
		catch(error){
			toast.error("Something Went Wrong..!", "danger");
		}
	};
	

	return(
		<>
		<div class="container">
			<Form action="" method="post" id="editform" noValidate validated={validated} onSubmit={handleSubmit}>
			<input type="hidden" name="id" value={props.data.id} />
					<Row>
						<Col md={12}>
							<MDBInput
								label="Name"
								type="text"
								name="customername"
								size="lg"
								required
								defaultValue={props.data.customername}
								onKeyPress={lettersOnly}
							/>
						</Col>
						
						<Col md={12} className="mt-3">
							<MDBInput
								label="DOB"
								type="date"
								name="dob"
								size="lg"
								defaultValue={props.data.dob}
							/>
						</Col>
						
						<Col md={12} className="mt-3">
							<MDBInput
								label="Mobile"
								type="text"
								name="mobile"
								size="lg"
								required
								defaultValue={props.data.mobile}
								onKeyPress={integerIndMobile}
							/>
						</Col>
						
						<Col md={12} className="mt-3">
							<MDBInput
								label="Email"
								type="email"
								name="email"
								size="lg"
								defaultValue={props.data.email}
							/>
						</Col>
						
						<Col md={12} className="mt-3">
							<MDBInput
								label="Address"
								type="text"
								name="address"
								size="lg"
								defaultValue={props.data.address}
								onKeyPress={lettersOnly}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="Company Name"
								type="text"
								name="companyname"
								size="lg"
								defaultValue={props.data.companyname}
								onKeyPress={lettersOnly}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="GST"
								type="text"
								name="gst"
								size="lg"
								defaultValue={props.data.gst}
								onKeyPress={integerIndGst}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="PAN"
								type="text"
								name="pan"
								size="lg"
								minLength="10"
								maxLength="10"
								defaultValue={props.data.pan}
							/>
						</Col>
					</Row>
					
                <Col className="mt-3">
					<button type="button" class="btn btn-outline-black btn-block"  type="submit" disabled={btnLoading}>
					{btnLoading ? 'Please wait..' : 'Update'}</button>
				</Col>
			
			</Form>
			</div>
		</>
	);
};

export default EditVendor;