import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { lettersOnly, integerIndMobile } from '../../utils';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const tdStyle = { border: '1px solid grey', borderCollapse: 'collapse', padding: '3px' }
//const tdStyle = { border: '1px solid grey', borderCollapse: 'collapse', padding: '3px', width: '100%', height: }

const PlasticCurrentStock = (props) => {

	const context = useContext(PsContext);
	const [loading, setIsLoading] = useState(false);
	const [groupData, setGroupData] = useState([]);
	const [plasticData, setPlasticData] = useState([]);
	const [stockData, setStockData] = useState([]);
	const [emptyData, setEmptyData] = useState([]);
	const [countData, setCountData] = useState([]);
	const [valueData, setValueData] = useState({});
	const [materialType, setMaterialType] = useState([]);
	console.log(countData);
	const printDocument = () => {
		var content = document.getElementById("printdata");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	console.log(groupData);
	useEffect(() => {
		listgroupname();
		//listplastic();
		liststock();
	}, []);

	const listplastic = (event) => {
		event.preventDefault();
		try {
			API.post('v1/plastic/listcurrentplastic', $("#filer_Date").serialize()).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setPlasticData(d);
				}
				else {
					toast.error('No Data Found', "danger");
				}
			});
		}
		catch (error) {

		}
	};

	// const listplastic = () => {
	// 	try {
	// 		setIsLoading(true);
	// 		var form = new FormData();
	// 		form.append('acyear', context.acyear.id);
	// 		API.post('v1/plastic/listplastic', form).then(res => {
	// 			if (res['data'].status == '1') {
	// 				var d = res['data'].data;
	// 				var d1 = d.filter(function (obj) {
	// 					return obj.material_type == 'plastic';
	// 				});
	// 				d1.forEach(element => {
	// 					element.order = 1;
	// 				});
	// 				var d2 = d.filter(function (obj) {
	// 					return obj.material_type == 'others' && obj.weight == 'kg';
	// 				});
	// 				d2.forEach(element => {
	// 					element.order = 2;
	// 				});
	// 				var d3 = d.filter(function (obj) {
	// 					return obj.material_type == 'others' && obj.weight == 'pcs';
	// 				});
	// 				d3.forEach(element => {
	// 					element.order = 3;
	// 				});
	// 				var value = d1.concat(d2, d3);

	// 				setPlasticData(value);
	// 				setCountData({
	// 					plastic: d1.length,
	// 					nonplastickg: d2.length,
	// 					nonplasticpiece: d3.length
	// 				});
	// 				setValueData({
	// 					plastic: d1[0].material_type,
	// 					nonplastickg: d2[0].material_type && d2[0].weight,
	// 					nonplasticpiece: d3[0].material_type && d3[0].weight
	// 				});
	// 				setIsLoading(false);
	// 			}
	// 			else {
	// 				setIsLoading(false);
	// 			}
	// 		});
	// 	}
	// 	catch (error) {
	// 		setIsLoading(false);
	// 	}
	// };

	const listgroupname = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listgroupplastic').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setGroupData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const liststock = () => {
		try {
			setIsLoading(true);
			var form = new FormData();
			form.append('acyear', context.acyear.id);
			API.post('v1/plastic/liststock', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setStockData(d);
					console.log(d);
					setIsLoading(false);
				}
				else {
					var f = res['data'].status;
					setEmptyData(f);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const getStock = (grpid, pid) => {
		var d = stockData;
		var stock = 0;
		var index = d.findIndex(function (obj) {
			return (obj.group_id == grpid && obj.plastic_id == pid);
		});
		if (index > -1) {
			stock = d[index].stock;
		}
		return parseFloat(stock).toFixed(2);
	}

	const getSales = (grpid, pid) => {
		var d = stockData;
		var sales = 0;
		var index = d.findIndex(function (obj) {
			return (obj.group_id == grpid && obj.plastic_id == pid);
		});
		if (index > -1) {
			sales = d[index].sales;
		}
		return parseFloat(sales).toFixed(2);
	}

	const getCollection = (grpid, pid) => {
		var d = stockData;
		var collection = 0;
		var index = d.findIndex(function (obj) {
			return (obj.group_id == grpid && obj.plastic_id == pid);
		});
		if (index > -1) {
			collection = d[index].collection;
		}
		return parseFloat(collection).toFixed(2);
	}

	const getTotalStock = (pid) => {
		var d = stockData;
		var stock = 0;
		d.filter(function (obj) {
			if (obj.plastic_id == pid) {
				stock += parseFloat(obj.stock);
			}
		});
		return stock;
	}

	const getTotalSales = (pid) => {
		var d = stockData;
		var sales = 0;
		d.filter(function (obj) {
			if (obj.plastic_id == pid) {
				sales += parseFloat(obj.sales);
			}
		});
		return sales;
	}

	const getTotalCollection = (pid) => {
		var d = stockData;
		var collection = 0;
		d.filter(function (obj) {
			if (obj.plastic_id == pid) {
				collection += parseFloat(obj.collection);
			}
		});
		return collection;
	}

	console.log(valueData);

	const valueDataChange = (e) => {
		setMaterialType(e);
	}

	return (
		<>
			<div className="container">
				<Navbar bg="white" expand="md">
					<Container>
						<Navbar.Brand >Material Current Stock</Navbar.Brand>
						<Nav>
							<Button type="button" variant="warning" onClick={printDocument}>Print</Button>&nbsp;
							<ReactHTMLTableToExcel
								id="test-table-xls-button"
								className="download-table-xls-button btn btn-info"
								table="table-to-xls"
								filename="Current Stock"
								sheet="currentstock"
								buttonText="Export as XLS" />
						</Nav>
					</Container>
				</Navbar>
				<Row className='mt-2'>
					<Col md={8}>
						<div className='ms-2' style={{ fontSize: '17px' }}>{materialType == 'plastic kg' ? 'Plastic (Kg) :' : materialType == 'others kg' ? 'Non Plastic (Kg) :' : materialType == 'others pcs' ? 'Non Plastic (Piece) :' : ''} </div>
					</Col>
					<Col md={4}>
						<Form action="" method="post" id="filer_Date" onSubmit={listplastic} style={{ padding: '0px 10px' }}>
							<input type="hidden" name="plasticValue" value={JSON.stringify(materialType)} />
							<Row>
								<Col md={8}>
									<select className="form-control form-control-sm" name="material_type" onChange={(e) => { valueDataChange(e.target.value) }} required>
										<option value="">Select</option>
										<option value="plastic kg">Plastic (Kg)</option>
										<option value="others kg">Non Plastic (Kg)</option>
										<option value="others pcs">Non Plastic (Piece)</option>
									</select>
								</Col>
								<Col md={4}>
									<Button type="submit" variant="success" size="sm" style={{ float: 'right' }} >
										Search</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row style={{ margin: '10px' }}>
					<Col md={12} id="printdata">
						{plasticData.length > 0 && materialType.length > 0 && materialType == 'plastic kg' || materialType == 'others kg' || materialType == 'others pcs' ? <div className='table-responsive' style={{height:'450px'}}> <table className="table mt-10 table-bordered" style={tdStyle} id="table-to-xls">
							<thead className="text-center">
								<tr>
									<th width="5%" rowSpan={2} style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '3px', fontSize: '12px',position: 'sticky',
									top: '0',
									backgroundColor: '#E5E4E2' }} ><b>S.No</b></th>
									<th width="10%" rowSpan={2} style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '3px', fontSize: '12px',position: 'sticky',
									top: '0',
									backgroundColor:'#E5E4E2' }} ><b>Group Name</b></th>
									<th width="5%" rowSpan={2} style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '3px', fontSize: '12px',position: 'sticky',
									top: '0',
									backgroundColor: '#E5E4E2' }}><b>Total</b></th>
									{plasticData.map((item, i) => {
										return <th width="10%" style={{ border: '1px 1px 1px 1px solid black', borderCollapse: 'collapse', padding: '3px', fontSize: '10px',position: 'sticky',
										top: '0',
										backgroundColor: '#E5E4E2' }}><b>{item.short_name}<br /> </b>({item.weight})</th>
									})}
								</tr>
								<tr>
									{/* {plasticData.map((item, i) => {
										return <th width="10%" colSpan={item.material_type == 'plastic' ? countData.plastic : item.material_type == 'others' && item.weight == 'kg' ? countData.nonplastickg : item.material_type == 'others' && item.weight == 'pcs' ? countData.nonplasticpiece : ''}>{item.material_type == 'plastic' ? 'Plastic' : item.material_type == 'others' && item.weight == 'kg' ? 'Non Plastic Kg' : item.material_type == 'others' && item.weight == 'pcs' ? 'Non Plastic Pieces' : ''}</th>
								})} 
									<th colSpan={countData.plastic}><strong>PLASTIC MATERIALS</strong></th>
									<th colSpan={countData.nonplastickg}><strong>NON-PLASTIC MATERIALS KG</strong></th>
									<th colSpan={countData.nonplasticpiece}><strong>NON-PLASTIC MATERIALS PIECE</strong></th>*/}
								</tr>
							</thead>
							<tbody className="text-center">
								{groupData.map((item, i) => {
									return <>
										<tr> <td rowspan="3" style={{ border: '1px solid grey', borderCollapse: 'collapse', padding: '3px', fontSize: '12px' }}>{i + 1}</td>
											<td rowspan="3" style={{ border: '1px solid grey', borderCollapse: 'collapse', padding: '3px', fontSize: '12px' }} align="left">{item.grp_name}</td>
											<td style={{ border: '1px solid grey', borderCollapse: 'collapse', padding: '3px', fontSize: '12px' }}>Total Collection</td>
											{plasticData.map((items, j) => {
												return <td >{getCollection(item.id, items.id)}
												</td>
											})}
										</tr>
										<tr>
											<td style={{ border: '1px solid grey', borderCollapse: 'collapse', padding: '3px', fontSize: '12px' }}>Total Sales</td>
											{plasticData.map((items, j) => {
												return <td >{getSales(item.id, items.id)}
												</td>
											})}
										</tr>
										<tr>
											<td style={{ border: '1px solid grey', borderCollapse: 'collapse', padding: '3px', fontSize: '12px' }}>Stock</td>
											{plasticData.map((items, j) => {
												return <td >{getStock(item.id, items.id)}
												</td>
											})}
										</tr>
									</>
								})}
								<tr>
									<td colSpan="3" align="right" style={{ border: '1px solid grey', borderCollapse: 'collapse', padding: '3px', fontSize: '12px' }}><b>Collection Grand Total</b></td>
									{plasticData.map((items, j) => {
										return <td style={tdStyle}><b>{parseFloat(getTotalCollection(items.id)).toFixed(2)}</b></td>
									})}
								</tr>
								<tr>
									<td colSpan="3" align="right" style={{ border: '1px solid grey', borderCollapse: 'collapse', padding: '3px', fontSize: '12px' }}><b>Sales Grand Total</b></td>
									{plasticData.map((items, j) => {
										return <td style={tdStyle}><b>{parseFloat(getTotalSales(items.id)).toFixed(2)}</b></td>
									})}
								</tr>
								<tr>
									<td colSpan="3" align="right" style={{ border: '1px solid grey', borderCollapse: 'collapse', padding: '3px', fontSize: '12px' }}><b>Stock Grand Total</b></td>
									{plasticData.map((items, j) => {
										return <td style={tdStyle}><b>{parseFloat(getTotalStock(items.id)).toFixed(2)}</b></td>
									})}
								</tr>
							</tbody>
						</table></div> : ''}
					</Col>
					<iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
				</Row>
			</div>
		</>
	);
};
export default PlasticCurrentStock;