import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import { integerIndMobile, lettersOnly } from '../../utils';
import { baseUrl } from '../../utils';

const EditEvent = (props) => {

	const context = useContext(PsContext);
	const [submit, sethandleSubmit] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [data, changeData] = useState([]);
	const [types, typeData] = useState([]);

	useEffect(() => {
		listdata();
		listeventtype();
	}, []);

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listgroup').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const listeventtype = () => {
		try {
			setIsLoading(true);
			API.get('v1/event/listtype').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					typeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			setBtnLoading(true);
			API.post('v1/event/editevent', $("#editform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Event Updated Successfully..!", "success");
					document.getElementById("editform").reset();
					const vrl = baseUrl + 'v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Event Edited Successfully');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
						method: 'post',
						url: vrl,
						data: formData
					};
					axios(config)
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
					setBtnLoading(false);
					props.afterFinish();
					props.afterList();
				}
				else {
					toast.error("Something Went Wrong..!", "danger");
					document.getElementById("editform").reset();
				}
				setIsLoading(false);
			});
		}
		catch (error) {
			toast.error("Something Went Wrong..!", "danger");
		}
	};

	const getValue = (field) => {
		return props.edit[field] || '';
	}

	return (
		<>
			<div className="container">
				<Form action="" method="post" id="editform" validated={validated} onSubmit={handleSubmit} >
					<input type="hidden" name="id" value={getValue('id')} />
					<Row>
						<Col md={12} >
							<MDBInput
								label="Event Name"
								type="text"
								name="eventname"
								size="lg"
								required
								defaultValue={getValue('eventname')}
								onKeyPress={lettersOnly}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<select className="form-control" name="type" required>
								<option value="">Type Name</option>
								{types.map((user, i) => {
									return <option value={user.id} selected={user.id == getValue('eventtype') ? true : false
									}> {user.name}</option>
								})}
							</select>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="Event Date"
								type="date"
								name="date"
								size="lg"
								required
								defaultValue={getValue('eventdate')}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="Event Time"
								type="time"
								name="time"
								size="lg"
								required
								defaultValue={getValue('eventtime')}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="Place"
								type="text"
								name="place"
								size="lg"
								defaultValue={getValue('place')}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<select className="form-control" name="group" required>
								<option value="">Group Name</option>
								{data.map((user, i) => {
									return <option value={user.id} selected={user.id == getValue('group_id') ? true : false
									}> {user.grp_name}</option>
								})}
							</select>
						</Col>
						<Col className="mt-3">
							<MDBInput
								label="Organised By"
								type="text"
								name="organiser"
								size="lg"
								defaultValue={getValue('organiser')}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="Organiser Mobile Number"
								type="text"
								name="mobile"
								size="lg"
								onKeyPress={integerIndMobile}
								defaultValue={getValue('mobile')}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="Requirements"
								type="text"
								name="requirements"
								size="lg"
								defaultValue={getValue('requirements')}
							/>
						</Col>
					</Row>
					<Col className="mt-3">
						<button type="button" class="btn btn-outline-black btn-block" type="submit" disabled={btnLoading}>
							{btnLoading ? 'Please wait..' : 'Update'}</button>
					</Col>
				</Form>
			</div >
		</>
	);
};

export default EditEvent;