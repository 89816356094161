import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Switch } from 'antd';
import { baseUrl, integerIndMobile, integerIndGst, imageUrl } from '../../utils';
import ReactFileReader from 'react-file-reader';
import AddLeave from './addLeave';
import EditLeave from './editLeave';
import moment from 'moment';

const LeaveEntry = (props) => {

	const context = useContext(PsContext);
	const [AddUser, setAddUser] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [openNewModel, setOpenNewModel] = useState(false);
	const [ViewData, setViewData] = useState(false);
	const [user, setUser] = useState([]);
	const [data, changeData] = useState([]);
	const [deleteData, setDeleteData] = useState([]);
	const [blockData, setBlockData] = useState([]);
	const [fileName, setFileName] = useState('');
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [loading, setIsLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [fieldValue, setFieldValue] = useState(false);
	const [openEditModel, setOpenEditModel] = useState(false);
	const [edit, setEdit] = useState([]);


	useEffect(() => {
		listdata();
	}, []);

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listleave').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};


	const showModal = () => {
		setOpenNewModel(true);
	}

	const editModal = (item) => {
		setEdit(item);
		setOpenEditModel(true);
	}


	return (
		<div className="container">
			<Modal show={openNewModel} size="md" onHide={() => { setOpenNewModel(false) }} backdrop="static">
				<Modal.Header closeButton >
					<Modal.Title >Add Leave</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AddLeave afterFinish={() => { setOpenNewModel(false) }} afterList={() => listdata()} />
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>

			<Modal show={openEditModel} size="md" onHide={() => { setOpenEditModel(false) }} backdrop="static">
				<Modal.Header closeButton >
					<Modal.Title >Edit Leave</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditLeave data={edit} afterFinish={() => { setOpenEditModel(false) }} afterList={() => listdata()} />
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>

			<Card>
				<Card.Header>
					<Card.Title>Leave Entry List <Button type="button" variant="success" style={{ float: 'right' }} onClick={() => showModal()}><i className='fa fa-plus' />Add</Button></Card.Title>

				</Card.Header>
				<Card.Body>
					<Table bordered>
						<thead >
							<tr>
								<th>S.No</th>
								<th>Date</th>
								<th>Title</th>
								<th>Leave type</th>
								<th >Manage</th>
							</tr>
						</thead>
						<tbody>
							{(data.map((item, i) => {
								return <tr>
									<td>{i + 1}</td>
									<td>{moment(item.leave_date).format('DD-MM-YYYY')}</td>
									<td>{item.description}</td>
									<td>{item.leave_type}</td>
									<td width="13%" align="center"><Button type="button" variant="success" onClick={() => editModal(item)}><i className='fa fa-edit' /></Button>&emsp;
									</td>
								</tr>
							}))}
						</tbody>
					</Table>
				</Card.Body>
			</Card>

		</div >
	);
};
export default LeaveEntry;