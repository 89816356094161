import React, { useContext } from 'react';
import { Switch, Route, withRouter, useHistory } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';

import PsContext from '../../context';
import Header from './header';
import pages from '../../routes/main';
import { Redirect } from 'react-router-dom'; 

const TopHeader = (props) => {

	const context = useContext(PsContext);
	const history = useHistory();

	if (context.adminLogged == 'yes') {

		return (
			<div className="app app-content-full-width app-sidebar-minified" >
				<Toaster
					toastOptions={{
						style: {
							borderRadius: '10px',
							background: '#333',
							color: '#fff',
						},
					}}
				/>
				<Header />

				<div className="app-content" >

					<Switch>
						{pages.map((item, i) => {
							return <Route path={item.path} component={item.component} exact={item.exact} />
						})
						}
					</Switch>

				</div>

			</div>
		);
	}
	else {
		return (<Redirect to="/login" />);
	}


};

export default withRouter(TopHeader);