import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { lettersOnly, integerIndMobile } from '../../utils';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const tdStyle = { border: '1px solid grey', borderCollapse: 'collapse', padding: '3px' }

const AttendanceReport = () => {

	const context = useContext(PsContext);
	const [loading, setIsLoading] = useState(false);
	const [staffData, setStaffData] = useState([]);
	const [data, setData] = useState([]);
	const [leave, setLeave] = useState([]);
	const [month, setMonth] = useState(moment().format("YYYY-MM"));

	const printDocument = () => {

		var content = document.getElementById("printdatamonthly");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}

	useEffect(() => {
		liststaff();
		listattendance();
		listLeave();
	}, []);

	const listattendance = () => {
		try {
			setIsLoading(true);
			var form = new FormData();
			form.append('acyear', context.acyear.id);
			form.append('month', $("#month").val());
			API.post('v1/user/getattendance', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setData(d);
					setIsLoading(false);
				}
				else {
					setData([]);
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const liststaff = () => {
		try {
			setIsLoading(true);
			API.get('v1/user/liststaffattendance').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setStaffData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const daysInMonth = () => {

		var smonth = $("#month").val();
		var year = moment(smonth).format('YYYY');
		var month = moment(smonth).format('MM');
		var ndays = new Date(year, month, 0).getDate();
		//console.log(ndays);
		return ndays + 1;
	}

	const getdays = () => {
		var days = [];

		for (var i = 1; i < daysInMonth(); i++) {

			days.push(<th width="3%" style={tdStyle}><b>{i}</b></th>);
		}

		return days;
	}

	//console.log(getdays());

	const checksunday = (dat) => {
		var bstate = false;
		var smonth = $("#month").val();
		var year = moment(smonth).format('YYYY');
		var month = moment(smonth).format('MM');

		var newDate = new Date('' + year + '-' + month + '-' + dat);

		if (newDate.getDay() == 0) {   //if Sunday
			bstate = true;
		}
		else {  //if Saturday
			bstate = false;
		}
		return bstate;
	}

	const addzero = (day) => {
		var temp = '' + day;
		if (temp.length == 1) {
			temp = "0" + temp;
			return temp;
		}
		else {

			return temp;

		}

	}

	const getcurdate = (date) => {

		var bstate = false;
		var smonth = $("#month").val();
		var year = moment(smonth).format('YYYY');
		var month = moment(smonth).format('MM');
		var newDate = new Date('' + year + '-' + month + '-' + addzero(date)).getTime();
		var today = new Date().getTime();

		if (newDate <= today) {

			bstate = true;

		}
		else {

			bstate = false;

		}

		return bstate;

	}

	const listLeave = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listleave').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setLeave(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const checkabsent = (staffid, date) => {
		var bstate = false;
		var smonth = $("#month").val() + '-' + date;
		var index = data.findIndex(function (obj) {
			return (obj.staff_id == staffid && obj.att_date == smonth);
		});
		if (index > -1) {
			bstate = data[index]['type'];
		}

		return bstate;

	}

	/*const getHoliday = () => {
		var h = leave;
		let g = h.filter(function (obj) {
			return (obj.leave_date == )
		})

	}*/

	const getabsent = (staffid) => {

		var ab = [];

		var smonth = $("#month").val();
		var year = moment(smonth).format('YYYY');
		var month = moment(smonth).format('MM');



		for (var i = 1; i < daysInMonth(); i++) {

			if (checksunday(i)) {

				ab.push(<td id="sunback" style={tdStyle}><span id="attsun" ><b style={{ color: 'orange', fontWeight: '700' }}>S</b></span></td>);

			}
			else {

				if (getcurdate(i)) {

					if (checkabsent(staffid, i)) {

						if (checkabsent(staffid, i) == 'Leave') {

							ab.push(<td style={tdStyle}><span id="attpre" ><b style={{ color: '#3939f1', fontWeight: '700' }}>L</b></span></td>);
						} else {
							ab.push(<td style={tdStyle}><span id="attpre" ><b style={{ color: '#4dbd74', fontWeight: '700' }}>X</b></span></td>);
						}
					}
					else {
						ab.push(<td style={tdStyle}><span id="abs"><b style={{ color: 'red', fontWeight: '700' }}>A</b></span></td>);
					}
				}
				else {
					ab.push(<td style={tdStyle}></td>);
				}
			}

		}

		return ab;

	}

	const isSunday = (dayInInt) => {
		dayInInt = ('0' + dayInInt).slice(-2);
		let day = moment(month + '-' + dayInInt, "YYYY-MM-DD").day();

		return (day == 0);
	};

	const checkStaffExistOnDate = (staff, dt) => {
		dt = ('0' + dt).slice(-2);
		let atDate = month + '-' + dt;
		let atData = data.find(item => item.staff_id == staff.id && item.att_date == atDate);

		return atData;
	};

	const isHoliday = (dt) => {
		let m = leave.filter(item => item.leave_date == dt.format('YYYY-MM-DD'));

		return (m.length > 0) ? true : false;
	};

	const showAttendanceStatus = (staff) => {

		let daysInMonth = moment(month, "YYYY-MM").daysInMonth();
		let tr = [];
		let today = moment((new Date()), "YYYY-MM-DD");

		for (let i = 1; i <= daysInMonth; i++) {
			let dt = moment(month + '-' + i, "YYYY-MM-DD");

			if (isSunday(i) && !isHoliday(dt) && (checkStaffExistOnDate(staff, i) && checkStaffExistOnDate(staff, i) != undefined && checkStaffExistOnDate(staff, i).id && checkStaffExistOnDate(staff, i).type != 'Leave')) {
				tr.push(<td style={styles.present} >X</td>);
			}
			else if (isSunday(i)) {
				tr.push(<td style={styles.sunday} >S</td>);
			}
			else if (isHoliday(dt)) {
				tr.push(<td style={styles.holiday} >H</td>);
			}
			else {
				let atStatus = checkStaffExistOnDate(staff, i);
				if (atStatus && atStatus != undefined && atStatus.id && atStatus.type == 'Leave') { // Leave

					tr.push(<td style={styles.leave} >L</td>);

				}
				else if (atStatus && atStatus != undefined && atStatus.id && atStatus.type != 'Leave') { // Set Present

					tr.push(<td style={styles.present} >X</td>);

				}
				//else if(today.diff(dt, 'days')>0){ // Set Absent
				else if (dt.format('YYYY-MM-DD') <= today.format('YYYY-MM-DD')) {
					tr.push(<td style={styles.absent} >A</td>);
				}
				else {
					tr.push(<td style={styles.absent} ></td>);
				}
			}
		}
		return tr;
	};

	const styles = {
		sunday: {
			color: 'orange',
			fontWeight: '700',
			border: '1px solid black',
			textAlign: 'center',
		},
		holiday: {
			color: '#346ef1',
			fontWeight: '700',
			border: '1px solid black',
			textAlign: 'center',
		},
		absent: {
			color: 'red',
			fontWeight: '700',
			border: '1px solid black',
			textAlign: 'center',
		},
		present: {
			color: '#4dbd74',
			fontWeight: '700',
			border: '1px solid black',
			textAlign: 'center',
		},
		leave: {
			color: '#3939f1',
			fontWeight: '700',
			border: '1px solid black',
			textAlign: 'center',
		}
	};

	const handleMonthchange = (e) => {
		setData([]);
		setMonth(e.target.value);
	};

	return (
		<>
			<div className="container">
				<Row style={{ margin: '5px' }}>
					<Col md={2}>
						<label>Month:</label>
						<MDBInput
							type="month"
							id="month"
							defaultValue={month}
							onChange={e => handleMonthchange(e)}
						/>
					</Col>
					<Col md={4}>
						<Button type="submit" className="mt-3" onClick={listattendance}>Submit</Button>&nbsp;
						<Button type="button" className="mt-3" variant="warning" onClick={printDocument}>Print</Button>&nbsp;
						<ReactHTMLTableToExcel
							id="test-table-xls-button"
							className="download-table-xls-button btn mt-3 btn-info"
							table="table-to-xls-month"
							filename="Staff Attendance"
							sheet="staffattendance"
							buttonText="Export as XLS" />
					</Col>
					<Col md={2}>

					</Col>
					<Col md={4} className='mt-3'>
						<b>Total Working Days:</b>
					</Col>
					<Col md={12} className="mt-3" style={tdStyle}>
						<table className="table mt-10 table-bordered" style={tdStyle} >
							<thead className="text-center">
								<tr>
									<th style={tdStyle} colSpan={2 + daysInMonth()}>
										<span style={{ fontSize: '18px' }}>Staff Attendance - {moment(month).format("MMM YYYY")}</span>
									</th>
								</tr>
								<tr>
									<th width="5%" style={tdStyle}><b>S.No</b></th>
									<th width="15%" style={tdStyle}><b>Staff Name</b></th>
									{getdays()}
								</tr>
							</thead>
							<tbody className="text-center">
								{staffData.map((item, i) => {
									return <tr>
										<td style={tdStyle}>{i + 1}</td>
										<td style={tdStyle} align="left">{item.name}</td>
										{/*getabsent(item.id)*/}
										{data && data.length > 0 && (showAttendanceStatus(item))}
									</tr>
								})}
							</tbody>
						</table>

					</Col>
				</Row>
				<div style={{ display: 'none' }}>
					<Row id="printdatamonthly" >
						<Col md={12} >
							<div align="center" style={{ color: '#08788C', fontSize: '30px' }}><b>WOMEN PRENEURS 4 PLASTIC</b></div>&nbsp;
							<table className="table mt-10 table-bordered" style={tdStyle} id="table-to-xls-month">
								<thead className="text-center">
									<tr>
										<th style={tdStyle} colSpan={2 + daysInMonth()}>
											<span style={{ fontSize: '18px' }}>Staff Attendance - {moment(month).format("MMM YYYY")}</span>
										</th>
									</tr>
									<tr>
										<th width="5%" style={tdStyle}><b>S.No</b></th>
										<th width="15%" style={tdStyle}><b>Staff Name</b></th>
										{getdays()}
									</tr>
								</thead>
								<tbody className="text-center">
									{staffData.map((item, i) => {
										return <tr>
											<td style={tdStyle}>{i + 1}</td>
											<td style={tdStyle} align="left">{item.name}</td>
											{/*getabsent(item.id)*/}
											{data && data.length > 0 && (showAttendanceStatus(item))}
										</tr>
									})}
								</tbody>
							</table>
							<iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default AttendanceReport;