import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../../utils/api';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import PsContext from '../../../context';
import { PSListItem } from '../../../comp';
import { lettersOnly, integerIndMobile } from '../../../utils';
import PlasticAddPrice from './plasticAddPrice';
import moment from 'moment';

const PlasticPriceSetup = () => {

	const context = useContext(PsContext);
	const [data, changeData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [addModal, setAddModal] = useState(false);
	const [editData, setEditData] = useState(false);

	const showModel = () => {
		setAddModal(true);
	}

	const editModel = (item) => {
		setEditData(item);
		setAddModal(true);
	}

	useEffect(() => {
		//listdata();
	}, []);

	const listdata = (event) => {
		event.preventDefault();
		try {
			setIsLoading(true);
			API.post('v1/plastic/listpriceplastic', $('#list').serialize()).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {

		}
	};

	return (
		<>
			<Modal show={addModal} onHide={() => setAddModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>New Price</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					< PlasticAddPrice data={editData} afterFinish={() => { setAddModal(false) }} afterList={() => { listdata() }} />
				</Modal.Body>
			</Modal>

			<div className="container">
				<Navbar bg="white" expand="md">
					<Container>
						<Navbar.Brand >Material Price</Navbar.Brand>
					</Container>
				</Navbar>

				<Row>
					<Form onSubmit={listdata} method='post' id="list">
						<Row className='mt-1'>
							<Col md={9}>
							</Col>
							<Col md={2} className='ms-0'>
								<select className='form-control sm' name="type">
									<option value="">Select Type</option>
									<option value="plastic kg">Plastic Kg</option>
									<option value="others kg">Non Plastic Kg</option>
									<option value="others pcs">Non Plastic Piece</option>
								</select>
							</Col>
							<Col md={1}>
								<Button type="submit" size='sm' className='mt-1' variant="success">Search</Button>
							</Col>
						</Row>
					</Form>
					<Col md={12} className='mt-1'>
						{data.length > 0 ? <div className='table-responsive' style={{height:'450px'}}>
							<table className="table mt-10 table-bordered">
							<thead className="text-center" style={{
								position: 'sticky',
									top: '0',
									backgroundColor: '#E5E4E2'
							}}>
								<tr>
									<th width="2%"><b>S.No</b></th>
									<th width="8%"><b>Plastic Name</b></th>
									<th width="8%"><b>Date</b></th>
									{/* <th width="5%"><b>Short Name</b></th> */}
									<th width="5%"><b>Current Price</b></th>
									<th width="5%"><b>Unit</b></th>
									<th width="5%"><b>Manage</b></th>
								</tr>
							</thead>
							<tbody className="text-center">
								{data.map((item, id) => {
									return <tr>
										<td>{id + 1}</td>
										<td align="left">{item.plastic_name}</td>
										<td align="center">{moment(item.setupdate).format('DD-MM-YYYY')}</td>
										{/* <td align="left">{item.short_name}</td> */}
										<td>Rs. {item.current_price}</td>
										<td>1 {item.weight}</td>
										<td><Button variant="success" onClick={() => { editModel(item) }}><i className="fas fa-edit"></i></Button></td>
									</tr>
								})}
							</tbody>
						</table> </div>: ''}
					</Col>
				</Row>
			</div>
		</>
	);
};
export default PlasticPriceSetup;