import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal,Table, Navbar ,Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import { Link }  from   'react-router-dom';
import axios from 'axios';
import { triggerFocus } from 'antd/lib/input/Input';
import {baseUrl, integerIndMobile, integerIndGst, imageUrl} from '../../utils';
import ReactFileReader from 'react-file-reader';

const AdminPanel = (props) =>{
	
	const context = useContext(PsContext);
    const [AddUser, setAddUser] = useState(false);
    const [editModel, setEditModel] = useState(false); 
    const [ViewData, setViewData] = useState(false); 
    const [user, setUser] = useState([]);  
    const [view, setView] = useState([]);  
	const [file, setFile] = useState([]);
    const [fileName,setFileName]=useState('');
	const [validated, setValidated] = useState(false);
    const [data, changeData] = useState('');
	const [btnLoading, setBtnLoading] = useState(false);
    const [loading, setIsLoading ] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [fieldValue, setFieldValue] = useState(false);
	
	const handleBlur = (e) => {
		setFieldValue(e.target.value);
		setValidated(true);
	}
	
	useEffect(() => {
		listdata();
	},[] );
	
	const handleFiles = (files) => {
		setFile(files.base64);
		setFileName(files.fileList[0].name);
	}
	
	const listdata = () => {
	   try {
		    setIsLoaded(true);
			API.get('v1/user/companysettings').then(res => {
			   if (res['data'].status == '1') {
				 var d = res['data'].data[0];
				 changeData(d);
				 setIsLoaded(false);
			   }
			   else {
				//alert(res['data'].msg, "danger");
				setIsLoaded(false);
			   }
		 });
	   }
	   catch (error) {
			setIsLoaded(false);
	   }
	}
	
	const handleSubmit = (event) => {
		event.preventDefault();
		try{
			setBtnLoading(true);
			API.post('/v1/user/updateadmin',$("#frm_Login").serialize()).then(res=>{
			if (res['data'].status == '1') {
			 toast.success("Updated Successfully..!", "success");
			 document.getElementById("frm_Login").reset();
			 setBtnLoading(false);
			 listdata();
			 const vrl=baseUrl+'v1/log/addlog';
				let formData = new FormData();
				formData.append('createon', '2021/12/10');   //append the values with key, value pair
				formData.append('context', 'Admin Panel Updated Successfully');
				formData.append('e_type', 'User_log');
				formData.append('action_by', 'admin');
				formData.append('addby', 1);
				formData.append('addip', 'localhost');

				var config = {
				  method: 'post',
				  url: vrl,
				  data : formData
				};

				axios(config)
				.then(function (response) {
				  console.log(JSON.stringify(response.data));
				})
				.catch(function (error) {
				  console.log(error);
				});
		}
		else {
			 toast.error("Something Went Wrong..!", "danger");
			 document.getElementById("frm_Login").reset();
			 setBtnLoading(false);
		}
		setIsLoaded(false);
		});
		}
		catch(error){

		}
	}
	
	const getLogo=()=>{
		if(fileName && fileName.length>4){
			return file;
		}
		else if(data && data.org_logo && data.org_logo.length>3){
			return `${imageUrl}/${data.org_logo}`;
		}
	};
	
	return(
		<div className="container">
		
		<Navbar bg="white" expand="lg">
			<Container>
				<Navbar.Brand >AdminPanel</Navbar.Brand>
					<Nav  className="justify-content-end">
					</Nav>
			</Container>
		</Navbar>
		<Row>
		<Col md={2}>
		</Col>
		<Col md={8}>
		<Card className="mt-3">
		{data!='' ?
		<Card.Body>
			<Form action="" method="post" id="frm_Login" validated={validated} onSubmit={handleSubmit}  className="needs-validation">
				<input type="hidden" value={data.id} name="id" />
				<input type="hidden" name="file" value= {JSON.stringify(file)} />
				<input type="hidden" name="fileName" value={fileName} />
					<Row>
					<Col md={6}>
					<Row>
						<Col md={12} className="mt-2">
							<MDBInput 
								label="Organization Name"
								type="text"
								name="org_name"
								required
								size="md"
								defaultValue={data.org_name}
							 />
						</Col>
					</Row>
					
					<Row>
						<Col md={12} className="mt-4">
							<img src = {getLogo()} width ='100px' height = '100px' />
							<ReactFileReader base64={true} handleFiles={handleFiles}>
							  <button type="button" className='btn btn-block mt-2' size="sm">{file == '' ? 'organization logo upload' : 'change'}</button>
							</ReactFileReader>
						</Col>
					</Row>
					
					<Row>
						<Col md={12} className="mt-4">
							<MDBInput 
								label="Organization Type"
								type="text"
								name="org_type"
								required
								size="md"
								defaultValue={data.org_type}
							 />
						</Col>
					</Row>
					
					<Row>
						<Col md={12} className="mt-4">
							<MDBInput 
								label="PAN Number"
								type="text"
								name="pan_number"
								required
								size="md"
								minLength="10"
								maxLength="10"
								defaultValue={data.pan_number}
							 />
						</Col>
					</Row>
					
					<Row>
						<Col md={12} className="mt-4">
							<MDBInput 
								label="GSTIN Number"
								type="text"
								name="gstin_number"
								required
								size="md"
								onKeyPress={integerIndGst}
								defaultValue={data.gstin_number}
							 />
						</Col>
					</Row>
					
					<Row>
						<Col md={12} className="mt-4">
							<MDBInput 
								label="Address Line1"
								type="text"
								name="address1"
								required
								size="md"
								defaultValue={data.address1}
							 />
						</Col>
					</Row>
					</Col>
					<Col md={6}>
					<Row>
						<Col md={12} className="mt-2">
							<MDBInput 
								label="Address Line2"
								type="text"
								name="address2"
								required
								size="md"
								defaultValue={data.address2}
							 />
						</Col>
					</Row>
					
					<Row>
						<Col md={12} className="mt-4">
							<MDBInput 
								label="City"
								type="text"
								name="city"
								required
								size="md"
								defaultValue={data.city}
							 />
						</Col>
					</Row>
					
					<Row>
						<Col md={12} className="mt-4">
							<MDBInput 
								label="State"
								type="text"
								name="state"
								required
								size="md"
								defaultValue={data.state}
							 />
						</Col>
					</Row>
					
					<Row>
						<Col md={12} className="mt-4">
							<MDBInput 
								label="Pincode"
								type="text"
								name="pincode"
								required
								size="md"
								defaultValue={data.pincode}
							 />
						</Col>
					</Row>
					
					<Row>
						<Col md={12} className="mt-4">
							<MDBInput 
								label="Mobile"
								type="text"
								name="mobile"
								required
								size="md"
								defaultValue={data.mobile}
								onKeyPress={integerIndMobile}
							 />
						</Col>
					</Row>
					
					<Row>
						<Col md={12} className="mt-4">
							<MDBInput 
								label="Email"
								type="text"
								name="email"
								required
								size="md"
								defaultValue={data.email}
							 />
						</Col>
					</Row>
				</Col>
			</Row>
			<Row className="mt-4" >
				<Col md={12}>
					<Button
						type="submit"
						size="md"
						variant="primary"
						className="btn-block"
						disabled={btnLoading}
					>
					{btnLoading ? 'Please wait..' : 'Update'}
					</Button>
				</Col>
			</Row>
			</Form>
			</Card.Body> : ''}
		</Card>
	</Col>
	</Row>
</div>
	);
};
export default AdminPanel;