import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { lettersOnly, integerIndMobile, decimalKeyPress } from '../../utils';
import { baseUrl } from '../../utils';
import axios from 'axios';
import Select from 'react-select';

const EditGroup = (props) => {

	const context = useContext(PsContext);
	const [submit, sethandleSubmit] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [data, changeData] = useState([]);
	const [villageData, setVillageData] = useState([]);

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		if (!form.checkValidity() === true) {
			event.stopPorpagation();
			setValidated(true);
			return;
		}
		try {
			setBtnLoading(true);
			API.post('/v1/group/editgroup', $("#frm_Login").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Updated Successfully..!", "success");
					document.getElementById("frm_Login").reset();
					setBtnLoading(false);
					const vrl = baseUrl + '/v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Edit Group');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
						method: 'post',
						url: vrl,
						data: formData
					};

					axios(config)
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
					props.afterFinish();
					props.afterList();
				}
				else {
					toast.error("Something Went Wrong..!", "danger");
					document.getElementById("frm_Login").reset();
				}
				setIsLoaded(false);
			});
		}
		catch (error) {

		}


	}

	const listVillage = () => {
		try {
			API.get('v1/group/village').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setVillageData(d);
				}
				else {

				}
			});
		}
		catch (error) {

		}
	}

	useEffect(() => {
		listdata();
		listVillage();
	}, []);

	const listdata = () => {
		try {
			API.get('v1/user/list').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
				}
				else {

				}
			});
		}
		catch (error) {

		}
	}

	const getValue = (field) => {
		return props.data[field] || '';

	}

	return (
		<>
			<div class="container">
				<Row>
					<Col md={12}>
						<Form action="" method="post" id="frm_Login" validated={validated} onSubmit={handleSubmit}>
							<input type="hidden" name="id" value={props.data.id} />
							<Row>
								<Col md={6}>
									<Row className="mt-3">
										<Col md={12}>
											<MDBInput
												label="Group Name"
												type="text"
												name="grp_name"
												size="sm"
												onKeyPress={lettersOnly}
												defaultValue={getValue('grp_name')}
											/>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col md={12}>
											<MDBInput
												label="Block"
												type="text"
												name="block"
												size="sm"
												defaultValue={getValue('block')}
											/>
										</Col>
									</Row>
									{/*<Row className="mt-3">
												<Col md={12}>
													<select className="form-control form-control-sm" name="app_access1" id="app_access1">
														<option value="">App Access 1</option>
														<option value="yes">Yes</option>
														<option value="no">No</option>
													</select>
												</Col>
						</Row>*/}
									<Row className="mt-3">
										<Col md={12}>
											<MDBInput
												label="President Name"
												type="text"
												name="pre_name"
												size="sm"
												onKeyPress={lettersOnly}
												defaultValue={getValue('pre_name')}
											/>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col md={12}>
											<MDBInput
												label="Secretary Name"
												type="text"
												name="sec_name"
												size="sm"
												onKeyPress={lettersOnly}
												defaultValue={getValue('sec_name')}
											/>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col md={12}>
											<MDBInput
												label="Treasurer Name"
												type="text"
												name="trusure_name"
												size="sm"
												onKeyPress={lettersOnly}
												defaultValue={getValue('trusure_name')}
											/>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col md={12}>
											<MDBInput
												label="Bank Name"
												type="text"
												name="bank_name"
												size="sm"
												onKeyPress={lettersOnly}
												defaultValue={getValue('bank_name')}
											/>
										</Col>
									</Row>
									<Row className="mt-4">
										<Col md={6}>
											<label>APP Access:</label>
										</Col>
										<Col md={6}>
											<div >
												<div className="form-check form-check-inline">
													<input type="radio" name="app_access1" value="Yes" className="form-check-input" defaultChecked={getValue('app_access1') == 'Yes' ? true : false} />
													<label for="app_access1">
														Yes
													</label>
												</div>
												<div className="form-check form-check-inline">
													<input type="radio" name="app_access1" value="No" className="form-check-input" defaultChecked={getValue('app_access1') == 'No' ? true : false} />
													<label for="app_access1">
														No
													</label>
												</div>
											</div>
											{/*<select className="form-control form-control-sm" name="app_access2" id="app_access2">
														<option value="">App Accesss 2</option>
														<option value="yes">Yes</option>
														<option value="no">No</option>
													</select>*/}
										</Col>
									</Row>
									<Row className="mt-4">
										<Col md={6}>
											<label>Plastic Bank Activate:</label>
										</Col>
										<Col md={6}>
											<div >
												<div className="form-check form-check-inline">
													<input type="radio" name="bank_active" value="Yes" className="form-check-input" defaultChecked={getValue('bank_active') == 'Yes' ? true : false} />
													<label for="bank_active">
														Yes
													</label>
												</div>
												<div className="form-check form-check-inline">
													<input type="radio" name="bank_active" value="No" className="form-check-input" defaultChecked={getValue('bank_active') == 'No' ? true : false} />
													<label for="bank_active">
														No
													</label>
												</div>
											</div>
											{/*<select className="form-control form-control-sm" name="app_access2" id="app_access2">
														<option value="">App Accesss 2</option>
														<option value="yes">Yes</option>
														<option value="no">No</option>
													</select>*/}
										</Col>
									</Row>

									{/*<Row className="mt-3">
												<Col md={12}>
													{/*<select className="form-control form-control-sm" name="incharge1" id="incharge1" required>
														<option value="">Incharge Person 1</option>
														{data.map((user, i) => {
														return <option value={user.id}>{user.name}</option>
														})}
													</select>
													<MDBInput
														label="Incharge Person 1"
														type="text"
														name="incharge1"
														size="sm"
														onKeyPress={lettersOnly}
														required
													/>
												</Col>
											</Row>
											<Row className="mt-3">
												<Col md={12}>
													{/*<select className="form-control form-control-sm" name="incharge2" id="incharge2" required>
														<option value="">Incharge Person 2</option>
														{data.map((user, i) => {
														return <option value={user.id}>{user.name}</option>
														})}
													</select>
													<MDBInput
														label="Incharge Person 2"
														type="text"
														name="incharge2"
														size="sm"
														onKeyPress={lettersOnly}
														required
													/>
												</Col>
											</Row>*/}
								</Col>
								<Col md={6}>
									<Row className="mt-3">
										<Col md={12}>
											<MDBInput
												label="Total Members"
												type="number"
												name="total_members"
												size="sm"
												defaultValue={getValue('total_members')}
											/>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col md={12}>
											<MDBInput
												label="Date of Etd"
												type="date"
												name="date_of_start"
												size="sm"
												required
												defaultValue={getValue('date_of_start')}
											/>
										</Col>
									</Row>

									<Row className="mt-3">
										<Col md={12}>
											<MDBInput
												label="President Mobile"
												type="text"
												name="pre_mobile"
												size="sm"
												onKeyPress={integerIndMobile}
												defaultValue={getValue('pre_mobile')}
											/>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col md={12}>
											<MDBInput
												label="Secretary  Mobile"
												type="text"
												name="sec_mobile"
												size="sm"
												onKeyPress={integerIndMobile}
												defaultValue={getValue('sec_mobile')}
											/>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col md={12}>
											<MDBInput
												label="Treasurer Mobile"
												type="text"
												name="trusure_mobile"
												size="sm"
												onKeyPress={integerIndMobile}
												defaultValue={getValue('trusure_mobile')}
											/>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col md={12}>
											<MDBInput
												label="Bank Account No"
												type="text"
												name="bank_ac_no"
												size="sm"
												onKeyPress={decimalKeyPress}
												defaultValue={getValue('bank_ac_no')}
											/>
										</Col>
									</Row>

									<Row className="mt-3">
										<Col md={12}>
											<MDBInput
												label="Address"
												type="text"
												name="address"
												size="md"
												defaultValue={getValue('address')}
											/>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col md={12}>
											<select className="form-control" name="village" size='sm' required>
												<option value="">Select Village</option>
												{villageData.map((user, i) => {
													return <option value={user.id} selected={getValue('village') == user.id ? getValue('village') : ''}>{user.villagename}</option>
												})}
											</select>
										</Col>
									</Row>

									{/*<Row className="mt-3">
												<Col md={12}>
													<MDBInput
														label="Mobile Number"
														type="text"
														name="mobileno1"
														size="sm"
														required
														onKeyPress={integerIndMobile}
													/>
												</Col>
											</Row>
											<Row className="mt-3">
												<Col md={12}>
													<MDBInput
														label="Mobile Number"
														type="text"
														name="mobileno2"
														size="sm"
														required
														onKeyPress={integerIndMobile}
													/>
												</Col>
										</Row>*/}
								</Col>
								<Col md={6}>

								</Col>
								<Col md={6}>

								</Col>
								{/*<Col md={5} className="mt-3">
											<MDBInput
												label="Address"
												type="text"
												name="address"
												size="md"
											/>
										</Col>
										<Col md={5} className="mt-3">
											<select className="form-control" name="village" size="sm">
												<option value="">Select Village</option>
												{villageData.map((user, i) => {
													return <option value={user.id}>{user.villagename}</option>
												})}
											</select>
											</Col>&nbsp;*/}
								<Button type="submit" variant="success" className='mt-2' size="md" block>Update</Button>
							</Row>
						</Form>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default EditGroup;