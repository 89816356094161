import React, { useState, useContext } from "react";
import { withRouter, useHistory, Link, Switch, Route } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import { baseUrl } from "../../utils";

import Layout from "./layout";
import Home from "./home";
import MobileAccountsGroup from "./indexGroupAccount";
import MobileAccountsPlasticBank from "./indexPb";

const MobileAccounts = (props) => {
  const context = useContext(PsContext);
  const [page, setPage] = useState("home");

  return (
    <Layout onAction={(p) => setPage(p)} page={page}>
      {page == "home" && <Home onAction={(p) => setPage(p)} />}

      {page == "group_accounts" && (
        <MobileAccountsGroup onAction={(p) => setPage(p)} {...props} />
      )}

      {page == "plastic_bank_accounts" && (
        <MobileAccountsPlasticBank onAction={(p) => setPage(p)} {...props} />
      )}
    </Layout>
  );
};
export default withRouter(MobileAccounts);
