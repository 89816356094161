import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col,  Modal , Form, Table} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import {integerIndMobile, baseUrl, decimalKeyPress} from '../../utils';

const EditSales = (props) =>{

	const context = useContext(PsContext);
	const [btnLoading, setBtnLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [salesData, setSalesData] = useState([]);
	const [plasticData, setPlasticData] = useState([]);
	const [data, setData] = useState([]);

	useEffect(() => {
        listplastic(); 
        listsales(); 
    } , [] );
	
	const listplastic = () =>{
		try{
			setIsLoading(true);
			var form = new FormData();
			form.append('acyear',context.acyear.id);
			API.post('v1/plastic/listplastic',form).then(res=>{
			if (res['data'].status=='1'){
				var d = res['data'].data;
				setPlasticData(d);
				setIsLoading(false);
			}
			else{
				setIsLoading(false);
			}
			});
		}
		catch(er){

		}
	};
	
	const listsales = () =>{
		try{
			setIsLoading(true);
			var form = new FormData();
			form.append('acyear',context.acyear.id);
			form.append('salesid',props.data.salesid);
			
			API.post('v1/plastic/listsalesbyid',form).then(res=>{
			if (res['data'].status=='1'){
				var d = res['data'].data;
				setData(d);
				setSalesData(d);
				setIsLoading(false);
			}
			else{
				setIsLoading(false);
			}
			});
		}
		catch(er){

		}
	};
	
	const handleSubmit = (event) =>{
		event.preventDefault();
		try{
			setBtnLoading(true);
			API.post('v1/plastic/editsales', $("#editform").serialize()).then(res =>{
			if(res['data'].status == '1'){
				toast.success("Updated Successfully","success");
				setBtnLoading(false);
				const vrl=baseUrl+'/v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Edit Plastic Sales');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
					  method: 'post',
					  url: vrl,
					  data : formData
					};

					axios(config)
					.then(function (response) {
					  console.log(JSON.stringify(response.data));
					})
					.catch(function (error) {
					  console.log(error);
					});	
				 props.afterFinish();
				 props.afterList();
			}
			else{
				toast.error("Something Went Wrong","danger");
				setBtnLoading(false);
			}
			});
		}
		catch(error){
			
		}
	}
	
	const onKgChange = (plastic_id, sAmt, price, field, e) =>{
		
		var d = [];
		var index = data.findIndex(function(obj){
			return(obj.plastic_id == plastic_id);
		});
		if(index > -1){
			data[index][field] = e;
			if(data[index].sales_amount > 0){
				sAmt = data[index].sales_amount;
			}
			if(field == 'selling_kg'){
				data[index].total_amount = e*sAmt;
			} else if(field == 'sales_amount'){
				data[index].total_amount = data[index].selling_kg*e;
			}
			d.push(data);
			const arr = [...salesData.filter((item,i)=> item.plastic_id!=plastic_id), ...d[0]];
			setSalesData(arr);
		}
		else{
			d.push({"id" : 0,"plastic_id" : plastic_id, "selling_kg" : e, "plastic_amount": price, "sales_amount" : sAmt, "total_amount" : e*sAmt});
			const arr = [...salesData, ...d];
			setSalesData(arr);
			const arr1 = [...data, ...d];
			setData(arr1);
		}
	}
	
	const getsales = (id,field) =>{
		var value = '';
		var d = salesData;
		var index = d.findIndex(function(obj){
			return(obj.plastic_id == id);
		});
		if(index > -1){
			value = d[index][field];
		}
		return value;
	}
	
	let totalAmount = 0;
	let totalKg = 0;
	let stotal = 0;
	
	return(
		<>
			<div className="container">
				<Form action="" method="post" id="editform" validated={validated} onSubmit={handleSubmit} >
					<input type="hidden" name="id" value={props.data.salesid} />
					<input type="hidden" name="acyear" value={context.acyear.id} />
					<input type="hidden" name="group" value={props.data.group_id} />
					<input type="hidden" name="vendor" value={props.data.vendor_id} />
					<input type="hidden" name="sdate" value={props.data.sales_date} />
					<input type="hidden" name="sales" value={JSON.stringify(salesData)} />
					<Row>
						<Col md={6}>
							<MDBInput
								label="Group Name"
								type="text"
								name="name"
								size="lg"
								disabled
								defaultValue={props.data.grp_name}
							/>
						</Col>
						<Col md={6}>
							<MDBInput
								label="Vendor Name"
								type="text"
								name="name"
								size="lg"
								disabled
								defaultValue={props.data.vendor_name}
							/>
						</Col>
						{salesData.length > 0 ?
						<Col md={12}>
							<Table responsive>
								<thead>
									<tr>
										<th>S.No</th>
										<th>Type</th>
										<th width="10%">Kg</th>
										<th width="10%">Amount</th>
										<th width="10%">Total</th>
									</tr>
								</thead>
								<tbody>
									{plasticData.map((item,i)=>{
										let kg = getsales(item.id,'selling_kg') ? getsales(item.id,'selling_kg') : 0;
										totalKg+=parseFloat(kg);
										let sales_amt = getsales(item.id,'sales_amount') ? getsales(item.id,'sales_amount') : item.current_price;
										stotal+=parseFloat(sales_amt);
										let amount = parseFloat(kg*sales_amt);
										let totalAmt = getsales(item.id,'total_amount') ? getsales(item.id,'total_amount') : 0;
										totalAmount+=parseFloat(totalAmt);
										return <tr>
											<td>{i+1}</td>
											<td>{item.plastic_name}</td>
											<td align="right">
												<input type="text" name="kg" onKeyPress={decimalKeyPress} defaultValue={kg} onChange={(e)=> {onKgChange(item.id,sales_amt,item.current_price,'selling_kg',e.target.value)}} />
											</td>
											<td align="right">{sales_amt}</td>
											{/*<td align="right">
											<input type="text" name="amt" onKeyPress={decimalKeyPress} defaultValue={sales_amt} onChange={(e)=> {onKgChange(item.id,'sales_amount',e.target.value)}} />
											</td>*/}
											<td align="right">{amount}</td>
										</tr>
									})}
									<tr>
										<td colSpan="2" align="right"><b>Total</b></td>
										<td ><b>{totalKg}</b></td>
										<td ><b>{stotal}</b></td>
										<td align="right"><b>{totalAmount}</b></td>
									</tr>
									<tr>
										<td colSpan="2" align="right"><b>Amount Received</b></td>
										<td colSpan="2" align="right">
											<input type="text" name="r_amount" defaultValue={salesData[0].received_amount} onKeyPress={decimalKeyPress} />
											<input type="hidden" name="net_amount" defaultValue={totalAmount} />
										</td>
									</tr>
								</tbody>
							</Table>
						</Col> : '' }
						<Col md={12} className="mt-3">
							<button type="button" className="btn btn-outline-black btn-block"  type="submit" disabled={btnLoading}>
							{btnLoading ? 'Please wait..' : 'Update'}</button>
						</Col>
					</Row>
                
			</Form>
			</div>
		</>
	);
};

export default EditSales; 