import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../../utils/api';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import PsContext from '../../../context';
import { PSListItem } from '../../../comp';
import { lettersOnly, decimalKeyPress, baseUrl } from '../../../utils';
import axios from 'axios';

const EditType = (props) => {

	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [getData, setGetData] = useState([]);
	const [valueData, setValueData] = useState(props.data.weight);

	console.log(valueData);
	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			setBtnLoading(true);
			API.post('v1/plastic/editplastic', $('#editform').serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Updated Successfully", "success");
					setBtnLoading(false);
					const vrl = baseUrl + 'v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Plastic Type Updated');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
						method: 'post',
						url: vrl,
						data: formData
					};

					axios(config)
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
					props.afterFinish();
					props.afterList();
				}
				else {
					toast.error("something went wrong", "error");
					setBtnLoading(false);
				}
			});
		}
		catch (error) {

		}
	};


	const getValue = (field) => {
		return props.data[field] || '';
	}

	const getChangeValue = (e) => {
		let s = e.target.value;
		setGetData(s);
	}

	return (
		<>
			<div className="contanier">
				<Form action="" id="editform" method="post" no validated={validated} onSubmit={handleSubmit}>
					<input type="hidden" name="id" value={props.data.id} />
					<Row>
						<Col md={12}>
							<MDBInput
								label="Plastic Name"
								type="text"
								size="sm"
								name="plastic_name"
								requried
								defaultValue={getValue('plastic_name')}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="Short Name"
								type="text"
								size="sm"
								name="short_name"
								requried
								defaultValue={getValue('short_name')}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="HSN"
								type="text"
								size="sm"
								name="hsn"
								requried
								defaultValue={getValue('hsn')}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="GST"
								type="text"
								size="sm"
								name="gst"
								requried
								defaultValue={getValue('gst')}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="Buying Rate (Maximum % of selling rate)"
								type="number"
								name="buying_rate"
								size="sm"
								defaultValue={getValue('buying_rate')}
								onKeyPress={decimalKeyPress}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<select className='form-control' name="material_type" defaultValue={getValue('material_type')} onChange={getChangeValue}>
								<option value="">-Select Material Type-</option>
								<option value="plastic">Plastic</option>
								<option value="others">Others</option>
							</select>
						</Col>
						<Col md={12} className="mt-3">
							{getData == 'others' ? <select className='form-control' name="weight" defaultValue={getValue('weight')}>
								<option value='kg'>Kg</option>
								<option value='pcs'>Pcs</option>
								<option value='liter'>Liter</option>
								<option value='meter'>Meter</option>
							</select> : <MDBInput
								type="text"
								name="weight"
								size="sm"
								defaultValue={getValue('weight')}
							/>}
						</Col>
						<Col md={12} className='mt-3'>
							<MDBInput
								type="number"
								name="order"
								size="sm"
								label="Plastic Order"
								defaultValue={getValue('plastic_order')}
							/>
						</Col>

						<Col md={12} className="mt-3">
							<select className="form-select" aria-label="Default select example" name="active_status" >
								<option value=""> Status</option>
								<option value="Active" selected={props.data.active_status == 'Active' ? true : false}>Active</option>
								<option value="InActive" selected={props.data.active_status == 'InActive' ? true : false}>InActive</option>
							</select>
						</Col>
						<Col md={12} className="mt-3">
							<Button type="submit" size="md" style={{ float: 'right' }} disabled={btnLoading}>{btnLoading ? 'Please wait..' : 'Update'}</Button>
						</Col>
					</Row>
				</Form>
			</div>
		</>
	);
}
export default EditType;