import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { lettersOnly, integerIndMobile } from '../../utils';

import Location from './location';

const tdStyle = { border: '1px solid grey', borderCollapse: 'collapse', padding: '3px' }

const DailyReport = () => {

	const context = useContext(PsContext);
	const [loading, setIsLoading] = useState(false);
	const [staffData, setStaffData] = useState([]);
	const [data, setData] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
	const [viewData, setViewData] = useState([]);

	const printDocument = () => {
		var content = document.getElementById("printdata");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}

	const showModal = (dt) => {
		setViewData(dt);
		setOpenModal(true);
	}

	useEffect(() => {
		liststaff();
		listattendance();
	}, []);

	const listattendance = () => {
		try {
			setIsLoading(true);
			var form = new FormData();
			form.append('acyear', context.acyear.id);
			form.append('date', $("#date").val());
			API.post('v1/user/getattendancebydate', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setData(d);
					setIsLoading(false);
				}
				else {
					setData([]);
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};



	const liststaff = () => {
		try {
			setIsLoading(true);
			API.get('v1/user/liststaffattendance').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setStaffData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};


	const getAtt = (staffid, field) => {
		var result = '';
		var index = data.findIndex(function (obj) {
			return (obj.staff_id == staffid);
		});
		if (index > -1) {

			result = data[index][field];

		}
		return result;

	}



	return (
		<>
			<Modal show={openModal} onHide={() => setOpenModal(false)} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>Location</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Location values={viewData} />
				</Modal.Body>
			</Modal>

			<div className="container">
				<Row style={{ margin: '5px' }}>
					<Col md={2}>
						<label>Date:</label>
						<MDBInput
							type="date"
							id="date"
							defaultValue={date}
							onChange={(e) => setDate(e.target.value)}
						/>
					</Col>
					<Col md={4}>
						<Button type="submit" className="mt-3" onClick={listattendance}>Submit</Button>&nbsp;
						<Button type="button" className="mt-3" variant="warning" onClick={printDocument}>Print</Button>&nbsp;
						<ReactHTMLTableToExcel
							id="test-table-xls-button"
							className="download-table-xls-button btn mt-3 btn-info"
							table="table-to-xls"
							filename="Staff Attendance"
							sheet="staffattendance"
							buttonText="Export as XLS" />
					</Col>
					<Col md={12} className="mt-3">
						<table className="table mt-10 table-bordered" style={tdStyle}>
							<thead className="text-center">
								<tr>
									<th style={tdStyle} colSpan={5}>
										<span style={{ fontSize: '18px' }}>Staff Attendance - {moment(date).format("DD-MM-YYYY")}</span>
									</th>
								</tr>
								<tr>
									<th width="5%" style={tdStyle}><b>S.No</b></th>
									<th width="15%" style={tdStyle}><b>Staff Name</b></th>
									<th width="15%" style={tdStyle}><b>IN</b></th>
									<th width="15%" style={tdStyle}><b>OUT</b></th>
									<th width="15%" style={tdStyle}><b>Location</b></th>
								</tr>
							</thead>
							<tbody className="text-center">
								{staffData.map((item, i) => {
									let str = [];
									let loc = getAtt(item.id, 'location');
									loc = loc && loc.length > 3 ? loc.split(",") : [];
									let str_out = getAtt(item.id, 'out_location');
									str_out = str_out && str_out.length > 3 ? str_out.split(",") : [];
									let lat = loc[0];
									let lon = loc[1];

									return <tr style={{ backgroundColor: getAtt(item.id, 'att_time') == false || getAtt(item.id, 'att_time') != null && getAtt(item.id, 'out_time') == null && getAtt(item.id, 'type') == 'Leave' ? '#F1EA87' : '' }}>
										<td style={tdStyle}>{i + 1}</td>
										<td style={tdStyle} align="left">{item.name}</td>
										<td style={tdStyle}>{getAtt(item.id, 'type') == 'Leave' ? '' : (getAtt(item.id, 'att_time'))}</td>
										<td style={tdStyle}>{getAtt(item.id, 'out_time')}</td>
										<td style={tdStyle} align="left" >
											{getAtt(item.id, 'type') == 'Leave' && getAtt(item.id, 'att_time') != null ? <b style={{ float: 'left' }}>Leave</b> : <>
												{getAtt(item.id, 'att_time') == false ? moment(date).day() == 0 ? <>Sunday</> : <b>Absent</b> : <a href={`https://maps.google.com?q=${lat},${lon}`} target="_blank">
													In
												</a>}
												&emsp;
												{getAtt(item.id, 'out_time') == false ? '' : (str_out && str_out.length > 0 && (<a href={`https://maps.google.com?q=${str_out[0]},${str_out[1]}`} target="_blank">
													Out
												</a>))}
											</>}
										</td>
									</tr>
								})}
							</tbody>
						</table>
						<iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
					</Col>
				</Row>


				<Row style={{ display: 'none' }}>
					<Col md={12} id="printdata">
						<table className="table mt-10 table-bordered" style={tdStyle} id="table-to-xls">
							<div align="center" style={{ color: '#08788C', fontSize: '30px' }}><b>WOMEN PRENEURS 4 PLASTIC</b></div>&nbsp;
							<thead className="text-center">
								<tr>
									<th style={tdStyle} colSpan={5}>
										<span style={{ fontSize: '18px' }}>Staff Attendance - {moment(date).format("DD-MM-YYYY")}</span>
									</th>
								</tr>
								<tr>
									<th width="5%" style={tdStyle}><b>S.No</b></th>
									<th width="15%" style={tdStyle}><b>Staff Name</b></th>
									<th width="15%" style={tdStyle}><b>IN</b></th>
									<th width="15%" style={tdStyle}><b>OUT</b></th>
									<th width="15%" style={tdStyle}><b>Location</b></th>
								</tr>
							</thead>
							<tbody className="text-center">
								{staffData.map((item, i) => {
									let str = [];
									let loc = getAtt(item.id, 'location');
									loc = loc && loc.length > 3 ? loc.split(",") : [];
									let str_out = getAtt(item.id, 'out_location');
									str_out = str_out && str_out.length > 3 ? str_out.split(",") : [];
									let lat = str[0];
									let lon = str[1];

									return <tr style={{ backgroundColor: getAtt(item.id, 'att_time') == false || getAtt(item.id, 'att_time') != null && getAtt(item.id, 'out_time') == null && getAtt(item.id, 'type') == 'Leave' ? '#F1EA87' : '' }}>
										<td style={tdStyle}>{i + 1}</td>
										<td style={tdStyle} align="left">{item.name}</td>
										<td style={tdStyle}>{getAtt(item.id, 'type') == 'Leave' ? '' : (getAtt(item.id, 'att_time'))}</td>
										<td style={tdStyle}>{getAtt(item.id, 'out_time')}</td>
										<td style={tdStyle} align="left" >
											{getAtt(item.id, 'type') == 'Leave' && getAtt(item.id, 'att_time') != null ? <b style={{ float: 'left' }}>Leave</b> : <>
												{getAtt(item.id, 'att_time') == false ? moment(date).day() == 0 ? <>Sunday</> : <b>Absent</b> : <a href={`https://maps.google.com?q=${lat},${lon}`} target="_blank">
													In
												</a>}
												&emsp;
												{getAtt(item.id, 'out_time') == false ? '' : (str_out && str_out.length > 0 && (<a href={`https://maps.google.com?q=${str_out[0]},${str_out[1]}`} target="_blank">
													Out
												</a>))}
											</>}
										</td>
									</tr>
								})}
							</tbody>
						</table>
						<iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default DailyReport;