import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import { Link } from 'react-router-dom';
import AddLedgerHead from './addLedgerHead';
import EditLedgerHead from './editLedgerHead';

import axios from 'axios';
import { triggerFocus } from 'antd/lib/input/Input';
import moment from 'moment';

const tdStyle = { border: '1px solid grey', borderCollapse: 'collapse', padding: '35px' }

const AccountsLedgerHead = () => {

	const context = useContext(PsContext);
	const [validated, setValidated] = useState(false);
	const [AddUser, setAddUser] = useState(false);
	const [addModel, setAddModel] = useState(false);
	const [editReportModel, setEditReportModel] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [viewData, setViewData] = useState(false);
	const [user, setUser] = useState([]);
	const [view, setView] = useState([]);
	const [data, changeData] = useState([]);
	const [reportData, setReportData] = useState([]);
	const [imgData, setImgData] = useState([]);
	const [stData, stChangeData] = useState([]);
	const [changeDate, changeDateData] = useState([]);
	const [searchData, setSearchData] = useState([]);
	const [imageData, setImageData] = useState([]);
	const [editImageData, setEditImageData] = useState([]);
	const [typeData, setTypeData] = useState([]);
	const [staffData, setStaffData] = useState([]);
	const [loading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [adminEnquiry, setAdminEnquiry] = useState(false);

	const [printData, setPrintData] = useState([]);
	const [print, setPrint] = useState(false);

	useEffect(() => {
		listdata();
	}, []);

	const printDocument = () => {

		var content = document.getElementById("new_print_tbl");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}

	const handlePrint = (dt) => {
		setPrintData(dt);
		setPrint(true);
		setTimeout(
			() => printDocument(),
			1000
		);
	};

	const filterChange = (e) => {
		var val = e.target.value;
		var name = e.target.name;
		if (val == '') {
			changeDateData(data);
		} else {
			let ndata = data.filter(function (item) {
				return (item[name] == val);
			});
			changeDateData(ndata);
		}
	}

	const showModel = (item) => {
		setUser(item);
		var dat = imageData.filter(function (obj) {
			return (obj.group_id == item.group_id && obj.group_meeting == item.id)
		})
		setEditImageData(dat);
		setEditModel(true);
	}

	const showAddModel = () => {
		setAddModel(true);
	}

	const showReportEditModel = (item) => {
		listReportData(item, 'edit');
	}

	const listReportData = (item, report) => {
		try {
			setIsLoading(true);
			var form = new FormData;
			form.append('event', item.id);
			API.post('v1/event/eventreportbyid', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data[0];
					var d1 = res['data'].image;
					setReportData(d);
					setImgData(d1);
					setUser(item);
					if (report == 'edit') {
						setEditReportModel(true);
					} else {
						setView(item);
						setViewData(true);
					}
				}
				else {
					setIsLoading(false);
					setUser(item);
					setReportData([]);
					setImgData([]);
					if (report == 'edit') {
						setEditReportModel(true);
					} else {
						setView(item);
						setViewData(true);
					}
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const viewModel = (item) => {
		setUser(item);
		var dat = imageData.filter(function (obj) {
			return (obj.group_id == item.group_id && obj.group_meeting == item.id)
		})
		setEditImageData(dat);
		setViewData(true);
	}




	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/user/list/ledgerthead').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const onSearchChange = (e) => {
		var d = data;
		let ndata = d.filter(function (item) {
			const grp_name = item.grp_name.toUpperCase();
			const s = e.target.value.toUpperCase();
			return (grp_name.indexOf(s) > -1);
		});
		setSearchData(ndata);
	}

	const onDateSearch = (event) => {
		event.preventDefault();
		try {
			setBtnLoading(true);
			API.post('v1/group/meetingsearch', $("#src_Date").serialize()).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeDateData(d);
					setBtnLoading(false);
				}
				else {
					changeDateData([]);
					toast.error('No Data Found', "danger");
					setBtnLoading(false);
				}
			});
		}
		catch (error) {

		}
	};



	const handleDelete = (item, id) => {
		try {
			if (window.confirm('Do you want to Remove?')) {
				setIsLoading(true);
				API.get('v1/user/deleteledgerhead?id=' + id).then(res => {
					if (res['data'].status == '1') {
						toast.success(" Deleted Successfully..!", "success");
						//var d = res['data'].data;
						var d = data;
						var index = d.findIndex(function (obj) {
							return (obj.id == id);
						});
						if (index > -1)
							d.splice(index, 1);
						setIsLoading(false);
						listdata();
					}
					else {
						toast.error("Something Went Wrong..!", "danger");
						setIsLoading(false);
					}
				});
			}
		}
		catch (error) {
			setIsLoading(false);
		}
	}

	const onFromChange = (event) => {

		var fromdate = $("#fromdate").val();
		var toMinDate = moment(fromdate).format("YYYY-MM-DD");
		if (fromdate != '') {
			$("#todate").attr('min', toMinDate);
		}
	};

	return (
		<div className="container" >
			<Navbar bg="white" expand="lg">
				<Container>
					<Navbar.Brand >Ledger Head</Navbar.Brand>
					<Nav className="justify-content-end">
						<Button variant="success" onClick={() => { showAddModel() }} size="sm"><i className="fas fa-plus"></i>&nbsp;Add LedgerHead</Button>
					</Nav>
				</Container>
			</Navbar>
			<Row className="text-center">
				<Col md={{ span: 2, offset: 10 }}>
					<Modal show={addModel} size="md" onHide={() => { setAddModel(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title>Add LedgerHead</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<AddLedgerHead edit={user} afterFinish={() => { setAddModel(false) }} afterList={() => listdata()} />
						</Modal.Body>
					</Modal>
				</Col>
				<Col md={{ span: 2, offset: 10 }}>
					<Modal show={editModel} size="md" onHide={() => { setEditModel(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title>Edit LedgerHead</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<EditLedgerHead edit={user} afterFinish={() => { setEditModel(false) }} afterList={() => listdata()} />
						</Modal.Body>
					</Modal>
				</Col>
			</Row>
			<Row>
				<Col md={12} >
					<Table className="table mt-10 table-bordered"  >
						<thead className="text-center">
							<tr>
								<th width="5%" >S.No</th>
								<th><b>Group Name</b></th>
								<th><b>LedgerHead Name</b></th>
								<th><b>LedgerHead Type</b></th>
								<th><b>Action</b></th>
							</tr>
						</thead>
						<tbody className="text-center">
							{(data.map((item, id) => {
								return <tr>
									<td>{id + 1}</td>
									<td align="left">{item.ledger_name}</td>
									<td align="left">{item.ledger_head_name}</td>
									<td>{item.ledger_head_type}</td>
									<td>
										<Button variant="info" onClick={() => { showModel(item) }} size="sm"><i className="fas fa-edit"></i> </Button>{' '}
										<Button variant="danger" size="sm" onClick={() => handleDelete(item, item.id)}><i className="fas fa-trash"></i> </Button>{' '}
									</td>
								</tr>
							}
							))}
						</tbody>
					</Table>
				</Col>
			</Row>
		</div>
	);
};
export default AccountsLedgerHead;