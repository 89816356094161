import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Switch } from 'antd';
import { baseUrl, integerIndMobile, integerIndGst, imageUrl, lettersOnly } from '../../utils';
import ReactFileReader from 'react-file-reader';
import { CKEditor } from 'ckeditor4-react';
import TextArea from 'antd/lib/input/TextArea';

const Indicators = (props) => {

	const context = useContext(PsContext);
	const [AddUser, setAddUser] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [ViewData, setViewData] = useState(false);
	const [user, setUser] = useState([]);
	const [data, changeData] = useState([]);
	const [deleteData, setDeleteData] = useState([]);
	const [blockData, setBlockData] = useState([]);
	const [fileName, setFileName] = useState('');
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [loading, setIsLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [fieldValue, setFieldValue] = useState(false);


	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			setBtnLoading(true);
			API.post('/v1/group/addindicator', $("#frm_Login").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Indicator Added Successfully..!", "success");
					document.getElementById("frm_Login").reset();
					setBtnLoading(false);
					listdata();
				}
				else {
					toast.error("Something Went Wrong..!", "danger");
					document.getElementById("frm_Login").reset();
					setBtnLoading(false);
				}
				setIsLoaded(false);
			});
		}
		catch (error) {

		}
	}

	const handleDelete = (item, id, stu) => {

		try {
			setBtnLoading(true);
			API.post('/v1/group/deleteindicator?id=' + id + '&status=' + stu).then(res => {
				if (res['data'].status == '1') {
					var d = data.filter(dt => dt.id != id);
					changeData(d);
					setBtnLoading(false);
					listdata();
				}
				else {
					toast.error("Something Went Wrong..!", "danger");
					document.getElementById("frm_Login").reset();
					setBtnLoading(false);
				}
				setIsLoaded(false);
			});
		}
		catch (error) {

		}
	}

	useEffect(() => {
		listdata();
	}, []);

	const listdata = () => {
		try {
			API.get('v1/group/listindicator').then(res => {
				if (res['data'].data) {
					var d = res['data'].data;
					changeData(d);
				}
				else {

				}
			});
		}
		catch (error) {

		}
	}



	const handleStatusChange = (item, id, e) => {
		let status = e ? '1' : '0';

		let msg = e ? 'Do you want to activate' : 'Do you want to deactivate';

		if (!window.confirm(msg)) {
			return;
		}

		handleDelete(item, id, status)

	}



	return (
		<div className="container">

			<Row className='mt-3'>
				<Col md={8}>
					<Card className="mt-3">
						<Card.Header>
							<Card.Title>
								List Indicators
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<Table bordered>
								<thead>
									<tr>
										<th>S.No</th>
										<th>Indicators</th>
										<th>Status</th>
										<th>Manage</th>
									</tr>
								</thead>
								<tbody>
									{data.map((item, i) => {
										return <tr style={{ backgroundColor: (item.status == '0' ? '#F1EA87' : '') }}>
											<td>{i + 1}</td>
											<td>{item.indicators}</td>
											{/*<td>{item.villagename}</td>*/}
											<td>{item.status == '1' ? 'Active' : 'Inactive'}</td>
											<td align="center"><Switch checked={item.status == '1'} onChange={e => { handleStatusChange(item, item.id, e) }} /></td>
										</tr>
									})}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
				</Col>
				<Col md={4}>
					<Card className="mt-3">
						<Card.Header><Card.Title>Add Indicators</Card.Title></Card.Header>
						<Card.Body>
							<Form action="" method="post" id="frm_Login" validated={validated} onSubmit={handleSubmit}>
								<Row>
									<Col md={3}>
										Indicator
									</Col>
									<Col md={8}>
										<MDBInput
											type="text"
											size="md"
											name="indicators"
											required
											onKeyPress={lettersOnly}
										/>
									</Col>
								</Row>
								<Row>
									<Col md={12}>
										<Button type="submit" variant="success" className='mt-2 btn-block' size="md"  > Add</Button>
									</Col>
								</Row>

							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div >
	);
};
export default Indicators;