import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { lettersOnly, integerIndMobile, momentDate } from '../../utils';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { TimePicker } from 'antd';
const tdStyle = { border: '1px solid grey', borderCollapse: 'collapse', padding: '3px' }
const tdLeaveStyle = { border: '1px solid grey', borderCollapse: 'collapse', padding: '3px', backgroundColor: 'rgb(255 203 203)' }

const EditAttendance = (props) => {

	const context = useContext(PsContext);
	const [loading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [staffData, setStaffData] = useState([]);
	const [data, setData] = useState([]);
	const [user, setUser] = useState([]);
	const [editModel, setEditModel] = useState(false);
	const [month, setMonth] = useState(moment().format("YYYY-MM"));
	const [time, setTime] = useState(moment().format("HH:mm:a"));
	const [validated, setValidated] = useState(false);
	const [staffUser, setStaffUser] = useState(props.staff);

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		console.log($("#addform").serialize());
		event.preventDefault();
		if (!form.checkValidity() === true) {
			event.stopPropagation();
			setValidated(true);
			return;
		}
		try {
			console.log($("#addform").serialize());
			setBtnLoading(true);
			API.post('v1/user/editattendance', $("#addform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Attendance Updated Successfully..!", "success");
					document.getElementById("addform").reset();
					setBtnLoading(false);
					props.afterFinish();
				}
				else {
					toast.error(res['data'].message, "danger");
					setBtnLoading(false);
				}
				setIsLoading(false);
			});
		}
		catch (error) {

		}
	};

	const getValue = (field) => {
		return props.data[field] || '';
	}

	return (
		<>
			<Row>
				<Col md={12} className="mt-3">
					<Form action="" method="post" id="addform" noValidate validated={validated} onSubmit={handleSubmit} >
						<input type="hidden" name="in_time_id" value={props.data.in_time_id} />
						<input type="hidden" name="out_time_id" value={props.data.out_time_id} />
						<input type="hidden" name="staff_id" value={props.data.staff_id} />
						<input type="hidden" name="staffId" value={props.staff} />
						<input type="hidden" name="userid" value={context.adminUser.id} />
						<Row>
							<Col md={12}>
								<select className="form-control" required name="type" defaultValue={getValue('type')}>
									<option value="">Select Staff Type</option>
									<option value="Field">Field</option>
									<option value="Review">Review</option>
									<option value="Training">Training</option>
									<option value="Holiday">Holiday</option>
									<option value="Office">Office</option>
									<option value="Leave">Leave</option>
								</select>
							</Col>
						</Row>
						<Row className='mt-2'>
							<Col md={12}>
								<MDBInput
									label="Date"
									type="date"
									name="att_date"
									size="md"
									defaultValue={getValue('att_date') != '' ? getValue('att_date') : 'Date'}
								/>
							</Col>
						</Row>
						<Row className='mt-2'>
							<Col md={3}>
								<label>In Time</label>
							</Col>
							<Col md={1}>
								:
							</Col>
							<Col md={8}>
								<TimePicker type="time"
									name="att_time"
									style={{ width: "100%" }}
									label="In Time"
									use12Hours
									format="h:mm a"
									defaultValue={moment(getValue('att_time'), 'h:mm:ss A')}
								/>
								{/*<MDBInput
									label="In Time"
									type="time"
									size="md"
									name="att_time"
									defaultValue={getValue('att_time') == true ? getValue('att_time') : "In Time"}
	/>*/}
							</Col>
						</Row>
						<Row className='mt-2'>
							<Col md={3}>
								<label>Out Time</label>
							</Col>
							<Col md={1}>
								:
							</Col>
							<Col md={8}>
								<TimePicker
									type="time"
									name="out_time"
									defaultValue={moment(getValue('out_time'), 'h:mm:ss A')}
									style={{ width: "100%" }}
									lable="Out Time"
									use12Hours
									format="h:mm a" />
								{/*<MDBInput
									label="Out Time"
									type="time"
									name="out_time"
									size="md"
									defaultValue={getValue('out_time') == true ? getValue('out_time') : "Report To Time"}
/>*/}
							</Col>
						</Row>

						<Button type="submit" className="mt-3 btn-block">Update</Button>
					</Form>
				</Col>
			</Row>

		</>
	);
};
export default EditAttendance;