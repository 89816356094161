import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown, Button } from "react-bootstrap";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import API from "../../../utils/api";

import { PsDropDown } from "../../../comp";
import PsContext from "../../../context";
import { appName, setLs } from "../../../utils";
import menus from "../../menus";

const Header = () => {
  const context = useContext(PsContext);
  const [logoutModal, setLogoutModal] = useState(false);
  const [acyearData, setAcyearData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const topMenu = () => menus.top.map((item, i) => menuType(item, i));

  const menuType = (item, i) => {
    if (item.childrens && item.childrens.length > 0) {
      return dropDown(item.name, item.childrens);
    } else {
      return (
        <NavLink
          to={item.path}
          className="nav-link"
          activeClassName="top-nav-active"
          exact={item.exact}
        >
          {item.name}
        </NavLink>
      );
    }
  };

  const dropDown = (title, items) => {
    return (
      <NavDropdown title={title}>
        {items.map((item, i) => dropDownItem(item))}
      </NavDropdown>
    );
  };

  const dropDownItem = (item) => {
    if (item.divider) return <NavDropdown.Divider />;

    return (
      <NavLink className="dropdown-item" to={item.path} exact={item.exact}>
        {item.name}
      </NavLink>
    );
  };

  const logoutClick = () => {
    setLogoutModal(true);
    //context.logoutAdmin();
  };

  useEffect(() => {
    listdata();
  }, []);

  const listdata = () => {
    try {
      setIsLoading(true);
      API.get("v1/user/listaccountingyear").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setAcyearData(d);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onAcyearChange = (e) => {
    var index = acyearData.findIndex(function (obj) {
      return obj.id == e;
    });
    if (index > -1) {
      setLs("admin_acyear", JSON.stringify(acyearData[index]));
      window.location.reload();
    }
  };

  return (
    <>
      <Navbar bg="white" expand="lg" className="app-header">
        <Container fluid>
          <Navbar.Brand>{appName}</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0">{topMenu()}</Nav>

            <Nav className="ml-auto my-2 my-lg-0 nav-left">
              {/*<select name="acyear" className="form-control form-control-sm" onChange={(e)=> {onAcyearChange(e.target.value)}}>
					{acyearData.map((item, i) => {
						return <option value={item.id} selected={context.acyear.id == item.id ? true : false}>{item.value}</option>
					})}
				</select>*/}
              <NavDropdown
                title={`${context.adminUser.username}`}
                id="navbarScrollingDropdown"
                drop="start"
              >
                <NavLink to="/adminpanel" className="dropdown-item">
                  <i className="fa fa-user fa-fw ms-auto text-gray-400 fs-14px"></i>{" "}
                  My Profile
                </NavLink>
                <NavDropdown.Divider />
              </NavDropdown>
              <a className="nav-link" href="javascript:;" onClick={logoutClick}>
                <i className="fa fa-sign-out-alt fa-fw ms-auto text-gray-400 fs-16px "></i>
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <MDBModal
        show={logoutModal}
        getOpenState={(e: any) => setLogoutModal(e)}
        tabIndex="-1"
      >
        <MDBModalDialog className="modal-frame-top">
          <MDBModalContent>
            <MDBModalBody>
              <div className="d-flex justify-content-center align-items-center my-3">
                <h5 className="pe-5">Do you want to logout ? </h5>
                <Button
                  type="button"
                  size="sm"
                  onClick={() => context.logoutAdmin()}
                >
                  Yes
                </Button>
                &emsp;
                <Button
                  type="button"
                  size="sm"
                  variant="info"
                  onClick={() => setLogoutModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default Header;
