import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Table, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import { integerIndMobile, baseUrl, decimalKeyPress } from '../../utils';
import moment from 'moment';

const EditMaterials = (props) => {

	const context = useContext(PsContext);
	const [btnLoading, setBtnLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [plasticData, setPlasticData] = useState([]);
	const [materialData, setMaterialData] = useState([]);
	const [data, setData] = useState([]);
	const [edit, setEdit] = useState([]);
	const [totalAmount, setTotalAmount] = useState(props.data.total_amount);
	const [kg, setKg] = useState(props.data.plastic_kg);
	const [valueData, setValueData] = useState(0);

	useEffect(() => {
		listmaterial();
		setTotalAmount(props.data.total_amount);
	}, []);

	useEffect(() => {
		console.log(totalAmount);
		let total = kg * props.data.plastic_amount;
		setTotalAmount(total);
	}, [kg]);

	const showModel = (item) => {
		setEditModel(true);
		setEdit(item);
	}

	const listmaterial = () => {
		try {
			setIsLoading(true);
			var form = new FormData();
			form.append('acyear', context.acyear.id);
			form.append('group_id', props.data.group_id);
			form.append('plasticValue', JSON.stringify(props.value));
			API.post('v1/plastic/listmaterialview', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					console.log(d);
					setData(d);
					setMaterialData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (er) {

		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			setBtnLoading(true);
			console.log($("#addform").serialize());
			API.post('v1/plastic/editplasticmaterial', $("#addform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Updated Successfully..!", "success");
					//document.getElementById("addform").reset();
					setBtnLoading(false);
					props.afterFinish();
					props.afterList();
				}
				else {
					toast.error(res['data'].message, "danger");
					setBtnLoading(false);
				}
				setIsLoading(false);
			});
		}
		catch (error) {

		}
	};

	// const handleSubmit = (event) => {
	// 	event.preventDefault();
	// 	try {
	// 		setBtnLoading(true);
	// 		API.post('v1/plastic/editplasticmaterial', $("#editform").serialize()).then(res => {
	// 			if (res['data'].status == '1') {
	// 				toast.success("Updated Successfully", "success");
	// 				setBtnLoading(false);
	// 			}
	// 			else {
	// 				toast.error("Something Went Wrong", "danger");
	// 				setBtnLoading(false);
	// 			}
	// 		});
	// 	}
	// 	catch (error) {

	// 	}
	// }

	console.log(props.data);

	const getTotalAmount = (e) => {
		let s = e;
		setKg(s);
		/* let total = s * totalAmount;
		setTotalAmount(total); */
	}

	console.log(totalAmount);

	return (
		<>
			<div className="container">
				<Form action="" method="post" id="addform" validated={validated} onSubmit={handleSubmit} >
					<input type="hidden" name="id" value={props.data.id} />
					<Row>
						<Col md={3}>
							<MDBInput
								label="Date"
								type="date"
								name="collection_date"
								size="sm"
								defaultValue={props.data.collection_date}
							/>
						</Col>
						<Col md={3}>
							<MDBInput
								label="Material Type"
								type="text"
								name="pname"
								size="sm"
								disabled
								defaultValue={props.data.pname}
							/>
						</Col>
						<Col md={3}>
							<MDBInput
								label={props.type == 'others pcs' ? "Pieces" : "KG"}
								type="text"
								name="plastic_kg"
								size="sm"
								//onChange={(e) => getTotalAmount(e.target.value)}
								defaultValue={props.data.plastic_kg}
							/>
						</Col>
						<Col md={3}>
							<MDBInput
								label="Amount"
								type="text"
								name="total_amount"
								size="sm"
								defaultValue={props.data.total_amount}
							/>
						</Col>
						<Col md={3}>
							<MDBInput
								label="Plastic Amount"
								type="text"
								name="plastic_amount"
								size="sm"
								readonly
								className='mt-2'
								defaultValue={props.data.plastic_amount}
							/>
						</Col>
					</Row>
					<button type="submit" style={{ float: 'right' }} className="btn btn-success mt-2" disabled={btnLoading}><i className='fa fa-check'></i>&emsp;
						{btnLoading ? 'Please wait..' : 'Update'}</button>
				</Form>
			</div>
		</>
	);
};

export default EditMaterials; 