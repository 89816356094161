import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col,  Modal , Form, Navbar,Container,Nav} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { integerIndMobile, lettersOnly } from '../../utils';

const AddTrack = (props) =>{
	
	const context = useContext(PsContext);
	const [submit, sethandleSubmit] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [data, changeData] = useState([]);

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		if (!form.checkValidity() === true) {
		  event.stopPropagation();
		  setValidated(true);
		  return;
		}
		try{
			setBtnLoading(true);
			API.post('v1/user/addtrack',$("#addform").serialize()).then(res=>{
				if (res['data'].status == '1') {
				  toast.success("Enquiry Updated Successfully..!", "success");
				  document.getElementById("addform").reset();
				  setBtnLoading(false);
				}
				else {
				  toast.error("Something Went Wrong..!", "danger");
				  document.getElementById("addform").reset(); 
				}		
				setIsLoading(false);
			});
		}
		catch(error){
			
		}
	};
	

	return(
		<>
			
			<div class="container">
			<Navbar bg="white" expand="lg">
				<Container>
					<Navbar.Brand > Tracking</Navbar.Brand>
					<Nav  className="justify-content-end">
						<Link to={{
							pathname: '/staff'
						}}>Manage</Link>
					</Nav>
				</Container>
				</Navbar>
				<Row>
					<Col md={2}>
					</Col>
					<Col md={8}>
					
						<Card>
						<Card.Body>
							<h3>Add Track</h3><hr />
								<Form action="" method="post" id="addform" noValidate validated={validated} onSubmit={handleSubmit} >
							<Row className="mt-3">
								<Col md={6}>
									<Col>
										<MDBInput
											label="Full Name"
											type="text"
											name="name"
											size="lg"
											onKeyPress={lettersOnly}
											required
										/>
									</Col>
									
									<Col className="mt-3">
										<MDBInput
											label="Email"
											type="text"
											name="email"
											size="lg"
											required
										/>
									</Col>
									
									<Col className="mt-3">
										<MDBInput
											label="Mobile"
											type="number"
											name="mobile"
											size="lg"
											onKeyPress={integerIndMobile}
											required
										/>
									</Col>
									
									<Col className="mt-3">
										<MDBInput
											label="Address"
											type="text"
											name="address"
											size="lg"
											required
										/>
									</Col>
									
									<Col className="mt-3">
										<MDBInput
											label="DOB"
											type="date"
											name="dob"
											size="lg"
											required
										/>
									</Col>
									
									<Col className="mt-3">
										<MDBInput
											label="Education Qualification"
											type="text"
											name="edu_qualification"
											size="lg"
											onKeyPress={lettersOnly}
											required
										/>
									</Col>
								 </Col>
									
									<Col md={6}>
									
									<Col className="mt-0">
										<MDBInput
											label="Year of Experience"
											type="text"
											name="year_ex"
											size="lg"
											onKeyPress={lettersOnly}
											required
										/>
									</Col>
									
									<Col className="mt-3">
										<MDBInput
											label="Upload CV"
											type="text"
											name="upload_cv"
											size="lg"
											onKeyPress={lettersOnly}
											required
										/>
									</Col>
									
									<Col className="mt-3">
										<MDBInput
											label="Date Of Appointment"
											type="date"
											name="doa"
											size="lg"
											required
										/>
									</Col>
									
									<Col className="mt-3">
										<MDBInput
											label="Designation"
											type="text"
											name="designation"
											size="lg"
											onKeyPress={lettersOnly}
											required
										/>
									</Col>
									
									<Col className="mt-3">
									<Row>
									<Col md={6}>
										<label>Mobile APP Access:</label><br />
											<div className="form-check form-check-inline">
											  <input  type="radio" name="maa" value="Male" className="form-check-input"/>
											  <label for="maa">
												Yes
											  </label>
											</div>
											<div className="form-check form-check-inline">
											  <input  type="radio" name="maa" value="Female" className="form-check-input"/>
											  <label  for="maa">
												No
											  </label>
											</div>
									</Col>
									<Col md={6}>
											<label>Gender:</label><br />
											<div className="form-check form-check-inline">
											  <input  type="radio" name="gender" value="Male"  className="form-check-input"/>
											  <label for="gender">
												Male
											  </label>
											</div><br />
											<div className="form-check form-check-inline">
											  <input  type="radio" name="gender" value="Female"  className="form-check-input"/>
											  <label  for="gender">
												Female
											  </label>
											</div><br />
											<div className="form-check form-check-inline">
											  <input  type="radio" name="gender" value="TG"  className="form-check-input"/>
											  <label  for="gender">
												TransGender
											  </label>
											</div>
									</Col>
									</Row>
									</Col>
								</Col>
									<Col className="mt-3">
										<button  class="btn btn-success "  style={{float: 'right'}} type="submit" disabled={btnLoading}>
										{btnLoading ? 'Please wait..' : 'Save'}</button>
									</Col>
							</Row>
						</Form>
					</Card.Body>
					</Card>
			</Col>
			</Row>
			</div>
		</>
	);
};

export default AddTrack;