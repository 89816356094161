import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col,  Modal , Form} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import {integerIndMobile, lettersOnly} from '../../utils';
import {baseUrl, imageUrl} from '../../utils';
import ReactFileReader from 'react-file-reader';
import PLUSIMAGE from '../../assets/css/images/plus.png';
import {CKEditor} from 'ckeditor4-react';

const EditBank = (props) =>{

	const context = useContext(PsContext);
	const [submit, sethandleSubmit] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [imgData, setImgData] = useState(props.images);
	const [removeData, setRemoveData] = useState([]);
	const [feedback, setFeedback] = useState(props.edit.feedback);
	const [photos, setPhotos] = useState([]);
	const [data, changeData] = useState([]);
	const [stdata, stChangeData] = useState([]);
	const [staffData, setStaffData] = useState([]);
	
	useEffect(() => {
        setFeedback(props.edit.feedback);
        listGroupName(); 
		listLedgerHead();
    } , [] );
	

	const listLedgerHead = () => {
		try {
            setIsLoading(true);
			API.get('v1/user/list/ledgerthead').then(res => {
			if (res['data'].status == '1') {
                var d = res['data'].data;
				stChangeData(d);
                setIsLoading(false);
				}
			else {
				setIsLoading(false);
				}
			});
		}
		catch (error) {
            setIsLoading(false);
		}
	};
	
	const listGroupName = () => {
		try {
			setIsLoading(true);
			API.get('v1/user/list/ledgergroup').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};
	
	const handleSubmit = (event) => {
		event.preventDefault();
		try{
			setBtnLoading(true);
			API.post('v1/user/edit/bankaccounts',$("#meetform").serialize()).then(res=>{
				if (res['data'].status == '1') {
				  toast.success("Bank Updated Successfully..!", "success");
				  document.getElementById("meetform").reset();
				  setBtnLoading(false);
				  props.afterFinish();
				  props.afterList();
				}	
				else {
				  toast.error("Something Went Wrong..!", "danger");
				  document.getElementById("editform").reset();
				}
				setIsLoading(false);
			});
		}
		catch(error){
			toast.error("Something Went Wrong..!", "danger");
		}
	};
	
	
	const handleDeleteClick = (id) =>  {
		try {
           var d = imgData.filter((item,i)=> item.id!=id);
			setImgData(d); 
			var r = [];
			r.push(id);
			const arr = [...removeData, ...r];
			setRemoveData(arr);
		}
		catch (error) {
            setIsLoading(false);
		}
	}
	
	const onFeedbackChange = (evt) => {
		setFeedback(evt.editor.getData());
	};

	return(
		<>
			<div className="container">
			<Form action="" method="post" id="meetform" validated={validated} onSubmit={handleSubmit} >
			<input type="hidden" name="group" value={props.edit.id} />
			<input type="hidden" name="id" value={props.edit.id} />
				<Row>
					<Col md={12} className="mt-3">
						<MDBInput
							label="Bank Name"
							type="text"
							name="ledger_head_name"
							size="sm"
							required
							onKeyPress={lettersOnly}
							defaultValue={props.edit.ledger_head_name}
						/>
					</Col>
					<Col md={12} className="mt-3">
						<MDBInput
							label="Account No"
							type="text"
							name="account_no"
							size="sm"
							required
							defaultValue={props.edit.account_no}
						/>
					</Col>
				</Row>
				<Col className="mt-3">
					<button  className="btn btn-outline-black btn-block"  type="submit" disabled={btnLoading}>
							{btnLoading ? 'Please wait..' : 'Update'}</button>
				</Col>
			</Form>
			</div>
		</>
	);
};

export default EditBank;