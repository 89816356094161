import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Modal,
  Form,
  Navbar,
  Container,
  Nav,
  Button,
  Table,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { MDBInput } from "mdb-react-ui-kit";
import API from "../../utils/api";
import $ from "jquery";
import moment from "moment";
import { Link } from "react-router-dom";
import PsContext from "../../context";
import { PSListItem } from "../../comp";
import { lettersOnly, momentDate } from "../../utils";
import EditMaterial from "./editMaterial";
import ViewMaterial from "./viewMaterial";

const MaterialCollectionReport = (props) => {
  const context = useContext(PsContext);
  const [validated, setValidated] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [materialData, setMaterialData] = useState([]);
  const [edit, setEdit] = useState([]);
  const [view, setView] = useState([]);
  const [plasticData, setPlasticData] = useState([]);
  const [plasticKg, setPlasticKg] = useState([]);
  const [editModel, setEditModel] = useState(false);
  const [viewModel, setViewModel] = useState(false);
  const [stData, stChangeData] = useState([]);
  const [data, setData] = useState([]);
  const [materialType, setMaterialType] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [totalAmountData, setTotalAmountData] = useState([]);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");

  useEffect(() => {
    //listdata();
    listgroupname();
  }, []);

  const filterChange = (e) => {
    var val = e.target.value;
    if (val == "") {
      setMaterialData(data);
    } else {
      let ndata = data.filter(function (item) {
        return item.group_id == val;
      });
      setMaterialData(ndata);
    }
  };

  const showModel = (item) => {
    console.log(item);
    listplastic(item);
  };

  const showViewModel = (item) => {
    setView(item);
    setViewModel(true);
  };

  const hideViewModel = () => {
    setIsLoading(true);
    setViewModel(false);
    setIsLoading(false);
  };

  const listplastic = (item) => {
    try {
      setIsLoading(true);
      var form = new FormData();
      form.append("acyear", context.acyear.id);
      form.append("materialid", item.group_id);
      API.post("v1/plastic/listmaterialbyid", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setPlasticData(d);
          setIsLoading(false);
          setEdit(item);
          setEditModel(true);
        } else {
          setIsLoading(false);
        }
      });
    } catch (er) {}
  };

  const onDateSearch = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post("v1/plastic/listmaterials", $("#filer_Date").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            var d = res["data"].data;
            var f = d.map(function (obj) {
              return obj.group_id;
            });
            console.log(f);
            listmaterial(f);
            setData(d);
            setMaterialData(d);
            setBtnLoading(false);
          } else {
            setData([]);
            setMaterialData([]);
            toast.error("No Data Found", "danger");
            setBtnLoading(false);
          }
        }
      );
    } catch (error) {}
  };

  const listgroupname = () => {
    try {
      setIsLoading(true);
      API.get("v1/group/listgroupplastic").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          stChangeData(d);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  // const listdata = () => {
  // 	try {
  // 		setIsLoading(true);
  // 		var form = new FormData();
  // 		form.append('acyear', context.acyear.id);
  // 		API.post('v1/plastic/listmaterials', form).then(res => {
  // 			if (res['data'].status == '1') {
  // 				var d = res['data'].data;
  // 				setData(d);
  // 				//setMaterialData(d);
  // 				setIsLoading(false);
  // 			}
  // 			else {
  // 				setIsLoading(false);
  // 			}
  // 		});
  // 	}
  // 	catch (error) {
  // 		setIsLoading(false);
  // 	}
  // };

  const onFromChange = (event) => {
    var fromdate = $("#fromdate").val();
    setFromDate(fromdate);
    var toMinDate = moment(fromdate).format("YYYY-MM-DD");
    if (fromdate != "") {
      $("#todate").attr("min", toMinDate);
    }
  };

  // const getPlasticKg = (Id) => {
  // 	console.log(Id);
  // 	try {
  // 		setIsLoading(true);
  // 		API.post('v1/plastic/listplastickg?Id=' + Id).then(res => {
  // 			if (res['data'].status == '1') {
  // 				var d = res['data'].data;
  // 				setPlasticKg(d);
  // 				setIsLoading(false);
  // 			}
  // 			else {

  // 			}
  // 		})
  // 	}
  // 	catch (er) {

  // 	}
  // }

  const listmaterial = (grpId) => {
    console.log(grpId);
    try {
      setIsLoading(true);
      var form = new FormData();
      form.append("acyear", context.acyear.id);
      form.append("from", fromDate);
      form.append("to", toDate);
      form.append("group_id", grpId.join(","));
      form.append("plasticValue", JSON.stringify(materialType));
      API.post("v1/plastic/listmaterialcollectionreport", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setTotalData(d);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (er) {}
  };

  const valueData = (e) => {
    setMaterialType(e);
  };

  const getTotalValue = (id) => {
    var total = totalData;
    var s = total.filter(function (obj) {
      return obj.group_id == id;
    });
    var total_amount = 0;
    s.forEach((obj) => {
      total_amount = total_amount + parseFloat(obj.total_amount);
    });
    return parseFloat(total_amount).toFixed(2);
  };

  const onTodate = (e) => {
    setToDate(e.target.value);
  };

  const grandTotalQty = () => {
    let s = materialData;
    let total = 0;
    s.map((item) => (total = parseFloat(total) + parseFloat(item.pkg)));
    console.log(materialType);
    if (materialType == "others pcs") {
      return parseInt(total);
    } else {
      return parseFloat(total).toFixed(2);
    }
  };

  const grandTotalAmount = () => {
    let s = totalData;
    let total = 0;
    s.map(
      (item) => (total = parseFloat(total) + parseFloat(item.total_amount))
    );
    console.log(total);
    return parseFloat(total).toFixed(2);
  };

  let total = 0;

  return (
    <>
      <div className="container">
        <Navbar bg="white" expand="md">
          <Container>
            <Navbar.Brand>Material Collection Report </Navbar.Brand>
          </Container>
        </Navbar>
        <Form
          action=""
          method="post"
          id="filer_Date"
          validated={validated}
          onSubmit={onDateSearch}
          style={{ padding: "0px 10px" }}
        >
          <input type="hidden" name="acyear" value={context.acyear.id} />
          <input
            type="hidden"
            name="plasticValue"
            value={JSON.stringify(materialType)}
          />
          <Row className="mt-3" style={{ margin: "5px" }}>
            <Col md={3}>
              <label>From Date:</label>
              <MDBInput
                type="date"
                id="fromdate"
                name="fromdate"
                onChange={onFromChange}
              />
            </Col>
            <Col md={3}>
              <label>To Date:</label>
              <MDBInput
                type="date"
                id="todate"
                name="todate"
                onChange={onTodate}
              />
            </Col>
            <Col md={2}>
              <label>Material type</label>
              <select
                className="form-control form-control-sm"
                name="material_type"
                onChange={(e) => {
                  valueData(e.target.value);
                }}
              >
                <option value="">Select</option>
                <option value="plastic kg">Plastic (Kg)</option>
                <option value="others kg">Non Plastic (Kg)</option>
                <option value="others pcs">Non Plastic (Piece)</option>
              </select>
            </Col>
            {/* <Col md={1}>
							<label>Weight</label>
							{materialType == 'plastic' ?
								<select className="form-control form-control-sm" name="weight" >
									<option value="">Select</option>
									<option value="kg">Kg</option>
								</select> :
								<select className="form-control form-control-sm" name="weight" >
									<option value="">Select</option>
									<option value="kg">Kg</option>
									<option value="pcs">Pieces</option>
								</select>}
						</Col> */}
            <Col md={1}>
              <Button
                type="sbumit"
                className="mt-4"
                variant="info"
                size="sm"
                style={{ float: "right" }}
                disabled={btnLoading}
              >
                {btnLoading ? "Please wait.." : "Search"}
              </Button>
            </Col>

            <Col md={2}>
              <label>Filter By Group</label>
              <select
                className="form-control form-control-sm"
                name="group"
                onChange={filterChange}
              >
                <option value="">Group</option>
                {stData.map((user, i) => {
                  return <option value={user.id}>{user.grp_name}</option>;
                })}
              </select>
            </Col>
          </Row>
        </Form>
        <Row className="mt-3" style={{ margin: "5px" }}>
          <Col md={12} className="mt-3">
            {(materialData.length > 0 &&
              materialType.length > 0 &&
              materialType == "plastic kg") ||
            materialType == "others kg" ||
            materialType == "others pcs" ? (
              <Table bordered className="mt-10" size="sm">
                <thead className="text-center">
                  {materialData.length > 0 ? (
                    <tr>
                      <th>S.No</th>
                      {/* <th>Material Id</th> */}
                      {/* <th>Collection Date</th> */}
                      <th>Group</th>
                      {/* <th>Type</th> */}
                      <th>Quantity</th>
                      <th>Total Amount</th>
                      <th>Manage</th>
                    </tr>
                  ) : (
                    ""
                  )}
                </thead>
                <tbody className="text-center">
                  {materialData.map((item, i) => {
                    let d = materialData.length;
                    {
                      materialType == "others pcs"
                        ? (total += parseFloat(item.pkg).toFixed(0))
                        : (total += parseFloat(item.pkg).toFixed(2));
                    }
                    return (
                      <tr>
                        <td>{d - i}</td>
                        {/* <td>{item.material_id}</td> */}
                        {/* <td>{momentDate(item.collection_date, 'DD-MM-YYYY')}</td> */}
                        <td align="left">{item.grp_name}</td>
                        {/* <td style={{ textTransform: 'capitalize' }}>{item.type}<br />
											{item.type == 'event' ? <b>{item.eventname}</b> :
												item.type == 'vendor' ? <b>{item.vendorname}</b> : ''}
										</td> */}
                        {materialType == "others pcs" ? (
                          <td align="right">{parseInt(item.pkg)}</td>
                        ) : (
                          <td align="right">{item.pkg}</td>
                        )}
                        <td align="right">{getTotalValue(item.group_id)}</td>
                        <td>
                          {/*<Button variant="success" size="sm" onClick={() => { showModel(item) }}><i className="fas fa-edit"></i></Button>&emsp;*/}
                          {materialType.length > 0 ? (
                            <Button
                              variant="warning"
                              size="sm"
                              onClick={() => {
                                showViewModel(item);
                              }}
                            >
                              <i className="fas fa-eye"></i>
                            </Button>
                          ) : (
                            <Button
                              variant="warning"
                              size="sm"
                              onClick={() => {
                                showViewModel(item);
                              }}
                              disabled
                            >
                              <i className="fas fa-eye"></i>
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {materialData.length > 0 ? (
                    <tr>
                      <td colspan="2">
                        <b>
                          <strong>Total</strong>
                        </b>
                      </td>
                      <td align="right">
                        <b>
                          {" "}
                          <strong>{grandTotalQty()}</strong>
                        </b>
                      </td>
                      <td align="right">
                        <b>
                          <strong>{grandTotalAmount()}</strong>
                        </b>
                      </td>
                      <td></td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </Table>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Modal
          show={editModel}
          size="lg"
          onHide={() => {
            setEditModel(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Material</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditMaterial
              data={edit}
              afterFinish={() => {
                setEditModel(false);
              }}
            />
          </Modal.Body>
        </Modal>
        <Modal show={viewModel} size="lg" onHide={hideViewModel}>
          <Modal.Header closeButton>
            <Modal.Title>View Material</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ViewMaterial
              data={view}
              value={materialType}
              from={fromDate}
              to={toDate}
              afterFinish={() => {
                setViewModel(false);
              }}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default MaterialCollectionReport;
