import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import EditVendor from './editVendor';
import ViewVendor from './viewVendor';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { triggerFocus } from 'antd/lib/input/Input';

const Vendor = (props) => {

    const context = useContext(PsContext);
    const [AddUser, setAddUser] = useState(false);
    const [editModel, setEditModel] = useState(false);
    const [ViewData, setViewData] = useState(false);
    const [user, setUser] = useState([]);
    const [view, setView] = useState([]);
    const [data, changeData] = useState([]);
    const [loading, setIsLoading] = useState(false);

    useEffect(() => {
        listdata();
    }, []);

    const showModel = (item) => {
        setUser(item);
        setEditModel(true);
    }

    const viewModel = (item) => {
        setView(item);
        setViewData(true);
    }

    const listdata = () => {
        try {
            setIsLoading(true);
            API.get('v1/vendor/listvendor').then(res => {
                if (res['data'].status == '1') {
                    var d = res['data'].data;
                    changeData(d);
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                }
            });
        }
        catch (error) {
            setIsLoading(false);
        }
    };

    const handleDelete = (item, id) => {
        if (window.confirm("Do You Want To Delete"))
            try {
                setIsLoading(true);
                API.post('v1/vendor/delete?id=' + id).then(res => {
                    if (res['data'].status == '1') {
                        toast.success("Vendor Deleted Successfully");
                        // var d = res['data'].data;
                        var d = data.filter(dt => dt.id != id);
                        setIsLoading(false);
                        listdata();
                    }
                });
            }
            catch (er) {

            }
    }

    return (
        <div className="container" >
            <Navbar bg="white" expand="lg">
                <Container>
                    <Navbar.Brand >Vendor / Customer Management</Navbar.Brand>
                    <Nav className="justify-content-end">
                        <Link
                            to={{
                                pathname: '/addvendor'
                            }}>
                            Add
                        </Link>
                    </Nav>
                </Container>
            </Navbar><br />

            <Row className="text-center">
                <Col md={{ span: 2, offset: 10 }}>
                    <Modal show={editModel} onHide={() => { setEditModel(false) }} backdrop="static">
                        <Modal.Header closeButton >
                            <Modal.Title >Edit Vendor</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EditVendor data={user} afterFinish={() => { setEditModel(false) }} afterList={() => listdata()} />
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={ViewData} onHide={() => { setViewData(false) }} backdrop="static">
                        <Modal.Header closeButton >
                            <Modal.Title >View Vendor</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ViewVendor data={view} />
                        </Modal.Body>
                    </Modal>
                </Col>
            </Row>

            <Table bordered className="mt-10">
                <thead className="text-center">
                    <tr>
                        <th>S.No</th>
                        <th><b>Customer Name</b></th>
                        <th><b>Mobile No</b></th>
                        <th><b>Address</b></th>
                        <th><b>Status</b></th>
                        <th ><b>Action</b></th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    {(data.map((item, id) => {
                        return <tr>
                            <td>{id + 1}</td>
                            <td align="left">{item.customername}</td>
                            <td>{item.mobile}</td>
                            <td align="left">{item.address}</td>
                            <td align="left">{item.activestatus}</td>
                            <td><Button variant="success" onClick={() => { showModel(item) }} size="sm"><i class="fas fa-edit"></i>  </Button>{' '}
                                <Button variant="primary" size="sm" onClick={() => viewModel(item, item.id)}><i class="fas fa-eye"></i>  </Button>{' '}
                                <Button variant="danger" size="sm" onClick={() => handleDelete(item, item.id)}><i class="fas fa-trash"></i>  </Button>{' '}
                            </td>
                        </tr>
                    }))}
                </tbody>
            </Table>
        </div>
    );
};

export default Vendor;