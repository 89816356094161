import React, { useState, useContext, useEffect } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Spin } from "antd";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import { baseUrl, momentDate } from "../../utils";

const PaymentOut = (props) => {
  const context = useContext(PsContext);

  const [validated, setValidated] = useState(false);
  const [loader, setLoader] = useState(false);
  const [ledgers, setLdgers] = useState([]);

  const [groups, setGroups] = useState([]);

  const [by, setBy] = useState([]);

  const [acc, setAcc] = useState([]);

  const [amount, setAmount] = useState(null);
  const [selAccountBy, setSelAccountBy] = useState("1");

  useEffect(() => {
    loadData();
    if (props.withGroup) loadGroups();
  }, []);

  const loadData = () => {
    setLoader(true);
    var form = new FormData();
    form.append("type", "all");

    API.post("v1/acc/ledgers", form).then((res) => {
      if (res["data"].errorcode == "200") {
        setLdgers(res["data"].data);
        var m = res["data"].data.filter(
          (item) => item.id == "1" || item.id == "29"
        );
        var n = res["data"].data.filter(
          (item) =>
            item.ledger_head_type == "party" && item.ledger_group_id == "4"
        );
        setAcc(n);
        setBy(m);
      } else {
        toast.error(res["data"].content || "Error");
      }
      setLoader(false);
    });
  };

  const loadGroups = () => {
    setLoader(true);

    API.get("v1/acc/groups").then((res) => {
      if (res["data"].errorcode == "200") {
        setGroups(res["data"].data);
      }
      setLoader(false);
    });
  };

  const getAllowedHead = (allowed_account_by) => {
    let d = acc.filter((item) => item.allowed_account_by == allowed_account_by);
    console.log(d);
    return d.map((item) => (
      <option value={item.id}> {item.ledger_head_name} </option>
    ));
  };

  const linkClick = (page) => {
    if (props.onAction) props.onAction(page);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setLoader(true);

    API.post("v1/acc/save_voucher", $("#mob_frm_PaymentOut").serialize()).then(
      (res) => {
        if (res["data"].errorcode == "200") {
          toast.success(res["data"].content || "Success");
          setAmount(null);
          document.getElementById("mob_frm_PaymentOut").reset();

          if (props.afterFinish) props.afterFinish();
        } else {
          toast.error(res["data"].content || "Error");
        }
        setLoader(false);
      }
    );
  };

  return (
    <>
      <Spin spinning={loader}>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          id="mob_frm_PaymentOut"
        >
          <input type="hidden" name="type" value="payment_out" />

          {!props.withGroup && (
            <>
              <input
                type="hidden"
                name="group_id"
                value={props.match.params.groupid}
              />
              <input
                type="hidden"
                name="staff_id"
                value={props.match.params.userid}
              />
            </>
          )}

          {props.withGroup && (
            <Row className="mb-2">
              <input type="hidden" name="staff_id" value={0} />
              <Col>
                <label>Group Name</label>
                <Form.Control as="select" name="group_id" required>
                  <option value="">-Select-</option>
                  {groups.map((item) => (
                    <option value={item.id}> {item.grp_name} </option>
                  ))}
                  ;
                </Form.Control>
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <label>Payment By</label>
              <Form.Control
                as="select"
                name="acc_by"
                required
                onChange={(e) => setSelAccountBy(e.target.value)}
              >
                {by.map((item) => (
                  <option value={item.id}> {item.ledger_head_name} </option>
                ))}
                ;
              </Form.Control>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <label>Under the Head</label>
              <Form.Control as="select" name="acc_head" required>
                <option value="">-Select-</option>
                {getAllowedHead(selAccountBy)}
              </Form.Control>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <label>Payment On</label>
              <Form.Control
                type="date"
                name="received_date"
                defaultValue={momentDate(new Date(), "YYYY-MM-DD")}
                max={momentDate(new Date(), "YYYY-MM-DD")}
                required
                readOnly
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <label>Amount</label>
              <Form.Control
                type="number"
                name="amount"
                required
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <label>Narration</label>
              <Form.Control as="textarea" name="narration" rows="4" />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <div className="text-center">
                <Button type="submit" className="btn-block" disabled={loader}>
                  Save Payment Out
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};
export default withRouter(PaymentOut);
