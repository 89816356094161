import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col,  Modal , Form} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import {integerIndMobile, lettersOnly} from '../../utils';
import {baseUrl} from '../../utils';
import ReactFileReader from 'react-file-reader';
import PLUSIMAGE from '../../assets/css/images/plus.png';
import {CKEditor} from 'ckeditor4-react';

const AddReport = (props) =>{

	const context = useContext(PsContext);
	const [submit, sethandleSubmit] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [feedback, setFeedback] = useState('');
	const [photos, setPhotos] = useState([]);
	const [photos1, setPhotos1] = useState([]);
	const styles = {
		imgStyle : {
			border : '1px solid #d8d8d8',margin : '10px 10px 0px 0px',height : '120px'
		},
		plusImgStyle : {
			padding:'10px',border : '1px solid #d8d8d8',margin : '10px',backgroundColor : '#f1f1f1',cursor : 'pointer'
		},
		btnStyle : {
			position: 'absolute',
			bottom: '175px',
			left: '132px',
			borderRadius: '32px',
			cursor : 'pointer'
		}
	}
	
	const handleSubmit = (event) => {
		event.preventDefault();
		try{
			setBtnLoading(true);
			API.post('v1/event/addeventreport',$("#reportform").serialize()).then(res=>{
				if (res['data'].status == '1') {
				  toast.success("Event Updated Successfully..!", "success");
				  document.getElementById("reportform").reset();
				  const vrl=baseUrl+'v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Event Report Added Successfully');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
					  method: 'post',
					  url: vrl,
					  data : formData
					};
					axios(config)
					.then(function (response) {
					  console.log(JSON.stringify(response.data));
					})
					.catch(function (error) {
					  console.log(error);
					});	
				  setBtnLoading(false);
				  props.afterFinish();
				  props.afterList();
				}	
				else {
				  toast.error("Something Went Wrong..!", "danger");
				  document.getElementById("editform").reset();
				}
				setIsLoading(false);
			});
		}
		catch(error){
			toast.error("Something Went Wrong..!", "danger");
		}
	};
	
	const getFiles = (files) => {
		var d = [];
		var d1 = [];
		if(photos.length > 0){
			for(var i=0;i<(files.base64).length;i++){
				d.push(files.base64[i]);
			}
			const arr = [...photos, ...d];
			setPhotos(arr);
		}else{
			setPhotos(files.base64);
		}

		if(photos1.length > 0){
			for(var i=0;i<files.length;i++){
				d1.push(files);
			}
			const arr1 = [...photos1, ...d1];
			setPhotos1(arr1);
		}else{
			setPhotos1(files.base64);
		}
	}
	
	const handleRemove = (index) => {
		var d = photos.filter((item,i)=> i!=index);
		setPhotos(d);
		var d1 = photos1.filter((item1,i1)=> i1!=index);
		setPhotos1(d1);
	}
	
	const onFeedbackChange = (evt) => {
		setFeedback(evt.editor.getData());
	};

	return(
		<>
			<div className="container">
			<Form action="" method="post" id="reportform" validated={validated} onSubmit={handleSubmit} >
			<input type="hidden" name="event" value={props.edit.id} />
			<input type="hidden" name="feedback" value={feedback} />
			<input type="hidden" name="files" value={JSON.stringify(photos1)} />
					<Row>
						<Col md={6} >
							<MDBInput
								label="Event Name"
								type="text"
								name="eventname"
								size="lg"
								disabled
								defaultValue={props.edit.eventname}
							/>
						</Col>
						<Col md={6}>
							<MDBInput
								label="No of Participants"
								type="number"
								name="participants"
								size="lg"
								required
							/>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="Special Invitees"
								type="text"
								name="invitees"
								size="lg"
							/>
						</Col>
						<Col md={12} className="mt-3">
							<CKEditor
								data={feedback}
								onChange={onFeedbackChange}
								config={{ extraPlugins: 'iframe', extraAllowedContent: '*(*);*{*}', allowedContent: true }}
							/>
						</Col>
					</Row>
					<Row className="mt-3">
						<Col md={12}>
							<label>Photo Upload</label>
						</Col>
					</Row>
					<Row>
						{photos.map((item,i)=>{
							return(<Col md={2} style={{maxWidth : '15%'}}>
									<img src={item} width="120px" style={styles.imgStyle} /><br />
									<button type="button" className="btn btn-danger btn-block btn-sm" style={{marginLeft : '10px'}} onClick={()=> {handleRemove(i)}}>Remove</button>
								</Col>);
						})}
						<Col md={2}>
							<ReactFileReader fileTypes={[".png",".jpg",".jpeg"]} multipleFiles={true} base64={true} handleFiles={getFiles}>
								<img src={PLUSIMAGE} width="120px" style={styles.plusImgStyle} />
							</ReactFileReader>
						</Col>
					</Row>
					<Col className="mt-3">
						<button  className="btn btn-outline-black btn-block"  type="submit" disabled={btnLoading}>
								{btnLoading ? 'Please wait..' : 'Save'}</button>
					</Col>
			</Form>
			</div>
		</>
	);
};

export default AddReport;