import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import { integerIndMobile, baseUrl, decimalKeyPress } from '../../utils';

const EditMaterial = (props) => {

	const context = useContext(PsContext);
	const [btnLoading, setBtnLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [plasticData, setPlasticData] = useState([]);
	const [materialData, setMaterialData] = useState([]);
	const [data, setData] = useState([]);

	useEffect(() => {
		listplastic();
		listmaterial();
	}, []);

	const listplastic = () => {
		try {
			setIsLoading(true);
			var form = new FormData();
			form.append('acyear', context.acyear.id);
			API.post('v1/plastic/listplastic', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setPlasticData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (er) {

		}
	};

	const listmaterial = () => {
		try {
			setIsLoading(true);
			var form = new FormData();
			form.append('acyear', context.acyear.id);
			form.append('materialid', props.data.group_id);

			API.post('v1/plastic/listmaterialbyid', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setData(d);
					setMaterialData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (er) {

		}
	};

	const handleSubmit = (event) => {
		console.log('hii');
		event.preventDefault();
		try {
			setBtnLoading(true);
			API.post('v1/plastic/editmaterial', $("#editform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Updated Successfully", "success");
					setBtnLoading(false);
					const vrl = baseUrl + '/v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Edit Plastic Material');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
						method: 'post',
						url: vrl,
						data: formData
					};

					axios(config)
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
					props.afterFinish();
					props.afterList();
				}
				else {
					toast.error("Something Went Wrong", "danger");
					setBtnLoading(false);
				}
			});
		}
		catch (error) {

		}
	}

	const onKgChange = (plastic_id, bAmt, price, field, e) => {

		var d = [];
		var index = data.findIndex(function (obj) {
			return (obj.plastic_id == plastic_id);
		});

		if (index > - 1) {
			data[index][field] = e;
			if (data[index].buying_amount > 0) {
				bAmt = data[index].buying_amount;
			}
			if (field == 'plastic_kg') {
				data[index].total_amount = e * bAmt;
			} else if (field == 'buying_amount') {
				data[index].total_amount = data[index].plastic_kg * e;
			}
			d.push(data);
			const arr = [...materialData.filter((item, i) => item.plastic_id != plastic_id), ...d[0]];
			setMaterialData(arr);
		}
		else {
			d.push({ "id": 0, "plastic_id": plastic_id, "plastic_kg": e, "plastic_amount": price, "buying_amount": bAmt, "total_amount": e * bAmt });
			const arr = [...materialData, ...d];
			setMaterialData(arr);
			const arr1 = [...data, ...d];
			setData(arr1);
		}
	}

	const getmaterial = (id, field) => {
		var value = '';
		var d = materialData;
		var index = d.findIndex(function (obj) {
			return (obj.plastic_id == id);
		});
		if (index > -1) {
			value = d[index][field];
		}
		return value;
	}

	let totalAmount = 0;
	let totalKg = 0;
	let btotal = 0;

	return (
		<>
			<div className="container">
				<Form action="" method="post" id="editform" validated={validated} onSubmit={handleSubmit} >
					<input type="hidden" name="id" value={props.data.material_id} />
					<input type="hidden" name="acyear" value={context.acyear.id} />
					<input type="hidden" name="type" value={props.data.type} />
					<input type="hidden" name="group" value={props.data.group_id} />
					<input type="hidden" name="cdate" value={props.data.collection_date} />
					<input type="hidden" name="materials" value={JSON.stringify(materialData)} />
					<Row>
						<Col md={6}>
							<MDBInput
								label="Group Name"
								type="text"
								name="name"
								size="lg"
								disabled
								defaultValue={props.data.grp_name}
							/>
						</Col>
						{props.data.type == 'event' ?
							<Col md={6}>
								<MDBInput
									label="Event Name"
									type="text"
									name="name"
									size="lg"
									disabled
									defaultValue={props.data.eventname}
								/>
							</Col> :
							<Col md={6}>
								<MDBInput
									label="Vendor Name"
									type="text"
									name="name"
									size="lg"
									disabled
									defaultValue={props.data.vendorname}
								/>
							</Col>}
						{materialData.length > 0 ?
							<Col md={12}>
								<Table responsive>
									<thead>
										<tr>
											<th>S.No</th>
											<th>Type</th>
											<th width="10%">Kg</th>
											<th width="10%">Amount</th>
											<th width="10%">Total</th>
										</tr>
									</thead>
									<tbody>
										{plasticData.map((item, i) => {
											let kg = getmaterial(item.id, 'total_kg') ? getmaterial(item.id, 'total_kg') : 0;
											totalKg += parseFloat(kg);
											let buyingAmt = (item.current_price * item.buying_rate) / 100;
											let bAmt = getmaterial(item.id, 'buying_amount') != '' ? getmaterial(item.id, 'buying_amount') : buyingAmt;
											btotal += parseFloat(bAmt);
											let amount = parseFloat(kg * bAmt);
											totalAmount += amount;
											return <tr>
												<td>{i + 1}</td>
												<td>{item.plastic_name}</td>
												<td align="right">
													<input type="text" name="buying_kg" id={"buy_" + item.id} onKeyPress={decimalKeyPress} defaultValue={kg} onChange={(e) => { onKgChange(item.id, bAmt, item.current_price, 'total_kg', e.target.value) }} />
												</td>
												<td align="right">{parseFloat(bAmt).toFixed(2)}</td>
												{/*<td align="right">
												<input type="text" name="buying_amt" id={"buyamt_"+item.id} onKeyPress={decimalKeyPress} defaultValue={bAmt} onChange={(e)=> {onKgChange(item.id,'buying_amount',e.target.value)}} />
											</td>*/}
												<td align="right">{parseFloat(amount).toFixed(2)}</td>
											</tr>
										})}
										<tr>
											<td colSpan="2" align="right"><b>Total</b></td>
											<td ><b>{parseFloat(totalKg).toFixed(2)}</b></td>
											<td ><b>{parseFloat(btotal).toFixed(2)}</b></td>
											<td align="right"><b>{parseFloat(totalAmount).toFixed(2)}</b></td>
										</tr>
										<tr>
											<td colSpan="2" align="right"><b>Amount Paid</b></td>
											<td colSpan="2" align="right">
												<input type="text" name="p_amount" defaultValue={materialData[0].paid_amount} onKeyPress={decimalKeyPress} />
												<input type="hidden" name="net_amount" defaultValue={totalAmount} />
											</td>
										</tr>
									</tbody>
								</Table>
							</Col> : ''}
						<Col md={12} className="mt-3">
							<button type="submit" className="btn btn-outline-black btn-block" disabled={btnLoading}>
								{btnLoading ? 'Please wait..' : 'Update'}</button>
						</Col>
					</Row>

				</Form>
			</div>
		</>
	);
};

export default EditMaterial; 