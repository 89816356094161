import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col,  Modal , Form, Table} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';

const ViewVendor = (props) =>{
	
	const context = useContext(PsContext);
	const [data, changeData] = useState([]);

	
	return(
		<>
			
			<div class="container">
				<Row>
					<Col md={{span:12 , offset:0}}>
						<Table bordered>
							<tbody>
								<tr>
									<th><b>Customer Name: </b></th><td colspan="3">{props.data.customername}</td>
									<th><b>Moblie :</b></th><td colspan="3">{props.data.mobile}</td>
								</tr>
								<tr>
									<th><b> Address</b></th><td colspan="3">{props.data.address}</td>
									<th><b> Status: </b></th><td colspan="3">{props.data.activestatus}</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default ViewVendor;