import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "antd";
import toast from "react-hot-toast";
import API from "../../utils/api";
import PsContext from "../../context";
import { PsBredCrumb } from "../../comp";
import { Bar, Column, Line, Pie } from "@ant-design/plots";
import $ from "jquery";
import ReactDOM from "react-dom";
import { CChart } from "@coreui/react-chartjs";
import StatCard from "./statcard";
import { Chart } from "chart.js";
import moment from "moment";
import { groupByMultiple, momentDate } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCheck,
  faUser,
  faUserTimes,
  faUserClock,
  faHandshake,
  faUsers,
  faCalendarDays,
  faHouseUser,
  faSheetPlastic,
  faHomeUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircleArrowRight,
  faCartShopping,
  faMoneyBillTrendUp,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
const cardStyle = { width: "15rem", height: "6rem", background: "lightgrey" };

const MyHome = () => {
  const context = useContext(PsContext);
  const [groupCount, setGroupCount] = useState(0);
  const [staffCount, setStaffCount] = useState(0);
  const [staffCountTotal, setStaffCountTotal] = useState(0);
  const [groupCountTotal, setGroupCountTotal] = useState([]);
  const [hbcTotal, setHbcTotal] = useState([]);
  const [hbcTotalValue, setHbcTotalValue] = useState([]);
  const [plasticKilo, setPlasticKilo] = useState([]);
  const [plasticPiece, setPlasticPiece] = useState([]);
  const [eventCount, setEventCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);
  const [stData, stChangeData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [plasticData, setPlasticData] = useState([]);
  const [plasticSale, setPlasticSale] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [top10Data, setTop10Data] = useState([]);
  const [top5Data, setTop5Data] = useState([]);
  const [top5Events, setTop5Events] = useState([]);
  const [eventTotalCount, setEventTotalCount] = useState([]);
  const [listGroupData, setListGroupData] = useState([]);
  const [collection, setCollection] = useState([]);
  const [eventType, setEventType] = useState([]);
  const [eventCountType, setEventCountType] = useState([]);
  const [eventTotalValue, setEventTotalValue] = useState([]);
  const [plasticStockValue, setPlasticStockValue] = useState([]);
  const [plasticStockValuePiece, setPlasticStockValuePiece] = useState([]);
  const [plasticSalesValuePiece, setPlasticSalesValuePiece] = useState([]);
  const [plasticSalesValue, setPlasticSalesValue] = useState(0);
  const [loading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [dataView, setDataView] = useState([]);
  const [count, countData] = useState([]);
  const [plasticSalesPlastic, setPlasticSalesPlastic] = useState([]);
  const [plasticSalesOther, setPlasticSalesOther] = useState([]);
  const [plasticStockPlastic, setPlasticStockPlastic] = useState([]);
  const [plasticStockOther, setPlasticStockOther] = useState([]);
  const [chartDates, setChartDates] = useState([]);
  const [visitData, setVisitData] = useState([]);
  const [visitDataValue, setVisitDataValue] = useState([]);
  const [averageVisit, setAverageVisit] = useState([]);
  const [plasticSaleCollection, setPlasticSaleCollection] = useState([]);
  const [otherSaleCollection, setOtherSaleCollection] = useState([]);
  const [plasticStockCollection, setPlasticStockCollection] = useState([]);
  const [otherStockCollection, setOtherStockCollection] = useState([]);
  const [valueHomeVisit, setValueHomeVisit] = useState("");
  const [pieData, setPieData] = useState(null);
  const theme = {
    primaryColor: "#007bff",
    infoColor: "#1890ff",
    successColor: "#52c41a",
    processingColor: "#1890ff",
    errorColor: "#f5222d",
    highlightColor: "#f5222d",
    warningColor: "#faad14",
    normalColor: "#d9d9d9",
    backgroundColor: "#f7f7f9",
    darkColor: "rgb(51, 51, 51)",
    textColor: "rgba(0, 0, 0, .65)",
    textColorSecondary: "rgba(0, 0, 0, .45)",
  };

  useEffect(() => {
    listgroupname();
    listplastic();
    listdata();
    listGrpSeeting();
    lastDayList();
    listPlasticChart();
    listHomeData();
    listdatacount();
  }, []);

  const ChartColor = ["#3585EA", "#FF5733"];

  const listdatacount = () => {
    try {
      setIsLoading(true);
      API.get("v1/group/questionreportlistcount").then((res) => {
        if (res["data"].status == "1") {
          var c = res["data"].count;
          countData(c);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listPlasticChart = () => {
    try {
      setIsLoading(true);

      API.get("v1/plastic/listplasticchart").then((res) => {
        if (res["data"].status == "1") {
          var salesplastic = res["data"].salesplastic;
          var salesother = res["data"].salesother;
          var stockplastic = res["data"].stockplastic;
          var stockother = res["data"].stockother;
          var dates = [];
          stockplastic = stockplastic.map((obj) => {
            obj.collection_date = moment(obj.collection_date).format(
              "YYYY-MMMM"
            );
            obj.stock = parseInt(obj.stock);
            return obj;
          });

          salesplastic = salesplastic.map((obj) => {
            obj.sales_date = moment(obj.sales_date).format("YYYY-MMMM");
            obj.sale = parseInt(obj.sale);
            return obj;
          });
          stockother = stockother.map((obj) => {
            obj.collection_date = moment(obj.collection_date).format(
              "YYYY-MMMM"
            );
            obj.stock = parseInt(obj.stock);
            return obj;
          });

          salesother = salesother.map((obj) => {
            obj.sales_date = moment(obj.sales_date).format("YYYY-MMMM");
            obj.sale = parseInt(obj.sale);
            return obj;
          });

          setPlasticSalesPlastic(salesplastic);

          setPlasticSalesOther(salesother);

          setPlasticStockPlastic(stockplastic);

          setPlasticStockOther(stockother);

          setChartDates(dates);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listplastic = () => {
    try {
      setIsLoading(true);

      var form = new FormData();
      form.append("acyear", context.acyear.id);
      API.post("v1/plastic/listplastic", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setPlasticData(d);
          setPlasticSale(d);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listgroupname = () => {
    try {
      setIsLoading(true);
      API.get("v1/group/listgroup").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setGroupData(d);

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listGrpSeeting = () => {
    try {
      setIsLoading(true);
      API.get("v1/user/listgrpsetting").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;

          setListGroupData(d);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const lastDayList = () => {
    try {
      setIsLoading(true);
      API.get("v1/user/lastdaycollection").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setCollection(d);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listdata = () => {
    try {
      setIsLoading(true);
      var form = new FormData();
      form.append("acyear", context.acyear.id);

      API.post("v1/user/dashboardcount", form).then((res) => {
        if (res["data"].status == "1") {
          var top10 = res["data"].top10;
          var top5 = res["data"].top5;
          var events = res["data"].events;
          var top5events = res["data"].top5events;
          var d = res["data"].group;
          var gt = res["data"].groupcount;
          var ht = res["data"].hbc;
          var hvt = res["data"].hbcValue;
          var plasticTotalStock = res["data"].pts;
          var plasticTotalSales = res["data"].ptsale;
          var et = res["data"].eventtype;
          var ect = res["data"].ectype;
          var s = res["data"].staff;
          var st = res["data"].stafftotal;
          var ec = res["data"].eventcount;
          var ev = res["data"].totalequery;
          // var pls = res['data'].plastickg;
          // var plsPcs = res['data'].plasticpcs;
          // var ptsPiece = res['data'].ptsPiece;
          // var ptsalePiece = res['data'].ptsalePiece;
          var plasticSales = res["data"].plasticSale;
          console.log(plasticSales);
          var othersSales = res["data"].otherSale;
          var plasticStock = res["data"].plasticStock;
          var othersStock = res["data"].otherStock;
          var t = res["data"].today == null ? 0 : res["data"].today;

          setGroupCount(d);
          setStaffCount(s);
          setStaffCountTotal(st);
          setGroupCountTotal(gt);
          setHbcTotal(ht);
          setHbcTotalValue(hvt);
          setPlasticSaleCollection(plasticSales);
          setOtherSaleCollection(othersSales);
          setPlasticStockCollection(plasticStock);
          setOtherStockCollection(othersStock);
          // setPlasticKilo(pls);
          // setPlasticPiece(plsPcs);
          setPlasticStockValue(plasticTotalStock);
          // setPlasticStockValuePiece(ptsPiece);
          setPlasticSalesValue(plasticTotalSales);
          // setPlasticSalesValuePiece(ptsalePiece);
          setEventType(et);
          setEventCountType(ect);
          setEventCount(ec.count);
          setEventTotalCount(ev.total);
          setTodayCount(t);
          setTop10Data(top10);
          setEventData(events);
          setTop5Data(top5);
          setTop5Events(top5events);
          setIsLoading(false);
          var totalPercentValuePlastic = parseInt(plasticStock[0].plastic) * 2;
          var totalPercentValueNonPlastic = parseInt(othersStock[0].others) * 2;
          setPieData({
            plastic: {
              collection: plasticStock[0].plastic,
              sales: parseInt(plasticSales[0].pSale),
              stock:
                parseInt(plasticStock[0].plastic) -
                parseInt(plasticSales[0].pSale),
            },
            NonPlastic: [
              {
                type: "sales : " + plasticSales[0].pSale.toString(),
                value: parseInt(plasticSales[0].pSale),
              },
              {
                type:
                  "stock : " +
                  (
                    parseInt(plasticStock[0].plastic.toString()) -
                    parseInt(plasticSales[0].pSale)
                  ).toString(),
                value:
                  parseInt(plasticStock[0].plastic.toString()) -
                  parseInt(plasticSales[0].pSale),
              },
              {
                type: "collection : " + plasticStock[0].plastic.toString(),
                value: plasticStock[0].plastic,
              },
            ],
          });
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listHomeData = () => {
    try {
      setIsLoading(true);
      API.get("/v1/group/questionreportchart").then((res) => {
        if (res["data"].status == "1") {
          var d = groupByMultiple(res["data"].data, function (obj) {
            return [obj.visit];
          });

          setVisitDataValue(d);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  let totalPiece =
    plasticPiece &&
      plasticPiece[0] &&
      plasticSalesValuePiece &&
      plasticSalesValuePiece[0]
      ? plasticPiece[0].plasticpiece - plasticSalesValuePiece[0].TotalSales
      : "";

  let total =
    plasticKilo && plasticKilo[0] && plasticSalesValue && plasticSalesValue[0]
      ? plasticKilo[0].plastickiloKg - plasticSalesValue[0].TotalSales
      : "";

  let currectStockPlastic =
    plasticSaleCollection &&
      plasticSaleCollection[0] &&
      plasticStockCollection &&
      plasticStockCollection[0]
      ? plasticStockCollection[0].plastic - plasticSaleCollection[0].pSale
      : "";

  let currectStockOthers =
    otherSaleCollection &&
      otherSaleCollection[0] &&
      otherStockCollection &&
      otherStockCollection[0]
      ? otherStockCollection[0].others - otherSaleCollection[0].oSale
      : "";

  const configCollection = {
    xField: "collection_date",
    yField: "stock",
    label: {
      // 可手动配置 label 数据标签位置
      position: "middle",
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      month: {
        alias: "Month",
      },
      collection: {
        alias: "Collection",
      },
    },
  };
  const configSales = {
    xField: "sales_date",
    yField: "sale",
    label: {
      // 可手动配置 label 数据标签位置
      position: "middle",
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      month: {
        alias: "Month",
      },
      collection: {
        alias: "Collection",
      },
    },
  };
  const configPlasticPie = {
    appendPadding: 10,
    angleField: "value",
    colorField: "type",
    color: ["#237804", "#0050b3", "#9e1068"],
    radius: 0.8,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };
  return (
    <>
      <div className="container">
        <Card title="Dashboard">
          <Row gutter={16}>
            <Col xs={24} sm={12} md={5}>
              <StatCard
                title="Staff"
                value={staffCount + "/12"}
                icon={<FontAwesomeIcon icon={faUsers} />}
                color={theme.primaryColor}
                link="/staff"
              />
            </Col>
            <Col xs={24} sm={12} md={5}>
              <StatCard
                title="WPEG"
                value={groupCount + "/16"}
                icon={<FontAwesomeIcon icon={faHandshake} />}
                color={theme.errorColor}
                link="/group"
              />
            </Col>
            <Col xs={24} sm={12} md={5}>
              <StatCard
                title="Events"
                value={eventCount + "/" + eventTotalCount}
                icon={<FontAwesomeIcon icon={faCalendarDays} />}
                color={"#fa8c16"}
                link="/eventlist"
              />
            </Col>
            <Col xs={24} sm={12} md={5}>
              <StatCard
                title="Home Visits"
                value={
                  (hbcTotal && hbcTotal[1] ? hbcTotal[1].housetotal : "0") +
                  "/6000"
                }
                icon={<FontAwesomeIcon icon={faHomeUser} />}
                color={"#9254de"}
                link="/house"
              />
            </Col>
            <Col xs={24} sm={12} md={4}>
              <StatCard
                title="Plastic Bank"
                value={
                  (eventCountType && eventCountType[0]
                    ? eventCountType[0].etotal
                    : "") +
                  "/" +
                  (eventType && eventType[0] ? eventType[0].no_of_event : "")
                }
                icon={<FontAwesomeIcon icon={faSheetPlastic} />}
                color={"#f759ab"}
                link="/material-collection-report"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} xl={16}>
              <Card title="Plastic">
                {pieData && pieData.plastic && (
                  <Row gutter={16}>
                    <Col xs={24} sm={12} md={8}>
                      <StatCard
                        title="Collection"
                        value={parseFloat(pieData.plastic.collection).toFixed(
                          0
                        )}
                        icon={<FontAwesomeIcon icon={faCartShopping} />}
                        color={"#9e1068"}
                        link="/material-collection-report"
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <StatCard
                        title="Sales"
                        value={pieData.plastic.sales}
                        icon={<FontAwesomeIcon icon={faMoneyBillTrendUp} />}
                        color={"#237804"}
                        link="/material-collection-report"
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <StatCard
                        title="Stock"
                        value={pieData.plastic.stock}
                        icon={<FontAwesomeIcon icon={faStore} />}
                        color={"#096dd9"}
                        link="/material-collection-report"
                      />
                    </Col>
                  </Row>
                )}
              </Card>
            </Col>
            <Col xs={24} xl={12}></Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} xl={12}>
              <Card title="Plastic Collection">
                {plasticStockPlastic && plasticStockPlastic.length > 0 && (
                  <Column
                    data={plasticStockPlastic}
                    color="#9e1068"
                    //color={ ['#237804', '#0050b3', '#9e1068']}
                    yAxis={{
                      min: 0,
                      max: 2500,
                    }}
                    {...configCollection}
                  />
                )}
              </Card>
            </Col>
            <Col xs={24} xl={12}>
              <Card title="Plastic Sales">
                {plasticSalesPlastic && plasticSalesPlastic.length > 0 && (
                  <Column
                    data={plasticSalesPlastic}
                    yAxis={{
                      min: 0,
                      max: 2500,
                    }}
                    color="#237804"
                    //color={ ['#237804', '#0050b3', '#9e1068']}
                    {...configSales}
                  />
                )}
              </Card>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} xl={12}>
              <Card title="Non Plastic Collection">
                {plasticStockOther && plasticStockOther.length > 0 && (
                  <Column
                    data={plasticStockOther}
                    yAxis={{
                      min: 0,
                      max: 7500,
                    }}
                    color="#9e1068"
                    {...configCollection}
                  />
                )}
              </Card>
            </Col>
            <Col xs={24} xl={12}>
              <Card title="Non Plastic Sales">
                {plasticSalesOther && plasticSalesOther.length > 0 && (
                  <Column
                    data={plasticSalesOther}
                    yAxis={{
                      min: 0,
                      max: 7500,
                    }}
                    color="#237804"
                    {...configSales}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default MyHome;
