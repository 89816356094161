import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import { Link } from 'react-router-dom';
import AddEvent from './addEvent';
import EditEvent from './editEvent';
import AddReport from './addReport';
import EditReport from './editReport';
import ViewEvent from './viewEvent';
import axios from 'axios';
import { triggerFocus } from 'antd/lib/input/Input';
import moment from 'moment';

const EventManagement = () => {

	const context = useContext(PsContext);
	const [validated, setValidated] = useState(false);
	const [AddUser, setAddUser] = useState(false);
	const [addModel, setAddModel] = useState(false);
	const [editReportModel, setEditReportModel] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [ViewData, setViewData] = useState(false);
	const [user, setUser] = useState([]);
	const [view, setView] = useState([]);
	const [data, changeData] = useState([]);
	const [reportData, setReportData] = useState([]);
	const [imgData, setImgData] = useState([]);
	const [stData, stChangeData] = useState([]);
	const [changeDate, changeDateData] = useState([]);
	const [loading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [adminEnquiry, setAdminEnquiry] = useState(false);

	useEffect(() => {
		listdata();
		listgroupname();
	}, []);

	const filterChange = (e) => {
		var val = e.target.value;
		if (val == '') {
			changeDateData(data);
		} else {
			let ndata = data.filter(function (item) {
				return (item.group_id == val);
			});
			changeDateData(ndata);
		}
	}

	const showModel = (item) => {
		setUser(item);
		setEditModel(true);
	}

	const showReportModel = (item) => {
		setUser(item);
		setAddModel(true);
	}

	const showReportEditModel = (item) => {
		listReportData(item, 'edit');
	}

	const listReportData = (item, report) => {
		try {
			setIsLoading(true);
			var form = new FormData;
			form.append('event', item.id);
			API.post('v1/event/eventreportbyid', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data[0];
					var d1 = res['data'].image;
					setReportData(d);
					setImgData(d1);
					setUser(item);
					if (report == 'edit') {
						setEditReportModel(true);
					} else {
						setView(item);
						setViewData(true);
					}
				}
				else {
					setIsLoading(false);
					setUser(item);
					setReportData([]);
					setImgData([]);
					if (report == 'edit') {
						setEditReportModel(true);
					} else {
						setView(item);
						setViewData(true);
					}
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const viewModel = (item) => {
		listReportData(item, 'view');
	}

	const listgroupname = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listgroup').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					stChangeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/event/listevent').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					changeDateData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	/*const onSearchChange = (e)=> {
			var d = data;
			let ndata = d.filter(function (item) {
			const grp_name = item.grp_name.toUpperCase();
			const s = e.target.value.toUpperCase();
			return (grp_name.indexOf(s) > -1);
			});
			setSearchData(ndata);
	}*/

	const onDateSearch = (event) => {
		event.preventDefault();
		try {
			setBtnLoading(true);
			API.post('v1/event/search', $("#src_Date").serialize()).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeDateData(d);
					setBtnLoading(false);
				}
				else {
					changeDateData([]);
					toast.error('No Data Found', "danger");
					setBtnLoading(false);
				}
			});
		}
		catch (error) {

		}
	};

	const onFromChange = (event) => {

		var fromdate = $("#fromdate").val();
		var toMinDate = moment(fromdate).format("YYYY-MM-DD");
		if (fromdate != '') {
			$("#todate").attr('min', toMinDate);
		}
	};

	return (
		<div className="container" >
			<Navbar bg="white" expand="lg">
				<Container>
					<Navbar.Brand >List of Event</Navbar.Brand>
					<Nav className="justify-content-end">
						{/*<Link to={{
							pathname: '/addevent'
						}}>Add New</Link>*/}
					</Nav>
				</Container>
			</Navbar>

			{/*	<Col md={2}>
				    <Button variant="success" onClick={()=>{setAddUser(true)}}><i class="fa fa-plus" aria-hidden="true"></i> Add New</Button>
					</Col> */}

			<Form action="" method="post" id="src_Date" validated={validated} onSubmit={onDateSearch} style={{ padding: '0px 10px' }}>
				<Row className="mt-2">
					<Col md={3}>
						<label>From Date:</label>
						<MDBInput
							type="date"
							id="fromdate"
							name="fromdate"
							onChange={onFromChange}
						/>
					</Col>
					<Col md={3}>
						<label>To Date:</label>
						<MDBInput
							type="date"
							id="todate"
							name="todate"
						/>
					</Col>
					<Col md={1}>
						<button className="mt-4" variant="warning" style={{ float: 'right' }} disabled={btnLoading}>
							{btnLoading ? 'Please wait..' : 'Search'}</button>
					</Col>
					<Col md={3}></Col>
					<Col md={2}>
						<label>Filter By Group</label>
						<select className="form-control form-control-sm" name="group" onChange={filterChange}>
							<option value="">Group</option>
							{stData.map((user, i) => {
								return <option value={user.id}>{user.grp_name}</option>
							})}
						</select>
					</Col>
				</Row>
			</Form><br />

			<Row className="text-center">
				<Col md={{ span: 2, offset: 10 }}>
					<Modal show={editModel} onHide={() => { setEditModel(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title>Edit Event</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<EditEvent edit={user} afterFinish={() => { setEditModel(false) }} afterList={() => listdata()} />
						</Modal.Body>
					</Modal>
					<Modal show={addModel} size="lg" onHide={() => { setAddModel(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title>Add Report</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<AddReport edit={user} afterFinish={() => { setAddModel(false) }} afterList={() => listdata()} />
						</Modal.Body>
					</Modal>
					<Modal show={editReportModel} size="lg" onHide={() => { setEditReportModel(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title>Edit Report</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<EditReport edit={user} report={reportData} images={imgData} afterFinish={() => { setEditReportModel(false) }} afterList={() => listdata()} />
						</Modal.Body>
					</Modal>

					<Modal show={ViewData} size="lg" onHide={() => { setViewData(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title >View Event</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ViewEvent data={view} report={reportData} images={imgData} />
						</Modal.Body>
					</Modal>
				</Col>
			</Row>

			<Table responsive bordered>
				<thead className="text-center">
					<tr>
						<th width="5%">S.No</th>
						<th width="10%"><b>Event Name</b></th>
						<th width="10%"><b>Event Type</b></th>
						<th width="15%"><b>Date & Time</b></th>
						<th width="10%"><b>Place</b></th>
						<th width="10%"><b>Group Name</b></th>
						<th width="15%"><b>Status</b></th>
						<th width="10%"><b>Action</b></th>
					</tr>
				</thead>
				<tbody className="text-center">
					{(changeDate.map((item, id) => {
						return <tr>
							<td>{id + 1}</td>
							<td>{item.eventname}</td>
							<td>{item.typename}</td>
							<td>{item.eventdate} {item.eventtime}</td>
							<td>{item.place}</td>
							<td>{item.grp_name}</td>
							<td>{item.requirements}</td>
							<td>
								{item.reportid == null ?
									<Button variant="success" onClick={() => { showReportModel(item) }} size="sm"><i className="fas fa-file"></i> </Button>
									:
									<Button variant="success" onClick={() => { showReportEditModel(item) }} size="sm"><i className="fas fa-file"></i> </Button>
								}{' '}
								<Button variant="info" onClick={() => { showModel(item) }} size="sm"><i className="fas fa-edit"></i> </Button>{' '}
								<Button variant="warning" size="sm" onClick={() => viewModel(item, item.id)}><i className="fas fa-eye"></i> </Button>{' '}
							</td>
						</tr>
					}
					))};
				</tbody>
			</Table>
		</div>
	);
};
export default EventManagement;