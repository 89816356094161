import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col,  Modal , Form} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import {integerIndMobile, lettersOnly} from '../../utils';
import ReactFileReader from 'react-file-reader';
import {baseUrl,imageUrl} from '../../utils';

const EditAnnounce = (props) =>{

	const context = useContext(PsContext);
	const [btnLoading, setBtnLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [file, setFile] = useState('');
	const [fileName, setFileName] = useState('');

	useEffect(() => {
		setFile(props.edit.upload_image);
    } , [] );
	
	const handleFiles = (files) => {
	  setFile(files.base64);
	  setFileName(files.fileList[0].name);
	}
	
	const handleSubmit = (event) =>{
		event.preventDefault();
		try{
			setBtnLoading(true);
			API.post('v1/announce/editannounce', $("#editform").serialize()).then(res =>{
			if(res['data'].status == '1'){
				toast.success("Announcement Updated Successfully","success");
				setBtnLoading(false);
				const vrl=baseUrl+'v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Announcement Updated Successfully');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
					  method: 'post',
					  url: vrl,
					  data : formData
					};
					axios(config)
					.then(function (response) {
					  console.log(JSON.stringify(response.data));
					})
					.catch(function (error) {
					  console.log(error);
					});	
				props.afterFinish();
				props.afterList();
			}
			else{
				toast.error("Something Went Wrong","danger");
				setBtnLoading(false);
			}
			});
		}
		catch(error){
			
		}
	}

	return(
		<>
			<div className="container">
				<Form action="" method="post" id="editform" validated={validated} onSubmit={handleSubmit} >
					<input type="hidden" name="id" value={props.edit.id} />
					<input type="hidden" name="upload_image" value={props.edit.upload_image} />
					<input type="hidden" name="file" value={JSON.stringify(file)} />
					<input type="hidden" name="fileName" value={fileName} />
					<Row>
						<Col md={12}>
							<MDBInput
								label="Title"
								type="text"
								name="title"
								size="lg"
								required
								defaultValue={props.edit.title}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="Content"
								type="text"
								name="content"
								size="lg"
								required
								defaultValue={props.edit.content}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<ReactFileReader base64={true} handleFiles={handleFiles} fileTypes={[".jpg",".jpeg",".png"]}>
							  <button type="button" className='btn btn-sm btn-block'>Upload Image</button>
							</ReactFileReader>
						</Col>
						{file!='' ? 
						<Col md={12} className="mt-4">
							<img src={fileName == '' ? imageUrl+file : file} style={{maxWidth : '300px'}} />
						</Col> : ''}
						<Col md={12} className="mt-3">
							<MDBInput
								label="Expiry Date"
								type="text"
								name="expiry_on"
								size="lg"
								required
								defaultValue={props.edit.expiry_on}
							/>
						</Col>
					</Row>
                <Col className="mt-3">
					<button type="button" className="btn btn-outline-black btn-block"  type="submit" disabled={btnLoading}>
					{btnLoading ? 'Please wait..' : 'Update'}</button>
				</Col>
			</Form>
			</div>
		</>
	);
};

export default EditAnnounce; 