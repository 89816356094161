import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory , Link} from 'react-router-dom';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Card, Form, Button, Table, Modal } from 'react-bootstrap';
import { Spin } from 'antd';

import { MDBInput } from 'mdb-react-ui-kit';
import PsContext from '../../context';
import API from '../../utils/api';
import {baseUrl, getAscSortOrder, groupByMultiple, momentDate} from '../../utils';
import axios from "axios";
import moment from "moment";

import PaymentIn from '../mobileAccounts/paymentIn';
import PaymentOut from '../mobileAccounts/paymentOut';

const Payments = (props) =>{
	
	const context = useContext(PsContext);
	const [loader, setLoader] = useState([]);

	
	const [fromDate, setFromDate] = useState(momentDate(new Date(),'YYYY-MM-DD'));
	const [toDate, setToDate] = useState(momentDate(new Date(),'YYYY-MM-DD'));

	const [data, setData] = useState([]);
	const [dataView, setDataView] = useState([]);

	const [paymentType, setPaymentType] = useState(false);
	const [groups, setGroups] = useState([]);
	const [groupId, setGroupId] = useState(0);
		
	useEffect(() => {
		
		loadData();
		loadGroups();

    } , [] );

	const loadGroups=()=>{
		setLoader(true);
		
		API.get('v1/acc/groups').then(res=>{
			if(res['data'].errorcode=='200'){
				let d = res['data'].data;
				setGroups(d.sort(getAscSortOrder('grp_name')));
			}	
			setLoader(false);
		});
	}

	const loadData=()=>{

		setLoader(true);
		setData([]);
		setDataView([]);
		var form = new FormData();
		form.append('group_id', groupId);
		form.append('staff_id', 0);
		form.append('from_date', fromDate);
		form.append('to_date', toDate);

		API.post('v1/acc/day_report',form).then(res=>{
			if(res['data'].errorcode=='200'){
				
				let m = groupByMultiple(res['data'].data, function(obj){
					return [obj.account_by];
				});

				setData(res['data'].data);
				setDataView(res['data'].data);
			}	
			else{
				toast.error(res['data'].content || 'Error');
			}
			setLoader(false);
		});
	}

	const getTotal=(field)=>{
		let total = 0;
		dataView.map(item => total = parseFloat(total) + parseFloat(item[field]));
		return parseFloat(total).toFixed(2);
	}

	return(
		<div className='container bg-transparent'>
			<Spin spinning={loader}>

			<Card style={{position: 'sticky', top: '50px'}}>
				<Card.Body>
					<Row>
						<Col md={2}>
							<h4>Payments</h4>
						</Col>	
						<Col md={3}>

						</Col>
						<Col md={7}>
							<div className='text-end'>
								<Button
									type="button"
									size="sm"
									onClick={e=>setPaymentType('in')}
								>
									<i className="fa fa-plus me-2"></i>Payment In
								</Button>
								{' '}
								<Button
									type="button"
									size="sm"
									onClick={e=>setPaymentType('out')}
								>
									<i className="fa fa-plus me-2"></i>Payment Out
								</Button>
							</div>
						</Col>
					</Row>
				<Row>
					<Col md={12}><hr /></Col>
				</Row>

				<Row>
					<Col md={4}>
						<label style={{fontSize: '11px'}} >Group Name</label>
						<Form.Control
							as="select"
							name="group_id"
							size="sm"
							onChange={(e)=>setGroupId(e.target.value)}
							required
						>
							<option value="0">-All Groups-</option>
							{groups.map(item => <option value={item.id}> {item.grp_name} </option>)};
						</Form.Control>
					</Col>
					<Col md={2}>
						<label style={{fontSize: '11px'}}>From Date</label>
						<Form.Control
							type="date"
							max={momentDate(new Date(),'YYYY-MM-DD')}
							value={fromDate}
							onChange={e=>setFromDate(e.target.value)}
							size="sm"
						/>
					</Col>
					<Col md={2}>
						<label style={{fontSize: '11px'}}>To Date</label>
						<Form.Control
							type="date"
							max={momentDate(new Date(),'YYYY-MM-DD')}
							min={fromDate}
							value={toDate}
							onChange={e=>setToDate(e.target.value)}
							size="sm"
						/>
					</Col>
					<Col md={2}>
						<Button 
							type="button"
							size="sm"
							className="mt-4 btn-block"
							onClick={loadData}
						>
							View
						</Button>
					</Col>
				</Row>
					
			<div className='table-responsive mt-3'>
				<table className='table table-sm table-bordered '>
					<thead>
						<tr>
							<th>S.No</th>
							<th>Date</th>
							<th>By</th>
							<th>Account Head</th>
							<th>Group Name</th>
							<th>Narration</th>
							<th>Expense</th>
							<th>Revenue</th>
						</tr>
					</thead>
					<tbody>
						{dataView.map((item,i)=>{
							return <tr key={i}>
								<td>{i+1}</td>
								<td>{momentDate(item.voucher_date,'DD/MM/YYYY')}</td>
								<td>{item.account_by_name}</td>
								<td>{item.ledger_head_name}</td>
								<td>{item.grp_name}</td>
								<td>{item.narration}</td>
								<td align="right">{item.debit_amount}</td>
								<td align="right">{item.credit_amount}</td>
							</tr>;
						})}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={5}></td>
								<td><b>Total : </b></td>
								<td align="right"><b>{getTotal('debit_amount')}</b></td>
								<td align="right"><b>{getTotal('credit_amount')}</b></td>
							</tr>
						</tfoot>						
				</table>
			</div>

			
			</Card.Body>
			</Card>

			</Spin>

			<Modal show={paymentType} onHide={e=>setPaymentType(false)} backdrop="static" >
				<Modal.Header closeButton >
					{paymentType=='in' ? 'Payment In' : 'Payment Out'}
				</Modal.Header>
				<Modal.Body>
						{paymentType=='in' && (<PaymentIn withGroup={true} afterFinish={loadData} />)}
						{paymentType=='out' && (<PaymentOut withGroup={true} afterFinish={loadData}  />)}
				</Modal.Body>
			</Modal>

		</div>
	);
};
export default withRouter(Payments);