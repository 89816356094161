import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../../utils/api';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import PsContext from '../../../context';
import { PSListItem } from '../../../comp';
import { lettersOnly, integerIndMobile, baseUrl } from '../../../utils';
import axios from 'axios';
import moment from 'moment';

const PlasticAddPrice = (props) => {

	const context = useContext(PsContext);
	const [validated, setValidated] = useState(false);
	const [data, changeData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [date, setDate] = useState('');

	useEffect(() => {
		var date = new Date().getDate() + '-' + (new Date().getMonth() + 1) + new Date().getFullYear();
		setDate(date);
	}, []);

	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			API.post('v1/plastic/addprice', $('#addform').serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("New Price Added Successfully", "success");
					const vrl = baseUrl + 'v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Plastic New Price Updated');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
						method: 'post',
						url: vrl,
						data: formData
					};

					axios(config)
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
					props.afterFinish();
					props.afterList();
				}
				else {
					toast.error("Something went wrong", "danger");
				}
			});
		}
		catch (er) {

		}
	};

	return (
		<Form action="" method="post" id="addform" validated={validated} onSubmit={handleSubmit}>
			<input type="Hidden" id="id" name="id" value={props.data.id} />
			<input type="Hidden" id="acyear" name="acyear" value={context.acyear.id} />
			<Row>
				<Col md={12}>
					<MDBInput
						label="New Price"
						type="text"
						name="price"

						required
					/>
				</Col>
				<Col md={12} className="mt-3">
					<MDBInput
						label="Date"
						type="date"
						name="setup_date"
						min={moment().format("YYYY-MM-DD")}
						defaultValue={date}
					/>
				</Col>
				<Col md={12} className="mt-3">
					<Button variant="success" type="submit" style={{ float: 'right' }}>Add</Button>
				</Col>
			</Row>
		</Form>
	);
};

export default PlasticAddPrice;