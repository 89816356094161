import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Modal,
  Form,
  Navbar,
  Container,
  Nav,
  Button,
  Table,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { MDBInput } from "mdb-react-ui-kit";
import API from "../../utils/api";
import $ from "jquery";
import moment, { normalizeUnits } from "moment";
import { Link } from "react-router-dom";
import PsContext from "../../context";
import { PSListItem } from "../../comp";
import { lettersOnly, integerIndMobile, momentDate } from "../../utils";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import EditAttendance from "./editAttendance";

const tdStyle = {
  border: "1px solid grey",
  borderCollapse: "collapse",
  padding: "3px",
};
const tdLeaveStyle = {
  border: "1px solid grey",
  borderCollapse: "collapse",
  padding: "3px",
  backgroundColor: "rgb(255 203 203)",
};

const EditStaffAttendance = () => {
  const context = useContext(PsContext);
  const [loading, setIsLoading] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [editModel, setEditModel] = useState(false);
  const [month, setMonth] = useState(moment().format("YYYY-MM"));

  const [staffId, setStaffId] = useState([]);

  const [totalDayas, setTotalDays] = useState(0);

  const printDocument = () => {
    var content = document.getElementById("printdatastaff");
    var pri = document.getElementById("print_frame").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  useEffect(() => {
    liststaff();
  }, []);

  const listattendance = () => {
    try {
      setIsLoading(true);
      var form = new FormData();
      form.append("acyear", context.acyear.id);
      form.append("staff", staffId); //$("#staff").val());
      form.append("month", month);
      let days = moment(month + "-01").daysInMonth();
      setTotalDays(days);
      console.log(form);
      API.post("v1/user/getattendancebystaff", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setData(d);
          console.log(d);

          setIsLoading(false);
        } else {
          setData([]);
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const liststaff = () => {
    try {
      setIsLoading(true);
      API.get("v1/user/liststaffattendance").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setStaffData(d);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getTr = () => {
    let tr = [];
    for (var i = 1; i <= totalDayas; i++) {
      let dt = `${month}-${i}`;
      let day = moment(dt).day();
      let item = data.filter(
        (item) => momentDate(item.att_date) == momentDate(dt)
      );

      let str = "";
      let lat = "";
      let lon = "";
      let lat2 = "";
      let lon2 = "";
      if (item && item.length > 0) {
        var loc = item[0].location;
        str = loc && loc.length > 2 ? loc.split(",") : [];
        lat = str[0];
        lon = str[1];
        if (item[0].out_location != null) {
          lat2 = item[0].out_location.split(",")[0];
          lon2 = item[0].out_location.split(",")[1];
        }
      }

      tr.push(
        <tr>
          <td>{i}</td>
          <td>{momentDate(dt, "DD/MM/YYYY")}</td>

          {item && item.length > 0 ? (
            <>
              <td style={item[0].type == "Leave" ? tdLeaveStyle : tdStyle}>
                {item[0].type == "Leave" ? "" : item[0].att_time}
              </td>
              <td style={item[0].type == "Leave" ? tdLeaveStyle : tdStyle}>
                {item[0].out_time}
              </td>
              <td
                align="left"
                style={item[0].type == "Leave" ? tdLeaveStyle : tdStyle}
              >
                {item[0].type == "Leave" &&
                item[0].att_time != null &&
                item[0] ? (
                  "leave"
                ) : (
                  <>
                    {lat && lon ? (
                      <a
                        target="_blank"
                        href={"https://maps.google.com/?q=" + lat + "," + lon}
                      >
                        In
                      </a>
                    ) : (
                      "In (NA)"
                    )}
                    &emsp;
                    {item[0].out_time == null ? (
                      ""
                    ) : lat2 && lon2 ? (
                      <a
                        target="_blank"
                        href={"https://maps.google.com/?q=" + lat2 + "," + lon2}
                      >
                        Out
                      </a>
                    ) : (
                      "Out (NA)"
                    )}
                  </>
                )}
              </td>
            </>
          ) : day == 0 ? (
            <td
              align="center"
              colSpan={3}
              style={{ backgroundColor: "#fffdc5" }}
            >
              {item.att_time == true || item.out_time == true
                ? item.att_time
                : "SUNDAY"}
            </td>
          ) : moment(dt) <= moment(new Date()) ? (
            <>
              <td style={tdLeaveStyle} colSpan={3}>
                Absent
              </td>
            </>
          ) : null}
          <td>
            {moment(dt) <= moment(new Date()) && (
              <Button
                type="button"
                variant="success"
                onClick={() => showModel(item)}
              >
                Edit
              </Button>
            )}
          </td>
        </tr>
      );
    }
    return tr;
  };

  const showModel = (item) => {
    let n = item;
    if (item && item.length > 0) n = item[0];
    setUser(n);
    setEditModel(true);
  };

  return (
    <>
      <div className="container">
        <Row style={{ margin: "5px" }}>
          <Col md={2}>
            <label>Staff:</label>
            <select
              name="staff"
              id="staff"
              className="form-control"
              onChange={(e) => setStaffId(e.target.value)}
            >
              <option value="">Select Staff</option>
              {staffData.map((item, i) => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
          </Col>
          <Col md={2}>
            <label>Date:</label>
            <MDBInput
              type="month"
              id="month"
              defaultValue={month}
              onChange={(e) => setMonth(e.target.value)}
            />
          </Col>
          <Col md={4}>
            <Button type="submit" className="mt-3" onClick={listattendance}>
              Submit
            </Button>
            &nbsp;
          </Col>
          <Col md={12} className="mt-3" id="printdatastaff">
            <table
              className="table mt-10 table-bordered"
              style={tdStyle}
              id="table-to-xls-staff"
            >
              <thead className="text-center">
                <tr>
                  <th style={tdStyle} colSpan={6}>
                    <span style={{ fontSize: "18px" }}>
                      Staff Attendance - {moment(month).format("MMM YYYY")}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th width="5%" style={tdStyle}>
                    <b>S.No</b>
                  </th>
                  <th width="15%" style={tdStyle}>
                    <b>Date</b>
                  </th>
                  <th width="15%" style={tdStyle}>
                    <b>IN</b>
                  </th>
                  <th width="15%" style={tdStyle}>
                    <b>OUT</b>
                  </th>
                  <th width="15%" style={tdStyle}>
                    <b>Location</b>
                  </th>
                  <th width="15%" style={tdStyle}>
                    <b>Manage</b>
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">{getTr()}</tbody>
            </table>
            <iframe
              name="print_frame"
              id="print_frame"
              width="0"
              height="0"
              frameborder="0"
              src="about:blank"
            ></iframe>
          </Col>
        </Row>
      </div>

      <Modal
        show={editModel}
        size="sm"
        onHide={() => {
          setEditModel(false);
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditAttendance
            data={user}
            staff={staffId}
            afterFinish={() => {
              setEditModel(false);
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default EditStaffAttendance;
