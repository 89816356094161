import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';

const EditLeave = (props) => {

	const context = useContext(PsContext);
	const [AddUser, setAddUser] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [openNewModel, setOpenNewModel] = useState(false);
	const [ViewData, setViewData] = useState(false);
	const [user, setUser] = useState([]);
	const [data, changeData] = useState([]);
	const [deleteData, setDeleteData] = useState([]);
	const [blockData, setBlockData] = useState([]);
	const [fileName, setFileName] = useState('');
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [loading, setIsLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [fieldValue, setFieldValue] = useState(false);


	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			setBtnLoading(true);
			API.post('/v1/group/editleaveentry', $("#frm_Login").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Leave Updated Successfully..!", "success");
					document.getElementById("frm_Login").reset();
					setBtnLoading(false);
					props.afterFinish();
					props.afterList();
				}
				else {
					toast.error("Something Went Wrong..!", "danger");
					document.getElementById("frm_Login").reset();
					setBtnLoading(false);
				}
				setIsLoaded(false);
			});
		}
		catch (error) {

		}
	}


	return (
		<div className="container">
			<Form action="" method="post" id="frm_Login" validated={validated} onSubmit={handleSubmit}>
				<input type="hidden" name="id" value={props.data.id} />
				<Row>
					<Col md={12}>
						<MDBInput
							type="text"
							size="md"
							name="description"
							label="Leave Description"
							required
							defaultValue={props.data.description}
						/>
					</Col>
				</Row>
				<Row className='mt-3'>
					<Col md={12}>
						<MDBInput
							type="date"
							size="md"
							name="leave_date"
							label="Date"
							defaultValue={props.data.leave_date}
							required
						/>
					</Col>
				</Row>
				<Row className='mt-3'>
					<Col md={12}>
						<select className="form-control mt-2" name="leave_type" size='sm' required defaultValue={props.data.leave_type}>
							<option value="">--Select Type--</option>
							<option value="Festival Holiday">Festival Holiday</option>
							<option value="Local Holiday">Local Holiday</option>
							<option value="Others">Others</option>
						</select>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<Button type="submit" variant="success" className='mt-2 btn-block' size="md"  > Update</Button>
					</Col>
				</Row>
			</Form>
		</div >
	);
};
export default EditLeave;