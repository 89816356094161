import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Table,
  Navbar,
  Container,
  Nav,
  Form,
} from "react-bootstrap";
import toast from "react-hot-toast";
import PsContext from "../../context";
import API from "../../utils/api";
import { MDBInput } from "mdb-react-ui-kit";
import $ from "jquery";
import { PSListItem } from "../../comp";
import { Link } from "react-router-dom";
import axios from "axios";
import { triggerFocus } from "antd/lib/input/Input";
import moment from "moment";
import { imageUrl } from "../../utils";

const tdStyle = {
  border: "1px solid grey",
  borderCollapse: "collapse",
  padding: "1px",
  textAlign: "center",
};

const thStyle = {
  border: "1px solid black",
  borderRadius: "10px",
  padding: "1px",
};

const styles = {
  imgStyle: {
    border: "1px solid #d8d8d8",
    height: "120px",
  },
};

const ViewEventList = (props) => {
  const context = useContext(PsContext);
  const [validated, setValidated] = useState(false);
  const [AddUser, setAddUser] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [editReportModel, setEditReportModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [user, setUser] = useState([]);
  const [view, setView] = useState([]);
  const [data, changeData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [imgData, setImgData] = useState([]);
  const [stData, stChangeData] = useState([]);
  const [changeDate, changeDateData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [editImageData, setEditImageData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [adminEnquiry, setAdminEnquiry] = useState(false);
  const [printModalOpen, setPrintModalOpen] = useState(false);

  const [printData, setPrintData] = useState([]);
  const [print, setPrint] = useState(false);

  const getEventTypeId = () => props.location.state.eventtypeid || "";

  const [eventType, setEventType] = useState(getEventTypeId());

  useEffect(() => {
    listdata();
    listgroupname();
    listtypedata();
    liststaffdata();
  }, []);

  const printDocument = () => {
    var content = document.getElementById("new_print_tbl");
    var pri = document.getElementById("print_frame").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  const handlePrint = (dt) => {
    setPrintData(dt);
    setPrint(true);
    setTimeout(() => printDocument(), 1000);
  };

  const openNewPrint = () => {
    setPrintModalOpen(true);
  };

  console.log(changeDate);

  useEffect(() => {
    handleEventTypeChange();
  }, [eventType, data]);

  const handleEventTypeChange = () => {
    var val = eventType;
    if (val == "") {
      changeDateData(data);
    } else {
      let ndata = data.filter(function (item) {
        return item.eventtype == val;
      });
      changeDateData(ndata);
    }
  };

  const filterChange = (e) => {
    var val = e.target.value;
    var name = e.target.name;
    if (val == "") {
      changeDateData(data);
    } else {
      let ndata = data.filter(function (item) {
        return item[name] == val;
      });
      changeDateData(ndata);
      if (name == "eventtype") setEventType(val);
    }
  };

  const showModel = (item) => {
    setUser(item);
    console.log(item);
    var dat = imageData.filter(function (obj) {
      console.log(obj);
      return obj.group_id == item.group_id && obj.group_meeting == item.id;
    });
    setEditImageData(dat);
    setEditModel(true);
  };

  const showAddModel = () => {
    setAddModel(true);
  };

  const showReportEditModel = (item) => {
    listReportData(item, "edit");
  };

  const listReportData = (item, report) => {
    try {
      setIsLoading(true);
      var form = new FormData();
      form.append("event", item.id);
      API.post("v1/event/eventreportbyid", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data[0];
          var d1 = res["data"].image;
          setReportData(d);
          setImgData(d1);
          setUser(item);
          if (report == "edit") {
            setEditReportModel(true);
          } else {
            setView(item);
            setViewData(true);
          }
        } else {
          setIsLoading(false);
          setUser(item);
          setReportData([]);
          setImgData([]);
          if (report == "edit") {
            setEditReportModel(true);
          } else {
            setView(item);
            setViewData(true);
          }
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const viewModel = (item) => {
    setUser(item);
    var dat = imageData.filter(function (obj) {
      return obj.group_id == item.group_id && obj.group_meeting == item.id;
    });
    setEditImageData(dat);
    setViewData(true);
  };

  const liststaffdata = () => {
    try {
      setIsLoading(true);
      API.get("v1/user/list").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setStaffData(d);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listgroupname = () => {
    try {
      setIsLoading(true);
      API.get("v1/group/listgroup").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          stChangeData(d);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listdata = () => {
    try {
      setIsLoading(true);
      API.get("v1/group/listgroupmeeting").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          var d1 = res["data"].image;
          changeData(d);
          changeDateData(d);
          setImageData(d1);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listtypedata = () => {
    try {
      setIsLoading(true);
      API.get("v1/event/listtype").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setTypeData(d);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onSearchChange = (e) => {
    var d = data;
    let ndata = d.filter(function (item) {
      const grp_name = item.grp_name.toUpperCase();
      const s = e.target.value.toUpperCase();
      return grp_name.indexOf(s) > -1;
    });
    setSearchData(ndata);
  };

  const onDateSearch = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post("v1/group/meetingsearch", $("#src_Date").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            var d = res["data"].data;
            changeDateData(d);
            setBtnLoading(false);
          } else {
            changeDateData([]);
            toast.error("No Data Found", "danger");
            setBtnLoading(false);
          }
        }
      );
    } catch (error) {}
  };

  const handleDelete = (item, id) => {
    try {
      if (window.confirm("Do you want to Remove?")) {
        setIsLoading(true);
        API.get("v1/group/deletegroupmeeting?id=" + id).then((res) => {
          if (res["data"].status == "1") {
            toast.success(" Deleted Successfully..!", "success");
            //var d = res['data'].data;
            var d = data;
            var index = d.findIndex(function (obj) {
              return obj.id == id;
            });
            if (index > -1) d.splice(index, 1);
            setIsLoading(false);
            listdata();
          } else {
            toast.error("Something Went Wrong..!", "danger");
            setIsLoading(false);
          }
        });
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onFromChange = (event) => {
    var fromdate = $("#fromdate").val();
    var toMinDate = moment(fromdate).format("YYYY-MM-DD");
    if (fromdate != "") {
      $("#todate").attr("min", toMinDate);
    }
  };

  const groupImage = (item) => {
    var dat = imageData.filter(function (obj) {
      return obj.group_id == item.group_id && obj.group_meeting == item.id;
    });

    return dat.map((img_data, i) => (
      <Col md={2}>
        <img
          src={imageUrl + img_data.image}
          width="120px"
          style={styles.imgStyle}
        />
      </Col>
    ));
  };

  const getTotalPart = () => {
    var totalPart = 0;
    changeDate.map(
      (item) =>
        (totalPart =
          parseInt(totalPart) +
          parseInt(item.male_participant) +
          parseInt(item.female_participant))
    );
    return totalPart;
  };

  let maleFemaleTotal = 0;

  return (
    <div className="container">
      <Navbar bg="white" expand="lg">
        <Container>
          <Navbar.Brand>
            Events Report - ({changeDate.length}) Total Participants - (
            {getTotalPart()})
          </Navbar.Brand>
          <Nav className="justify-content-end">
            <Link
              to={{
                pathname: "/eventlist",
              }}
            >
              Back to Event Summary
            </Link>
          </Nav>
        </Container>
      </Navbar>
      <Form
        action=""
        method="post"
        id="src_Date"
        validated={validated}
        onSubmit={onDateSearch}
        style={{ padding: "0px 10px" }}
      >
        <Row className="mt-2">
          <Col md={2}>
            <label>From Date:</label>
            <MDBInput
              type="date"
              id="fromdate"
              name="fromdate"
              onChange={onFromChange}
            />
          </Col>
          <Col md={2}>
            <label>To Date:</label>
            <MDBInput type="date" id="todate" name="todate" />
          </Col>
          <Col md={1}>
            <button
              className="mt-4"
              variant="warning"
              style={{ float: "right" }}
              disabled={btnLoading}
            >
              {btnLoading ? "Please wait.." : "Search"}
            </button>
          </Col>
          <Col md={2}>
            <label>Filter By Group</label>
            <select
              className="form-control form-control-sm"
              name="group_id"
              onChange={filterChange}
            >
              <option value="">Group</option>
              {stData.map((user, i) => {
                return <option value={user.id}>{user.grp_name}</option>;
              })}
            </select>
          </Col>
          <Col md={2}>
            <label>Filter By Event Type</label>
            <select
              className="form-control form-control-sm"
              name="eventtype"
              onChange={(e) => setEventType(e.target.value)}
            >
              <option value="">Type</option>
              {typeData.map((user, i) => {
                return (
                  <option
                    value={user.id}
                    selected={eventType == user.id ? "selected" : ""}
                  >
                    {user.name}
                  </option>
                );
              })}
            </select>
          </Col>
          <Col md={2}>
            <label>Filter By Staff</label>
            <select
              className="form-control form-control-sm"
              name="staff_id"
              onChange={filterChange}
            >
              <option value="">Staff</option>
              {staffData.map((user, i) => {
                return <option value={user.id}>{user.name}</option>;
              })}
            </select>
          </Col>
        </Row>
      </Form>
      <br />

      {/*<Row className="text-center">
				<Col md={{ span: 2, offset: 10 }}>
					<Modal show={addModel} size="lg" onHide={() => { setAddModel(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title>Add Report</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<AddMeeting edit={user} afterFinish={() => { setAddModel(false) }} afterList={() => listdata()} />
						</Modal.Body>
					</Modal>
				</Col>
				<Col md={{ span: 2, offset: 10 }}>
					<Modal show={editModel} size="lg" onHide={() => { setEditModel(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title>Edit Report</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<EditMeeting edit={user} images={editImageData} afterFinish={() => { setEditModel(false) }} afterList={() => listdata()} />
						</Modal.Body>
					</Modal>
				</Col>
				<Col md={{ span: 2, offset: 10 }}>
					<Modal show={viewData} size="lg" onHide={() => { setViewData(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title>View Report</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ViewMeeting edit={user} images={editImageData} afterFinish={() => { setViewData(false) }} />
						</Modal.Body>
					</Modal>
				</Col>
				<Col md={{ span: 2, offset: 10 }}>
					<Modal show={printModalOpen} size="lg" onHide={() => { setPrintModalOpen(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title>Print</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<PrintData edit={user} images={editImageData} />
						</Modal.Body>
					</Modal>
				</Col>
						</Row>*/}
      <Row>
        <Col md={12}>
          <Table className="table mt-10 table-bordered">
            <thead className="text-center">
              <tr>
                <th width="5%">S.No</th>
                <th width="15%">
                  <b>Date & Time</b>
                </th>
                <th width="10%">
                  <b>Group Name</b>
                </th>
                <th width="10%">
                  <b>Staff Name</b>
                </th>
                <th width="10%">
                  <b>Event Type</b>
                </th>
                <th width="10%">
                  <b>Type Of Participants</b>
                </th>
                <th width="10%">
                  <b>Action</b>
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {changeDate.map((item, id) => {
                var len = changeDate.length;
                //maleFemaleTotal += parseInt(item.male_participant) + parseInt(item.female_participant);
                return (
                  <tr>
                    <td>{len - id}</td>
                    <td>
                      {moment(item.meeting_date).format("DD-MM-YYYY")} <br />
                      {moment(item.from_time, ["HH:mm"]).format(
                        "hh:mm A"
                      )} to {moment(item.to_time, ["HH:mm"]).format("hh:mm A")}
                    </td>
                    <td>{item.grp_name}</td>
                    <td>{item.staffname}</td>
                    <td>{item.typename}</td>
                    <td>
                      {item.title} <br /> <b>Male:</b> {item.male_participant} ,{" "}
                      <b>Female:</b> {item.female_participant} <br />{" "}
                      <b>Total:</b>
                      {parseInt(item.male_participant) +
                        parseInt(item.female_participant)}
                    </td>
                    <td>
                      <Button
                        variant="info"
                        onClick={() => {
                          showModel(item);
                        }}
                        size="sm"
                      >
                        <i className="fas fa-edit"></i>{" "}
                      </Button>{" "}
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => viewModel(item)}
                      >
                        <i className="fas fa-eye"></i>{" "}
                      </Button>{" "}
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(item, item.id)}
                      >
                        <i className="fas fa-trash"></i>{" "}
                      </Button>{" "}
                      <Button
                        variant="success"
                        size="sm"
                        onClick={(e) => handlePrint(item)}
                      >
                        <i className="fa fa-print"></i>
                      </Button>{" "}
                      {/*<Button variant="success" size="sm" onClick={() => { openNewPrint() }}><i className="fa fa-print"></i></Button>*/}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>

      <div style={{ display: "none" }} id="new_print_tbl">
        <h2 style={{ textAlign: "center" }}>Staff Report</h2>
        {/*<Table bordered responsive align="center" width="100%" height="80%" style={tdStyle}>
					<tbody style={tdStyle}>
						<tr style={tdStyle}>
							<th style={tdStyle}><b>Group Name:</b></th><td colspan="3" style={tdStyle}> {printData.grp_name}</td>
							<th style={tdStyle}><b>Staff Name:</b></th><td colspan="3" style={tdStyle}>{printData.staffname}</td>
						</tr>
						<tr style={tdStyle}>
							<th style={tdStyle}><b> Event Type: </b></th><td colspan="3" style={tdStyle}> {printData.typename}</td>
							<th style={tdStyle}><b> Event Name: </b></th><td colspan="3" style={tdStyle}> {printData.title}</td>
						</tr>
						<tr style={tdStyle}>
							<th style={tdStyle}><b> Place/Village: </b></th><td colspan="3" style={tdStyle}> {printData.place}</td>
							<th style={tdStyle}><b> Venue: </b></th><td colspan="3" style={tdStyle}> {printData.venue}</td>
						</tr>
						<tr style={tdStyle}>
							<th style={tdStyle}><b> Total Participants: </b></th><td colspan="3" style={tdStyle}> {printData.participants}</td>
							<th style={tdStyle}> <b> Male/Female: </b></th><td colspan="3" style={tdStyle}>
								Male : {printData.male_participant}&emsp;
								Female : {printData.female_participant}
							</td>
						</tr>
						<tr style={tdStyle}>
							<th style={tdStyle}><b> Special Category: </b></th><td style={tdStyle} colspan="3">{printData.special_category}</td>
							<th style={tdStyle}><b> Banking: </b></th><td style={tdStyle} colspan="3">{printData.banking}</td>
						</tr>
						<tr style={tdStyle}>
							<th style={tdStyle}><b> Plastic Bank: </b></th><td style={tdStyle} colspan="3">{printData.plastic_bank}</td>
							<th style={tdStyle}><b> Organising: </b></th><td style={tdStyle} colspan="3">{printData.organising}</td>
						</tr>
						<tr style={tdStyle}>
							<th style={tdStyle}><b> Kap Study: </b></th><td style={tdStyle} colspan="3">{printData.kap_study}</td>
							<th style={tdStyle}><b> Door Conselling: </b></th><td style={tdStyle} colspan="3">{printData.door}</td>
						</tr>
						<tr style={tdStyle}>
							<th style={tdStyle}><b> Topic: </b></th><td style={tdStyle} colspan="3">{printData.topics}</td>
							<th style={tdStyle}><b> Report Date: </b></th><td style={tdStyle} colspan="3">{moment(printData.meeting_date).format('DD-MM-YYYY')}</td>
						</tr>
						<tr style={tdStyle}>
							<th style={tdStyle}><b> Reporting Time: </b></th><td style={tdStyle} colspan="3">{moment(printData.from_time, ['HH:mm']).format("hh:mm A")} to {moment(printData.to_time, ['HH:mm']).format("hh:mm A")}</td>
							<th style={tdStyle}><b> Resource person / guest: </b></th><td style={tdStyle} colspan="3">{printData.invitees}</td>
						</tr>
						<tr style={tdStyle}>
							<th style={tdStyle}><b> Content: </b></th><td style={tdStyle} colspan="5">
								<div dangerouslySetInnerHTML={{ __html: printData.feedback }}></div>
							</td>
						</tr>
						<tr style={tdStyle}>
							<th><b> Photos: </b></th><td style={tdStyle} colspan="5">
								<Row>

									<img src={imageUrl + printData.image} width="120px" style={styles.imgStyle} />
				imageData.map((item, i) => {
											return (<Col md={6}>
												<img src={imageUrl + item.image} width="120px" style={styles.imgStyle} />
											</Col>);
										})
									{groupImage(printData)}

			</Row>
		</td>
						</tr >
					</tbody >
									</Table >*/}
      </div>
      <iframe
        name="print_frame"
        id="print_frame"
        width="0"
        height="0"
        frameborder="0"
        src="about:blank"
      ></iframe>
    </div>
  );
};
export default ViewEventList;
