export default {
  top: [
    //{ name: 'Dashboard', path: '/home', exact: true },
    {
      name: "Staff Details",
      path: "/attendance-report",
      childrens: [
        { name: "Staff Details", path: "/staff" },
        { name: "Staff Attendance Report", path: "/attendance-report" },
      ],
    },

    {
      name: "WPEG",
      path: "/manage",
      childrens: [
        { name: "Group Details", path: "/group" },
        { name: "Group Meeting Report", path: "/groupmeetingreport" },
        { name: "Financial Statement", path: "/financial-statement" },
        { name: "Financial Summary", path: "/financial-summary" },
      ],
    },

    {
      name: "Events",
      path: "/event",
      childrens: [
        { name: "Events Planned", path: "/eventtype" },
        { name: "Events Summary", path: "/eventlist" },
        { name: "Event Reports", path: "/staff-report" },
        { name: "Village Wise Summary", path: "/eventreport" },
      ],
    },

    {
      name: "HBC Details",
      childrens: [
        { name: "HBC Details", path: "/house" },
        { name: "Status Report", path: "/qrl-report" },
        { name: "HBC Outcome Report", path: "/hbcoutcome" },
        { name: "HBC Outcome", path: "/allhbcoutcome" },
      ],
    },

    {
      name: "Plastic Bank",
      path: "/plastic",
      childrens: [
        { name: "Material Price Setup", path: "/pricesetup" },
        {
          name: "Material Collection Report",
          path: "/material-collection-report",
        },
        { name: "Material Sales Report", path: "/salesreport" },
        { name: "Material Current Stock", path: "/currentstock" },
        {
          name: "Plastic Bank Financial Statement",
          path: "/plastic-bank-financial-statement",
        },
        {
          name: "Plastic Bank Financial Summary",
          path: "/plastic-bank-financial-summary",
        },
      ],
    },

    // {
    // 	name: 'Report', path: '/material-collection-report', childrens: [
    // 		{ name: 'Material Collection Report', path: '/material-collection-report' },
    // 		{ name: 'Plastic Sales Report', path: '/salesreport' },
    // 		{ name: 'Plastic Collection Source Report', path: '/srcreport' },
    // 		{ name: 'Plastic Sales Group Wise Report', path: '/salesgroupwise' },
    // 		{ name: 'Staff Wise Collection Report', path: '/staffwise-report' },
    // 		//{ name: 'HBCG Survey', path: '/hbcg-survey' },
    // 	]
    // },
    // {
    // 	name: 'Accounts', path: '/accounts', childrens: [
    // 		{ name: 'Payments', path: '/payments' },
    // 		{ name: 'Ledger Head', path: '/ledgerhead' },
    // 		{ name: 'Bank Accounts', path: '/bankaccounts' },
    // 	]
    // },
    // {
    {
      name: "Admin",
      path: "/admin",
      childrens: [
        { name: " Accounting Year", path: "/accountingyear" },
        { name: "Admin Panel", path: "/adminpanel" },
        { name: "Announcements", path: "/announcement" },
        { name: "Assign Group to Staff", path: "/groupsettings" },
        { name: "Bank Accounts", path: "/bankaccounts" },
        { name: "Indicators", path: "/indicators" },
        { name: "Indicators Report", path: "/hbcg-report" },
        { name: "Ledger Head", path: "/ledgerhead" },
        { name: "Leave Entry", path: "/leaveentry" },
        { name: "Material Collection", path: "/material-collection" },
      ],
    },
    {
      name: "Admin",
      path: "/admin",
      childrens: [
        { name: "Material Sales", path: "/plasticsales" },
        { name: "Payments", path: "/payments" },
        { name: "Plastic Type", path: "/typemanagement" },
        { name: "Plastic Collection Source Report", path: "/srcreport" },
        { name: "Plastic Price Chart", path: "/pricechart" },
        { name: "Plastic Sales Group Wise Report", path: "/salesgroupwise" },
        { name: "Staff Wise Collection Report", path: "/staffwise-report" },
        { name: "Target Setup", path: "/target-setup" },
        { name: "Users", path: "/users" },
        { name: "Vendor", path: "/vendor" },
        { name: "Village", path: "/addvillage" },
      ],
    },
    // 	name: 'Settings', path: '/setting', childrens: [
    // 		{ name: 'Village', path: '/addvillage' },
    // 		{ name: 'Admin Panel', path: '/adminpanel' },
    // 		{ name: 'Users', path: '/users' },
    // 		{ name: ' Accounting Year', path: '/accountingyear' },
    // 		{ name: 'Announcements', path: '/announcement' },
    // 		{ name: 'Leave Entry', path: '/leaveentry' }
    // 	]
    // },
  ],
  bottom: [],
};
