import React, { useState, useContext, useEffect } from "react";
import { Card, Row, Col, Modal, Form, Table, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { MDBInput } from "mdb-react-ui-kit";
import API from "../../utils/api";
import $ from "jquery";
import PsContext from "../../context";
import { PSListItem } from "../../comp";
import axios from "axios";
import { integerIndMobile, baseUrl, decimalKeyPress } from "../../utils";
import moment from "moment";
import EditSaless from "./editSaless";

const ViewSales = (props) => {
  const context = useContext(PsContext);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [plasticData, setPlasticData] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState([]);
  const [amountData, setAmountData] = useState(0);

  useEffect(() => {
    listmaterial();
  }, []);

  const showModel = (item) => {
    setEditModel(true);
    setEdit(item);
  };

  const listmaterial = () => {
    try {
      setIsLoading(true);
      var form = new FormData();
      form.append("acyear", context.acyear.id);
      form.append("group_id", props.data.group_id);
      form.append("from", props.from);
      form.append("to", props.to);
      form.append("plasticValue", JSON.stringify(props.value));
      API.post("v1/plastic/listsalesview", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          console.log(d);
          setData(d);
          setMaterialData(d);
          totalAmountData();
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (er) {}
  };

  // const handleSubmit = (event) => {
  // 	console.log('hii');
  // 	event.preventDefault();
  // 	try {
  // 		setBtnLoading(true);
  // 		API.post('v1/plastic/editmaterial', $("#editform").serialize()).then(res => {
  // 			if (res['data'].status == '1') {
  // 				toast.success("Updated Successfully", "success");
  // 				setBtnLoading(false);
  // 				const vrl = baseUrl + '/v1/log/addlog';
  // 				let formData = new FormData();
  // 				formData.append('createon', '2021/12/10');   //append the values with key, value pair
  // 				formData.append('context', 'Edit Plastic Material');
  // 				formData.append('e_type', 'User_log');
  // 				formData.append('action_by', 'admin');
  // 				formData.append('addby', 1);
  // 				formData.append('addip', 'localhost');
  // 				var config = {
  // 					method: 'post',
  // 					url: vrl,
  // 					data: formData
  // 				};
  // 				axios(config)
  // 					.then(function (response) {
  // 						console.log(JSON.stringify(response.data));
  // 					})
  // 					.catch(function (error) {
  // 						console.log(error);
  // 					});
  // 				props.afterFinish();
  // 				props.afterList();
  // 			}
  // 			else {
  // 				toast.error("Something Went Wrong", "danger");
  // 				setBtnLoading(false);
  // 			}
  // 		});
  // 	}
  // 	catch (error) {

  // 	}
  // }

  const totalAmountData = () => {
    let totalamount = 0;
    materialData.map((item) => {
      totalamount = parseFloat(totalamount) + parseFloat(item.total_amount);
    });
    return parseFloat(totalamount).toFixed(2);
  };

  console.log(props.value);
  let totalAmount = 0;
  let total = 0;
  let btotal = 0;
  return (
    <>
      <div className="container">
        <Form action="" method="post" id="editform" validated={validated}>
          <Row>
            <Col md={3}>
              <MDBInput
                label="Group Name"
                type="text"
                name="name"
                size="lg"
                disabled
                defaultValue={props.data.grp_name}
              />
            </Col>
            <Col md={3}>
              <MDBInput
                label="Material Type"
                type="text"
                name="name"
                size="lg"
                disabled
                defaultValue={props.value}
              />
            </Col>
            <Col md={3}>
              <MDBInput
                label={
                  props.value == "others pcs" ? "Total Pieces" : "Total KG"
                }
                type="text"
                name="name"
                size="lg"
                disabled
                defaultValue={
                  props.value == "others pcs"
                    ? parseFloat(props.data.salesKg).toFixed(0)
                    : parseFloat(props.data.salesKg).toFixed(2)
                }
              />
            </Col>

            <Col md={3}>
              <MDBInput
                label="Total Amount"
                type="text"
                name="name"
                size="lg"
                disabled
                value={totalAmountData()}
              />
            </Col>
            {materialData.length > 0 ? (
              <Col md={12} className="mt-2">
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Date</th>
                      <th>Type</th>
                      {props.value == "others pcs" ? (
                        <th width="10%">Pieces</th>
                      ) : (
                        <th width="10%">Kg</th>
                      )}
                      <th width="10%">Amount</th>
                      <th width="10%">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {materialData.map((item, i) => {
                      total += parseFloat(item.selling_kg);
                      totalAmount += parseFloat(item.total_amount);
                      var d = materialData.length;
                      return (
                        <tr>
                          <td>{d - i}</td>
                          <td>
                            {moment(item.sales_date).format("DD-MM-YYYY")}
                          </td>
                          <td>{item.pname}</td>
                          <td align="right">
                            {props.value == "others pcs"
                              ? parseFloat(item.selling_kg).toFixed(0)
                              : parseFloat(item.selling_kg).toFixed(2)}
                          </td>
                          <td align="right">{item.total_amount}</td>
                          <td>
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => {
                                showModel(item);
                              }}
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan={3} align="right">
                        Total
                      </td>
                      <td align="right">
                        {props.value == "others pcs"
                          ? parseFloat(total).toFixed(0)
                          : parseFloat(total).toFixed(2)}
                      </td>
                      <td colSpan={2} align="left">
                        {parseFloat(totalAmount).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <Modal
                  show={editModel}
                  size="lg"
                  onHide={() => {
                    setEditModel(false);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Sales</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <EditSaless
                      data={edit}
                      type={props.value}
                      afterFinish={() => {
                        setEditModel(false);
                      }}
                      afterList={() => {
                        listmaterial();
                      }}
                    />
                  </Modal.Body>
                </Modal>

                {/* <Table responsive bordered >
									<thead>
										<tr>
											<th>S.No</th>
											<th>Type</th>
											<th width="10%">Kg</th>
											<th width="10%">Amount</th>
											<th width="10%">Total</th>
										</tr>
									</thead>
									<tbody>
										{plasticData.map((item, i) => {
											let kg = getmaterial(item.id, 'total_kg') ? getmaterial(item.id, 'total_kg') : 0;
											totalKg += parseFloat(kg);
											let buyingAmt = (item.current_price * item.buying_rate) / 100;
											let bAmt = getmaterial(item.id, 'buying_amount') != '' ? getmaterial(item.id, 'buying_amount') : buyingAmt;
											btotal += parseFloat(bAmt);
											let amount = parseFloat(kg * bAmt);
											totalAmount += amount;
											return <tr>
												<td>{i + 1}</td>
												<td>{item.plastic_name}</td>
												<td>{kg}</td>
												{/* <td align="right">
													<input type="text" name="buying_kg" id={"buy_" + item.id} onKeyPress={decimalKeyPress} defaultValue={kg} onChange={(e) => { onKgChange(item.id, bAmt, item.current_price, 'total_kg', e.target.value) }} readOnly />
												</td>
												<td align="right">{parseFloat(bAmt).toFixed(2)}</td>
												{/*<td align="right">
												<input type="text" name="buying_amt" id={"buyamt_"+item.id} onKeyPress={decimalKeyPress} defaultValue={bAmt} onChange={(e)=> {onKgChange(item.id,'buying_amount',e.target.value)}} />
											</td>
												<td align="right">{parseFloat(amount).toFixed(2)}</td>
											</tr>
										})}
										<tr>
											<td colSpan="2" align="right"><b>Total</b></td>
											<td ><b>{parseFloat(totalKg).toFixed(2)}</b></td>
											<td ><b>{parseFloat(btotal).toFixed(2)}</b></td>
											<td align="right"><b>{parseFloat(totalAmount).toFixed(2)}</b></td>
										</tr>
										 <tr>
											<td colSpan="2" align="right"><b>Amount Paid</b></td>
											<td colSpan="2" align="right">
												<input type="text" name="p_amount" defaultValue={materialData[0].paid_amount} onKeyPress={decimalKeyPress} />
												<input type="hidden" name="net_amount" defaultValue={totalAmount} />
											</td>
										</tr> 
									</tbody>
								</Table> */}
              </Col>
            ) : (
              ""
            )}
            <Col md={12} className="mt-3">
              {/* <button type="submit" className="btn btn-outline-black btn-block" disabled={btnLoading}>
								{btnLoading ? 'Please wait..' : 'Update'}</button> */}
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default ViewSales;
