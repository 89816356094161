import React, { useState, useContext } from "react";
import { withRouter, useHistory, Link, Switch, Route } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import { baseUrl } from "../../utils";

import PaymentInPb from "./paymentInPb";
import PaymentOutPb from "./paymentOutPb";
import HomePb from "./homePb";

import { Layout } from "antd";
import DailyReportPb from "./dailyReportPb";

const MobileAccountsPlasticBank = (props) => {
  const context = useContext(PsContext);
  const [page, setPage] = useState("home_pb");

  return (
    <Layout onAction={(p) => setPage(p)} page={page}>
      {page == "home_pb" && <HomePb onAction={(p) => setPage(p)} />}

      {page == "payment_in_pb" && (
        <PaymentInPb onAction={(p) => setPage(p)} {...props} />
      )}

      {page == "payment_out_pb" && (
        <PaymentOutPb onAction={(p) => setPage(p)} {...props} />
      )}

      {page == "day_report_pb" && (
        <DailyReportPb onAction={(p) => setPage(p)} {...props} />
      )}
    </Layout>
  );
};
export default withRouter(MobileAccountsPlasticBank);
