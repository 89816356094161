import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col,  Modal , Form, Table ,Navbar, Nav,Container, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { integerIndMobile, lettersOnly, integerIndGst } from '../../utils';
import {baseUrl} from '../../utils';
import axios from 'axios';

const AddVendor = (props) =>{

	const context = useContext(PsContext);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
	const [data, changeData] = useState([]);
	const [dob, setDob] = useState('');

	useEffect(() => {
		var date = new Date().getDate()+'-'+(new Date().getMonth()+1)+new Date().getFullYear();
		setDob(date);
    } , [] );
	
    const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		if (!form.checkValidity() === true) {
		  event.stopPropagation();
		  setValidated(true);
		  return;
		}
		try{
			setBtnLoading(true);
			API.post('v1/vendor/addvendor',$("#addform").serialize()).then(res=>{
				if (res['data'].status == '1') {
				  toast.success("Vendor Added Successfully..!", "success");
				  document.getElementById("addform").reset();
				  setBtnLoading(false);
				  const vrl=baseUrl+'/v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Add Vendor');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
					  method: 'post',
					  url: vrl,
					  data : formData
					};

					axios(config)
					.then(function (response) {
					  console.log(JSON.stringify(response.data));
					})
					.catch(function (error) {
					  console.log(error);
					});	
				}
				else {
				  toast.error("Something Went Wrong..!", "danger");
				  document.getElementById("addform").reset();
				}		
				setIsLoading(false);
			});
		}
		catch(error){
			
		}
	};

	return(
		<>
		<div class="container">
            <Navbar bg="white" expand="lg">
				<Container>
					<Navbar.Brand >Add Vendor / Customer </Navbar.Brand>
					<Nav  className="justify-content-end">
                    <Link
                        to={{
                            pathname:'/vendor'
                    }}>
                        Manage
                    </Link>
					</Nav>
				</Container>
			</Navbar>
		<Card>
			<Card.Body>
            <Form action="" method="post" id="addform" noValidate validated={validated} onSubmit={handleSubmit} >
				<Row className="mt-2">
					<Col md={6}>
						<Col>
							<MDBInput
								label="Vendor Name"
								type="text"
								id="customername"
								name="customername"
								size="lg"
								required
								onKeyPress={lettersOnly}
							/>
						</Col> 
						<Col  className="mt-3">
							<MDBInput
								label="Company Name"
								type="text"
								id="companyname"
								name="companyname"
								size="lg"
								onKeyPress={lettersOnly}
							/>
						</Col>
						<Col  className="mt-3">
							<MDBInput
								label="GSTIN"
								type="text"
								id="gst"
								name="gst"
								size="lg"
								onKeyPress={integerIndGst}
							/>
						</Col>
						<Col  className="mt-3">
							<MDBInput
								label="PAN"
								type="text"
								id="pan"
								name="pan"
								size="lg"
								minLength="10"
								maxLength="10"
							/>
						</Col>
					</Col>
					<Col md={6}>
						<Col  className="mt-0">
							<MDBInput
								label="DOB"
								type="date"
								id="dob"
								name="dob"
								size="lg"
								defaultValue={dob}
							/>
						</Col>
						<Col  className="mt-3">
							<MDBInput
								label="Mobile No"
								type="text"
								id="mobile"
								name="mobile"
								size="lg"
								onKeyPress={integerIndMobile}
								required
							/>
						</Col>
						<Col className="mt-3">
							<MDBInput
								label="Email-ID"
								type="text"
								id="email"
								name="email"
								size="lg"
							/>
						</Col>
						<Col className="mt-3">
							<MDBInput
								label="Address"
								type="text"
								id="address"
								name="address"
								size="lg"
							/>
						</Col>
					</Col>
						<Col md={6}>
							<Col className="mt-3">
								<select className="form-select" aria-label="Default select example" name="activestatus">
								  <option selected> Status</option>
								  <option value="Active">Active</option>
								  <option value="InActive">InActive</option>
								</select>
							</Col>
						</Col>
						<Col md={6}>
							<Col className="mt-3">
							<Button  className="btn btn-success"  style={{float: 'right'}} type="submit" disabled={btnLoading}>
								{btnLoading ? 'Please wait..' : 'Add'}</Button>
						</Col>
					</Col>
				</Row>
			</Form>
			</Card.Body>
			</Card>
			</div>
		</>
	);
};
export default AddVendor;