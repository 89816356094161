import React, { useState, useContext, useEffect } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Button, Form, Table } from "react-bootstrap";
import {
  Spin,
  Button as AntButton,
  Form as AntForm,
  Modal,
  Select,
  InputNumber,
  Radio,
  Space,
  DatePicker,
  message,
} from "antd";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import { apiRequest } from "../../models/core";
import {
  baseUrl,
  getAscSortOrder,
  groupByMultiple,
  momentDate,
} from "../../utils";
import axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEdit } from "@fortawesome/free-solid-svg-icons";
import { green } from "@ant-design/colors";
const styles = {
  table: {
    border: "1px solid black",
    borderCollapse: "collapse",
  },
  tr: {
    border: "1px solid black",
    borderCollapse: "collapse",
  },
  td: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
  },
  tdBg: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
    backgroundColor: "rgb(224 247 211)",
  },
  tdRed: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
    backgroundColor: "rgb(245 227 221)",
    fontStyle: "italic",
    fontWeight: "bold",
  },
};

const FinancialStatement = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState([]);

  const [fromDate, setFromDate] = useState(
    momentDate(new Date(), "YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(momentDate(new Date(), "YYYY-MM-DD"));

  const [data, setData] = useState([]);
  const [dataView, setDataView] = useState([]);

  const [paymentType, setPaymentType] = useState(false);
  const [groups, setGroups] = useState([]);

  const [groupId, setGroupId] = useState(0);

  const [income, setIncome] = useState([]);
  const [expense, setExpense] = useState([]);
  const [cashInHand, setCashInHand] = useState(0);
  const [bankBalance, setBankBalance] = useState(0);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [viewData, setViewData] = useState({});
  const [ledgers, setLedgers] = useState([]);
  const [editForm] = AntForm.useForm();
  const [cashOrBank, setCashOrBank] = useState(0);
  const [voucherType, setVoucherType] = useState("");
  const [voucherDate, setVoucherDate] = useState(moment());
  const [editLoader, setEditLoader] = useState(false);
  useEffect(() => {
    loadGroups();
    //loadData();
    loadLedgers();
  }, []);
  const loadLedgers = () => {
    var reqData = {
      query_type: "query",
      query:
        "SELECT l.id,l.ledger_group_id,g.ledger_name as ledger_group_name,l.allowed_account_by,l.ledger_head_type,l.ledger_head_name FROM ledger_head l,ledger_group g WHERE l.ledger_group_id=g.id and l.status='1'",
    };

    apiRequest(reqData)
      .then((res) => {
        setLedgers(res);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  const loadGroups = () => {
    setLoader(true);

    API.get("v1/acc/groups").then((res) => {
      if (res["data"].errorcode == "200") {
        setGroups(res["data"].data);
      }
      setLoader(false);
    });
  };

  const loadData = () => {
    setLoader(true);
    setData([]);
    setDataView([]);
    var form = new FormData();
    form.append("group_id", groupId);
    form.append("staff_id", 0);
    form.append("from_date", fromDate);
    form.append("to_date", toDate);

    API.post("v1/acc/statement", form).then((res) => {
      if (res["data"].errorcode == "200") {
        setData(res["data"].data);
        //setDataView(res['data'].data);
      } else {
        toast.error(res["data"].content || "Error");
      }
      setLoader(false);
    });
  };

  const getSelectOptions = (ByOrTo, type, payment_mode) => {
    if (type == "payment_in") {
      let by = ledgers.filter((item) => item.id == "1" || item.id == "29");
      let to = ledgers.filter(
        (item) =>
          item.allowed_account_by == parseInt(payment_mode) &&
          item.ledger_group_name == "INCOME"
      );
      console.log("to", to);

      if (ByOrTo === "by")
        return by.map((item) => (
          <Select.Option value={item.id}>{item.ledger_head_name}</Select.Option>
        ));
      else
        return to.map((item) => (
          <Select.Option value={item.id}>{item.ledger_head_name}</Select.Option>
        ));
    } else if (type == "payment_out") {
      let to = ledgers.filter((item) => item.id == "1" || item.id == "29");
      let by = ledgers.filter(
        (item) =>
          item.allowed_account_by == payment_mode &&
          item.ledger_group_name == "EXPENSE"
      );
      if (ByOrTo === "by")
        return by.map((item) => (
          <Select.Option value={item.id}>{item.ledger_head_name}</Select.Option>
        ));
      else
        return to.map((item) => (
          <Select.Option value={item.id}>{item.ledger_head_name}</Select.Option>
        ));
    }
  };

  const onFinishEdit = (values) => {
    setEditLoader(true);
    var processedValues = {};
    if (values.voucher_type == "payment_in") {
      processedValues = {
        voucher_type: values.voucher_type,
        voucher_date: values.voucher_date,
        account_by: values.by_account,
        ledger_head_id: values.to_account,
        debit_amount: "0",
        credit_amount: values.amount,
        amount: values.amount,
      };
    } else if (values.voucher_type == "payment_out") {
      processedValues = {
        voucher_type: values.voucher_type,
        voucher_date: values.voucher_date,
        account_by: values.to_account,
        ledger_head_id: values.by_account,
        debit_amount: values.amount,
        credit_amount: "0",
        amount: values.amount,
      };
    }
    var reqData = {
      query_type: "update",
      table: "vouchers",
      where: { id: viewData.id },
      values: processedValues,
    };

    apiRequest(reqData)
      .then((res) => {
        message.success("Transaction Updated");
        loadData();
        setVisibleEditModal(false);
        setEditLoader(false);
      })
      .catch((err) => {
        message.error(err);
        setEditLoader(false);
      });
    //take backup before update.
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment();
  };
  return (
    <div className="container bg-transparent">
      <Spin spinning={loader}>
        <Card style={{ position: "sticky", top: "50px" }}>
          <Card.Body>
            <Row>
              <Col md={12}>
                <h4>Financial Statements</h4>
                <hr />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={4}>
                <label style={{ fontSize: "11px" }}>Group Name</label>
                <Form.Control
                  as="select"
                  name="group_id"
                  size="sm"
                  onChange={(e) => setGroupId(e.target.value)}
                  required
                >
                  <option value="0">-All Groups-</option>
                  {groups.map((item) => (
                    <option value={item.id}> {item.grp_name} </option>
                  ))}
                  ;
                </Form.Control>
              </Col>
              <Col md={2}>
                <label style={{ fontSize: "11px" }}>From Date</label>
                <Form.Control
                  type="date"
                  max={momentDate(new Date(), "YYYY-MM-DD")}
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  size="sm"
                />
              </Col>
              <Col md={2}>
                <label style={{ fontSize: "11px" }}>To Date</label>
                <Form.Control
                  type="date"
                  max={momentDate(new Date(), "YYYY-MM-DD")}
                  min={fromDate}
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  size="sm"
                />
              </Col>
              <Col md={2}>
                <Button
                  type="button"
                  size="sm"
                  className="mt-4 btn-block"
                  onClick={loadData}
                >
                  View
                </Button>
              </Col>
            </Row>
            <table width="100%">
              <thead>
                <tr style={styles.tr}>
                  <th colSpan={5} style={{ textAlign: "center" }}>
                    WPEGS: FINANCIAL STATEMENT
                  </th>
                </tr>
                <tr style={styles.tr}>
                  <th style={styles.td}>S. No. </th>
                  <th style={styles.td}>Date </th>
                  <th style={styles.td}> Particulars </th>
                  <th style={styles.td}>Pay In</th>
                  <th style={styles.td}>Pay Out</th>
                  <th style={styles.td}>Group</th>
                  <th style={styles.td}>Narration</th>
                  <th style={styles.td}>Edit</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => {
                    return (
                      <tr style={styles.tr}>
                        <th style={styles.td}>{data.length - index}</th>
                        <th style={styles.td}>
                          {moment(item.voucher_date).format("DD/MM/YYYY")}{" "}
                        </th>
                        <th style={styles.td}>
                          <span style={{ fontWeight: "bold", color: "green" }}>
                            BY
                          </span>{" "}
                          {item.credit_amount > 0
                            ? item.account_by_name
                            : item.ledger_head_name}
                          <br />
                          &nbsp; &nbsp; &nbsp;{" "}
                          <span style={{ fontWeight: "bold", color: "red" }}>
                            TO
                          </span>{" "}
                          {item.debit_amount > 0
                            ? item.account_by_name
                            : item.ledger_head_name}
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            borderCollapse: "collapse",
                            verticalAlign: "top",
                            padding: "5px",
                          }}
                        >
                          {item.credit_amount > 0 ? item.amount : ""}
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            borderCollapse: "collapse",
                            verticalAlign: "top",
                            padding: "5px",
                          }}
                        >
                          <br />
                          {item.debit_amount > 0 ? item.amount : ""}
                        </th>
                        <th style={styles.td}>{item.grp_name}</th>
                        <th style={styles.td}>{item.narration}</th>
                        <th style={styles.td}>
                          <AntButton
                            type="primary"
                            size="small"
                            shape="circle"
                            onClick={() => {
                              setVisibleEditModal(true);
                              setViewData(item);
                              setCashOrBank(item.account_by);
                              setVoucherType(item.voucher_type);
                              setVoucherDate(
                                moment(item.voucher_date, "YYYY-MM-DD")
                              );
                              editForm.setFieldsValue({
                                voucher_type: item.voucher_type,
                                amount: item.amount,
                                voucher_date: item.voucher_date,
                              });
                              if (item.voucher_type == "payment_in") {
                                editForm.setFieldsValue({
                                  by_account: item.account_by,
                                  to_account: item.ledger_head_id,
                                });
                              } else {
                                editForm.setFieldsValue({
                                  to_account: item.account_by,
                                  by_account: item.ledger_head_id,
                                });
                              }
                              console.log("i", item);
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </AntButton>
                        </th>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {/* {data.length > 0 && (
							<table width="100%" algin="center">
								{income.map(item => getIncome(item))}
							</table>
						)} */}
          </Card.Body>
        </Card>
      </Spin>

      <Modal
        visible={visibleEditModal}
        zIndex={1000}
        footer={null}
        closeIcon={
          <AntButton type="outlined" shape="circle">
            <FontAwesomeIcon icon={faClose} />
          </AntButton>
        }
        centered={false}
        closable={true}
        style={{ marginTop: "20px" }}
        width={600}
        // footer={null}
        onCancel={() => {
          setVisibleEditModal(false);
        }}
        title={
          <>
            Edit {viewData.ledger_head_name} (
            <span style={{ color: green[5] }}>{viewData.amount} </span>)
          </>
        }
      >
        <Spin spinning={editLoader}>
          <AntForm
            name="basic"
            form={editForm}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 20 }}
            initialValues={{ remember: true }}
            onFinish={onFinishEdit}
            autoComplete="off"
          >
            <AntForm.Item
              label="Voucher Date"
              name="voucher_date"
              rules={[{ required: true, message: "Please Select Date" }]}
            >
              <Space direction="vertical">
                <DatePicker
                  format="DD/MM/YYYY"
                  disabledDate={disabledDate}
                  value={voucherDate}
                  onChange={(date) => {
                    editForm.setFieldsValue({
                      voucher_date: moment(date).format("YYYY-MM-DD"),
                    });
                    setVoucherDate(date);
                  }}
                  allowClear={false}
                />
              </Space>
            </AntForm.Item>
            <AntForm.Item
              label="Voucher Type"
              name="voucher_type"
              rules={[{ required: true, message: "Please Voucher Type" }]}
            >
              <Radio.Group
                buttonStyle="solid"
                onChange={(e) => {
                  setVoucherType(e.target.value);
                }}
              >
                <Radio.Button value="payment_in">Payment In</Radio.Button>
                <Radio.Button value="payment_out">Payment Out</Radio.Button>
              </Radio.Group>
            </AntForm.Item>

            {voucherType == "payment_in" && (
              <>
                <AntForm.Item
                  label="By Account"
                  name="by_account"
                  rules={[
                    { required: true, message: "Please Select By Account" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="By Account"
                    optionFilterProp="children"
                    //onChange={heightOnChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => setCashOrBank(value)}
                  >
                    {getSelectOptions("by", voucherType, cashOrBank)}
                  </Select>
                </AntForm.Item>
                <AntForm.Item
                  label="To Account"
                  name="to_account"
                  rules={[
                    { required: true, message: "Please Select To Account" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="By Account"
                    optionFilterProp="children"
                    //onChange={heightOnChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {getSelectOptions("to", voucherType, cashOrBank)}
                  </Select>
                </AntForm.Item>
              </>
            )}
            {voucherType == "payment_out" && (
              <>
                <AntForm.Item
                  label="To Account"
                  name="to_account"
                  rules={[
                    { required: true, message: "Please Select To Account" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="By Account"
                    optionFilterProp="children"
                    //onChange={heightOnChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => setCashOrBank(value)}
                  >
                    {getSelectOptions("to", voucherType, cashOrBank)}
                  </Select>
                </AntForm.Item>
                <AntForm.Item
                  label="By Account"
                  name="by_account"
                  rules={[
                    { required: true, message: "Please Select By Account" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="By Account"
                    optionFilterProp="children"
                    //onChange={heightOnChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {getSelectOptions("by", voucherType, cashOrBank)}
                  </Select>
                </AntForm.Item>
              </>
            )}

            <AntForm.Item
              label="Amount"
              name="amount"
              rules={[{ required: true, message: "Please Enter Amount" }]}
            >
              <InputNumber
                placeholder="Amount"
                type="number"
                style={{ width: "100%" }}
              />
            </AntForm.Item>
            <AntForm.Item wrapperCol={{ offset: 12, span: 24 }}>
              <AntButton
                size="large"
                type="primary"
                htmlType="submit"
                style={{}}
              >
                Save
              </AntButton>
            </AntForm.Item>
          </AntForm>
        </Spin>
      </Modal>
    </div>
  );
};
export default withRouter(FinancialStatement);
