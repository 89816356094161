import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col,  Modal , Form, Table} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import {baseUrl, imageUrl} from '../../utils';

const ViewEvent = (props) =>{
	
	const context = useContext(PsContext);
	const [data, changeData] = useState([]);
	const styles = {
		imgStyle : {
			border : '1px solid #d8d8d8',margin : '10px 10px 0px 0px',height : '120px'
		},
		plusImgStyle : {
			padding:'10px',border : '1px solid #d8d8d8',margin : '10px',backgroundColor : '#f1f1f1',cursor : 'pointer'
		},
		btnStyle : {
			position: 'absolute',
			bottom: '175px',
			left: '132px',
			borderRadius: '32px',
			cursor : 'pointer'
		}
	}

	return(
		<>
			
			<div class="container">
            <Row>
				<Col md={{span:12 , offset:0}}>
                    <Table bordered>
                        <tbody>
                            <tr>
                                <th><b>Event Name: </b></th><td colspan="3">{props.data.eventname}</td>
                                <th><b>Event Type </b></th><td colspan="3">{props.data.typename}</td>
                            </tr>
                            <tr>
                                <th><b> Date & Time: </b></th><td colspan="3">{props.data.eventdate} {props.data.eventtime}</td>
                                <th><b> Place: </b></th><td colspan="3">{props.data.place}</td>
                            </tr>
                            <tr>
                                <th><b> Group Name: </b></th><td colspan="3">{props.data.grp_name}</td>
                                <th><b> Status: </b></th><td colspan="3">{props.data.requirements}</td>
                            </tr>
                        </tbody>
                    </Table>
						
				</Col>
				<Col md={{span:12 , offset:0}}>
                    <label><b>Content : </b></label>
					{props.report.length > 0 ?
					<div dangerouslySetInnerHTML={{__html : props.report.feedback}}></div> : '-'}
				</Col>
				<Col md={{span:12 , offset:0}}>
                    <label><b>Images : </b></label>
					{props.images.length > 0 ?
						<Row>
							{props.images.map((item,i)=>{
								return(<Col md={2} style={{maxWidth : '15%'}}>
										<img src={imageUrl+item.image} width="120px" style={styles.imgStyle} />
									</Col>);
							})}
						</Row>
					: ''}
				</Col>
			</Row>
			
			
			</div>
		</>
	);
};

export default ViewEvent;