import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import { Switch } from 'antd';
import moment from 'moment';
import EditHouse from './editHouse';

const thStyle = {
	transform: "rotate(-158deg)",
	width: "173px",
	background: "#1d2026",
	opacity: "0.25",
	color: "black"
}

const hrStyle = {
	transform: "rotate(90deg)"
}

const tdStyle = { border: '1px solid grey', borderCollapse: 'collapse', padding: '3px' }

const IndicatorReport = (props) => {

	const context = useContext(PsContext);
	const [AddUser, setAddUser] = useState(false);
	const [userModal, setUserModal] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [ViewData, setViewData] = useState(false);
	const [user, setUser] = useState([]);
	const [view, setView] = useState([]);
	const [data, changeData] = useState([]);
	const [date, changeDate] = useState([]);
	const [value, changeValue] = useState([]);

	useEffect(() => {
		listdata();
		listQuestionData();
	}, []);

	const listdata = () => {
		try {
			API.get('v1/group/listindicator').then(res => {
				if (res['data'].data) {
					var d = res['data'].data;
					changeData(d);
				}
				else {

				}
			});
		}
		catch (error) {

		}
	}

	const listQuestionData = () => {
		try {
			API.get('v1/group/listquestionindicator').then(res => {
				if (res['data'].data) {
					var d = res['data'].data;
					var d1 = res['data'].datanew;
					changeDate(d);
					changeValue(d1);
				}
				else {

				}
			});
		}
		catch (error) {

		}
	}

	const getRowData = () => {
		var tr = [];
		date.map((item, i) => {
			tr.push(<React.Fragment>
				<td></td>
				<td></td>
			</React.Fragment>);
		});
		return tr;
	};

	/*changeMonth = (e) => {
		setMonth(e.target.value);
	}*/

	return (
		<div className="container" >
			<Navbar bg="white" expand="lg">
				<Container>
					<Navbar.Brand >Home Visit Report</Navbar.Brand>
					<Nav className="justify-content-end">

					</Nav>
				</Container>
			</Navbar>

			<Table bordered responsive className="table mt-10 table-bordered" style={tdStyle}>
				<thead className="text-center">
					<tr>
						<th width="15%">Date<hr style={thStyle} />Indicator</th>
						{date.map((item) => {
							return <th colSpan={2}>{moment(item.idate).format("DD-MM-YYYY")}</th>
						})}
					</tr>
				</thead>
				<tbody className="text-center">
					<tr>
						<td style={{ backgroundColor: 'red', fontSize: '15px' }}><b>Report Generation Format</b></td>
						{date.map((item) => {
							return <React.Fragment>
								<td style={{ backgroundColor: '#D5D5D5' }}><b>Score</b></td>
								<td style={{ backgroundColor: '#D5D5D5' }}><b>%</b></td>
							</React.Fragment>
						})}
					</tr>
					{data.map((item) => {
						return <tr>
							<td>{item.indicators}</td>
							{getRowData()}
						</tr>
					})}
				</tbody>
			</Table>
		</div >
	);
};
export default IndicatorReport;