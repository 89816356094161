import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import { Link } from 'react-router-dom';
import EditType from './editType';
import axios from 'axios';
import { triggerFocus } from 'antd/lib/input/Input';
import { baseUrl } from '../../utils';
import { Switch } from 'antd';

const EventType = () => {

	const context = useContext(PsContext);
	const [AddUser, setAddUser] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [ViewData, setViewData] = useState(false);
	const [user, setUser] = useState([]);
	const [view, setView] = useState([]);
	const [data, setData] = useState([]);
	const [stData, stChangeData] = useState([]);
	const [searchData, setSearchData] = useState([]);
	const [loading, setIsLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);

	useEffect(() => {
		listdata();
	}, []);

	const showModal = (item) => {
		setUser(item);
		setEditModel(true);
	}

	const viewModel = (item, id) => {
		setView(item, id);
		setViewData(true);
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			setIsLoading(true);
			API.post('v1/event/addtype', $("#addform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Event Type Added Successfully", "success");
					document.getElementById("addform").reset();
					setIsLoading(false);
					const vrl = baseUrl + '/v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Event Type Added Successfully');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', context.adminUser.id);
					formData.append('addip', 'localhost');

					var config = {
						method: 'post',
						url: vrl,
						data: formData
					};

					axios(config)
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
					listdata();
				}
				else {
					toast.error("Something Went Wrong", "danger");
					document.getElementById("addform").reset();
				}
			});
		}
		catch (error) {

		}
	}

	console.log(data);

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/event/listtype').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setData(d);
					setSearchData(d);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const handleDeleteClick = (item, id, sta) => {
		try {
			setIsLoading(true);
			API.get('v1/event/deletetype?id=' + id + '&status=' + sta).then(res => {
				if (res['data'].status == '1') {
					//var d = res['data'].data;
					var d = data;

					var index = d.findIndex(function (obj) {
						return (obj.id == id);
					});
					if (index > -1)
						d.splice(index, 1);
					setData(d);
					setIsLoading(false);
					listdata();
				}
				else {
					toast.error("Something Went Wrong..!", "danger");
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	}

	const searchEvent = (e) => {
		var d = data;
		var f = d.filter(function (item) {
			const name = item.name.toUpperCase();
			const s = e.target.value.toUpperCase();
			return (name.indexOf(s) > -1);
		});
		setSearchData(f);
	}

	const handleStatusChange = (item, id, e) => {

		let status = e ? '1' : '0';

		let msg = e ? 'Do you want to activate ?' : 'Do  you want to deactivate?';
		if (!window.confirm(msg)) {
			return;
		}

		handleDeleteClick(item, id, status)

	}

	return (
		<>
			<div className="container" >
				<Navbar bg="white" expand="lg">
					<Container>
						<Navbar.Brand >Event Planned - ({searchData.length})</Navbar.Brand>
						<Nav className="justify-content-end">

						</Nav>
					</Container>
				</Navbar>
				<Row className='m-2'>
					<Col md={3}>
						<MDBInput
							label="Search Event Name"
							type="text"
							onChange={searchEvent}
						/>
					</Col>
				</Row>

				<Modal show={editModel} onHide={() => { setEditModel(false) }}>
					<Modal.Header closeButton>
						<Modal.Title>Edit</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<EditType edit={user} afterFinish={() => { setEditModel(false) }} afterList={() => { listdata() }} />
					</Modal.Body>
				</Modal>
				<Card>
					<Card.Body>
						<Row>
							<Col md={8}>
								<Table bordered className="mt-10">
									<thead className="text-center">
										<tr>
											<th width="5%">S.No</th>
											<th width="10%"><b>Name</b></th>
											<th width="10%"><b>No Of Events</b></th>
											<th width="20%"><b>Participants Per Event</b></th>
											<th width="10%"><b>Manage</b></th>
										</tr>
									</thead>
									<tbody className="text-center">
										{(searchData.map((item, id) => {
											return <tr>
												<td>{id + 1}</td>
												<td align="left">{item.name}</td>
												<td>{item.no_of_event}</td>
												<td>{item.participants_per_event}</td>
												<td><Button variant="success" onClick={() => { showModal(item) }} size="sm"><i className="fas fa-edit"></i> </Button>&emsp;
													<Switch checked={item.status == '1'} onChange={e => { handleStatusChange(item, item.id, e) }} />
												</td>
											</tr>
										}))}
									</tbody>
								</Table>
							</Col>
							<Col md={4}>
								<Card className="card bg-white">
									<Card.Body>
										<h4>Add Event Name</h4><hr />
										<Form action="" method="post" id="addform" validated={validated} onSubmit={handleSubmit} >
											<input type="hidden" name="userid" value={context.adminUser.id} />
											<Row>
												<Col md={12}>
													<MDBInput
														label="Event Name"
														type="text"
														name="name"
														required
														size="md"
													/>
												</Col>
											</Row>
											<Row className='mt-2'>
												<Col md={12}>
													<MDBInput
														label="No Of Events"
														type="text"
														name="no_of_event"
														required
														size="md"
													/>
												</Col>
											</Row>
											<Row className='mt-2'>
												<Col md={12}>
													<MDBInput
														label="Participants Per Event"
														type="text"
														name="participants_per_event"
														required
														size="md"
													/>
												</Col>
											</Row>
											<Row className="mt-4" >
												<Col md={12} >
													<Button
														type="submit"
														size="md"
														variant="primary"
														className="btn-block"
														disabled={btnLoading}
													>
														{btnLoading ? 'Please wait..' : 'Add'}
													</Button>
												</Col>
											</Row>
										</Form>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</div>
		</>
	);
};
export default EventType;