import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table, Tabs, Tab } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { lettersOnly, integerIndMobile } from '../../utils';

import MonthlyReport from './attendanceReport';
import DailyReport from './dailyReport';
import StaffReport from './staffWiseReport';
import EditStaffAttendance from './editStaffAttendance';

const tdStyle = { border: '1px solid grey', borderCollapse: 'collapse', padding: '3px' }

const ReportPage = () => {

	const context = useContext(PsContext);
	const [loading, setIsLoading] = useState(false);
	const [staffData, setStaffData] = useState([]);
	const [data, setData] = useState([]);
	const [month, setMonth] = useState(moment().format("YYYY-MM"));

	const printDocument = () => {
		var content = document.getElementById("printdata");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}

	useEffect(() => {
		liststaff();
		listattendance();
	}, []);

	const listattendance = () => {
		try {
			setIsLoading(true);
			var form = new FormData();
			form.append('acyear', context.acyear.id);
			form.append('month', $("#month").val());
			API.post('v1/user/getattendance', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setData(d);
					setIsLoading(false);
				}
				else {
					setData([]);
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const liststaff = () => {
		try {
			setIsLoading(true);
			API.get('v1/user/list').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setStaffData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const daysInMonth = () => {

		var smonth = $("#month").val();
		var year = moment(smonth).format('YYYY');
		var month = moment(smonth).format('MM');
		var ndays = new Date(year, month, 0).getDate();
		return ndays + 1;

	}

	const getdays = () => {
		var days = [];

		for (var i = 1; i < daysInMonth(); i++) {

			days.push(<th width="3%" style={tdStyle}><b>{i}</b></th>);

		}

		return days;
	}

	const checksunday = (dat) => {
		var bstate = false;
		var smonth = $("#month").val();
		var year = moment(smonth).format('YYYY');
		var month = moment(smonth).format('MM');

		var newDate = new Date('' + year + '-' + month + '-' + dat);

		if (newDate.getDay() == 0) {   //if Sunday
			bstate = true;
		}
		else {  //if Saturday
			bstate = false;
		}
		return bstate;
	}

	const addzero = (day) => {
		var temp = '' + day;
		if (temp.length == 1) {

			temp = "0" + temp;
			return temp;
		}
		else {

			return temp;
		}

	}

	const getcurdate = (date) => {
		var bstate = false;
		var smonth = $("#month").val();
		var year = moment(smonth).format('YYYY');
		var month = moment(smonth).format('MM');
		var newDate = new Date('' + year + '-' + month + '-' + addzero(date)).getTime();
		var today = new Date().getTime();

		if (newDate <= today) {

			bstate = true;
		}
		else {

			bstate = false;
		}
		return bstate;
	}

	const checkabsent = (staffid, date) => {
		var bstate = false;
		var smonth = $("#month").val() + '-' + date;
		var index = data.findIndex(function (obj) {
			return (obj.staff_id == staffid && obj.att_date == smonth);
		});
		if (index === -1) {
			bstate = true;
		}
		return bstate;
	}

	const getabsent = (staffid) => {

		var ab = [];

		var smonth = $("#month").val();
		var year = moment(smonth).format('YYYY');
		var month = moment(smonth).format('MM');

		for (var i = 1; i < daysInMonth(); i++) {

			if (checksunday(i)) {

				ab.push(<td id="sunback" style={tdStyle}><span id="attsun" ><b style={{ color: 'orange', fontWeight: '700' }}>S</b></span></td>);

			}
			else {

				if (getcurdate(i)) {

					if (checkabsent(staffid, i)) {

						ab.push(<td style={tdStyle}><span id="abs"><b style={{ color: 'red', fontWeight: '700' }}>A</b></span></td>);
					}
					else {

						ab.push(<td style={tdStyle}><span id="attpre" ><b style={{ color: '#4dbd74', fontWeight: '700' }}>X</b></span></td>);
					}
				}
				else {
					ab.push(<td style={tdStyle}></td>);
				}
			}

		}

		return ab;

	}

	return (
		<>
			<div className="container">
				<Navbar bg="white" expand="md">
					<Container>
						<Navbar.Brand >Staff Attendance Report</Navbar.Brand>
					</Container>
				</Navbar><br />

				<Tabs defaultActiveKey="1" id="uncontrolled-tab-example" className="mb-3">
					<Tab eventKey="1" title="Date Wise Report">
						<DailyReport />
					</Tab>
					<Tab eventKey="2" title="Monthly Report">
						<MonthlyReport />
					</Tab>
					<Tab eventKey="3" title="Staff Wise Report">
						<StaffReport />
					</Tab>
					<Tab eventKey="4" title="Manage">
						<EditStaffAttendance />
					</Tab>
				</Tabs>

			</div>
		</>
	);
};
export default ReportPage;