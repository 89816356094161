import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import AddUser from './addStaff';
import AddTrack from './addTrack';
import EditUser from './editStaff';
import ViewUser from './viewStaff';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import Paginator from 'react-hooks-paginator';
import { triggerFocus } from 'antd/lib/input/Input';
import { baseUrl } from '../../utils';

const StaffAttendance = (props) => {

	const context = useContext(PsContext);
	const [AddUser, setAddUser] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [ViewData, setViewData] = useState(false);
	const [user, setUser] = useState([]);
	const [view, setView] = useState([]);
	const [data, changeData] = useState([]);
	const [loading, setIsLoading] = useState(false);
	const [searchData, setSearchData] = useState([]);
	const [offset, setOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [fromdate, setFromDate] = useState(moment().format("YYYY-MM-DD"));

	useEffect(() => {
		listdata();
	}, []);

	const showModel = (item) => {
		setUser(item);
		setEditModel(true);
	}

	const viewModel = (item) => {
		setView(item);
		setViewData(true);
	}

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/user/liststaffattendance').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setSearchData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	/* const onSearchChange = (event) => {
		event.preventDefault();
		try{
		setBtnLoading(true);
		API.post('v1/enquiry/search/enquiry',$("#src_Add").serialize()).then(res=>{
		if (res['data'].status == '1') {
		 var d = res['data'].data;
		 changeSearchData(d);
		 setIsLoading(false);
		 setBtnLoading(false);
		}
		else {
		 setAdminEnquiry(true);
		 setBtnLoading(false);
		}
		 setIsLoading(false);
		});
		}
		catch(error){

		}
		};
 */

	const onSearchChange = (e) => {
		var d = data;
		let ndata = d.filter(function (item) {
			const name = item.name.toUpperCase();
			const mobile = item.mobile;
			const designation = item.designation.toUpperCase();
			const s = e.target.value.toUpperCase();
			return (name.indexOf(s) > -1 || mobile.indexOf(s) > -1 || designation.indexOf(s) > -1);
		});
		setSearchData(ndata);
	}

	const handleDeleteClick = (item, id) => {
		try {
			if (window.confirm('Do you want to Remove?')) {
				setIsLoading(true);
				API.get('v1/user/delete?id=' + id).then(res => {
					if (res['data'].status == '1') {
						toast.success("User Deleted Successfully..!", "success");

						//var d = res['data'].data;
						var d = data;
						var index = d.findIndex(function (obj) {
							return (obj.id == id);
							const vrl = baseUrl + 'v1/log/addlog';
							let formData = new FormData();
							formData.append('createon', '2021/12/10');   //append the values with key, value pair
							formData.append('context', 'User Deleted Staff Member');
							formData.append('e_type', 'User_log');
							formData.append('action_by', 'admin');
							formData.append('addby', 1);
							formData.append('addip', 'localhost');

							var config = {
								method: 'post',
								url: vrl,
								data: formData
							};
							axios(config)
								.then(function (response) {
									console.log(JSON.stringify(response.data));
								})
								.catch(function (error) {
									console.log(error);
								});
						});
						if (index > -1)
							d.splice(index, 1);
						changeData(d);
						setIsLoading(false);
						listdata();
					}

					else {
						toast.error("Something Went Wrong..!", "danger");
						setIsLoading(false);
					}
				});
			}

		}
		catch (error) {
			setIsLoading(false);
		}
	}

	const onFromChange = (event) => {

		var fromdate = $("#fromdate").val();
		setFromDate(fromdate);
	};

	return (
		<div className="container" >
			<Navbar bg="white" expand="lg">
				<Container>
					<Navbar.Brand >Staff Attendance</Navbar.Brand>
					<Nav className="justify-content-end">
						<Link to={{
							pathname: '/addstaff'
						}}>Add New</Link>{' '}
						|
						{' '}
						<Link to={{
							pathname: '/addtrack'
						}}>Tracking</Link>
					</Nav>
				</Container>
			</Navbar>
			<Row style={{ margin: '10px' }}>
				<Col md={2}>
					<MDBInput
						type="date"
						id="fromdate"
						defaultValue={fromdate}
						onChange={onFromChange}
					/>
				</Col>
				<Col md={4}>
					<Button type="submit" size="sm" onClick={listdata}>Submit</Button>
				</Col>
			</Row>
			<Row className="text-center">
				<Col md={{ span: 2, offset: 10 }}>

					<Modal show={editModel} size="lg" onHide={() => { setEditModel(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title >Edit User</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<EditUser data={user} afterFinish={() => { setEditModel(false) }} afterList={() => listdata()} />
						</Modal.Body>
						<Modal.Footer>

						</Modal.Footer>
					</Modal>

					<Modal show={ViewData} onHide={() => { setViewData(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title >View User</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ViewUser data={view} />
						</Modal.Body>
					</Modal>
				</Col>
			</Row>
			<Table bordered responsive className="table table-bordered">
				<thead className="text-center">
					<tr>
						<th width="5%">S.No</th>
						<th width="20%"><b>Name Of the Staff</b></th>
						<th width="10%"><b>Date</b></th>
						<th width="10%"><b>Type</b></th>
						<th width="15%"><b>IN / OUT</b></th>
						<th width="20%"><b>Manage</b></th>
					</tr>
				</thead>
				<tbody className="text-center">
					{(searchData.map((item, id) => {
						return <tr>
							<td>{id + 1}</td>
							<td>{item.staffname}</td>
							<td>{moment(item.att_date).format("DD-MM-YYYY")}</td>
							<td>{item.type.toUpperCase()}</td>
							<td>{item.in_out}</td>
							<td><Button variant="success" onClick={() => { showModel(item) }} size="sm"><i class="fas fa-edit"></i>  </Button>{' '}
								<Button variant="primary" size="sm" onClick={() => viewModel(item, item.id)}><i class="fas fa-eye"></i>  </Button>{' '}
								<Button variant="warning" size="sm" onClick={() => handleDeleteClick(item, item.id)}><i class="fas fa-trash"></i>  </Button>{' '}
							</td>
						</tr>
					}))}
				</tbody>
			</Table>
		</div>
	);
};
export default StaffAttendance;