import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

import PsContext from '../../../context';
import { PSListItem } from '../../../comp';
import EditGeneral from './generalEdit';

const General = (props) =>{
	
	const context = useContext(PsContext);
	const [adminCompany, setAdminCompany] = useState(false);
	const [edit, setEdit] = useState(false);

	return(
		<>
			<Modal show={adminCompany} onHide={()=> setAdminCompany(false)}>
				<Modal.Header closeButton>
				  <Modal.Title>Edit General</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditGeneral afterfinish= {()=> setAdminCompany(false)}/>
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
			
			
			
			<Card className="no-shadow" >
				<Card.Body>
					<Row >
						<Col>
							<h5>General</h5>
						</Col>
						<Col className="d-flex justify-content-end" >
						
							<Button size="sm" variant="outline-primary" onClick={()=> setAdminCompany(true)}>
								<i className="fas fa-edit me-1" ></i> Edit 
							</Button>
						
						</Col>
					</Row>
					<hr />
					<PSListItem title="Name" subTitle={context.adminCompany.name} boldSubTitle />
					<PSListItem title="GST Number" subTitle={context.adminCompany.gst} boldSubTitle />
					<PSListItem title="Logo" subTitle={
						<img src="https://logos-download.com/wp-content/uploads/2016/09/React_logo_wordmark.png" style={{ width: 200 }} />
					} /> 
				</Card.Body>
			</Card>
		</>
	);
};

export default General;