import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col,  Modal , Form, Navbar, Container, Nav, Option, Dropdown, Button} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import {integerIndMobile, lettersOnly} from '../../utils';
import axios from 'axios';
import {momentDate} from '../../utils';
import { Select } from 'antd'
import {baseUrl} from '../../utils';

const AddEvent = (props) =>{

	const context = useContext(PsContext);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [data, changeData] = useState([]);
	const [types, typeData] = useState([]);
	const [eventDate, setEvent] = useState('');
	const [eventTime, setEventTime] = useState('');

	useEffect(() => {
        listeventtype(); 
        listdata(); 
		var date = new Date().getDate()+'-'+(new Date().getMonth()+1)+new Date().getFullYear();
		setEvent(date);
		setEventTime(date);
    } , [] );

	const listdata = () => {
		try {
            setIsLoading(true);
			API.get('v1/group/listgroup').then(res => {
			if (res['data'].status == '1') {
                var d = res['data'].data;
				changeData(d);
                setIsLoading(false);
				}
			else {
				setIsLoading(false);
				}
			});
		}
		catch (error) {
            setIsLoading(false);
		}
	};

	const listeventtype = () => {
		try {
            setIsLoading(true);
			API.get('v1/event/listtype').then(res => {
			if (res['data'].status == '1') {
                var d = res['data'].data;
				typeData(d);
                setIsLoading(false);
				}
			else {
				setIsLoading(false);
				}
			});
		}
		catch (error) {
            setIsLoading(false);
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		try{
			setBtnLoading(true);
			API.post('v1/event/addevent',$("#addform").serialize()).then(res=>{
				if (res['data'].status == '1') {
				  toast.success("Event Added Successfully..!", "success");
				  document.getElementById("addform").reset();
				  setBtnLoading(false);
				  const vrl=baseUrl+'v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');//append the values with key, value pair
					formData.append('context', 'Event Added Successfully');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
					  method: 'post',
					  url: vrl,
					  data : formData
					};
					axios(config)
					.then(function (response) {
					  console.log(JSON.stringify(response.data));
					})
					.catch(function (error) {
					  console.log(error);
					});
				}
				else {
				  toast.error("Something Went Wrong..!", "danger");
				  document.getElementById("addform").reset();
				   setBtnLoading(false);
				}
				setIsLoading(false);
			});
		}
		catch(error){

		}
	};

	return(
		<>
		<div class="container">
		<Navbar bg="white" expand="lg">
			<Container>
				<Navbar.Brand >Events Management - Add Event</Navbar.Brand>
					<Nav  className="justify-content-end">
						<Link to={{
								pathname: '/event'
							}}>
							View Event
						</Link>
					</Nav>
			</Container>
		</Navbar>
		<Row>
		<Col md={12}>
		<Card className="mt-3">
		<Card.Body>
		<Form action="" method="post" id="addform" validated={validated} onSubmit={handleSubmit}>
			<Row className="mt-2">
				<Col md={2}>
					
				</Col>
				<Col md={4}>
					<Col>
						<select className="form-control form-control-sm" name="group" required>
							<option value="">Group Name</option>
							{data.map((user, i) => {
							return <option value={user.id}>{user.grp_name}</option>
							})}
						</select> 
					</Col>
					<Col className="mt-3">
						<select className="form-control form-control-sm" name="type" required>
							<option value="">Event Type</option>
							{types.map((user, i) => {
							return <option value={user.id}>{user.name}</option>
							})}
						</select> 
					</Col>
					<Col  className="mt-3">
						<MDBInput
							label="Event Date"
							type="date"
							name="date"
							size="md"
							required
							min={moment().format("YYYY-MM-DD")}
							defaultValue={eventDate}
						/>
					</Col>
					<Col  className="mt-3">
						<MDBInput
							label="Organised By"
							type="text"
							name="organiser"
							size="md"
						/>
					</Col>
					
				</Col>
				<Col md={4}>
					<Col>
						<MDBInput
							label="Event Name"
							type="text"
							name="eventname"
							size="md"
							required
							onKeyPress={lettersOnly}
						/>
					</Col>
					<Col className="mt-3">
						<MDBInput
							label="Place"
							type="text"
							name="place"
							size="md"
							onKeyPress={lettersOnly}
						/>
					</Col>
					<Col  className="mt-3">
						<MDBInput
							label="Event Time"
							type="time"
							name="time"
							size="md"
							required
							defaultValue={eventTime}
						/>
					</Col>
					<Col className="mt-3">
						<MDBInput
							label="Organiser Mobile Number"
							type="text"
							name="mobile"
							size="md"
							onKeyPress={integerIndMobile}
						/>
					</Col>
				</Col>
				<Col md={2}></Col>
				<Col md={2}></Col>
				<Col md={8} className="mt-3">
					<MDBInput
						label="Requirements"
						type="text"
						name="requirements"
						size="md"
					/>
				</Col>
				<Col md={2}></Col>
				<Col md={2}></Col>
				<Col md={8} className="mt-3">
					<Button  style={{float: 'right'}} type="submit" Variant = "success" disabled={btnLoading}>
						{btnLoading ? 'Please wait..' : 'Add'}</Button>
				</Col>
				<Col md={2}></Col>
			</Row>
		</Form>
		</Card.Body>
		</Card>
		</Col>
		</Row>
		</div>
		</>
	);
};

export default AddEvent;