import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal,Table, Navbar ,Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import { Link }  from   'react-router-dom';
import EditAccountingYear from './editAccountingyear';
import axios from 'axios';
import moment from 'moment';
import { triggerFocus } from 'antd/lib/input/Input';
import ReactFileReader from 'react-file-reader';
import {baseUrl, momentDate} from '../../utils';

const AccountingYear = () =>{
	
	const context = useContext(PsContext);
    const [AddUser, setAddUser] = useState(false);
    const [editModel, setEditModel] = useState(false); 
    const [user, setUser] = useState([]);   
    const [data, setData] = useState([]);
    const [stData, stChangeData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [loading, setIsLoading ] = useState(false);
    const [validated, setValidated ] = useState(false);
    const [btnLoading, setBtnLoading ] = useState(false);
	const [file, setFile] = useState('');
	const [fileName, setFileName] = useState('');
	const [acDate, setDate] = useState('');
	
	useEffect(() => {
        listdata(); 
		setDate(moment().format("DD-MM-YYYY"));
    } , [] );
	
	const showModal = (item) =>{
		setUser(item);
		setEditModel(true);
	}
	
	const handleSubmit = (event) =>{
		event.preventDefault();
		try{
			setIsLoading(true);
			API.post('v1/user/addaccountingyear',$("#addform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Accounting Year Added Successfully","success");
					document.getElementById("addform").reset();
					const vrl=baseUrl+'v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Accounting Year Added Successfully');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
					  method: 'post',
					  url: vrl,
					  data : formData
					};
					axios(config)
					.then(function (response) {
					  console.log(JSON.stringify(response.data));
					})
					.catch(function (error) {
					  console.log(error);
					});	
					setIsLoading(false);
					setFile('');
					setFileName('');
					listdata();
				}
				else{
					toast.error("Something Went Wrong", "danger");
					document.getElementById("addform").reset();
				}
			});
		}
		catch(error){

		}
	} 

	const listdata = () => {
		try {
            setIsLoading(true);
			API.get('v1/user/listaccountingyear').then(res => {
			if (res['data'].status == '1') {
                var d = res['data'].data;
				setData(d);
				}
			else {
				setIsLoading(false);
				}
			});
		}
		catch (error) {
            setIsLoading(false);
		}
	};
	
	const onFromChange = (event) => {
	
		var fromdate = $("#fromdate").val();
		var toMinDate = moment(fromdate).format("YYYY-MM-DD");
		if(fromdate!=''){
			$("#todate").attr('min',toMinDate);
		}
	};

	return(
	<>
        <div className="container" >
		<Navbar bg="white" expand="lg">
				<Container>
					<Navbar.Brand >Accounting Year</Navbar.Brand>
				</Container>
				</Navbar>
			<Modal show={editModel} onHide={()=>{setEditModel(false)}}>
				<Modal.Header closeButton>
					<Modal.Title>Edit</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditAccountingYear edit = {user} afterFinish={()=>{setEditModel(false)}} afterList={()=>{listdata()}} />
				</Modal.Body>
			</Modal>
			<Card>
				<Card.Body>
				<Row>
				<Col md={8}>
					<Table  bordered  className="mt-10">
						<thead className="text-center">
							<tr>
								<th width="5%">S.No</th>
								<th width="20%"><b>Start Date</b></th>
								<th width="25%"><b>End Date</b></th>
								<th width="10%"><b>Accounting Year</b></th>
								<th width="15%"><b>Manage</b></th>
							</tr>
						</thead>
						<tbody className="text-center">
							{(data.map((item, id ) => {
								return <tr>
									  <td>{id+1}</td>
									  <td>{momentDate(item.start_date,'DD-MM-YYYY')}</td>
									  <td>{momentDate(item.end_date,'DD-MM-YYYY')}</td>
									  <td>{item.value}</td>
									  <td>
										<Button variant="success" onClick={() => {showModal(item)}} size="sm"><i className="fas fa-edit"></i> </Button>
									  </td>
								</tr>
							}))}
						</tbody>
					</Table>
				</Col>
				<Col md={4}>
					<Card className="card bg-white">
						<Card.Body>
							<h4>Add</h4><hr/>
								<Form action="" method="post" id="addform" validated={validated} onSubmit={handleSubmit} >
									<Row className="mt-4" >
										<Col md={12}>
											<MDBInput 
												label="Start Date"
												type="date"
												name="start_date"
												required
												id="fromdate"
												size="md"
												defaultValue={acDate}
												onChange={onFromChange}
											 />
										</Col>
									</Row>
									<Row className="mt-4" >
										<Col md={12}>
											<MDBInput 
												label="End Date"
												type="date"
												name="end_date"
												required
												size="md"
												id="todate"
												defaultValue={acDate}
											 />
										</Col>
									</Row>
									<Row className="mt-4" >
										<Col md={12} >
											<Button
												type="submit"
												size="md"
												variant="primary"
												className="btn-block"
												disabled={btnLoading}
											>
											{btnLoading ? 'Please wait..' : 'Add'}
											</Button>
										</Col>
									</Row>
								</Form>
						</Card.Body>
					</Card>
					</Col>
				</Row>
				</Card.Body>
			</Card>
        </div>
		</>
	);
};
export default AccountingYear;