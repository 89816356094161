import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Table,
  Navbar,
  Container,
  Nav,
  Form,
} from "react-bootstrap";
import toast from "react-hot-toast";
import PsContext from "../../context";
import API from "../../utils/api";
import { MDBInput } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { Switch, Spin } from "antd";
import moment from "moment";
import $ from "jquery";
import EditHouse from "./editHouse";
import EditStar from "./editStar";
import EditStar1 from "./editStar1";
import AddHouse from "./addHouse";
import { CardFixedTop, momentDate } from "../../utils";
import Pagination from "react-js-pagination";

const AllHBCOutcome = (props) => {
  const [data, changeData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searchHBCData, setSearchHBCData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [block, setBlock] = useState([]);
  const [visitUser, setVisitUser] = useState([]);
  const [village, setVillage] = useState([]);
  const [villageName, setVillageName] = useState([]);
  const [searchVillage, setSearchVillage] = useState([]);
  const [searchBlock, setSearchBlock] = useState([]);
  const [loader, setLoader] = useState(false);
  const [maxVisitCount, setMaxVisitCount] = useState(0);
  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState([]);
  const [listVisitReport, setListVisitReport] = useState([]);
  const [addModel, setAddModel] = useState(false);
  const [user, setUser] = useState([]);
  const [visitCount, setVisitCount] = useState([]);

  const [fromDate, setFromDate] = useState(
    momentDate(new Date(), "YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(momentDate(new Date(), "YYYY-MM-DD"));

  useEffect(() => {
    listVisitUser();
    listBlock();
    listVillage();
  }, []);

  const listdata = () => {};

  // const cellloader1 = (id, visit, ch) => {
  //   //console.log("id+visit"+id+visit);
  //   // const res= listVisitReport.filter((item)=>{
  //   //     if(item.indicator_id===id && item.visit_count===visit)
  //   //         return item.items_sum;
  //   //     else
  //   //         return "N/A";
  //   // });
  //   const res = listVisitReport.filter((item) => (item.indicator_id === id && parseInt(item.visit_count) === parseInt(visit)));
  //   //console.log(res.length>0?res[0].items_sum:0);

  //   if (ch == 1)
  //     return res.length > 0 ? res[0].items_sum : 0;
  //   else if (ch == 2)
  //     return res.length > 0 ? res[0].visit_count : 0;

  //   //    return "ff";
  // }

  const cellloader1 = (id, visit) => {
    const res = listVisitReport.find(
      (item) =>
        item.indicator_id === id &&
        parseInt(item.visit_count) === parseInt(visit)
    );
    return res
      ? {
          member_count: res.member_count,
          items_sum: res.items_sum,
          idate: res.idate,
        }
      : { member_count: 0, items_sum: 0, idate: 0 };
  };

  const dateChange = () => {
    var fromDate = $("#fromdate").val();
    var toDate = moment(fromDate).format("YYYY-MM-DD");
    if (fromDate != "") {
      $("#todate").attr("min", toDate);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    try {
      //setIsLoading(true);
      setVisitUser();
      API.post("v1/group/listVisitUserAll", $("#search").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            var d = res["data"].data;
            var uniqueEntriesMap = {};
            d.forEach((item) => {
              var key = item.user_id + "|" + item.staff_name;
              if (!uniqueEntriesMap[key]) {
                uniqueEntriesMap[key] = {
                  user_id: item.user_id,
                  staff_name: item.staff_name,
                };
              }
            });
            var uniqueEntries = Object.values(uniqueEntriesMap);
            var uniqueVisitCountEntriesMap = {};
            d.forEach((item) => {
              var key = item.visit_count;
              if (!uniqueVisitCountEntriesMap[key]) {
                uniqueVisitCountEntriesMap[key] = {
                  visit_count: item.visit_count,
                };
              }
            });
            var uniqueVisitCountEntries = Object.values(
              uniqueVisitCountEntriesMap
            );
            setUser(uniqueEntries);
            setVisitCount(uniqueVisitCountEntries);
            setVisitUser(d);
          } else {
            setIsLoading(false);
          }
        }
      );
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listBlock = () => {
    try {
      setIsLoading(true);
      API.get("v1/group/block").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setBlock(d);
        } else {
          setIsLoading(false);
        }
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listVillage = () => {
    try {
      setIsLoading(true);
      API.get("v1/group/village").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setVillage(d);
        } else {
          setIsLoading(false);
        }
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const filterVillage = (e) => {
    var d = village.filter(function (item, i) {
      return item.block_id == e.target.value;
    });
    setVillageName(d);
  };

  //console.log(block);
  //console.log(village);

  const getBlock = (e) => {
    let d = searchBlock;
    if (e.target.value == "") {
      return setSearchHBCData(d);
    }
    let nadata = d.filter(function (obj) {
      return obj.block_id == e.target.value;
    });
    setSearchHBCData(nadata);
  };

  const getVillage = (e) => {
    let d = searchVillage;
    if (e.target.value == "") {
      return setSearchHBCData(d);
    } else {
      let data = d.filter(function (obj) {
        return obj.village_id == e.target.value;
      });
      setSearchHBCData(data);
    }
  };

  const getValue = () => {
    var d = [];
    for (var i = 1; i <= 10; i++) {
      d.push(<option value={i}>visit - {i}</option>);
    }
    return d;
  };

  const showAddModel = () => {
    setAddModel(true);
  };

  const listVisitUser = () => {
    try {
      setIsLoading(true);
      setVisitUser();
      API.post("v1/group/listVisitUserAll", $("#search").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            var d = res["data"].data;
            console.log(d)
            var uniqueEntriesMap = {};
            d.forEach((item) => {
              var key = item.user_id + "|" + item.staff_name;
              if (!uniqueEntriesMap[key]) {
                uniqueEntriesMap[key] = {
                  user_id: item.user_id,
                  staff_name: item.staff_name,
                };
              }
            });
            var uniqueEntries = Object.values(uniqueEntriesMap);
            var uniqueVisitCountEntriesMap = {};
            d.forEach((item) => {
              var key = item.visit_count;
              if (!uniqueVisitCountEntriesMap[key]) {
                uniqueVisitCountEntriesMap[key] = {
                  visit_count: item.visit_count,
                };
              }
            });
            var uniqueVisitCountEntries = Object.values(
              uniqueVisitCountEntriesMap
            );
            console.log(uniqueEntries)
            console.log(uniqueVisitCountEntries)
            setUser(uniqueEntries);
            setVisitCount(uniqueVisitCountEntries);
            setVisitUser(d);
          } else {
            setIsLoading(false);
          }
        }
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleRating = (rate, itemId) => {
    try {
      var form = new FormData();
      form.append("id", itemId);
      form.append("rating", rate);
      API.post("v1/group/editratingscore2", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Score Updated", "success");
        }
      });
    } catch (error) {}
  };

  const handleRating3 = (idate, itemId) => {
    try {
      var form = new FormData();
      form.append("id", itemId);
      form.append("idate", idate);
      API.post("v1/group/editratingscore2", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Score Updated", "success");
        }
      });
    } catch (error) {}
  };

  const handleRating4 = (visit_count, itemId) => {
    try {
      var form = new FormData();
      form.append("id", itemId);
      form.append("visit_count", visit_count);
      API.post("v1/group/editratingscore2", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Score Updated", "success");
        }
      });
    } catch (error) {}
  };

  const handleRating2 = (rate, itemId) => {
    try {
      var form = new FormData();
      form.append("id", itemId);
      form.append("isyes", rate);
      API.post("v1/group/editratingscore2", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Score Updated", "success");
        }
      });
    } catch (error) {}
  };

  return (
    <div className="container">
      <Navbar bg="white" expand="lg">
        <Container>
          <Navbar.Brand>HBC Outcome </Navbar.Brand>
          <Nav className="justify-content-end"></Nav>
        </Container>
      </Navbar>
      <Form method="post" id="search" onSubmit={handleSearch}>
        <Row className="mt-2">
          <Col md={2}>
            <label className="control-label">From</label>
            <Form.Control
              type="date"
              name="fromdate"
              id="fromdate"
              size="sm"
              // defaultValue={momentDate(new Date(), "YYYY-MM-DD")}
              // onChange={dateChange}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              max={momentDate(new Date(), "YYYY-MM-DD")}
            />
          </Col>
          <Col md={2}>
            <label className="control-label">To</label>
            <Form.Control
              type="date"
              name="todate"
              size="sm"
              // defaultValue={momentDate(new Date(), "YYYY-MM-DD")}
              id="todate"
              max={momentDate(new Date(), "YYYY-MM-DD")}
              min={fromDate}
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <label className="control-label">Block Search</label>
            <select
              className="form-control form-control-sm"
              name="block"
              onChange={filterVillage}
            >
              <option value="">-Select-</option>
              {block.map((item) => {
                return <option value={item.id}>{item.block}</option>;
              })}
            </select>
          </Col>
          <Col md={2}>
            <label className="control-label">Village Search</label>
            <select className="form-control form-control-sm" name="village">
              <option value="">-Select-</option>
              {villageName.map((item) => {
                return <option value={item.id}>{item.villagename}</option>;
              })}
            </select>
          </Col>
          <Col md={2}>
            <label className="control-label">Staffs</label>
            <select className="form-control form-control-sm" name="user_id">
              <option value="">-Select-</option>
              {user.map((item) => {
                return <option value={item.user_id}>{item.staff_name}</option>;
              })}
            </select>
          </Col>
          <Col md={2}>
            <label className="control-label">Visit</label>
            <select className="form-control form-control-sm" name="visit">
              <option value="">-Select-</option>
              {visitCount.map((item) => {
                return (
                  <option value={item.visit_count}>{item.visit_count}</option>
                );
              })}
            </select>
          </Col>
        </Row>
        <Row>
          <Col md={11}></Col>
          <Col md={1} className="mt-3">
            <Button
              variant="warning"
              size="sm"
              style={{ float: "left", marginTop: "4px" }}
              type="submit"
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>

      <Row className="text-center">
        <Col md={{ span: 2, offset: 10 }}></Col>
      </Row>
      <Spin spinning={loading}>
        <Row className="mt-2">
          <Col md={12}>
            <div className="table-responsive" style={{ height: "450px" }}>
              <table className="table mt-10 table-bordered">
                <thead
                  className="text-center"
                  style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#E5E4E2",
                  }}
                >
                  <tr>
                    <th>S.No</th>
                    <th>Indicators</th>
                    <th>Date Created</th>
                    <th width="5%">Name</th>
                    <th>Mobile</th>
                    <th>No of Family Members</th>
                    <th>Staff Name</th>
                    <th>Visit</th>
                    <th>Village Name</th>
                    <th>Block</th>
                    <th width="10%">Yes/No</th>
                    <th>Items</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {visitUser && visitUser.length > 0 ? (
                    visitUser.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.indicators}</td>
                          {/* <td> {moment(item.idate).format("DD/MM/YY")}</td> */}
                          <td>
                            <Form.Control
                              type="date"
                              size="sm"
                              className="fw-bold"
                              defaultValue={item.idate}
                              onChange={(e) =>
                                handleRating3(e.target.value, item.indi_id)
                              }
                            />
                          </td>
                          <td>
                            {item.mr_and_mrs}
                            {item.fam_head_name}
                          </td>
                          <td>{item.mobile}</td>
                          <td>{item.no_of_fam}</td>
                          <td>{item.staff_name}</td>
                          <td>
                            <Form.Control
                              type="text"
                              size="sm"
                              className="fw-bold"
                              defaultValue={item.visit_count}
                              onChange={(e) =>
                                handleRating4(e.target.value, item.indi_id)
                              }
                            />
                          </td>
                          <td>{item.villagename}</td>
                          <td>{item.block}</td>
                          <td>
                            {" "}
                            <Form.Control
                              as="select"
                              size="sm"
                              className="fw-bold form-select form-select-sm"
                              onChange={(e) =>
                                handleRating2(e.target.value, item.indi_id)
                              }
                              defaultValue={item.isyes}
                            >
                              <option value="-1">Select</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </Form.Control>
                          </td>
                          <td>
                            {" "}
                            <Form.Control
                              type="text"
                              size="sm"
                              className="fw-bold"
                              defaultValue={item.rateing}
                              onChange={(e) =>
                                handleRating(e.target.value, item.indi_id)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5">No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
export default AllHBCOutcome;
