import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { lettersOnly, integerIndMobile, momentDate } from '../../utils';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const tdStyle = { border: '1px solid grey', borderCollapse: 'collapse', padding: '3px' }
const tdLeaveStyle = { border: '1px solid grey', borderCollapse: 'collapse', padding: '3px', backgroundColor: 'rgb(255 203 203)' }

const StaffWiseReport = () => {

	const context = useContext(PsContext);
	const [loading, setIsLoading] = useState(false);
	const [staffData, setStaffData] = useState([]);
	const [data, setData] = useState([]);
	const [user, setUser] = useState([]);
	const [editModel, setEditModel] = useState(false);
	const [month, setMonth] = useState(moment().format("YYYY-MM"));
	const [totalDayas, setTotalDays] = useState(0);

	const printDocument = () => {

		var content = document.getElementById("printdatastaff");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}

	useEffect(() => {
		liststaff();
	}, []);

	const listattendance = () => {
		try {
			setIsLoading(true);
			var form = new FormData();
			form.append('acyear', context.acyear.id);
			form.append('staff', $("#staff").val());
			form.append('month', month);
			let days = moment(month + '-01').daysInMonth();
			setTotalDays(days);
			API.post('v1/user/getattendancebystaff', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setData(d);

					setIsLoading(false);
				}
				else {
					setData([]);
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};



	const liststaff = () => {
		try {
			setIsLoading(true);
			API.get('v1/user/liststaffattendance').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setStaffData(d);
					console.log(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const daysInMonth = () => {

		var smonth = $("#month").val();
		var year = moment(smonth).format('YYYY');
		var month = moment(smonth).format('MM');
		var ndays = new Date(year, month, 0).getDate();

		return ndays + 1;

	}

	/*const getdays = (month, year) => {


		/*const monthIndex = month - 1;
		const names = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
		const date = new Date(year, monthIndex, 1);
		const result = [];
		while (date.getMonth() === monthIndex) {
			result.push(date.getDate() + '-' + names[date.getDay()]);
			date.setDate(date.getDate() + 1);
		}

		console.log(result);


	var days = [];

	for (var i = 1; i < daysInMonth(); i++) {

		days.push(<tr width="3%" style={tdStyle}><th><b>{i}</b></th><th></th></tr>);

	}

	return days;

}*/

	const getTr = () => {
		let tr = [];
		for (var i = 1; i <= totalDayas; i++) {
			let dt = `${month}-${i}`;
			let day = moment(dt).day();
			let item = data.filter(item => momentDate(item.att_date) == momentDate(dt));

			let str = '';
			let lat = '';
			let lon = '';
			let lat2 = '';
			let lon2 = '';

			if (item && item.length > 0) {
				var loc = item[0].location;
				str = loc && loc.length > 2 ? loc.split(",") : [];
				lat = str[0];
				lon = str[1];
				if (item[0].out_location != null) {
					lat2 = item[0].out_location.split(",")[0];
					lon2 = item[0].out_location.split(",")[1];
				}
			}

			tr.push(<tr>
				<td style={tdStyle}>{i}</td>
				<td style={tdStyle}>{momentDate(dt, 'DD/MM/YYYY')}</td>

				{item && item.length > 0 ?
					<>
						<td style={item[0].type == "Leave" ? tdLeaveStyle : tdStyle}>{item[0].type == "Leave" ? '' : (item[0].att_time)}</td>
						<td style={item[0].type == "Leave" ? tdLeaveStyle : tdStyle}>{item[0].out_time}</td>
						<td style={item[0].type == "Leave" ? tdLeaveStyle : tdStyle} align="center">{item[0].type == "Leave" && item[0].att_time != null && item[0] ? 'leave' : <>
							{lat && lon ? < a target="_blank" href={"https://maps.google.com/?q=" + lat + "," + lon} >In</a> : 'In (NA)'}
							&emsp;
							{item[0].out_time == null ? '' : lat2 && lon2 ? < a target="_blank" href={"https://maps.google.com/?q=" + lat2 + "," + lon2} >Out</a> : 'Out (NA)'}</>}
						</td>
					</>
					:
					day == 0 ? <td align="center" colSpan={3} style={{ backgroundColor: '#fffdc5' }}>
						{item.att_time == true || item.out_time == true ? item.att_time :
							'SUNDAY'
						}</td>
						:
						(moment(dt) <= moment(new Date())) ?
							<>
								<td style={tdLeaveStyle} colSpan={3}>Absent</td>
							</>
							: null
				}

			</tr >);
		}
		return tr;
	}



	return (
		<>

			<div className="container">
				<Row style={{ margin: '5px' }}>
					<Col md={2}>
						<label>Staff:</label>
						<select name="staff" id="staff" className="form-control">
							<option value="">Select Staff</option>
							{staffData.map((item, i) => {
								return <option value={item.id}>{item.name}</option>
							})}
						</select>
					</Col>
					<Col md={2}>
						<label>Date:</label>
						<MDBInput
							type="month"
							id="month"
							defaultValue={month}
							onChange={e => setMonth(e.target.value)}

						/>
					</Col>
					<Col md={4}>
						<Button type="submit" className="mt-3" onClick={listattendance}>Submit</Button>&nbsp;
						<Button type="button" className="mt-3" variant="warning" onClick={printDocument}>Print</Button>&nbsp;

						<ReactHTMLTableToExcel
							id="test-table-xls-button"
							className="download-table-xls-button btn mt-3 btn-info"
							table="table-to-xls-staff"
							filename="Staff Attendance"
							sheet="staffattendance"
							buttonText="Export as XLS" />&nbsp;
						{/*<Button type="button" className="mt-3" variant="success" onClick={showModel}>Edit</Button>*/}
					</Col>
					<Col md={12} className="mt-3" >
						<table className="table mt-10 table-bordered" style={tdStyle}>
							<thead className="text-center">
								<tr>
									<th style={tdStyle} colSpan={6}>
										<span style={{ fontSize: '18px' }}>Staff Attendance - {moment(month).format("MMM YYYY")}</span>
									</th>
								</tr>
								<tr>
									<th width="5%" style={tdStyle}><b>S.No</b></th>
									<th width="15%" style={tdStyle}><b>Date</b></th>
									<th width="15%" style={tdStyle}><b>IN</b></th>
									<th width="15%" style={tdStyle}><b>OUT</b></th>
									<th width="15%" style={tdStyle}><b>Location</b></th>
								</tr>
							</thead>
							<tbody className="text-center">
								{getTr()}
								{/*data.map((item, i) => {
									const str = item.location.split(",");
									const lat = str[0];
									const lon = str[1];
									return <tr style={{ backgroundColor: item.type == "Leave" ? '#F1EA87' : '' }}>
										<td style={tdStyle}>{i + 1}</td>
										<td style={tdStyle}>{moment(item.att_date).format("DD-MM-YYYY")}</td>
										<td style={tdStyle}>{item.type == "Leave" ? '' : (item.att_time)}</td>
										<td style={tdStyle}>{item.out_time}</td>
										<td style={tdStyle}>{item.type == "Leave" && item.att_time != null ? 'leave' :
											< a target="_blank" href={"https://maps.google.com/?q=" + lat + "," + lon} >Link</a>}
										</td>
									</tr>
								})*/}

							</tbody>
						</table>

					</Col>
				</Row>
				<div style={{ display: 'none' }}>
					<Row>
						<Col md={12} id="printdatastaff">
							<table className="table mt-10 table-bordered" style={tdStyle} id="table-to-xls-staff">
								<div align="center" style={{ color: '#08788C', fontSize: '30px' }}><b>WOMEN PRENEURS 4 PLASTIC</b></div>&nbsp;
								<thead className="text-center">
									<tr>
										<th style={tdStyle} colSpan={6}>
											<span style={{ fontSize: '18px' }}>Staff Attendance - {moment(month).format("MMM YYYY")}</span>
										</th>
									</tr>
									<tr>
										<th width="5%" style={tdStyle}><b>S.No</b></th>
										<th width="15%" style={tdStyle}><b>Date</b></th>
										<th width="15%" style={tdStyle}><b>IN</b></th>
										<th width="15%" style={tdStyle}><b>OUT</b></th>
										<th width="15%" style={tdStyle}><b>Location</b></th>
									</tr>
								</thead>
								<tbody className="text-center">
									{getTr()}
									{/*data.map((item, i) => {
									const str = item.location.split(",");
									const lat = str[0];
									const lon = str[1];
									return <tr style={{ backgroundColor: item.type == "Leave" ? '#F1EA87' : '' }}>
										<td style={tdStyle}>{i + 1}</td>
										<td style={tdStyle}>{moment(item.att_date).format("DD-MM-YYYY")}</td>
										<td style={tdStyle}>{item.type == "Leave" ? '' : (item.att_time)}</td>
										<td style={tdStyle}>{item.out_time}</td>
										<td style={tdStyle}>{item.type == "Leave" && item.att_time != null ? 'leave' :
											< a target="_blank" href={"https://maps.google.com/?q=" + lat + "," + lon} >Link</a>}
										</td>
									</tr>
								})*/}
								</tbody>
							</table>
							<iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default StaffWiseReport;