import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import moment from 'moment';
import { Select, Spin } from 'antd';

import AddGroupMeetingReport from './addGroupMeetingReport';
import EditGroupMeetingReport from './editGroupMeetingReport';
import ViewGroupMeetingReport from './viewGroupMeetingReport';
import { momentDate } from '../../utils';
import EditGroupMeeting from './editGroupMeeting';

const { Option } = Select;

const GroupMeetingReport = () => {

	const context = useContext(PsContext); 
	const [validated, setValidated] = useState(false);
	const [user, setUser] = useState([]);
	const [addModel, setAddModel] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [viewData, setViewData] = useState(false);
	const [data, changeData] = useState([]);
	const [imgData, setImgData] = useState([]);
	const [editImageData, setEditImageData] = useState([]);
	const [viewImageData, setViewImageData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [groups, setGroups] = useState([]);
	const [fromDate, setFromDate] = useState(momentDate(new Date(), 'YYYY-MM-DD'));
	const [toDate, setToDate] = useState(momentDate(new Date(), 'YYYY-MM-DD'));
	const [groupId, setGroupId] = useState(null);

	const [showEditModal, setShowEditModal] = useState(false);
	const [editModalData, setEditModalData] = useState([]);
	const [score, setScore] = useState([]);


	const loadGroups = () => {
		setIsLoading(true);
		API.get('v1/group/listgroup').then(res => {
			if (res['data'].errorcode == '200') {
				setGroups(res['data'].data);
			}
			setIsLoading(false);
		});
	};

	const userModal = () => {
		setAddModel(true);
	}

	const viewModel = (item) => {
		setUser(item);
		var dat = imgData.filter(function (obj) {
			return (obj.gmf_id == item.id)
		})
		setViewImageData(dat);
		setViewData(true);
	}

	/*const showModel = (item) => {
		console.log(item);
		setUser(item);

		/*var d = imgData.filter(function (obj) {
			return (obj.gmf_id == item.id);
		})
		setEditModel(true);
	}*/

	const listdata = () => {
		try {
			setIsLoading(true);
			changeData([]);
			var form = new FormData();
			form.append('group_id', groupId);
			form.append('date_from', fromDate);
			form.append('date_to', toDate);

			API.post('v1/group/listgroupmeetingreport', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					var d1 = res['data'].image;
					changeData(d);
					setImgData(d1);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const handleGroupChange = (e) => {
		setGroupId(e);
	};

	const handleDelete = (item) => {
		if (!window.confirm('Do you want to delete the report?')) {
			return;
		}

		setIsLoading(true);
		var form = new FormData();
		form.append('group_id', item.id);
		API.post('v1/group/delete_group_meeting', form).then(res => {
			if (res['data'].errorcode == '200') {
				alert(res['data'].message || 'Removed');
				listdata();
			}
			else {
				alert(res['data'].message || 'Error');
			}
		});
	};

	useEffect(() => {
		listdata();
		loadGroups();
		getTotalCount();
	}, []);

	const getTotalCount = () => {
		let total = 0;
		data.map(item => total = parseInt(total) + parseInt(item.no_of_participants));
		return parseInt(total);
	}

	const openEditModal = (item) => {
		setEditModel(item);
		let dat = imgData.filter(function (obj) {
			return (obj.gmf_id == item.id);
		});
		setEditImageData(dat);
		setShowEditModal(true);
	}

	let total = 0;
	return (

		<div className="container" >
			<Navbar bg="white" expand="lg">
				<Container>
					<Navbar.Brand >Group Meeting Report - ({data.length}) Total participants - ({getTotalCount()})</Navbar.Brand>
					<Nav className="justify-content-end">
						<Button type="button" variant="success" style={{ float: 'right' }} onClick={userModal}><i className='fa fa-plus' />Add</Button>
					</Nav>
				</Container>
			</Navbar>

			<Row className="text-center">
				<Col md={{ span: 2, offset: 10 }}>
					<Modal show={addModel} size="md" onHide={() => { setAddModel(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title>Add Group Meeting Report</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<AddGroupMeetingReport afterFinish={() => { setAddModel(false) }} afterList={() => listdata()} />
						</Modal.Body>
					</Modal>
					<Modal show={showEditModal} size="lg" onHide={() => { setShowEditModal(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title>Edit Group Meeting Report</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<EditGroupMeetingReport data={editModel} image={editImageData} afterFinish={() => { setShowEditModal(false) }} afterList={() => listdata()} />
						</Modal.Body>
					</Modal>
					<Modal show={viewData} size="lg" onHide={() => { setViewData(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title>View Group Meeting Report</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ViewGroupMeetingReport data={user} image={viewImageData} afterFinish={() => { setViewData(false) }} />
						</Modal.Body>
					</Modal>
				</Col>
			</Row>

			<Spin spinning={isLoading} >

				<Row className='px-2 py-3'>
					<Col md={3}>
						<label style={{ fontSize: '11px' }}>Group</label>
						<Select
							showSearch
							style={{ width: '100%' }}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							defaultValue={groupId}
							onChange={handleGroupChange}
						>
							<Option value={null}>All Groups</Option>
							{groups.map(item => <Option value={item.id}>{item.grp_name}</Option>)}
						</Select>
					</Col>
					<Col md={2}>
						<label style={{ fontSize: '11px' }}>From Date</label>
						<Form.Control
							type="date"
							size="sm"
							max={momentDate(new Date(), 'YYYY-MM-DD')}
							value={fromDate}
							onChange={e => setFromDate(e.target.value)}
						/>
					</Col>
					<Col md={2}>
						<label style={{ fontSize: '11px' }}>To Date</label>
						<Form.Control
							type="date"
							size="sm"
							max={momentDate(new Date(), 'YYYY-MM-DD')}
							min={fromDate}
							value={toDate}
							onChange={e => setToDate(e.target.value)}
						/>
					</Col>
					<Col md={2} className=''>
						<Button type="button" size="sm" variant="primary" style={{ marginTop: '22px' }} onClick={listdata}>
							Load Report
						</Button>
					</Col>
				</Row>

				<Row className='mt-2'>
					<Col md={12}>
						<Table bordered responsive className="table">
							<thead className="text-center">
								<tr>
									<th width="3%">S.No</th>
									<th width="10%"><b>Group Name</b></th>
									<th width="10%"><b>Date of Meeting</b></th>
									<th width="10%"><b>Start Time</b></th>
									<th width="10%"><b>End Time</b></th>
									<th width="10%"><b>No of Participants</b></th>
									<th width="10%"><b>Total Members</b></th>
									<th width="10%"><b>Manage</b></th>
								</tr>
							</thead>
							<tbody className="text-center">
								{(data.map((item, i) => {
									let d = data.length;
									return <tr>
										<td>{d - i}</td>
										<td>{item.gname}</td>
										<td>{moment(item.date_meeting).format("DD-MM-YYYY")}</td>
										<td>{item.start_time}</td>
										<td>{item.end_time}</td>
										<td>{item.no_of_participants}</td>
										<td>{item.total_participants == '' || item.total_participants == null ? '--' : item.total_participants}</td>
										<td>
											<Button variant="success" onClick={() => { openEditModal(item) }} size="sm"><i className="fas fa-edit"></i> </Button>
											<Button variant="warning" className='ms-3' onClick={() => { viewModel(item) }} size="sm"><i className="fas fa-eye"></i> </Button>
											<Button 
												type="button"
												size="sm"
												variant="danger"
												className='ms-3'
												onClick={() => handleDelete(item)}
											>
												<i className='fa fa-times'></i>
											</Button>
										</td>
									</tr>
								}))}
							</tbody>
						</Table>
					</Col>
				</Row>

			</Spin>


		</div>
	);
};
export default GroupMeetingReport;