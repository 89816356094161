import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Table,
  Navbar,
  Container,
  Nav,
  Form,
} from "react-bootstrap";
import toast from "react-hot-toast";
import PsContext from "../../context";
import API from "../../utils/api";
import { MDBInput } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { Switch, Spin } from "antd";
import moment from "moment";
import $ from "jquery";
import EditHouse from "./editHouse";
import EditStar from "./editStar";
import EditStar1 from "./editStar1";
import AddHouse from "./addHouse";
import { CardFixedTop, momentDate } from "../../utils";
import Pagination from "react-js-pagination";

const HouseManagement = (props) => {
  const context = useContext(PsContext);
  const [AddUser, setAddUser] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [ViewData, setViewData] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editStarModal, setEditStarModal] = useState(false);
  const [editStarModal1, setEditStarModal1] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const currentTotalRecords = useRef(0);
  const [itemsPerPage] = useState(50);

  const [user, setUser] = useState([]);
  const [star, setStar] = useState([]);
  const [view, setView] = useState([]);
  const [data, changeData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searchHBCData, setSearchHBCData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [block, setBlock] = useState([]);
  const [village, setVillage] = useState([]);
  const [villageName, setVillageName] = useState([]);
  const [searchVillage, setSearchVillage] = useState([]);
  const [searchBlock, setSearchBlock] = useState([]);
  const [questionData, setQuestionData] = useState([]);
  const [visitId, setVisitId] = useState([]);
  const [loader, setLoader] = useState(false);

  // const [currentPage, setCurrentPage] = useState(0);
  // const [todosPerPage, setTodosPerPage] = useState(10);
  // const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    setLoader(false);

    listBlock();
    listVillage();
    //listQuestionReport();
    setLoader(true);
  }, []);

  const showModel = (item) => {
    setUser(item);
    setEditModel(true);
  };

  useEffect(() => {
    listdata();
    // resetReport();
  }, []);

  // const resetReport = () => {
  //   setCurrentPage(1);
  //   try {
  //     setIsLoading(true);
  //     API.get("v1/group/listhousecount").then((res) => {
  //       if (res["data"].status == "1") {
  //         let d = res["data"].data;
  //         currentTotalRecords.current = parseInt(d.count);
  //         listdata(1, itemsPerPage);
  //       } else {
  //         setIsLoading(false);
  //       }
  //     });
  //   } catch (error) {
  //     setIsLoading(false);
  //   }
  // };

  // const onShowChange = (e) => {
  //   setCurrentPage(1);
  //   setActivePage(1);
  //   setTodosPerPage(e.target.value);
  // };

  // const handlePageChange = (pageNumber) => {
  //   setActivePage(pageNumber);
  //   setCurrentPage(Number(pageNumber));
  // };
  const handlePageChange = (page, pageSize) => {
    // Update the current page state
    setCurrentPage(page);
    listdata(page, pageSize);
  };

  const showStarModel = (item) => {
    setView(item);
    setEditStarModal(true);
    // setEditStarModal1(true);
  };

  const closeStarModel = () => {
    setEditStarModal(false);
    // setEditStarModal1(false);
    listdata();
  };

  const showStarModel1 = (item) => {
    setView(item);
    setEditStarModal1(true);
  };

  const closeStarModel1 = () => {
    setEditStarModal1(false);
    listdata();
  };

  const listdata = () => {
    try {
      setIsLoading(true);
      API.get("v1/group/listhouse").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
          setSearchData(d);
          setSearchHBCData(d);
          setSearchBlock(d);
          setSearchVillage(d);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  // const listQuestionReport = () => {
  // 	try {
  // 		setIsLoading(true);
  // 		API.get('v1/group/listquestionsdata').then(res => {
  // 			if (res['data'].status == '1') {
  // 				var d = res['data'].data;
  // 				setQuestionData(d);
  // 				setIsLoading(false);
  // 			}
  // 			else {
  // 				setIsLoading(false);
  // 			}
  // 		});
  // 	}
  // 	catch (error) {
  // 		setIsLoading(false);
  // 	}
  // };

  const onSearchChange = (e) => {
    var d = data;
    let ndata = d.filter(function (item) {
      const fam_head_name = item.fam_head_name.toUpperCase();
      const mobile = item.mobile;
      const street = item.street_name.toUpperCase();
      const s = e.target.value.toUpperCase();
      return (
        fam_head_name.indexOf(s) > -1 ||
        mobile.indexOf(s) > -1 ||
        street.indexOf(s) > -1
      );
    });
    setSearchData(ndata);
  };

  const handleDeleteClick = (house, id, sta) => {
    try {
      {
        setIsLoading(true);
        API.get("v1/group/deletehouse?id=" + id + "&status=" + sta).then(
          (res) => {
            if (res["data"].status == "1") {
              var d = data.filter((dt) => dt.id != id);
              changeData(d);
              changeData(d);
              setIsLoading(false);
              listdata();
            } else {
              toast.error("Something Went Wrong..!", "danger");
              setIsLoading(false);
            }
          }
        );
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const openNewModal = () => {
    setOpenModal(true);
  };

  const handleStatusChange = (item, id, e) => {
    let status = e ? "1" : "0";

    let msg = e ? "Do you want to activate ?" : "Do  you want to deactivate?";
    if (!window.confirm(msg)) {
      return;
    }

    handleDeleteClick(item, id, status);
  };

  const dateChange = () => {
    var fromDate = $("#fromdate").val();
    var toDate = moment(fromDate).format("YYYY-MM-DD");
    if (fromDate != "") {
      $("#todate").attr("min", toDate);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      API.post("v1/group/listhouse", $("#search").serialize()).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
          setSearchData(d);
          setSearchHBCData(d);
          setSearchBlock(d);
          setSearchVillage(d);
          setIsLoading(false);
        } else {
          toast.error(res["data"].message);
          setIsLoading(false);
        }
      });
    } catch (er) {}
  };

  const listBlock = () => {
    try {
      setIsLoading(true);
      API.get("v1/group/block").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setBlock(d);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listVillage = () => {
    try {
      setIsLoading(true);
      API.get("v1/group/village").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setVillage(d);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const filterVillage = (e) => {
    var d = village.filter(function (item, i) {
      return item.block_id == e.target.value;
    });
    setVillageName(d);
  };

  console.log(block);
  console.log(village);

  const getBlock = (e) => {
    let d = searchBlock;
    if (e.target.value == "") {
      return setSearchHBCData(d);
    }
    let nadata = d.filter(function (obj) {
      return obj.block_id == e.target.value;
    });
    setSearchHBCData(nadata);
  };

  const getVillage = (e) => {
    let d = searchVillage;
    if (e.target.value == "") {
      return setSearchHBCData(d);
    } else {
      let data = d.filter(function (obj) {
        return obj.village_id == e.target.value;
      });
      setSearchHBCData(data);
    }
  };

  const getValue = () => {
    var d = [];
    for (var i = 1; i <= 10; i++) {
      d.push(<option value={i}>visit - {i}</option>);
    }
    return d;
  };

  // const indexOfLastTodo = currentPage * todosPerPage;
  // const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  // const currentTodos = searchHBCData.slice(indexOfFirstTodo, indexOfLastTodo);

  // const shows = [
  //   { value: "10", name: "10" },
  //   { value: "20", name: "20" },
  //   { value: "50", name: "50" },
  //   { value: "100", name: "100" },
  // ];

  const visitOnChange = (id) => {
    setVisitId(id);
  };

  return (
    <div className="container">
      {/* <CardFixedTop title="Manage HBC">
				<ul className='list-inline mb-0'>
					<li className='list-inline-item'>

					</li>
					<li className='list-inline-item'>
						<Button variant="white shadow-0 border-start" onClick={() => { openNewModal() }} size="sm">
							<i className="fas fa-plus"></i> New
						</Button>
					</li>
					<li className='list-inline-item'>
						<Button variant="white shadow-0 border-start" onClick={() => { listdata() }} size="sm">
							Refresh
						</Button>
					</li>
				</ul>
			</CardFixedTop> */}
      <Navbar bg="white" expand="lg">
        <Container>
          <Navbar.Brand> Manage HBC - {searchData.length}</Navbar.Brand>
          <Nav className="justify-content-end">
            <Button
              type="button"
              variant="success"
              style={{ float: "right" }}
              onClick={() => {
                openNewModal();
              }}
            >
              <i className="fa fa-plus" /> New
            </Button>
          </Nav>
        </Container>
      </Navbar>
      <Form method="post" id="search" onSubmit={handleSearch}>
        <Row className="mt-2">
          <Col md={2}>
            <label className="control-label" style={{ marginLeft: "4px" }}>
              Search by name,mobile,street
            </label>
            <Form.Control
              type="text"
              placeholder="Search"
              size="sm"
              style={{ marginLeft: "4px" }}
              onChange={onSearchChange}
            />
          </Col>
          <Col md={2}>
            <label className="control-label">From</label>
            <Form.Control
              type="date"
              name="fromdate"
              id="fromdate"
              size="sm"
              onChange={dateChange}
              max={momentDate(new Date(), "YYYY-MM-DD")}
            />
          </Col>
          <Col md={2}>
            <label className="control-label">To</label>
            <Form.Control
              type="date"
              name="todate"
              size="sm"
              id="todate"
              max={momentDate(new Date(), "YYYY-MM-DD")}
            />
          </Col>
          <Col md={2}>
            <label className="control-label">Block Search</label>
            <select
              className="form-control form-control-sm"
              name="block"
              onChange={filterVillage}
            >
              <option value="">-Select-</option>
              {block.map((item) => {
                return <option value={item.id}>{item.block}</option>;
              })}
            </select>
          </Col>
          <Col md={2}>
            <label className="control-label">Village Search</label>
            <select className="form-control form-control-sm" name="village">
              <option value="">-Select-</option>
              {villageName.map((item) => {
                return <option value={item.id}>{item.villagename}</option>;
              })}
            </select>
          </Col>
          <Col md={1}>
            <label className="control-label">Visit</label>
            <select
              className="form-control form-control-sm"
              name="visit"
              onChange={(e) => visitOnChange(e.target.value)}
            >
              <option value="">-Select-</option>
              {getValue()}
            </select>
          </Col>
          <Col md={1} className="mt-3">
            <Button
              variant="warning"
              size="sm"
              style={{ float: "left", marginTop: "4px" }}
              type="submit"
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>

      <Row className="text-center">
        <Col md={{ span: 2, offset: 10 }}>
          <Modal
            show={openModal}
            size="lg"
            onHide={() => {
              setOpenModal(false);
            }}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title> Add Home</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddHouse
                afterFinish={() => {
                  setOpenModal(false);
                }}
                afterList={() => listdata()}
              />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          <Modal
            show={editModel}
            size="lg"
            onHide={() => {
              setEditModel(false);
            }}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title> Edit Home</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EditHouse
                data={user}
                afterFinish={() => {
                  setEditModel(false);
                }}
                afterList={() => listdata()}
              />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          <Modal
            show={editStarModal}
            size="lg"
            onHide={() => {
              setEditStarModal(false);
            }}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title> Edit Star</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EditStar data={star} user={view} visit={visitId} />
            </Modal.Body>
          </Modal>
          <Modal
            show={editStarModal1}
            size="lg"
            onHide={closeStarModel1}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title> Outcome </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EditStar1 data={star} user={view} />
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <Row className="mt-2">
          <Col md={12}>
            {" "}
            <div className="table-responsive" style={{ height: "450px" }}>
              <table className="table mt-10 table-bordered">
                <thead
                  className="text-center"
                  style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#E5E4E2",
                  }}
                >
                  <tr>
                    <th width="5%">S.No</th>
                    <th width="10%">
                      <b>Created On</b>
                    </th>
                    <th width="15%">
                      <b>Name</b>
                    </th>
                    <th width="10%">
                      <b>Phone</b>
                    </th>
                    <th width="5%">
                      <b>family Members</b>
                    </th>
                    <th width="10%">
                      <b>Street</b>
                    </th>
                    <th width="10%">
                      <b>Block Name</b>
                    </th>
                    <th width="10%">
                      <b>Village Name</b>
                    </th>
                    <th width="25%">
                      <b>Manage</b>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {searchData.map((item, id) => {
                    let d = searchData.length;
                    return (
                      <tr
                        style={{
                          backgroundColor: item.status == "0" ? "#F1EA87" : "",
                        }}
                      >
                        <td>{d - id}</td>
                        <td>{momentDate(item.idate, "DD-MM-YYYY")}</td>
                        <td align="left">
                          {item.mr_and_mrs}. {item.fam_head_name}
                        </td>
                        <td>{item.mobile}</td>
                        <td>{item.no_of_fam}</td>
                        <td align="left">{item.street_name}</td>
                        <td align="left">{item.bname}</td>
                        <td align="left">{item.vname}</td>
                        <td>
                          {item.status == "1" ? (
                            <Button
                              variant="success"
                              onClick={() => {
                                showModel(item);
                              }}
                              size="sm"
                            >
                              <i className="fas fa-edit"></i>{" "}
                            </Button>
                          ) : (
                            ""
                          )}{" "}
                          <Button
                            variant="warning"
                            onClick={() => {
                              showStarModel(item);
                            }}
                            size="sm"
                          >
                            <i className="fas fa-star"></i>
                          </Button>
                          {/*  <Button
                            variant="info"
                            onClick={() => {
                              visitId && visitId > 0
                                ? showStarModel1(item)
                                : alert("Please Select Visit.");
                            }}
                            size="sm"
                          >
                            <i className="fas fa-star"></i>
                          </Button> */}
                          <Switch
                            checked={item.status == "1"}
                            onChange={(e) => {
                              handleStatusChange(item, item.id, e);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Col>
          {/* <Col md={6}>
            show &nbsp;
            <select name="show" onChange={onShowChange} id="show">
              {shows.map((e, key) => {
                return (
                  <option key={key} value={e.value}>
                    {e.name}
                  </option>
                );
              })}
            </select>
            &nbsp; entries &emsp;Total {searchData.length} Records
          </Col> */}
          {/* <Col md={6}>
            <Pagination
              hideOnSinglePage={false}
              total={currentTotalRecords.current}
              showSizeChanger
              showQuickJumper
              defaultPageSize={itemsPerPage}
              current={currentPage}
              onChange={(page, pageSize, e) => {
                handlePageChange(page, pageSize);
              }}
            />
          </Col> */}
        </Row>
      </Spin>
    </div>
  );
};
export default HouseManagement;
