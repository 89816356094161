import moment from "moment";
import { Link } from "react-router-dom";

export const appName = (
  <Link
    to={{
      pathname: "/home",
    }}
  >
    <img src="provision-logo-1.png" width="100%" />
  </Link>
);

 export const baseUrl = 'https://emis.provision-ngl.org/admin/';
 export const imageUrl = 'https://emis.provision-ngl.org/admin/public/uploads/';

//export const imageUrl = "http://localhost/git/provision/api/public/uploads/";
//export const baseUrl = "http://localhost/git/provision/api/";

export const GOOGLE_API_KEY = "AIzaSyCHGZQG4ii8CFc4TaCPKowAnuaVcR1xD0s";

export const CardFixedTop = ({ title, children }) => {
  return (
    <div className="card card-fixed-top">
      <div className="card-body">
        <div className="d-flex  justify-content-between">
          <div>
            <b>{title}</b>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

/**
 * setLs
 * to store the user inputs into localStorage
 **/
export function setLs(key, value) {
  localStorage.setItem(key, value);
}

/**
 * getLs
 * to get the stored values from localStorage
 **/
export function getLs(key) {
  return localStorage.getItem(key) || false;
}

export function getAscSortOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

/**
 * getDescSortOrder
 * to sort an array by particular field
 * @param Property of an array
 * @return int
 */
export function getDescSortOrder(prop) {
  return function (a, b) {
    if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}

/**
 * integerKeyPress
 * to handle textbox key press event and check whether the input is integer or not
 *
 * @param EVENT
 * @return NULL
 */
export function integerKeyPress(e) {
  if (e.which != 8 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

/**
 * decimalKeyPress
 * to handle textbox key press event and check whether the input is decimal or not
 *
 * @param EVENT
 * @return NULL
 */
export function decimalKeyPress(e) {
  if (e.which != 8 && e.which != 46 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Decimal numbers only");
    e.preventDefault();
    return false;
  }
  if (e.which == 46 && e.target.value.indexOf(".") != -1) {
    // showAdvice(this, "Only one period allowed in decimal numbers");
    e.preventDefault();
    return false; // only one decimal allowed
  }
}

/**
 * lettersOnly
 * to handle textbox key press event and check whether the input is alphabet or not
 *
 * @param EVENT
 * @return NULL
 */
export function lettersOnly(e) {
  const inputValue = e.which;
  // allow letters and whitespaces only.
  if (
    e.which != 8 &&
    !(inputValue >= 65 && inputValue <= 122) &&
    inputValue != 32 &&
    inputValue != 0
  ) {
    e.preventDefault();
    return false;
  }
}

/**
 * momentDate
 * to convert a date format using moment
 * @param1 $date
 * @param2 $format
 * @return date
 */
export function momentDate(value, toFormat = "DD/MMM/YYYY") {
  try {
    if (
      value == "0000-00-00" ||
      value == "" ||
      value == " " ||
      value == null ||
      value == NaN
    ) {
      return "";
    }
    // var d = new Date(value).getTime();
    return moment(value).format(toFormat);
  } catch (error) {
    console.log(error);
    return "";
  }
}

/**
 * integerIndMobile
 * to handle textbox key press event and check whether the input is integer and less than 10 characters or not
 *
 * @param EVENT
 * @return NULL
 */
export function integerIndMobile(e) {
  const len = e.target.value.length;
  if (len >= 10) {
    e.preventDefault();
    return false;
  }
  if (e.which !== 8 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

/**
 * integerIndGst
 * to handle textbox key press event and check whether the input is integer and less than 15 characters or not
 *
 * @param EVENT
 * @return NULL
 */
export function integerIndGst(e) {
  const len = e.target.value.length;
  if (len >= 15) {
    e.preventDefault();
    return false;
  }
  if (e.which !== 8 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

export function integerAadhar(e) {
  const len = e.target.value.length;
  if (len >= 12) {
    e.preventDefault();
    return false;
  }

  if (e.which !== 10 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

/** Group by data using multiple column **/
export function groupByMultiple(array, f) {
  var groups = {};
  array.forEach(function (o) {
    var group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
}

export function calculateAge(birthday) {
  // birthday is a date
  var birthday = new Date(birthday);
  var ageDifMs = new Date().getTime() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
