import React, { useState, useContext, useEffect } from "react";
import { Card, Row, Col, Modal, Form, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { MDBInput } from "mdb-react-ui-kit";
import API from "../../utils/api";
import $ from "jquery";
import PsContext from "../../context";
import { PSListItem } from "../../comp";
import axios from "axios";
import Select from "react-select";
import { integerIndMobile, lettersOnly, baseUrl } from "../../utils";

const AddUser = (props) => {
  const context = useContext(PsContext);
  const [submit, sethandleSubmit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [data, changeData] = useState([]);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    listdata();
    listUserdata();
  }, []);

  const listdata = () => {
    try {
      setIsLoading(true);
      API.get("v1/group/listgroup").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listUserdata = () => {
    try {
      setIsLoading(true);
      API.get("v1/user/list").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setUserData(d);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (!form.checkValidity() === true) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

	const groupField = form.querySelector("input[name='group_id']");
    const staffField = form.querySelector("input[name='staff_id']");

    const isGroupSelected = groupField && groupField.value.trim() !== "";
    const isStaffSelected = staffField && staffField.value.trim() !== "";

    if (!isGroupSelected && !isStaffSelected) {
        toast.error("Please select either a Group or Staff!", "danger");
        return;
    }

    try {
      setBtnLoading(true);
      API.post("v1/user/createadminuser", $("#editform").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            toast.success("User Added Successfully..!", "success");
            document.getElementById("editform").reset();
            setBtnLoading(false);
          } else {
            toast.error("Something Went Wrong..!", "danger");
            document.getElementById("editform").reset();
          }
          setIsLoading(false);
        }
      );
    } catch (error) {
      toast.error("Something Went Wrong..!", "danger");
    }
  };

  const handleChecked = (e) => {
    setSelectedGroup(e);
    console.log(e);
  };
  const handleStaffChecked = (e) => {
    setSelectedStaff(e);
    console.log(e);
  };
  const typeValue = selectedGroup?.type || selectedStaff?.type || "";
  const Name = selectedGroup?.name || selectedStaff?.name || "";
  return (
    <>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title>Add User Management</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form
                action=""
                method="post"
                id="editform"
                onSubmit={handleSubmit}
              >
                <input
                  type="hidden"
                  name="group_id"
                  value={selectedGroup ? selectedGroup.group_id : ""}
                />
              	<input
					type="hidden"
					name="type"
					value={typeValue}
				/>
				<input
					type="hidden"
					name="name"
					value={Name}
				/>
                <input
                  type="hidden"
                  name="staff_id"
                  value={selectedStaff ? selectedStaff.staff_id : ""}
                />
                <Row>
                  <Col md={12}>
                    <Select
                      className="mt-2"
                      isClearable
                      options={data.map((item, i) => {
                        return {
                          group_id: item.id,
                          name: item.grp_name,
                          label: item.grp_name,
                          type: "group incharge",
                        };
                      })}
                      placeholder="Select Group"
                      onChange={handleChecked}
                      name=""
                      size="sm"
                    />
                    <Select
                      className="mt-2"
                      isClearable
                      options={userData.map((item, i) => {
                        return {
                          staff_id: item.id,
                          name: item.name,
                          label: item.name,
                          type: "staff",
                        };
                      })}
                      placeholder="Select Staff"
                      onChange={handleStaffChecked}
                      name=""
                      size="sm"
                    />
                    {/* <MDBInput
											label="Name"
											type="text"
											name="name"
											className='mt-2'
											size="md"
											onKeyPress={lettersOnly}
										/> */}
                    <MDBInput
                      label="Mobile"
                      type="text"
                      name="username"
                      className="mt-2"
                      size="md"
                      onKeyPress={integerIndMobile}
                    />
                    <MDBInput
                      label="Password"
                      type="text"
                      name="password"
                      className="mt-2"
                      size="md"
                    />
                  </Col>
                </Row>
                <Button
                  variant="success"
                  className="mt-3"
                  style={{ float: "right" }}
                  size="sm"
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddUser;
