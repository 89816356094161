import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import { Link } from 'react-router-dom';
import AddGroup from './addGroup';
import EditGroup from './editGroup';
import ViewGroup from './viewGroup';
import axios from 'axios';
import { triggerFocus } from 'antd/lib/input/Input';
import { baseUrl } from '../../utils';

const GroupSettings = (props) => {

	const context = useContext(PsContext);
	const [validated, setValidated] = useState(false);
	const [loading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [sfData, changeData] = useState([]);
	const [grpData, groupData] = useState([]);
	const [data, staffData] = useState([]);
	const [caldata, printData] = useState([]);

	useEffect(() => {
		listdata();
		liststaff();
		listgroupsettings();
	}, []);

	const liststaff = () => {
		try {
			setIsLoading(true);
			API.get('/v1/user/list').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					//console.log(d);
					staffData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('/v1/group/listgroup').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);

					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};


	const listgroupsettings = () => {
		try {
			setIsLoading(true);
			API.get('/v1/group/listgroupsetting').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					groupData(d);

					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const handleSubmit = (event) => {

		var value = event.target.value;
		var str = value.split("_");
		var isChecked = 0;
		if (event.target.checked) {
			isChecked = 1;
		}
		try {
			setBtnLoading(true);
			let formData = new FormData();
			formData.append('isChecked', isChecked);
			formData.append('staff_id', str[0]);
			formData.append('group_id', str[1]);

			API.post('v1/group/groupsetting', formData).then(res => {
				if (res['data'].status == '1') {
					toast.success("Settings Updated Successfully..!", "success");
					setBtnLoading(false);
					const vrl = baseUrl + '/v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Group Settings Updated');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
						method: 'post',
						url: vrl,
						data: formData
					};

					axios(config)
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
					listgroupsettings();
				}
				else {
					toast.error("Something Went Wrong..!", "danger");
					setBtnLoading(false);
				}
				setIsLoading(false);
			});
		}
		catch (error) {

		}
	};

	const getChecked = (staff, grp) => {
		var bstate = false;
		var d = grpData;
		var index = d.findIndex(function (obj) {
			return (obj.staff_id == staff && obj.grp_id == grp);
		});
		if (index > -1) {
			bstate = true;
		}
		return bstate;
	}



	return (
		<>
			<div className="container">
				<Navbar bg="white" expand="lg">
					<Container>
						<Navbar.Brand > Assign Group to Staff</Navbar.Brand>
						<Nav className="justify-content-end">

						</Nav>
					</Container>
				</Navbar>

				<Row>
					<Col md={12}>
						<Table responsive bordered className="table">
							<thead className="text-center">
								<tr>
									<th width="5%">S.No</th>
									<th width="20%"><b>Name Of the Group</b></th>
									<th width="5%"><b>Block</b></th>
									{(data.map((staff) => {
										return <th width="10%"><b>{staff.name}</b></th>
									}))}

								</tr>
							</thead>
							<tbody className="text-center">
								{(sfData.map((item, id) => {
									return <tr>
										<td>{id + 1}</td>
										<td align="left">{item.grp_name}</td>
										<td align="left">{item.block}</td>
										{(data.map((staff) => {

											return <td><b>
												<input type="checkbox" id={"staff_" + staff.id} value={staff.id + "_" + item.id} data-staff={staff.id} data-grp={item.id} onClick={handleSubmit} checked={getChecked(staff.id, item.id)} />
											</b></td>
										}))}
									</tr>
								}))}
							</tbody>
						</Table>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default GroupSettings;