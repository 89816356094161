import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory , Link} from 'react-router-dom';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Card, Form, Button, Table, Modal } from 'react-bootstrap';
import { MDBInput } from 'mdb-react-ui-kit';
import PsContext from '../../context';
import API from '../../utils/api';
import {baseUrl} from '../../utils';
import axios from "axios";
import moment from "moment";
import ViewMeeting from '../groupManagement/viewMeeting';

const ReportPage = (props) =>{
	
	const context = useContext(PsContext);
	const history = useHistory();
	const [viewModal, setViewModal] = useState(false);
	const [fieldValue, setFieldValue] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const [editData, setEditData] = useState([]);
	const [editImageData, setEditImageData] = useState([]);
	const [imageData, setImageData] = useState([]);
	
	useEffect(() => {
        listdata(); 
    } , [] );

	const listdata = () => {
		try {
            setIsLoading(true);
			var form = new FormData();
			form.append('userid',props.match.params.userid);
			form.append('month',props.match.params.month);
			API.post('v1/user/listeventreportformob',form).then(res => {
			if (res['data'].status == '1') {
                var d = res['data'].data;
                setData(d);
                setImageData(d);
                setIsLoading(false);
			}
			else {
				setIsLoading(false);
				}
			});
		}
		catch (error) {
            setIsLoading(false);
		}
	};
	
	const viewReport = (item) => {
		setEditData(item);
		
		setEditImageData(item.images);
		setViewModal(true);
	}

	return(
		<>
			<div className="container" >
				{data.length > 0 ?
				<Row className="mt-2" >
					<Col md={12}>
						<center>{data.length} Events</center>
					</Col>
					<Col md={12}>
					{(data.map((item, id ) => {
						return <Card>
							<Card.Body>
								<b>{item.grp_name}</b>
								<span style={{float : 'right'}}>{moment(item.meeting_date).format('DD-MM-YYYY')}</span>
								<br />
								{item.title}
								<span style={{float : 'right'}}>
									<Button type="submit" size="sm" onClick={(e) => {viewReport(item)}}>View</Button>
								</span>
							</Card.Body>
						</Card>
					}))} 
					</Col>
				</Row> : 
				<center>No Data Found...</center>
				}
				<Modal show={viewModal} size="lg" onHide={()=> {setViewModal(false) }} backdrop="static"> 
					<Modal.Header closeButton >
						<Modal.Title>View Report</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ViewMeeting edit={editData} images={editImageData} afterFinish={() => {setViewModal(false)}} />
					</Modal.Body>
				</Modal>
			</div>
		</>
	);
};
export default withRouter(ReportPage);