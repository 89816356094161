import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { decimalKeyPress, integerIndMobile, baseUrl } from '../../utils';
import axios from 'axios';
import moment from 'moment';

const PlasticSales = () => {

	const context = useContext(PsContext);
	const [data, changeData] = useState([]);
	const [vendorData, setVendorData] = useState([]);
	const [groupData, setGroupData] = useState([]);
	const [salesData, setSalesData] = useState([]);
	const [materialType, setMaterialType] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);

	useEffect(() => {

		listgroup();
		listvendor();
	}, []);

	const listgroup = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listgroup').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setGroupData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {

		}
	};

	const listvendor = () => {
		try {
			setIsLoading(true);
			API.get('v1/vendor/listvendor').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setVendorData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {

		}
	};

	const listdata = (groupid) => {
		try {
			setIsLoading(true);
			var form = new FormData();
			form.append('acyear', context.acyear.id);
			form.append('groupid', groupid);
			form.append('materialType', materialType);

			API.post('v1/plastic/listplasticbygroup', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {

		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			if (salesData.length > 0) {
				setBtnLoading(true);
				console.log($("#addform").serialize());
				API.post('v1/plastic/addsales', $("#addform").serialize()).then(res => {
					if (res['data'].status == '1') {
						toast.success("Sales Added Successfully..!", "success");
						document.getElementById("addform").reset();
						setBtnLoading(false);
						setSalesData([]);
						const vrl = baseUrl + '/v1/log/addlog';
						let formData = new FormData();
						formData.append('createon', '2021/12/10');   //append the values with key, value pair
						formData.append('context', 'Add Plastic Sales');
						formData.append('e_type', 'User_log');
						formData.append('action_by', 'admin');
						formData.append('addby', 1);
						formData.append('addip', 'localhost');

						var config = {
							method: 'post',
							url: vrl,
							data: formData
						};

						axios(config)
							.then(function (response) {
								console.log(JSON.stringify(response.data));
							})
							.catch(function (error) {
								console.log(error);
							});
					}
					else {
						toast.error("Something Went Wrong..!", "danger");
						document.getElementById("addform").reset();
					}
					setIsLoading(false);
				});
			} else {

				toast.error("Please input the Material kg ", "success");
			}
		}
		catch (error) {

		}
	};

	const changeAmt = (id, e, amt) => {
		var d = [];
		var index = salesData.findIndex(function (obj) {
			return (obj.id == id);
		});
		let skgid = "#sell_" + id;
		let kg = $(skgid).val();
		console.log(salesData);
		var total = parseFloat(kg * e);
		if (index > -1) {
			if (parseFloat(e) < parseFloat(amt)) {
				let sid = "#sellamt_" + id;
				$(sid).val(salesData[index].samt);
				alert('Enter minimum of sale amount');
			} else {
				d.push({ "id": id, "kg": kg, "amount": amt, "samt": e, "total": total });
				const arr = [...salesData.filter((item, i) => i != index), ...d];
				setSalesData(arr);
				let sid = "#sellamt_" + id;
				$(sid).val(e);
			}
		}
	}


	const changeKg = (id, e, stock, amt) => {
		var d = [];
		var index = salesData.findIndex(function (obj) {
			return (obj.id == id);
		});
		if (index > -1) {

			if (parseFloat(e) > parseFloat(stock)) {
				let sid = "#sell_" + id; 
				$(sid).val(salesData[index].kg);
				alert('Enter below stock');
			} else {

				d.push({ "id": id, "kg": e, "amount": amt, "samt": amt, "total": e * amt });
				const arr = [...salesData.filter((item, i) => i != index), ...d];
				setSalesData(arr);
				let sid = "#sell_" + id;
				$(sid).val(e);
			}
		} else {
			var s = [];
			if (parseFloat(e) > parseFloat(stock)) {
				let sid = "#sell_" + id;
				$(sid).val('');
				alert('Enter below stock');
			} else {
				s.push({ "id": id, "kg": e, "amount": amt, "samt": amt, "total": e * amt });
				const arr = [...salesData.filter((item, i) => i != index), ...s];
				setSalesData(arr);
			}
		}
	}

	const changeType = (e) => {
		setMaterialType(e);
	}

	let amt = 0;
	let total = 0;
	let kgtotal = 0;
	let stotal = 0;

	return (
		<>
			<div className="container">
				<Navbar bg="white" expand="md">
					<Container>
						<Navbar.Brand >Material Sales</Navbar.Brand>
					</Container>
				</Navbar>
				<Card>
					<Card.Body>
						<Form action="" method="post" id="addform" onSubmit={handleSubmit}>
							<input type="hidden" name="sales" value={JSON.stringify(salesData)} />
							<input type="hidden" name="acyear" value={context.acyear.id} />
							<Row className="mt-3">
								<Col md={1}><label>Material Type</label></Col>
								<Col md={2}>
									<select className="form-control" required onChange={(e) => { changeType(e.target.value) }}>
										<option value="">Select Type</option>
										<option value="plastic kg">Plastic Kg</option>
										<option value="others kg">Non Plastic Kg</option>
										<option value="others pcs">Non Plastic Piece</option>
									</select>
								</Col>
								<Col md={1}><label>Group</label></Col>
								<Col md={2}>
									<select name="group" className="form-control" required onChange={(e) => { listdata(e.target.value) }}>
										<option value="">Select Group</option>
										{groupData.map((user, i) => {
											return <option value={user.id}>{user.grp_name}</option>
										})}
									</select>
								</Col>

								<Col md={1}><label>Vendor</label></Col>
								<Col md={2}>
									<select name="vendor" className="form-control" required>
										<option value="">Select Vendor</option>
										{vendorData.map((user, i) => {
											return <option value={user.id}>{user.customername}</option>
										})}
									</select>
								</Col>
								<Col md={1}><label>Date</label></Col>
								<Col md={2}>
									<input type="date" name="salesdate" className="form-control" defaultValue={moment().format("YYYY-MM-DD")} required />
								</Col>
								<Col md={12} className="mt-3">
									<Table bordered className="mt-10">
										<thead className="text-center">
											<tr>
												<th width="25%" rowSpan="2"><b>Type</b></th>
												<th width="10%" rowSpan="2"><b>Stock (kg)</b></th>
												<th width="10%" colSpan="2"><b>Selling</b></th>
												<th width="15%" rowSpan="2"><b>Total</b></th>
											</tr>
											<tr>
												<th width="10%" ><b>KG</b></th>
												<th width="10%" ><b>Amount</b></th>
											</tr>
										</thead>
										<tbody className="text-center">
											{data.map((item, i) => {
												let id = "#sell_" + item.id;
												let sid = "#sellamt_" + item.id;
												let selling = $(id).val();
												let samt = $(sid).val();
												let stock = item.stock - item.sales_stock;
												amt = selling != '' ? parseFloat(selling * samt) : 0;
												let amount = selling != '' ? parseFloat(selling * item.current_price) : 0;
												total += parseFloat(amount);
												stotal += parseFloat(item.current_price);
												kgtotal += selling != '' ? parseFloat(selling) : 0;
												return <tr>
													<td>{item.plastic_name}</td>
													<td>{stock}</td>
													<td>
														<input type="text" name="selling_kg" onKeyPress={decimalKeyPress} id={"sell_" + item.id} onChange={(e) => { changeKg(item.id, e.target.value, stock, item.current_price) }} />
													</td>
													<td>{item.current_price}</td>
													{/*<td>
													<input type="text" name="selling_amt" onKeyPress={decimalKeyPress} id={"sellamt_"+item.id} onBlur={(e)=> {changeAmt(item.id,e.target.value,item.current_price)}} />
												</td>*/}
													<td>{amount > 0 ? parseFloat(amount).toFixed(2) : ''}</td>
												</tr>
											}
											)}
											<tr>
												<td align="right" colSpan="2"><b>Total</b></td>
												<td width="15%"><b>{kgtotal > 0 ? parseFloat(kgtotal).toFixed(2) : ''}</b></td>
												<td width="15%"><b>{stotal > 0 ? parseFloat(stotal).toFixed(2) : ''}</b></td>
												<td width="15%"><b>{total > 0 ? parseFloat(total).toFixed(2) : ''}</b></td>
											</tr>
											<tr>
												<td align="right" colSpan="3"><b>Amount Received</b></td>
												<td align="right">
													<input type="text" onKeyPress={decimalKeyPress} name="r_amount" required />
												</td>
											</tr>
										</tbody>
									</Table>
								</Col>
								<Col md={12}>
									<button type="submit" className="btn btn-success btn-block btn-md" disabled={btnLoading}>Save</button>
								</Col>
							</Row>
						</Form>
					</Card.Body>
				</Card>
			</div>
		</>
	);
};
export default PlasticSales;