import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col,  Modal , Form, Table} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import {integerIndMobile, lettersOnly} from '../../utils';
import {imageUrl} from '../../utils';

const ViewAnnounce = (props) =>{

	return(
		<div className="container">
			<Table >
				<tbody>
					<tr>
						<Row>
							<Col md={3}>
								<th>Title</th>
							</Col>
							<Col md={9}>
								<td>{props.view.title}</td>
							</Col>
						</Row>
					</tr>
					<tr>
						<Row>
							<Col md={3}>
								<th>Content</th>
							</Col>
							<Col md={9}>
								<td>{props.view.content}</td>
							</Col>
						</Row>
					</tr>
					<tr>
						<Row>
							<Col md={3}>
								<th>Expiry Date</th>
							</Col>
							<Col md={9}>
								<td>{props.view.expiry_on}</td>
							</Col>
						</Row>
					</tr>
					<tr>
						<Row>
							<Col md={12}>
								<th>Image</th>
							</Col>
							<Col md={12}>
								{props.view.upload_image == null || props.view.upload_image == '' ? '' :
								<td><img src={imageUrl+props.view.upload_image} style={{maxWidth : '300px'}} /></td>}
							</Col>
						</Row>
					</tr>
				</tbody>
			</Table>
		</div>
	);
};
export default ViewAnnounce;