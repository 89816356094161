import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import { integerIndMobile, lettersOnly } from '../../utils';
import { baseUrl, imageUrl } from '../../utils';
import ReactFileReader from 'react-file-reader';
import PLUSIMAGE from '../../assets/css/images/plus.png';
import { CKEditor } from 'ckeditor4-react';

const EditMeeting = (props) => {

	const context = useContext(PsContext);
	const [submit, sethandleSubmit] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [imgData, setImgData] = useState(props.images);
	const [removeData, setRemoveData] = useState([]);
	const [feedback, setFeedback] = useState(props.edit.feedback);
	const [photos, setPhotos] = useState([]);
	const [data, changeData] = useState([]);
	const [staffData, setStaffData] = useState([]);
	const [typeData, setTypeData] = useState([]);
	const [photos1, setPhotos1] = useState([]);
	const [villageData, setVillageData] = useState([]);
	const styles = {
		imgStyle: {
			border: '1px solid #d8d8d8', margin: '10px 10px 0px 0px', height: '120px'
		},
		plusImgStyle: {
			padding: '10px', border: '1px solid #d8d8d8', margin: '10px', backgroundColor: '#f1f1f1', cursor: 'pointer'
		},
		btnStyle: {
			position: 'absolute',
			bottom: '175px',
			left: '132px',
			borderRadius: '32px',
			cursor: 'pointer'
		}
	}

	useEffect(() => {
		setFeedback(getValue('feedback'));
		listdata();
		liststaffdata();
		listtypedata();
		listVillage();
	}, []);

	const listVillage = () => {
		try {
			API.get('v1/group/village').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setVillageData(d);
				}
				else {

				}
			});
		}
		catch (error) {

		}
	}

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listgroup').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const liststaffdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/user/list').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setStaffData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const listtypedata = () => {
		try {
			setIsLoading(true);
			API.get('v1/event/listtype').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setTypeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			setBtnLoading(true);
			API.post('v1/group/editgroupmeeting', $("#meetform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Group Meeting Updated Successfully..!", "success");
					document.getElementById("meetform").reset();
					const vrl = baseUrl + 'v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Group MeetingUpdated Successfully');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
						method: 'post',
						url: vrl,
						data: formData
					};
					axios(config)
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
					setBtnLoading(false);
					props.afterFinish();
					props.afterList();
				}
				else {
					toast.error("Something Went Wrong..!", "danger");
					document.getElementById("editform").reset();
				}
				setIsLoading(false);
			});
		}
		catch (error) {
			toast.error("Something Went Wrong..!", "danger");
		}
	};

	const getFiles = (files) => {
		var d = [];
		var d1 = [];
		if (photos.length > 0) {
			for (var i = 0; i < (files.base64).length; i++) {
				d.push(files.base64[i]);
			}
			const arr = [...photos, ...d];
			setPhotos(arr);
		} else {
			setPhotos(files.base64);
		}

		if (photos1.length > 0) {
			for (var i = 0; i < files.length; i++) {
				d1.push(files);
			}
			const arr1 = [...photos1, ...d1];
			setPhotos1(arr1);
		} else {
			setPhotos1(files.base64);
		}
	}

	const handleRemove = (index) => {
		var d = photos.filter((item, i) => i != index);
		setPhotos(d);
		var d1 = photos1.filter((item1, i1) => i1 != index);
		setPhotos1(d1);
	}

	const handleDeleteClick = (id) => {
		try {
			var d = imgData.filter((item, i) => item.id != id);
			setImgData(d);
			var r = [];
			r.push(id);
			const arr = [...removeData, ...r];
			setRemoveData(arr);
		}
		catch (error) {
			setIsLoading(false);
		}
	}

	const onFeedbackChange = (evt) => {
		setFeedback(evt.editor.getData());
	};

	const getValue = (field) => {
		return props.edit[field] || '';
	}

	return (
		<>
			<div className="container">
				<Form action="" method="post" id="meetform" validated={validated} onSubmit={handleSubmit} >
					<input type="hidden" name="group" value={getValue('id')} />
					<input type="hidden" name="id" value={getValue('id')} />
					<input type="hidden" name="files" value={JSON.stringify(photos1)} />
					<input type="hidden" name="removefiles" value={JSON.stringify(removeData)} />
					<input type="hidden" name="feedback" value={feedback} />
					<Row>
						<Col md={6} >
							<select className="form-control form-control-sm" name="staff" required>
								<option value="">Staff</option>
								{staffData.map((user, i) => {
									return <option value={user.id} selected={getValue('staff_id') == user.id ? true : false}>{user.name}</option>
								})}
							</select>
						</Col>
						<Col md={6} >
							<select className="form-control form-control-sm" name="group" required>
								<option value="">Group Name</option>
								{data.map((user, i) => {
									return <option value={user.id} selected={getValue('group_id') == user.id ? true : false}>{user.grp_name}</option>
								})}
							</select>
						</Col>
						{/*<Col md={6} className="mt-3">
							<select className="form-control form-control-sm" name="work_type" required>
								<option value="">Type of Work</option>
								<option value="Field" selected={getValue('').work_type == 'Field' ? true : false}>Field</option>
								<option value="Review" selected={getValue('').work_type == 'Review' ? true : false}>Review</option>
								<option value="Training" selected={getValue('').work_type == 'Training' ? true : false}>Training</option>
								<option value="Holiday" selected={getValue('').work_type == 'Holiday' ? true : false}>Holiday</option>
								<option value="Leave" selected={getValue('').work_type == 'Leave' ? true : false}>Leave</option>
							</select> 
						</Col>*/}
						<Col md={6} className="mt-3">
							<select className="form-control form-control-sm" name="event_type" required>
								<option value="">Event Type</option>
								{typeData.map((user, i) => {
									return <option value={user.id} selected={getValue('eventtype') == user.id ? true : false}>{user.name}</option>
								})}
							</select>
						</Col>
						{/* <Col md={6} className="mt-3">
							<MDBInput
								label="Event Name"
								type="text"
								name="title"
								size="sm"
								required
								defaultValue={getValue('title')}
							/>
						</Col> */}
						<Col md={6} className="mt-3">
							<select className="form-control" name="place" size='sm' required>
								<option value="">Select Village</option>
								{villageData.map((user, i) => {
									return <option value={user.id} selected={getValue('place') == user.id ? getValue('place') : ''}>{user.villagename}</option>
								})}
							</select>
						</Col>
						<Col md={6} className="mt-3">
							<MDBInput
								label="Venue"
								type="text"
								name="venue"
								size="sm"
								defaultValue={getValue('venue')}
							/>
						</Col>
						<Col md={6} className="mt-3">
							<MDBInput
								label="Total Participants"
								type="number"
								name="participants"
								size="sm"
								required
								maxLength="3"
								defaultValue={getValue('participants')}
							/>
						</Col>
						<Col md={3} className="mt-3">
							<MDBInput
								label="Male"
								type="number"
								name="male"
								size="sm"
								maxLength="3"
								defaultValue={getValue('male_participant')}
							/>
						</Col>
						<Col md={3} className="mt-3">
							<MDBInput
								label="Female"
								type="number"
								name="female"
								size="sm"
								maxLength="3"
								defaultValue={getValue('female_participant')}
							/>
						</Col>
						<Col md={6} className="mt-3">
							<MDBInput
								label="Special Category"
								type="text"
								name="special_category"
								size="sm"
								defaultValue={getValue('special_category')}
							/>
						</Col>
						{/* <Col md={6} className="mt-3">
							<MDBInput
								label="Banking"
								type="text"
								name="banking"
								size="sm"
								defaultValue={getValue('banking')}
							/>
						</Col>
						<Col md={6} className="mt-3">
							<MDBInput
								label="Plastic Bank"
								type="text"
								name="plastic_bank"
								size="sm"
								defaultValue={getValue('plastic_bank')}
							/>
						</Col>
						<Col md={6} className="mt-3">
							<MDBInput
								label="Organising"
								type="text"
								name="organising"
								size="sm"
								defaultValue={getValue('organising')}
							/>
						</Col>
						<Col md={6} className="mt-3">
							<MDBInput
								label="Kap Study"
								type="text"
								name="kap_study"
								size="sm"
								defaultValue={getValue('kap_study')}
							/>
						</Col>
						<Col md={6} className="mt-3">
							<MDBInput
								label="Door Conselling"
								type="text"
								name="door"
								size="sm"
								defaultValue={getValue('door')}
							/>
						</Col> */}
						<Col md={6} className="mt-3">
							<MDBInput
								label="Topics"
								type="text"
								name="topic"
								size="sm"
								defaultValue={getValue('topics')}
							/>
						</Col>
						<Col md={6} className="mt-3">
							<MDBInput
								label="Meeting Date"
								type="date"
								name="date"
								size="md"
								required
								defaultValue={getValue('meeting_date')}
							/>
						</Col>
						<Col md={6} className="mt-3">
							<MDBInput
								label="Report From Time"
								type="time"
								name="ftime"
								size="md"
								required
								defaultValue={getValue('from_time')}
							/>
						</Col>
						<Col md={6} className="mt-3">
							<MDBInput
								label="Report To Time"
								type="time"
								name="ttime"
								size="md"
								required
								defaultValue={getValue('to_time')}
							/>
						</Col>
						<Col md={6} className="mt-3">
							<MDBInput
								label="Resource person / guest"
								type="text"
								name="invitees"
								size="sm"
								defaultValue={getValue('invitees')}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<CKEditor
								initData={getValue('feedback')}
								data={feedback}
								onChange={onFeedbackChange}
								config={{ extraPlugins: 'iframe', extraAllowedContent: '*(*);*{*}', allowedContent: true }}
							/>
						</Col>
					</Row>
					<Row className="mt-3">
						<Col md={12}>
							<label>Photo Upload</label>
						</Col>
					</Row>
					<Row>
						{imgData.map((item, i) => {
							return (<Col md={2} style={{ maxWidth: '15%' }}>
								<img src={imageUrl + item.image} width="120px" style={styles.imgStyle} /><br />
								<button type="button" className="btn btn-danger btn-block btn-sm" style={{ marginLeft: '10px' }} onClick={() => { handleDeleteClick(item.id) }}>Remove</button>
							</Col>);
						})}
						{photos.map((item, i) => {
							return (<Col md={2} style={{ maxWidth: '15%' }}>
								<img src={item} width="120px" style={styles.imgStyle} /><br />
								<button type="button" className="btn btn-danger btn-block btn-sm" style={{ marginLeft: '10px' }} onClick={() => { handleRemove(i) }}>Remove</button>
							</Col>);
						})}
						<Col md={2}>
							<ReactFileReader fileTypes={[".png", ".jpg", ".jpeg"]} multipleFiles={true} base64={true} handleFiles={getFiles}>
								<img src={PLUSIMAGE} width="120px" style={styles.plusImgStyle} />
							</ReactFileReader>
						</Col>
					</Row>
					<Col className="mt-3">
						<button className="btn btn-outline-black btn-block" type="submit" disabled={btnLoading}>
							{btnLoading ? 'Please wait..' : 'Update'}</button>
					</Col>
				</Form>
			</div>
		</>
	);
};

export default EditMeeting;