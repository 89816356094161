import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import { Link } from 'react-router-dom';
import EditAnnounce from './editAnnounce';
import axios from 'axios';
import ViewAnnounce from './viewAnnounce';
import { triggerFocus } from 'antd/lib/input/Input';
import ReactFileReader from 'react-file-reader';
import { baseUrl } from '../../utils';

const Announcement = () => {

	const context = useContext(PsContext);
	const [AddUser, setAddUser] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [ViewData, setViewData] = useState(false);
	const [user, setUser] = useState([]);
	const [view, setView] = useState([]);
	const [data, setData] = useState([]);
	const [stData, stChangeData] = useState([]);
	const [searchData, setSearchData] = useState([]);
	const [loading, setIsLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [file, setFile] = useState('');
	const [fileName, setFileName] = useState('');
	const [expiryDate, setExpiryDate] = useState('');

	const handleFiles = (files) => {
		setFile(files.base64);
		setFileName(files.fileList[0].name);
	}

	useEffect(() => {
		listdata();
		var date = new Date().getDate() + '-' + (new Date().getMonth() + 1) + new Date().getFullYear();
		setExpiryDate(date);
	}, []);

	const showModal = (item) => {
		setUser(item);
		setEditModel(true);
	}

	const viewModel = (item, id) => {
		setView(item, id);
		setViewData(true);
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			setBtnLoading(true);
			API.post('v1/announce/addannounce', $("#addform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Announcement Added Successfully", "success");
					document.getElementById("addform").reset();
					const vrl = baseUrl + 'v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Announcement Added Successfully');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
						method: 'post',
						url: vrl,
						data: formData
					};
					axios(config)
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
					setBtnLoading(false);
					setFile('');
					setFileName('');
					listdata();
				}
				else {
					toast.error("Something Went Wrong", "danger");
					document.getElementById("addform").reset();
				}
			});
		}
		catch (error) {

		}
	}

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/announce/listannounce').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setData(d);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const handleDeleteClick = (item, id) => {
		try {
			setIsLoading(true);
			if (window.confirm('Do you want to Remove?')) {
				API.get('v1/announce/deleteannounce?id=' + id).then(res => {
					if (res['data'].status == '1') {
						toast.success(" Deleted Successfully..!", "success");
						//var d = res['data'].data;
						var d = data;
						var index = d.findIndex(function (obj) {
							return (obj.id == id);
						});
						if (index > -1)
							d.splice(index, 1);
						setData(d);
						setIsLoading(false);
						listdata();
					}
					else {
						toast.error("Something Went Wrong..!", "danger");
						setIsLoading(false);
					}
				});
			}
		}
		catch (error) {
			setIsLoading(false);
		}
	}

	return (
		<>
			<div className="container" >
				<Navbar bg="white" expand="lg">
					<Container>
						<Navbar.Brand >Announcement</Navbar.Brand>
						<Nav className="justify-content-end">

						</Nav>
					</Container>
				</Navbar>
				<Modal show={editModel} onHide={() => { setEditModel(false) }}>
					<Modal.Header closeButton>
						<Modal.Title>Edit</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						< EditAnnounce edit={user} afterFinish={() => { setEditModel(false) }} afterList={() => { listdata() }} />
					</Modal.Body>
				</Modal>
				<Modal show={ViewData} onHide={() => { setViewData(false) }}>
					<Modal.Header closeButton>
						<Modal.Title>View</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						< ViewAnnounce view={view} />
					</Modal.Body>
				</Modal>
				<Card>
					<Card.Body>
						<Row>
							<Col md={8}>
								<Table bordered className="mt-10">
									<thead className="text-center">
										<tr>
											<th width="5%">S.No</th>
											<th width="20%"><b>Title</b></th>
											<th width="25%"><b>Content</b></th>
											<th width="10%"><b>Expiry On</b></th>
											<th width="15%"><b>Manage</b></th>
										</tr>
									</thead>
									<tbody className="text-center">
										{(data.map((item, id) => {
											return <tr>
												<td>{id + 1}</td>
												<td align="left">{item.title}</td>
												<td align="left">{item.content}</td>
												<td>{item.expiry_on}</td>
												<td><Button variant="success" onClick={() => { showModal(item) }} size="sm"><i className="fas fa-edit"></i> </Button>&nbsp;
													<Button variant="primary" onClick={() => { viewModel(item, id) }} size="sm"><i className="fas fa-eye"></i> </Button>&nbsp;
													<Button variant="warning" onClick={() => handleDeleteClick(item, item.id)} size="sm"><i className="fas fa-trash"></i></Button>
												</td>
											</tr>
										}))}
									</tbody>
								</Table>
							</Col>
							<Col md={4}>
								<Card className="card bg-white">
									<Card.Body>
										<h4>Add Announcement</h4><hr />
										<Form action="" method="post" id="addform" validated={validated} onSubmit={handleSubmit} >
											<input type="hidden" name="file" value={JSON.stringify(file)} />
											<input type="hidden" name="fileName" value={fileName} />
											<Row>
												<Col md={12}>
													<MDBInput
														label="Title"
														type="text"
														name="title"
														required
														size="md"
													/>
												</Col>
											</Row>
											<Row className="mt-3" >
												<Col md={12}>
													<MDBInput
														label="Content"
														type="text"
														name="content"
														size="md"
													/>
												</Col>
											</Row>
											<Row className="mt-3" >
												<Col md={12}>
													<ReactFileReader base64={true} handleFiles={handleFiles} fileTypes={[".jpg", ".jpeg", ".png"]}>
														<button type="button" className='btn btn-sm btn-block'>Upload Image</button>
													</ReactFileReader>
												</Col>
												{file != '' ?
													<Col md={12} className="mt-4">
														<img src={file} style={{ maxWidth: '300px' }} />
													</Col> : ''}
											</Row>
											<Row className="mt-4" >
												<Col md={12}>
													<MDBInput
														label="Expiry Date"
														type="date"
														name="expiry_on"
														required
														size="md"
														defaultValue={expiryDate}
													/>
												</Col>
											</Row>
											<Row className="mt-4" >
												<Col md={12} >
													<Button
														type="submit"
														size="md"
														variant="primary"
														className="btn-block"
														disabled={btnLoading}
													>
														{btnLoading ? 'Please wait..' : 'Add'}
													</Button>
												</Col>
											</Row>
										</Form>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</div>
		</>
	);
};
export default Announcement;