import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import EditGroup from './editGroup';
import ViewGroup from './viewGroup';
import { Switch } from 'antd';
import moment from 'moment';

const tableStyle = { border: '1px solid black' }

const checkred = {
	backgroundColor: '#fd0d0d',
	borderColor: '#d80a0a'
}

const GroupManagement = (props) => {

	const context = useContext(PsContext);
	const [AddUser, setAddUser] = useState(false);
	const [userModal, setUserModal] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [ViewData, setViewData] = useState(false);
	const [user, setUser] = useState([]);
	const [view, setView] = useState([]);
	const [data, changeData] = useState([]);
	const [stData, stChangeData] = useState([]);
	const [searchData, setSearchData] = useState([]);
	const [active, setActive] = useState([]);
	const [inActive, setInActive] = useState([]);
	const [loading, setIsLoading] = useState(false);

	useEffect(() => {
		listdata();
		Active();
		Inactive();
	}, []);

	const showModel = (item) => {
		setUser(item);
		setEditModel(true);
	}

	const viewModel = (item) => {
		setView(item);
		setViewData(true);
	}

	const userModel = () => {
		setUserModal(true);
	}

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listgroup').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setSearchData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const Active = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listactive').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setActive(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const Inactive = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listinactive').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setInActive(d);
					console.log(d);

					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const onSearchChange = (e) => {
		var d = data;
		let ndata = d.filter(function (item) {
			const grp_name = item.grp_name.toUpperCase();
			const s = e.target.value.toUpperCase();
			return (grp_name.indexOf(s) > -1);
		});
		setSearchData(ndata);
	}

	const handleDeleteClick = (item, id, sta) => {
		try {
			{
				setIsLoading(true);
				API.get('v1/group/delete?id=' + id + '&status=' + sta).then(res => {
					if (res['data'].status == '1') {
						//var d = res['data'].data;
						var d = data.filter(dt => dt.id != id);
						changeData(d);
						listdata();
						setIsLoading(false);
					}
					else {
						toast.error("Something Went Wrong..!", "danger");
						setIsLoading(false);
					}
				});
			}
		}
		catch (error) {
			setIsLoading(false);
		}
	}

	const handleStatusChange = (item, id, e) => {

		let status = e ? '1' : '0';

		let msg = e ? 'Do you want to activate ?' : 'Do  you want to deactivate?';
		if (!window.confirm(msg)) {
			return;
		}

		handleDeleteClick(item, id, status)

	}

	const getAverageMembers = (attend, total) => {

		if (parseFloat(total) > 0 && parseFloat(attend) > 0) {

			return Math.round(parseFloat(attend) / parseFloat(total)).toFixed(0);
		}
		return 0;
	};
	console.log(data);
	return (
		<div className="container" >
			<Navbar bg="white" expand="lg">
				<Container>
					{/* <Navbar.Brand >Manage Group (Active Group :  {active.map((item) => { return <b>{item.ac}</b> })} &emsp;  Inactive Group :&emsp;{inActive.map((item) => { return <b>{item.os}</b> })} )</Navbar.Brand> */}
					<Navbar.Brand > Group Details(Active Group :  {active.map((item) => { return <b>{item.ac}</b> })} &emsp;  Inactive Group :&emsp;{inActive.map((item) => { return <b>{item.os}</b> })} )</Navbar.Brand>
					<Nav className="justify-content-end">
						<Link to={{
							pathname: '/addgroup'
						}}>Add New</Link>
					</Nav>
				</Container>
			</Navbar>

			{/*	<Col md={2}>
				    <Button variant="success" onClick={()=>{setAddUser(true)}}><i class="fa fa-plus" aria-hidden="true"></i> Add New</Button>
				</Col> */}

			<Row className="mt-3">
				<Col md={8}>
				</Col>
				<Col md={4}>
					<MDBInput
						label="Search "
						type="text"
						onChange={onSearchChange}
					/>
				</Col>
			</Row><br />

			<Row className="text-center">
				<Col md={{ span: 2, offset: 10 }}>
					<Modal show={editModel} size="lg" onHide={() => { setEditModel(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title >Edit Group</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<EditGroup data={user} afterFinish={() => { setEditModel(false) }} afterList={() => listdata()} />
						</Modal.Body>
						<Modal.Footer>
						</Modal.Footer>
					</Modal>

					<Modal show={ViewData} size="md" onHide={() => { setViewData(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title >View Group</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ViewGroup data={view} />
						</Modal.Body>
					</Modal>

					<Modal show={userModal} size="md" onHide={() => { setUserModal(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title></Modal.Title>
						</Modal.Header>
						<Modal.Body>

						</Modal.Body>
					</Modal>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<Table bordered responsive style={tableStyle} className="table">
						<thead className="text-center">
							<tr>
								<th width="3%">S.No</th>
								<th width="3%"><b>Group Name</b></th>
								<th width="3%"><b>Village Name</b></th>
								<th width="5%"><b>Total Members</b></th>
								<th width="3%"><b>Formation Date</b></th>
								<th width="5%"><b>Total Number Of Meeting</b></th>
								<th width="5%"><b>Average Participants</b></th>
								<th width="10%"><b>President Name & Mobile</b></th>
								<th width="10%"><b>Plastic Bank</b></th>
								<th width="8%"><b>Bank Name</b></th>
								<th width="15%"><b>Manage</b></th>
							</tr>
						</thead>
						<tbody className="text-center">
							{(searchData.map((item, id) => {
								let d = searchData.length;
								return <tr style={{ backgroundColor: (item.active_status == '0' ? '#F1EA87' : '') }}>
									<td><b>{d - id}</b></td> 
									<td align="left"><b>{item.grp_name}</b></td>
									<td align="left"><b>{item.village_name}</b></td>
									<td ><b>{item.total_members}</b></td>
									<td><b>{moment(item.date_of_start).format("DD-MM-YYYY")}</b></td>
									<td>{item.total_meetings}</td>
									<td>{getAverageMembers(item.total_members_in_meeting, item.total_meetings)}</td>
									<td align="left"><b>{item.pre_name}{''} {item.pre_mobile}</b></td>
									<td align="left"><b><Form.Check type='radio' disabled className="check-red-focus-visible" defaultChecked={item.bank_active == 'Yes' ? true : false} />{item.bank_active}</b></td>
									<td align="left"><b>{item.bank_name} {item.bank_ac_no}</b></td>
									<td>
										{item.active_status == '1' ? <Button variant="success" onClick={() => { showModel(item) }} size="sm"><i className="fas fa-edit"></i> </Button> : ''}{' '}
										<Button variant="warning" size="sm" onClick={() => viewModel(item, item.id)}><i className="fas fa-eye"></i> </Button>{' '}
										<Switch checked={item.active_status == '1'} onChange={e => { handleStatusChange(item, item.id, e) }} />
									</td>
								</tr>
							}))}
						</tbody>
					</Table>
				</Col>
			</Row>
		</div>
	);
};
export default GroupManagement;