import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Modal,
  Form,
  Table,
  Navbar,
  Nav,
  Container,
  Button,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { MDBInput } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import API from "../../utils/api";
import $ from "jquery";
import PsContext from "../../context";
import { PSListItem } from "../../comp";
import { integerIndMobile, lettersOnly, integerIndGst } from "../../utils";
import { baseUrl } from "../../utils";
import axios from "axios";
import { Rating } from "react-simple-star-rating";

const EditStar = (props) => {
  const [data, changeData] = useState([]);
  const [ratingResponse, setRatingResponse] = useState([]);
  const [questionData, setQuestionData] = useState([]);
  const [loading, setIsLoading] = useState(false);

  const handleRating = (rate, itemId) => {
    try {
      let divide = rate / 20;
      var form = new FormData();
      form.append("id", itemId);
      form.append("rating", divide);
      API.post("v1/group/editratingscore", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Score Updated", "success");
        }
      });
    } catch (error) {}
  };

  const handleValue = (rate, indecator) => {
    let divide = rate / 20;

    var newResponse = ratingResponse;

    newResponse.forEach((item) => {
      console.log("loop item", item.indicator, indecator);
      if (item.indicator == indecator) {
        item.rating = divide;
        console.log("current item", item);
      }
      return item;
    });
    console.log("res", newResponse);
    setRatingResponse(newResponse);
  };

  const handleSubmit = () => {
    try {
      console.log(ratingResponse);
      var form = new FormData();
      form.append("house_id", props.user.id);
      form.append("value", JSON.stringify(ratingResponse));
      API.post("v1/group/addquestionrateing", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Score Added", "success");
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    listdata();
    const rRes = [
      { indicator: 6, rating: 0 },
      { indicator: 7, rating: 0 },
      { indicator: 8, rating: 0 },
      { indicator: 9, rating: 0 },
      { indicator: 10, rating: 0 },
      { indicator: 11, rating: 0 },
      { indicator: 12, rating: 0 },
      { indicator: 13, rating: 0 },
      { indicator: 14, rating: 0 },
      { indicator: 15, rating: 0 },
      { indicator: 16, rating: 0 },
      { indicator: 17, rating: 0 },
      { indicator: 19, rating: 0 },
    ];
    setRatingResponse(rRes);
  }, []);

  const listdata = () => {
    try {
      API.get("v1/group/listindicator").then((res) => {
        if (res["data"].data) {
          var d = res["data"].data;
          changeData(d);
        } else {
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    listQuestionReport(props.user.id);
  }, [props.user]);

  const listQuestionReport = (id) => {
    try {
      setIsLoading(true);
      var form = new FormData();
      form.append("visit_count", props.visit);
      API.get(
        "v1/group/listquestionsdata?hid=" + id + "&visit_count=" + props.visit
      ).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setQuestionData(d);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  console.log(data);
  console.log(props.user);
  console.log(props.data);
  return (
    <>
      <h5>Family Details :</h5>
      <Table bordered responsive className="table">
        <tbody>
          <tr>
            <th>Name</th>
            <td>{props.user.fam_head_name}</td>
            <th>Mobile</th>
            <td>{props.user.mobile}</td>
          </tr>
          <tr>
            <th>Block Name</th>
            <td>{props.user.bname}</td>
            <th>Village Name</th>
            <td>{props.user.vname}</td>
          </tr>
        </tbody>
      </Table>
      <h5>Indicators Rating Score :</h5>
      {questionData.length > 0 ? (
        <Table bordered responsive className="table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Indicators</th>
              <th>Rating</th>
            </tr>
          </thead>
          <tbody>
            {questionData.map((item, i) => {
              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>{item.indicators}</td>
                  <td>
                    <Rating
                      initialValue={item.rateing}
                      onClick={(rate) => {
                        handleRating(rate, item.id);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <Table bordered responsive className="table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Indicators</th>
              <th>Rating</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => {
              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>{item.indicators}</td>
                  <td>
                    <Rating
                      onClick={(rate) => {
                        handleValue(rate, item.id);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan="3">
                <Button
                  type="button"
                  size="sm"
                  className="mt-1"
                  style={{ float: "right" }}
                  variant="success"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};
export default EditStar;
