import React, { useState, useContext } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import { baseUrl } from "../../utils";
import axios from "axios";

const LoginPage = () => {
  const context = useContext(PsContext);
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [fieldValue, setFieldValue] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const handleBlur = (e) => {
    setFieldValue(e.target.value);
    setValidated(true);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (!form.checkValidity() === true) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    setBtnLoading(true);
    API.post("v1/user/login", $("#frm_Login").serialize()).then((res) => {
      console.log(res);
      if (res["data"].errorcode != 200) {
        toast.error(res["data"].content || res["data"].message);
        setBtnLoading(false);
      } else {
        const vrl = baseUrl + "v1/log/addlog";
        let formData = new FormData();
        formData.append("createon", "2021/12/10"); //append the values with key, value pair
        formData.append("context", "user logged in");
        formData.append("e_type", "User_log");
        formData.append("action_by", "admin");
        formData.append("addby", 1);
        formData.append("addip", "localhost");

        var config = {
          method: "post",
          url: vrl,
          data: formData,
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });

        context.saveAdminLogin(
          res["data"].data.user,
          res["data"].data.company,
          res["data"].data.acyear,
          res["data"].token
        );
        history.push("/home");
      }
    });
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />

      <div>
        {/* Login page design..*/}
        <div className="container-fluid">
          <Row className="mt-5">
            <Col md={{ span: 4, offset: 4 }}>
              <Card>
                <Card.Header>
                  <img src="blurtopbackground.png" width="100%" />
                </Card.Header>
                <Card.Body>
                  <h4>Sign In</h4>
                  <hr />
                  <Form
                    action=""
                    method="post"
                    id="frm_Login"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      <Col md={12}>
                        <MDBInput
                          label="Username"
                          type="text"
                          name="userName"
                          id="userName"
                          required
                          size="lg"
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback type="invalid">
                          Username is required
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col md={12}>
                        <MDBInput
                          label="Password"
                          type="password"
                          name="passWord"
                          id="passWord"
                          required
                          size="lg"
                          onBlur={handleBlur}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col md={12}>
                        <Button
                          type="submit"
                          size="md"
                          variant="primary"
                          className="btn-block"
                          disabled={btnLoading}
                        >
                          {btnLoading ? "Please wait.." : "Sign In"}
                        </Button>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col md={12}>
                        <Link
                          to={{
                            pathname: "/forgotpassword",
                          }}
                        >
                          Forgot Password
                        </Link>{" "}
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        {/* Login page design..*/}
      </div>
    </>
  );
};
export default withRouter(LoginPage);
