import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../../context';
import { PSListItem } from '../../../comp';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../../utils/api';
import $ from 'jquery';


const EditGeneral = (props) =>{
	
	const context = useContext(PsContext);
	const [adminCompany, setAdminCompany] = useState(false);
	const [editData, setEditData] = useState(false);
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [value, setValue] = useState('');
	
	const handleSubmit = (event) => {
		event.preventDefault();
		try{
			setBtnLoading(true);
			API.post('v1/user/update',$("#frm_EditGeneral").serialize()).then(res=>{
				if (res['data'].status == '1') {
				  toast.success("Customer Updated Successfully..!", "success");
				  document.getElementById("frm_EditGeneral").reset();
				  setBtnLoading(false);
				  props.afterfinish();
				}
				else {
				  toast.error("Something Went Wrong..!", "danger");
				  document.getElementById("frm_EditGeneral").reset();
				}
				setIsLoading(false); 
			});
		}
		catch(error){
			
		}
	};


	return(
		<>
				<Form action="" method="post" id="frm_EditGeneral" noValidate validated={validated} onSubmit={handleSubmit} >
				<input type="hidden" value={context.adminCompany.id} name="id" /> 
					<Row>
						<Col md={12} >
							<MDBInput 
								label="Name"
								type="text"
								name="name"
								id="name"
								required
								size="lg"
								defaultValue={context.adminCompany.name}
								
							 />
						</Col>
					</Row>
					<Row className="mt-4" >
						<Col md={12} >
							<MDBInput 
								label="GST Number"
								type="text"
								name="gst"
								id="gst"
								required
								size="lg"
								defaultValue={context.adminCompany.gst}
								
							 />
						</Col>
					</Row>
				   <Row className="mt-4" >
						<Col md={12} >
							<MDBInput 
								label="Logo"
								type="text"
								name="gst"
								id="gst"
								required
								size="lg"
								defaultValue={context.adminCompany.gst}
								
							 />
						</Col>
					</Row> 
					<Row className="mt-4" >
						<Col md={12} >
							<Button
								type="submit"
								size="md"
								variant="success"
								className="btn-block"
								disabled={btnLoading}
							>
							{btnLoading ? 'Please wait..' : 'Update'}
							</Button>
						</Col>
					</Row>
					
				</Form>
		</>
	);
};
export default EditGeneral;