import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../../utils/api';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import PsContext from '../../../context';
import { PSListItem } from '../../../comp';
import EditType from './editType';
import { lettersOnly, integerIndMobile, baseUrl } from '../../../utils';
import axios from 'axios';

const PlasticType = () => {

	const context = useContext(PsContext);
	const [data, changeData] = useState([]);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState([]);
	const [plasticIndex, setPlasticIndex] = useState([]);
	const [nonPlasticKgIndex, setNonPlasticKgIndex] = useState([]);
	const [nonPlasticPieceIndex, setNonPlasticPieceIndex] = useState([]);
	const [editModel, setEditModel] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [countData, setCountData] = useState({});

	const showModel = (item) => {
		setEdit(item);
		setEditModel(true);
	}

	useEffect(() => {
		listdata();
		plasticNonPlastic();
	}, []);

	const listdata = () => {
		try {
			setBtnLoading(true);
			var form = new FormData();
			form.append('acyear', context.acyear.id);
			API.get('v1/plastic/listplastics').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					var d1 = d.filter((obj) => obj.material_type == 'plastic');
					d1.forEach(function (obj) {
						obj.orderno = 1
					});
					var d2 = d.filter((obj) => obj.material_type == 'others' && obj.weight == 'kg');
					d2.forEach(function (obj) {
						obj.orderno = 2
					});
					var d3 = d.filter((obj) => obj.material_type == 'others' && obj.weight == 'pcs');
					d3.forEach(function (obj) {
						obj.orderno = 3
					});
					console.log(d2)
					var pData = d1.concat(d2, d3);
					//pData.sort((a, b) => a.orderno - b.orderno);
					setCountData({
						plastic: d1.length,
						nonplastic: d2.length,
						nonplasticpiece: d3.length
					})
					console.log(pData)
					changeData(pData);
					setBtnLoading(false);
				}
				else {
					setBtnLoading(false);
				}
			});
		}
		catch (er) {

		}
	};

	const handleDelete = (item, id) => {
		try {
			if (window.confirm('Do you want to Remove?')) {
				setIsLoading(true);
				API.get('v1/plastic/deleteplastic?id=' + id).then(res => {
					if (res['data'].status == '1') {
						var d = data.filter(dt => dt.id != id);
						console.log(id);
						console.log(d);
						changeData(d);
						toast.success("Deleted Successfully", "success");

						setIsLoading(false);
						const vrl = baseUrl + 'v1/log/addlog';
						let formData = new FormData();
						formData.append('createon', '2021/12/10');   //append the values with key, value pair
						formData.append('context', 'Plastic Type Removed');
						formData.append('e_type', 'User_log');
						formData.append('action_by', 'admin');
						formData.append('addby', 1);
						formData.append('addip', 'localhost');

						var config = {
							method: 'post',
							url: vrl,
							data: formData
						};

						axios(config)
							.then(function (response) {
								console.log(JSON.stringify(response.data));
							})
							.catch(function (error) {
								console.log(error);
							});
					}
					else {
						toast.error("something went wrong", "danger");
						setIsLoading(false);
					}
				});
			}
		}
		catch (error) {

		}
	}

	const plasticNonPlastic = () => {
		let value = data.filter(function (obj) {
			return (obj.material_type == 'plastic');
		});
		let valueIndex = value.map((item, i) => {
			return i;
		});
		setPlasticIndex(valueIndex);

		let NonKgValue = data.filter(function (obj) {
			return (obj.material_type == 'others' && obj.weight == 'kg');
		});
		let NonKgValueIndex = NonKgValue.map((item, i) => {
			return i;
		});
		setNonPlasticKgIndex(NonKgValueIndex);

		let NonPieceValue = data.filter(function (obj) {
			return (obj.material_type == 'others' && obj.weight == 'pcs');
		});
		let NonPieceValueIndex = NonPieceValue.map((item, i) => {
			return i;
		});
		setNonPlasticPieceIndex(NonPieceValueIndex);
	}

	return (
		<>
			<Modal show={editModel} onHide={() => { setEditModel(false) }}>
				<Modal.Header closeButton>
					<Modal.Title>Edit Type</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditType data={edit} afterFinish={() => { setEditModel(false) }} afterList={() => { listdata() }} />
				</Modal.Body>
			</Modal>

			<div className="container">
				<Navbar bg="white" expand="md">
					<Container>
						<Navbar.Brand >Plastic Type -({data.length})</Navbar.Brand>
						<Nav className="justify-content-end">{' '}
							<Link
								to={{
									pathname: '/addtype'
								}}>Add
							</Link>{' '}
						</Nav>
					</Container>
				</Navbar><br />

				<Table responsive bordered>
					<thead className="text-center">
						<tr>
							<th width="5%"><b>S.No</b></th>
							<th width="15%"><b>Plastic Name</b></th>
							<th width="5%"><b>Short Name</b></th>
							<th width="5%"><b>HSN</b></th>
							<th width="5%"><b>GST</b></th>
							<th width="10%"><b>Status</b></th>
							<th width="10%"><b>Manage</b></th>
						</tr>
					</thead>
					<tbody className="text-center">
						<tr>
							<td colSpan={7} style={{ fontSize: '17px' }}><b><strong>PLASTIC MATERIALS</strong></b></td>
						</tr>
						{data.map((item, id) => {
							return <tr>
								{item.material_type == 'plastic' ? <td>{id + 1}</td> : ''}
								{item.material_type == 'plastic' ? <td align="left">{item.plastic_name}</td> : ''}
								{item.material_type == 'plastic' ? <td>{item.short_name}</td> : ''}
								{item.material_type == 'plastic' ? <td>{item.hsn}</td> : ''}
								{item.material_type == 'plastic' ? <td>{item.gst}</td> : ''}
								{item.material_type == 'plastic' ? <td>{item.active_status}</td> : ''}
								{item.material_type == 'plastic' ? <td><Button variant="success" size="md" onClick={() => { showModel(item) }}><i className="fas fa-edit"></i></Button>&emsp;
									<Button variant="danger" size="md" onClick={() => { handleDelete(item, item.id) }}><i className="fas fa-trash"></i></Button>
								</td> : ''}
							</tr>
						})}
						<tr>
							<td colSpan={7} style={{ fontSize: '17px' }}><b><strong>NON-PLASTIC MATERIALS - KG</strong></b></td>
						</tr>
						{data.map((item, id) => {
							return <tr>
								{item.material_type == 'others' && item.weight == 'kg' ? <td>{id + 1 - countData.plastic}</td> : ''}
								{item.material_type == 'others' && item.weight == 'kg' ? <td align="left">{item.plastic_name}</td> : ''}
								{item.material_type == 'others' && item.weight == 'kg' ? <td>{item.short_name}</td> : ''}
								{item.material_type == 'others' && item.weight == 'kg' ? <td>{item.hsn}</td> : ''}
								{item.material_type == 'others' && item.weight == 'kg' ? <td>{item.gst}</td> : ''}
								{item.material_type == 'others' && item.weight == 'kg' ? <td>{item.active_status}</td> : ''}
								{item.material_type == 'others' && item.weight == 'kg' ? <td><Button variant="success" size="md" onClick={() => { showModel(item) }}><i className="fas fa-edit"></i></Button>&emsp;
									<Button variant="danger" size="md" onClick={() => { handleDelete(item, item.id) }}><i className="fas fa-trash"></i></Button>
								</td> : ''}
							</tr>
						})}
						<tr>
							<td colSpan={7} style={{ fontSize: '17px' }}><b><strong>NON-PLASTIC MATERIALS - PIECES</strong></b></td>
						</tr>
						{data.map((item, id) => {
							return <tr>
								{item.material_type == 'others' && item.weight == 'pcs' ? <td>{id + 1 - countData.nonplastic - countData.plastic}</td> : ''}
								{item.material_type == 'others' && item.weight == 'pcs' ? <td align="left">{item.plastic_name}</td> : ''}
								{item.material_type == 'others' && item.weight == 'pcs' ? <td>{item.short_name}</td> : ''}
								{item.material_type == 'others' && item.weight == 'pcs' ? <td>{item.hsn}</td> : ''}
								{item.material_type == 'others' && item.weight == 'pcs' ? <td>{item.gst}</td> : ''}
								{item.material_type == 'others' && item.weight == 'pcs' ? <td>{item.active_status}</td> : ''}
								{item.material_type == 'others' && item.weight == 'pcs' ? <td><Button variant="success" size="md" onClick={() => { showModel(item) }}><i className="fas fa-edit"></i></Button>&emsp;
									<Button variant="danger" size="md" onClick={() => { handleDelete(item, item.id) }}><i className="fas fa-trash"></i></Button>
								</td> : ''}
							</tr>
						})}
					</tbody>
				</Table>
			</div>
		</>
	);
};
export default PlasticType;