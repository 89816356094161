import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import { integerIndMobile, lettersOnly } from '../../utils';
import { baseUrl } from '../../utils';

const EditStaff = (props) => {

	const context = useContext(PsContext);
	const [submit, sethandleSubmit] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [data, changeData] = useState([]);

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		if (!form.checkValidity() === true) {
			event.stopPropagation();
			setValidated(true);
			return;
		}
		try {
			setBtnLoading(true);
			API.post('v1/user/edituser', $("#editform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("User Updated Successfully..!", "success");
					document.getElementById("editform").reset();
					setBtnLoading(false);
					props.afterFinish();
					props.afterList();
					const vrl = baseUrl + '/v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'User Edit Staff Member');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
						method: 'post',
						url: vrl,
						data: formData
					};

					axios(config)
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
				}
				else {
					toast.error("Something Went Wrong..!", "danger");
					document.getElementById("editform").reset();
				}
				setIsLoading(false);
			});
		}
		catch (error) {
			toast.error("Something Went Wrong..!", "danger");
		}
	};

	const getValue = (field) => {
		return props.data[field] || '';
	}


	return (
		<>
			<div class="container">
				<Form action="" method="post" id="editform" noValidate validated={validated} onSubmit={handleSubmit} >
					<input type="hidden" name="id" value={getValue('id')} />
					<Row>
						<Col md={6}>
							<Row>
								<Col md={12}>
									<MDBInput
										label="NAME"
										type="text"
										id="name"
										name="name"
										size="lg"
										required
										defaultValue={getValue('name')}
										onKeyPress={lettersOnly}
									/>
								</Col>

								<Col md={12} className="mt-3">
									<MDBInput
										label="DOB"
										type="date"
										id="dob"
										name="dob"
										size="lg"
										required
										defaultValue={getValue('dob')}
									/>
								</Col>

								<Col md={12} className="mt-3">
									<label>Gender:</label><br />
									<div className="mt-2">
										<div className="form-check form-check-inline">
											<input type="radio" name="gender" value="Male" className="form-check-input" defaultChecked={getValue('gender') == 'Male' ? true : false} />
											<label for="gender">
												Male
											</label>
										</div>
										<div className="form-check form-check-inline">
											<input type="radio" name="gender" value="Female" className="form-check-input" defaultChecked={getValue('gender') == 'Female' ? true : false} />
											<label for="gender">
												Female
											</label>
										</div>
										<div className="form-check form-check-inline">
											<input type="radio" name="gender" value="TG" className="form-check-input" defaultChecked={getValue('gender') == 'TG' ? true : false} />
											<label for="gender">
												TransGender
											</label>
										</div>
									</div>
								</Col>

								<Col md={12} className="mt-3">
									<MDBInput
										label="MOBILE"
										type="number"
										id="mobile"
										name="mobile"
										size="lg"
										required
										defaultValue={getValue('mobile')}
										onKeyPress={integerIndMobile}
									/>
								</Col>

								<Col md={12} className="mt-3">
									<MDBInput
										label="Email"
										type="email"
										id="email"
										name="email"
										size="lg"
										required
										defaultValue={getValue('email')}
									/>
								</Col>
								<Col md={12} className="mt-3">
									<label>Mobile APP Access:</label><br />
									<div className="mt-2">
										<div className="form-check form-check-inline">
											<input type="radio" name="mobileAccess" value="Yes" className="form-check-input" defaultChecked={getValue('mobile_access') == 'Yes' ? true : false} />
											<label for="mobileAccess">
												Yes
											</label>
										</div>
										<div className="form-check form-check-inline">
											<input type="radio" name="mobileAccess" value="No" className="form-check-input" defaultChecked={getValue('mobile_access') == 'No' ? true : false} />
											<label for="mobileAccess">
												No
											</label>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
						<Col md={6}>
							<Col md={12} >
								<MDBInput
									label="Education Qualification"
									type="text"
									id="education"
									name="edu_qualification"
									size="lg"
									defaultValue={getValue('edu_qualification')}
								/>
							</Col>
							<Col md={12} className="mt-3">
								<MDBInput
									label="Designation"
									type="text"
									id="designation"
									name="designation"
									size="lg"
									required
									defaultValue={getValue('designation')}
									onKeyPress={lettersOnly}
								/>
							</Col>
							<Col md={12} className="mt-3">
								<MDBInput
									label="Year of Experience"
									type="text"
									id="year_ex"
									name="year_ex"
									size="lg"
									defaultValue={getValue('year_ex')}
								/>
							</Col>
							<Col md={12} className="mt-3">
								<MDBInput
									label="Date Of Appointment"
									type="date"
									id="doa"
									name="doa"
									size="lg"
									defaultValue={getValue('doa')}
								/>
							</Col>
							<Col md={12} className="mt-3">
								<MDBInput
									label="Address"
									type="text"
									id="address"
									name="address"
									size="lg"
									defaultValue={getValue('address')}
								/>
							</Col>
							<Col md={12} className="mt-3">
								<select className="form-select form-select-md" name="staff_type" defaultValue={getValue('staff_type')} requried>
									<option value="" disabled>Staff Type</option>
									<option value="0">Project Staff</option>
									<option value="1">Admin Staff</option>
								</select>
							</Col>
							<Col md={12} className="mt-3">
								<MDBInput
									label="Order"
									type="number"
									name="ordered"
									size="lg"
									defaultValue={getValue('ordered')}
								/>
							</Col>
						</Col>
						<Col md={12} className="mt-3">
							<button type="button" class="btn btn-outline-black btn-block" type="submit" disabled={btnLoading}>
								{btnLoading ? 'Please wait..' : 'Update'}</button>
						</Col>
					</Row>
				</Form>
			</div>
		</>
	);
};

export default EditStaff;