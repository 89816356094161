import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../../context';
import { PSListItem } from '../../../comp';
import EditAddress from './edit';

const Address = (props) =>{
	
	const context = useContext(PsContext);
	const [adminCompany, setAdminCompany] = useState(false);
	const [edit, setEdit] = useState(false);

	return(
		<>
			<Modal show={adminCompany} onHide={()=> setAdminCompany(false)}>
				<Modal.Header closeButton>
				  <Modal.Title>Edit Address</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditAddress afterfinish= {()=> setAdminCompany(false)}/>
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
			
			<Card className="no-shadow" >
				<Card.Body>
					<Row >
						<Col>
							<h5>Address</h5>
						</Col>
						<Col className="d-flex justify-content-end" >
							<Button size="sm" variant="outline-primary" onClick={()=> setAdminCompany(true)} >
								<i className="fas fa-edit me-1" ></i> Edit
							</Button>
						</Col>
					</Row>
					<hr />
					
					<PSListItem title="Address" subTitle={context.adminCompany.address} boldSubTitle />
					<PSListItem title="City" subTitle={context.adminCompany.city} boldSubTitle />
					<PSListItem title="Country" subTitle={context.adminCompany.country} boldSubTitle />
					<PSListItem title="State" subTitle={context.adminCompany.state} boldSubTitle />
					<PSListItem title="Pincode" subTitle={context.adminCompany.pincode} boldSubTitle />
					
				</Card.Body>
			</Card>
		</>
	);
};
export default Address;