import { Spin } from "antd";
import moment from "moment";
import React, { useContext, useState, useEffect } from "react";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import PsContext from "../../context";
import { groupByMultiple, momentDate } from "../../utils";
import API from "../../utils/api";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import $ from "jquery";
import { Bar, Column } from "@ant-design/plots";
const styles = {
  table: {
    border: "1px solid black",
    borderCollapse: "collapse",
  },
  tr: {
    border: "1px solid black",
    borderCollapse: "collapse",
  },
  td: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
    fontSize: "12px",
    paddingLeft: "10px",
  },
  tdBg: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
    backgroundColor: "rgb(224 247 211)",
  },
  tdRed: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
    backgroundColor: "rgb(245 227 221)",
    fontStyle: "italic",
    fontWeight: "bold",
  },
};

const QrlReport = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const [click, setClick] = useState(false);
  const [data, changeData] = useState([]);
  const [qusData, setQusData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [vdata, visitData] = useState([]);
  const [count, countData] = useState([]);
  const [dataView, setDataView] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chartDataFinal, setChartDataFinal] = useState([]);
  const rating = 5;

  useEffect(() => {
    listdatacount();
    setChartData([
      {
        type: "Sample 1",
        sales: 38,
      },
      {
        type: "Sample 2",
        sales: 52,
      },
    ]);
  }, []);

  const listdatacount = () => {
    try {
      setIsLoading(true);
      API.get("v1/group/questionreportlistcount").then((res) => {
        if (res["data"].status == "1") {
          var c = res["data"].count;
          countData(c);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    listdata();
  }, []);

  const listdata = () => {
    try {
      API.get("v1/group/listindicator").then((res) => {
        if (res["data"].data) {
          var d = res["data"].indicator_count[0].count;
          setQusData(d);
        } else {
        }
      });
    } catch (error) {}
  };

  const listData = () => {
    try {
      setIsLoading(true);
      var visitCount = $("#visit").val();
      var fromDate = $("#fromdate").val();
      var toDate = $("#todate").val();
      API.post(
        "/v1/group/questionreportlist?visitCount=" +
          visitCount +
          "&fromdate=" +
          fromDate +
          "&todate=" +
          toDate
      ).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
          var d1 = d.map(function (obj) {
            return {
              Indicator: obj.indicators,
              Score: obj.rate,
            };
          });
          setChartData(d1);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const viewVisit = (e) => {
    setDataView(e);
  };

  const viewClick = () => {
    listData();
  };

  const getValue = () => {
    var d = [];
    for (var i = 1; i <= 10; i++) {
      d.push(<option value={i}>visit - {i}</option>);
    }
    return d;
  };

  const config = {
    chartData,
    xField: "Indicator",
    yField: "Score",
    columnWidthRatio: 0.3,
    meta: {
      Indicator: {
        alias: "Name",
      },
      Score: {
        alias: "Value",
      },
    },
  };

  const getTotalPercentage = () => {
    var total = 0;
    data.map(
      (obj) =>
        (total = parseFloat(total) + parseFloat((obj.vcount / rating) * 100))
    );
    var per = parseFloat(total).toFixed(2);
    var value = parseFloat(per / qusData).toFixed(1)
      ? parseFloat(per / qusData).toFixed(1)
      : 0;
    return value;
  };

  const dateChange = () => {
    var fromDate = $("#fromdate").val();
    var toDate = moment(fromDate).format("YYYY-MM-DD");
    if (fromDate != "") {
      $("#todate").attr("min", toDate);
    }
  };

  return (
    <div className="container">
      <Card>
        <Card.Header>
          <Row>
            <Col md={2}>
              <h5>Status Report</h5>
            </Col>
            <Col md={1}></Col>
            <Col md={2}>
              <label className="control-label">From</label>
              <Form.Control
                type="date"
                name="fromdate"
                id="fromdate"
                size="sm"
                onChange={dateChange}
                max={momentDate(new Date(), "YYYY-MM-DD")}
              />
            </Col>
            <Col md={2}>
              <label className="control-label">To</label>
              <Form.Control
                type="date"
                name="todate"
                id="todate"
                size="sm"
                max={momentDate(new Date(), "YYYY-MM-DD")}
              />
            </Col>
            <Col md={2}>
              <label className="control-label">Visit</label>
              <Form.Control
                as="select"
                className="form-control"
                name="visit"
                id="visit"
                size="sm"
                required
                onChange={(e) => viewVisit(e.target.value)}
              >
                <option value="">-Select-</option>
                {getValue()}
                {/* {count.map((item) => {
									return <option value={item.visit_count}>visit - {item.visit_count}</option>
								})} */}
              </Form.Control>
            </Col>
            <Col md={1} className="mt-3">
              <Button variant="success" type="button" onClick={viewClick}>
                view
              </Button>
            </Col>
            <Col md={2} className="mt-3">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn mt-0 btn-info"
                table="table-to-xls"
                filename="Question Report List"
                sheet="questionreport"
                buttonText="Export as XLS"
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Spin spinning={loader}>
            {data.length > 0 ? (
              <Row className="m-0">
                <Col md={12}>
                  <table
                    bordered
                    width="100%"
                    align="center"
                    style={styles.table}
                    id="table-to-xls"
                  >
                    <thead>
                      <tr>
                        <th colSpan="6">
                          <div
                            align="center"
                            style={{
                              fontSize: "15px",
                              backgroundColor: "#CACBCA",
                            }}
                          >
                            <b>VISIT - {dataView}</b>
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th style={styles.table}>S.No</th>
                        <th style={styles.table}>Indicators</th>
                        <th style={styles.table}>No of Response</th>
                        <th style={styles.table}>Total Score</th>
                        <th style={styles.table}>Average Score</th>
                        <th style={styles.table}>Percentage %</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, i) => {
                        return (
                          <tr>
                            <td style={styles.table}>{i + 1}</td>
                            <td style={styles.table}>{item.indicators}</td>
                            <td align="right" style={styles.table}>
                              {item.tcount}
                            </td>
                            <td align="right" style={styles.table}>
                              {item.rate}
                            </td>
                            <td align="right" style={styles.table}>
                              {parseFloat(item.vcount).toFixed(2)}
                            </td>
                            {/* <td align="right" style={styles.table}>{Math.round((item.tcount) / (item.rate) * 100)}%</td> */}
                            <td align="right" style={styles.table}>
                              {parseFloat((item.vcount / rating) * 100).toFixed(
                                1
                              )}
                              %{" "}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td style={styles.table} align="right" colSpan={5}>
                          Total Percentage
                        </td>
                        <td align="right">{getTotalPercentage()}%</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            ) : dataView == "" ? (
              ""
            ) : (
              "No data found"
            )}
          </Spin>
        </Card.Body>
      </Card>
      {/* <Row>
				<Col md={12} className="dash-row">
					<h4>SUMMARY CHART</h4><br />
					<h5>Plastic Stock</h5>
				</Col>
				<Col md={12}>
					<Column {...config} />
				</Col>
			</Row> */}
    </div>
  );
};

export default QrlReport;
