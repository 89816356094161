import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { decimalKeyPress, baseUrl } from '../../utils';

const TargetSetup = (props) => {

	const context = useContext(PsContext);
	const [loading, setIsLoading] = useState(false);
	const [groupData, setGroupData] = useState([]);
	const [plasticData, setPlasticData] = useState([]);
	const [targetData, setTargetData] = useState([]);
	const [btnLoading, setBtnLoading] = useState(false);

	useEffect(() => {
		listgroupname();
		//listplastic();
		listtarget();
	}, []);

	const listplastic = (event) => {
		event.preventDefault();
		try {

			API.post('v1/plastic/listchartplastic', $('#list').serialize()).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setPlasticData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {

		}
	};

	// const listplastic = () => {
	// 	try {
	// 		setIsLoading(true);
	// 		var form = new FormData();
	// 		form.append('acyear', context.acyear.id);
	// 		API.post('v1/plastic/listplastic', form).then(res => {
	// 			if (res['data'].status == '1') {
	// 				var d = res['data'].data;
	// 				setPlasticData(d);
	// 				setIsLoading(false);
	// 			}
	// 			else {
	// 				setIsLoading(false);
	// 			}
	// 		});
	// 	}
	// 	catch (error) {
	// 		setIsLoading(false);
	// 	}
	// };

	const listgroupname = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listgroup').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setGroupData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const handleSubmit = (pid, grpid, e) => {

		try {
			setBtnLoading(true);
			let formData = new FormData();
			formData.append('plastic_id', pid);
			formData.append('group_id', grpid);
			formData.append('target_value', e);

			API.post('v1/plastic/updatetarget', formData).then(res => {
				if (res['data'].status == '1') {
					toast.success("Target Updated Successfully..!", "success");
					setBtnLoading(false);
					const vrl = baseUrl + '/v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Plastic Target Setup');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
						method: 'post',
						url: vrl,
						data: formData
					};

					axios(config)
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
					listtarget();
				}
				else {
					toast.error("Something Went Wrong..!", "danger");
					setBtnLoading(false);
				}
				setIsLoading(false);
			});
		}
		catch (error) {

		}
	};

	const listtarget = () => {
		try {
			setIsLoading(true);
			API.get('/v1/plastic/listtarget').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setTargetData(d);

					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const getTarget = (pid, grpid) => {
		var target = '';
		var d = targetData.filter(function (obj) {
			return (obj.group_id == grpid && obj.plastic_id == pid);
		});
		if (d.length > 0) {
			target = d[0].value;
		}
		return target;
	}

	return (
		<>
			<div className="container">
				<Navbar bg="white" expand="md">
					<Container>
						<Navbar.Brand >Plastic Target Setup</Navbar.Brand>
					</Container>
				</Navbar>
				<Row className='mt-1'>
					<Form onSubmit={listplastic} method='post' id="list">
						<Row>
							<Col md={9}>
							</Col>
							<Col md={2} className='ms-0'>
								<select className='form-control sm' name="type">
									<option value="">Select Type</option>
									<option value="plastic kg">Plastic Kg</option>
									<option value="others kg">Non Plastic Kg</option>
									<option value="others pcs">Non Plastic Piece</option>
								</select>
							</Col>
							<Col md={1}>
								<Button type="submit" size='sm' variant="success">Search</Button>
							</Col>
						</Row>
					</Form>
					<Col md={12} className="mt-2">
						{plasticData.length > 0 ? <Table bordered responsive>
							<thead className="text-center">
								<tr>
									<th width="10%"><b>S.No</b></th>
									<th width="20%"><b>Group Name</b></th>
									{plasticData.map((item, i) => {
										return <th width="10%"><b>{item.short_name}</b></th>
									})}
								</tr>
							</thead>
							<tbody className="text-center">
								{groupData.map((grp, i) => {
									return <tr>
										<td>{i + 1}</td>
										<td align="left">{grp.grp_name}</td>
										{plasticData.map((items, j) => {
											return <td>
												<input type="text" className="form-control form-control-sm" name="kilogram" onKeyPress={decimalKeyPress} id={"p_" + items.id} onChange={(e) => { handleSubmit(items.id, grp.id, e.target.value) }} defaultValue={getTarget(items.id, grp.id)} />
											</td>
										})}
									</tr>
								})}
							</tbody>
						</Table> : ''}
					</Col>
				</Row>
			</div>
		</>
	);
};

export default TargetSetup;