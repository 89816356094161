import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import { integerIndMobile, lettersOnly } from '../../utils';

const EditType = (props) => {

	const context = useContext(PsContext);
	const [btnLoading, setBtnLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			setIsLoading(true);
			API.post('v1/event/edittype', $("#editform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Updated Successfully", "success");
					setIsLoading(false);
					props.afterFinish();
					props.afterList();
				}
				else {
					toast.error("Something Went Wrong", "danger");
					setIsLoading(false);
				}
			});
		}
		catch (error) {

		}
	}

	const getValue = (field) => {
		return props.edit[field] || '';
	}

	return (
		<>
			<div className="container">
				<Form action="" method="post" id="editform" validated={validated} onSubmit={handleSubmit} >
					<input type="hidden" name="id" value={props.edit.id} />
					<Row>
						<Col md={12}>
							<MDBInput
								label="Event Name"
								type="text"
								name="name"
								size="lg"
								required
								defaultValue={getValue('name')}
							/>
						</Col>
					</Row>
					<Row className='mt-2'>
						<Col md={12}>
							<MDBInput
								label="No Of Events"
								type="text"
								name="no_of_event"
								size="lg"
								required
								defaultValue={getValue('no_of_event')}
							/>
						</Col>
					</Row>
					<Row className='mt-2'>
						<Col md={12}>
							<MDBInput
								label="Participants Per Event"
								type="text"
								name="participants_per_event"
								size="lg"
								required
								defaultValue={getValue('participants_per_event')}
							/>
						</Col>
					</Row>
					<Col className="mt-3">
						<button className="btn btn-outline-black btn-block" type="submit" disabled={btnLoading}>
							{btnLoading ? 'Please wait..' : 'Update'}</button>
					</Col>
				</Form>
			</div>
		</>
	);
};

export default EditType; 