import React, { useState, useContext, useEffect } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button, Collapse } from "react-bootstrap";
import { Spin } from "antd";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import { baseUrl, groupByMultiple, momentDate } from "../../utils";

const DailyReportPb = (props) => {
  const context = useContext(PsContext);

  const [validated, setValidated] = useState(false);
  const [loader, setLoader] = useState(false);

  const [fromDate, setFromDate] = useState(
    momentDate(new Date(), "YYYY-MM-DD")
  );

  const [data, setData] = useState([]);
  const [dataView, setDataView] = useState([]);

  const loadData = () => {
    setLoader(true);
    var form = new FormData();
    form.append("group_id", props.match.params.groupid);
    form.append("staff_id", props.match.params.userid);
    form.append("from_date", fromDate);
    form.append("to_date", fromDate);

    API.post("v1/acc/day_report_pb", form).then((res) => {
      if (res["data"].errorcode == "200") {
        let m = groupByMultiple(res["data"].data, function (obj) {
          return [obj.account_by];
        });

        setData(res["data"].data);
        setDataView(m);
      } else {
        toast.error(res["data"].content || "Error");
      }
      setLoader(false);
    });
  };

  const linkClick = (page) => {
    if (props.onAction) props.onAction(page);
  };

  const ledgerGroupItem = (items) => {
    let item = items[0];
    let total = 0;
    let m = (
      <div className="ms-3">
        <b>{item.ledger_name}</b>
        <hr className="my-1" />
        <div className="ms-2">
          {items.map((j, k) => {
            total = parseFloat(total) + parseFloat(j.amount);
            return (
              <div className="my-2">
                {j.ledger_head_name}
                <span className="float-end">{j.amount}</span>
              </div>
            );
          })}
          <div className="text-end border-top mt-2">
            <b>Total : {parseFloat(total).toFixed(2)}</b>
          </div>
        </div>
      </div>
    );

    return m;
  };

  const ledgerGroup = (items) => {
    let item = items[0];
    let ledgers = groupByMultiple(items, function (obj) {
      return [obj.ledger_name];
    });

    return (
      <div>
        <a>
          <b>{item.account_by_name}</b>
        </a>
        <hr className="my-1" />
        {ledgers.map((j) => ledgerGroupItem(j))}
      </div>
    );
  };

  const resetAll = () => {
    setData([]);
    setDataView([]);
  };

  return (
    <>
      <Spin spinning={loader}>
        {dataView && dataView.length < 1 && (
          <>
            <Row className="mt-2">
              <Col>
                <label>Report Date</label>
                <Form.Control
                  type="date"
                  name="from_date"
                  value={fromDate}
                  max={momentDate(new Date(), "YYYY-MM-DD")}
                  onChange={(e) => setFromDate(e.target.value)}
                  required
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <div className="text-center">
                  <Button
                    type="submit"
                    className="btn-block"
                    disabled={loader}
                    onClick={(e) => loadData()}
                  >
                    Load Reports
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}

        {dataView && dataView.length > 0 && (
          <>
            <Row>
              <Col className="border">
                Report Date: <br />
                <b>{momentDate(fromDate, "DD/MM/YYYY")}</b>
              </Col>
              <Col className="border">
                <Button
                  variant="danger"
                  className="btn-block"
                  onClick={(e) => resetAll()}
                >
                  Reset
                </Button>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                {dataView.map((item, i) => {
                  return ledgerGroup(item);
                })}
              </Col>
            </Row>
          </>
        )}
      </Spin>
    </>
  );
};
export default withRouter(DailyReportPb);
