import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { lettersOnly, integerIndMobile } from '../../utils';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const tdStyle = { border: '1px solid grey', borderCollapse: 'collapse', padding: '3px' }

const PlasticPriceChart = (props) => {

	const context = useContext(PsContext);
	const [data, changeData] = useState([]);
	const [plasticData, setPlasticData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [type, setType] = useState('');

	const printDocument = () => {
		var content = document.getElementById("printdata");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}

	useEffect(() => {
		listdata();
	}, []);

	const listplastic = (event) => {
		event.preventDefault();
		try {
			API.post('v1/plastic/listchartplastic', $('#list').serialize()).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setPlasticData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {

		}
	};

	const listdata = () => {
		try {
			setIsLoading(true);
			var form = new FormData();
			form.append('acyear', context.acyear.id);
			API.post('v1/plastic/listpricechart', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {

		}
	};

	const getMonth = (acyear) => {

		//var dateStart = moment(acyear.start_date);
		//var dateEnd = moment(acyear.end_date);

		var dateStart = moment('2022-01-01');
		var dateEnd = moment(new Date());

		var timeValues = [];
		var result = [];
		while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
			result.push(<th width="5%" style={tdStyle}><b>{dateStart.format('MMM YYYY')}</b></th>)
			dateStart.add(1, 'month');
		}
		return result;
	}

	const getStock = (acyear, pid) => {

		//var dateStart = moment(acyear.start_date);
		//var dateEnd = moment(acyear.end_date);

		var dateStart = moment('2022-01-01');
		var dateEnd = moment(new Date());

		var timeValues = [];
		var result = [];
		while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {

			result.push(<td style={tdStyle}>{getPrice(pid, dateStart.format('YYYY-MM'))}</td>)
			dateStart.add(1, 'month');
		}
		return result;
	}

	const getPrice = (pid, month) => {
		var price = '';
		var d = data.filter(function (obj) {
			let date = moment(obj.setup_date).format('YYYY-MM');
			return (obj.plastic_name_id == pid && date == month);
		});
		if (d.length > 0) {
			price = d[0]['price'];
		}
		return price;
	}

	const getPlasticType = (e) => {
		setType(e.target.value);
		listplastic();
	}

	return (
		<>
			<div className="container">
				<Navbar bg="white" expand="md">
					<Container>
						<Navbar.Brand>Plastic Price Chart</Navbar.Brand>
						<Nav>
							<Button type="button" variant="warning" onClick={printDocument}>Print</Button>&nbsp;
							<ReactHTMLTableToExcel
								id="test-table-xls-button"
								className="download-table-xls-button btn btn-info"
								table="table-to-xls"
								filename="Price Chart"
								sheet="pricechart"
								buttonText="Export as XLS" />
						</Nav>
					</Container>
				</Navbar><br />
				<Row style={{ margin: '0px' }}>
					<Form onSubmit={listplastic} method='post' id="list">
						<Row>
							<Col md={9}>
							</Col>
							<Col md={2} className='ms-0'>
								<select className='form-control sm' name="type">
									<option value="">Select Type</option>
									<option value="plastic kg">Plastic Kg</option>
									<option value="others kg">Non Plastic Kg</option>
									<option value="others pcs">Non Plastic Piece</option>
								</select>
							</Col>
							<Col md={1}>
								<Button type="submit" size='sm' className='mt-1' variant="success">Search</Button>
							</Col>
						</Row>
					</Form>
					<Col md={12} id="printdata" className='mt-2'>
						{plasticData.length > 0 ? <table className="table mt-10 table-bordered" style={tdStyle} id="table-to-xls">
							<thead className="text-center">
								<tr>
									<th width="2%" style={tdStyle}><b>S.No</b></th>
									<th width="10%" style={tdStyle}><b>Plastic Name</b></th>
									<th width="3%" style={tdStyle}><b>Short Name</b></th>
									{getMonth(context.acyear)}
								</tr>
							</thead>
							<tbody className="text-center">
								{plasticData.map((item, i) => {
									return <tr>
										<td style={tdStyle}>{i + 1}</td>
										<td style={tdStyle} align="left">{item.plastic_name}</td>
										<td style={tdStyle} align="left">{item.short_name}</td>
										{getStock(context.acyear, item.id)}
									</tr>
								})}
							</tbody>
						</table> : ''}
					</Col>
					<iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
				</Row>

			</div>
		</>
	);
};
export default PlasticPriceChart;