import React, { useState, useContext, useEffect } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button, Table, Modal } from "react-bootstrap";
import { Spin } from "antd";

import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import {
  baseUrl,
  getAscSortOrder,
  groupByMultiple,
  momentDate,
} from "../../utils";
import axios from "axios";
import moment from "moment";
const styles = {
  table: {
    border: "1px solid black",
    borderCollapse: "collapse",
  },
  tr: {
    border: "1px solid black",
    borderCollapse: "collapse",
  },
  trBalanceGreen: {
    border: "1px solid black",
    borderCollapse: "collapse",
    background: "#95de64",
  },
  trBalanceRed: {
    border: "1px solid black",
    borderCollapse: "collapse",
    background: "#ffa39e",
  },
  trTotal: {
    border: "1px solid black",
    borderCollapse: "collapse",
    background: "#d9d9d9",
  },
  td: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
  },
  tdright: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
    textAlign: "right",
  },
  tdBg: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
    backgroundColor: "rgb(224 247 211)",
  },
  tdRed: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
    backgroundColor: "rgb(245 227 221)",
    fontStyle: "italic",
    fontWeight: "bold",
  },
};
const FinancialSummary = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState([]);

  const [fromDate, setFromDate] = useState(
    momentDate(new Date(), "YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(momentDate(new Date(), "YYYY-MM-DD"));

  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);

  const [dataView, setDataView] = useState([]);

  const [paymentType, setPaymentType] = useState(false);
  const [groups, setGroups] = useState([]);

  const [groupId, setGroupId] = useState(0);

  const [income, setIncome] = useState([]);
  const [expense, setExpense] = useState([]);
  const [cashInHand, setCashInHand] = useState(0);
  const [bankBalance, setBankBalance] = useState(0);

  useEffect(() => {
    loadGroups();
    //loadData();
  }, []);

  const loadGroups = () => {
    setLoader(true);

    API.get("v1/acc/groups").then((res) => {
      if (res["data"].errorcode == "200") {
        setGroups(res["data"].data);
      }
      setLoader(false);
    });
  };

  const loadData = () => {
    setLoader(true);
    setData([]);
    setDataView([]);
    var form = new FormData();
    form.append("group_id", groupId);
    form.append("staff_id", 0);
    form.append("from_date", fromDate);
    form.append("to_date", toDate);

    API.post("v1/acc/summary", form).then((res) => {
      if (res["data"].errorcode == "200") {
        let ledgers = res["data"].ledgers;

        let data = res["data"].data;
        let opening_balances = res["data"].op_data;
        let summary = data;
        summary.forEach((obj) => {
          obj.opening_balance = 0;
        });

        opening_balances.forEach((item) => {
          let d = summary.findIndex(
            (obj) =>
              obj.account_by == item.account_by &&
              obj.ledger_head_id === item.ledger_head_id
          );
          if (d > -1) {
            summary[d].opening_balance = item.op_amount;
          } else {
            summary.push({
              account_by: item.account_by,
              account_by_group_id: item.account_by_group_id,
              account_by_group_name: item.account_by_group_name,
              account_by_name: item.account_by_name,
              ledger_group_id: item.ledger_group_id,
              ledger_group_name: item.ledger_group_name,
              ledger_head_id: item.ledger_head_id,
              ledger_head_name: item.ledger_head_name,
              net_amount: "0",
              opening_balance: item.op_amount,
            });
          }
        });
        console.log("summary", summary);
        summary = summary.sort(
          (a, b) => a.ledger_head_name - b.ledger_head_name
        );
        setSummary(summary);
        setData(res["data"].data);
      } else {
        toast.error(res["data"].content || "Error");
      }
      setLoader(false);
    });
  };

  const zeroAmount1 = (amt, withZero = false) =>
    amt && parseInt(amt) != 0 ? Math.abs(amt).toFixed(0) : withZero ? "0" : "0";

  const zeroAmount = (amt, withZero = false) => {
    if (amt && parseInt(amt) !== 0) {
      const formattedAmt = Math.abs(amt).toLocaleString("en-IN");
      return formattedAmt;
    } else {
      return withZero ? "0" : "0";
    }
  };

  const getIncome = (item) => {
    return (
      <tr style={styles.tr}>
        <td style={styles.td}>{item.ledger_head_name}</td>
        <td style={styles.tdBg} align="right" width="150">
          {zeroAmount(parseInt(item.ledger_opening_balance), true)}
        </td>
        <td style={styles.tdBg} align="right" width="150">
          {zeroAmount(parseInt(item.total_credit), true)}
        </td>
        <td style={styles.tdBg} align="right" width="150">
          {zeroAmount(
            parseInt(item.ledger_opening_balance) + parseInt(item.total_credit),
            true
          )}
        </td>
      </tr>
    );
  };
  const getTotal = (account_by, type) => {
    var d = [];
    /* 	if (type === 'BANK')
				d = summary.filter(obj => obj.account_by_name === account_by && obj.ledger_group_name !== 'CASH');
			else */
    d = summary.filter(
      (obj) =>
        obj.account_by_name === account_by && obj.ledger_group_name === type
    );

    var opTotal = 0;
    var curTotal = 0;

    d.forEach((item) => {
      if (item.opening_balance)
        opTotal = opTotal + parseInt(item.opening_balance);
      curTotal = curTotal + parseInt(item.net_amount);
    });
    if (type == "INCOME") {
      return (
        <tr style={styles.trTotal}>
          <th style={styles.td}>Total</th>
          <th style={styles.tdright}>{zeroAmount(opTotal)}</th>
          <th style={styles.tdright}>{zeroAmount(curTotal)}</th>
          <th style={styles.tdright}>
            {zeroAmount(parseInt(opTotal) + parseInt(curTotal), true)}
          </th>
        </tr>
      );
    } else if (type == "EXPENSE") {
      var amountInHand = getBalance(account_by, "object");
      return (
        <tr style={styles.trTotal}>
          <th style={styles.td}>Total</th>
          <th style={styles.tdright}>
            {(opTotal + amountInHand.opBalance).toLocaleString("en-IN")}
          </th>
          <th style={styles.tdright}>
            {(curTotal + amountInHand.curBalance).toLocaleString("en-IN")}
          </th>
          <th style={styles.tdright}>
            {(
              parseInt(opTotal) +
              parseInt(curTotal) +
              amountInHand.opBalance +
              amountInHand.curBalance
            ).toLocaleString("en-IN")}
          </th>
        </tr>
      );
    }
  };
  const getBalance = (account_by, resultType = "tr") => {
    var d = [];
    /* 	if (type === 'BANK')
				d = summary.filter(obj => obj.account_by_name === account_by && obj.ledger_group_name !== 'CASH');
			else */
    d = summary.filter(
      (obj) =>
        obj.account_by_name === account_by && obj.ledger_group_name === "INCOME"
    );

    var opTotal = 0;
    var curTotal = 0;

    d.forEach((item) => {
      if (item.opening_balance)
        opTotal = opTotal + parseInt(item.opening_balance);
      curTotal = curTotal + parseInt(item.net_amount);
    });
    //SUBSTRACT EXPENSE
    d = summary.filter(
      (obj) =>
        obj.account_by_name === account_by &&
        obj.ledger_group_name === "EXPENSE"
    );

    var opTotal1 = 0;
    var curTotal1 = 0;

    d.forEach((item) => {
      if (item.opening_balance)
        opTotal1 = opTotal1 + parseInt(item.opening_balance);
      curTotal1 = curTotal1 + parseInt(item.net_amount);
    });
    var finalOpTotal = zeroAmount1(opTotal) - zeroAmount1(opTotal1);
    var finalCurTotal = zeroAmount1(curTotal) - zeroAmount1(curTotal1);

    if (resultType == "tr") {
      return (
        <tr style={styles.trBalanceGreen}>
          <th style={styles.td}>
            {account_by == "CASH" ? "Cash in Hand" : "Cash in Bank"}
          </th>
          <th style={styles.tdright}>{zeroAmount(finalOpTotal.toFixed(0))}</th>
          <th style={styles.tdright}>{zeroAmount(finalCurTotal.toFixed(0))}</th>
          <th style={styles.tdright}>
            {zeroAmount(parseInt(finalOpTotal) + parseInt(finalCurTotal))}
          </th>
        </tr>
      );
    } else {
      return {
        opBalance: finalOpTotal,
        curBalance: finalCurTotal,
      };
    }
  };
  return (
    <div className="container bg-transparent">
      <Spin spinning={loader}>
        <Card style={{ position: "sticky", top: "50px" }}>
          <Card.Body>
            <Row>
              <Col md={12}>
                <h4>Financial Summary</h4>
                <hr />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={4}>
                <label style={{ fontSize: "11px" }}>Group Name</label>
                <Form.Control
                  as="select"
                  name="group_id"
                  size="sm"
                  onChange={(e) => setGroupId(e.target.value)}
                  required
                >
                  <option value="0">-All Groups-</option>
                  {groups.map((item) => (
                    <option value={item.id}> {item.grp_name} </option>
                  ))}
                  ;
                </Form.Control>
              </Col>
              <Col md={2}>
                <label style={{ fontSize: "11px" }}>From Date</label>
                <Form.Control
                  type="date"
                  max={momentDate(new Date(), "YYYY-MM-DD")}
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  size="sm"
                />
              </Col>
              <Col md={2}>
                <label style={{ fontSize: "11px" }}>To Date</label>
                <Form.Control
                  type="date"
                  max={momentDate(new Date(), "YYYY-MM-DD")}
                  min={fromDate}
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  size="sm"
                />
              </Col>
              <Col md={2}>
                <Button
                  type="button"
                  size="sm"
                  className="mt-4 btn-block"
                  onClick={loadData}
                >
                  View
                </Button>
              </Col>
            </Row>
            <table width="100%">
              <thead>
                <tr style={styles.tr}>
                  <th colSpan={9} style={{ textAlign: "center" }}>
                    WPEGS: FINANCIAL SUMMARY
                  </th>
                </tr>

                <tr style={styles.tr}>
                  <th colSpan={9} style={{ textAlign: "center" }}>
                    CASH
                  </th>
                </tr>
                <tr>
                  <th colspan={4}>
                    <table width="100%" algin="center">
                      <tr style={styles.tr}>
                        <th style={styles.td}>
                          <span style={{ fontWeight: "bold", color: "green" }}>
                            Income
                          </span>
                        </th>
                        <th style={styles.td}>Upto previous period</th>
                        <th style={styles.td}>This period</th>
                        <th style={styles.td}>Total</th>
                      </tr>
                      {summary
                        .filter(
                          (obj) =>
                            obj.account_by_name === "CASH" &&
                            obj.ledger_group_name === "INCOME"
                        )
                        .sort((a, b) =>
                          a.ledger_head_name.localeCompare(b.ledger_head_name)
                        )
                        .map((item) => {
                          return (
                            <tr style={styles.tr}>
                              <th style={styles.td}>{item.ledger_head_name}</th>
                              <th style={styles.tdright}>
                                {zeroAmount(
                                  parseInt(item.opening_balance),
                                  true
                                )}
                              </th>
                              <th style={styles.tdright}>
                                {zeroAmount(parseInt(item.net_amount), true)}
                              </th>
                              <th style={styles.tdright}>
                                {zeroAmount(
                                  parseInt(item.opening_balance) +
                                    parseInt(item.net_amount),
                                  true
                                )}
                              </th>
                            </tr>
                          );
                        })}
                      {getTotal("CASH", "INCOME")}
                    </table>
                  </th>
                  <th></th>
                  <th colspan={4} style={{ verticalAlign: "top" }}>
                    <table width="100%" algin="center">
                      <tr style={styles.tr}>
                        <th style={styles.td}>
                          <span style={{ fontWeight: "bold", color: "red" }}>
                            Expenses
                          </span>
                        </th>
                        <th style={styles.td}>Upto previous period</th>
                        <th style={styles.td}>This period</th>
                        <th style={styles.td}>Total</th>
                      </tr>
                      {summary
                        .filter(
                          (obj) =>
                            obj.account_by_name === "CASH" &&
                            obj.ledger_group_name === "EXPENSE"
                        )
                        .sort((a, b) =>
                          a.ledger_head_name.localeCompare(b.ledger_head_name)
                        )
                        .map((item) => {
                          return (
                            <tr style={styles.tr}>
                              <th style={styles.td}>{item.ledger_head_name}</th>
                              <th style={styles.tdright}>
                                {zeroAmount(
                                  parseInt(item.opening_balance),
                                  true
                                )}
                              </th>
                              <th style={styles.tdright}>
                                {zeroAmount(parseInt(item.net_amount), true)}
                              </th>
                              <th style={styles.tdright}>
                                {zeroAmount(
                                  parseInt(item.opening_balance) +
                                    parseInt(item.net_amount),
                                  true
                                )}
                              </th>
                            </tr>
                          );
                        })}
                      {getBalance("CASH")}
                      {getTotal("CASH", "EXPENSE")}
                    </table>
                  </th>
                </tr>
                <tr style={styles.tr}>
                  <th colSpan={9} style={{ textAlign: "center" }}>
                    BANK
                  </th>
                </tr>
                <tr>
                  <th colspan={4}>
                    <table width="100%" algin="center">
                      <tr style={styles.tr}>
                        <th style={styles.td}>
                          <span style={{ fontWeight: "bold", color: "green" }}>
                            Income
                          </span>
                        </th>
                        <th style={styles.td}>Upto previous period</th>
                        <th style={styles.td}>This period</th>
                        <th style={styles.td}>Total</th>
                      </tr>
                      {summary
                        .filter(
                          (obj) =>
                            obj.account_by_name === "BANK" &&
                            obj.ledger_group_name === "INCOME"
                        )
                        .sort((a, b) =>
                          a.ledger_head_name.localeCompare(b.ledger_head_name)
                        )
                        .map((item) => {
                          return (
                            <tr style={styles.tr}>
                              <th style={styles.td}>{item.ledger_head_name}</th>
                              <th style={styles.tdright}>
                                {zeroAmount(
                                  parseInt(item.opening_balance),
                                  true
                                )}
                              </th>
                              <th style={styles.tdright}>
                                {zeroAmount(parseInt(item.net_amount), true)}
                              </th>
                              <th style={styles.tdright}>
                                {zeroAmount(
                                  parseInt(item.opening_balance) +
                                    parseInt(item.net_amount),
                                  true
                                )}
                              </th>
                            </tr>
                          );
                        })}
                      {getTotal("BANK", "INCOME")}
                    </table>
                  </th>
                  <th></th>
                  <th colspan={4} style={{ verticalAlign: "top" }}>
                    <table width="100%" algin="center">
                      <tr style={styles.tr}>
                        <th style={styles.td}>
                          <span style={{ fontWeight: "bold", color: "red" }}>
                            Expenses
                          </span>
                        </th>
                        <th style={styles.td}>Upto previous period</th>
                        <th style={styles.td}>This period</th>
                        <th style={styles.td}>Total</th>
                      </tr>
                      {summary
                        .filter(
                          (obj) =>
                            obj.account_by_name === "BANK" &&
                            obj.ledger_group_name === "EXPENSE"
                        )
                        .sort((a, b) =>
                          a.ledger_head_name.localeCompare(b.ledger_head_name)
                        )
                        .map((item) => {
                          return (
                            <tr style={styles.tr}>
                              <th style={styles.td}>{item.ledger_head_name}</th>
                              <th style={styles.tdright}>
                                {zeroAmount(
                                  parseInt(item.opening_balance),
                                  true
                                )}
                              </th>
                              <th style={styles.tdright}>
                                {zeroAmount(parseInt(item.net_amount), true)}
                              </th>
                              <th style={styles.tdright}>
                                {zeroAmount(
                                  parseInt(item.opening_balance) +
                                    parseInt(item.net_amount),
                                  true
                                )}
                              </th>
                            </tr>
                          );
                        })}
                      {getBalance("BANK")}
                      {getTotal("BANK", "EXPENSE")}
                    </table>
                  </th>
                </tr>
              </thead>
            </table>
          </Card.Body>
        </Card>
      </Spin>
    </div>
  );
};
export default withRouter(FinancialSummary);
