import React, { useState, useContext } from "react";
import { withRouter, useHistory, Link, Switch, Route } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import { baseUrl } from "../../utils";

import PaymentIn from "./paymentIn";
import PaymentOut from "./paymentOut";
import DailyReport from "./dailyReport";

import HomeGroupAccount from "./homeGroupAccount";
import { Layout } from "antd";

const MobileAccountsGroup = (props) => {
  const context = useContext(PsContext);
  const [page, setPage] = useState("home_group_account");

  return (
    <Layout onAction={(p) => setPage(p)} page={page}>
      {page == "home_group_account" && (
        <HomeGroupAccount onAction={(p) => setPage(p)} />
      )}

      {page == "payment_in" && (
        <PaymentIn onAction={(p) => setPage(p)} {...props} />
      )}

      {page == "payment_out" && (
        <PaymentOut onAction={(p) => setPage(p)} {...props} />
      )}

      {page == "day_report" && (
        <DailyReport onAction={(p) => setPage(p)} {...props} />
      )}
    </Layout>
  );
};
export default withRouter(MobileAccountsGroup);
