import Axios from 'axios';
import toast from 'react-hot-toast';
import { Select } from 'antd';
import crypto from 'crypto';
import API from '../utils/api'
export const listCollections = async () => {
	const form = new FormData();
	return new Promise((resolve, reject) => {
		let url = `v1/admin/list-collections`;
		Axios.get(url, form)
			.then(function ({ data }) {
				if (data.status !== '1') {
					toast.error(data.message || 'Error');
				}
				resolve(data.data);
			})
			.catch(function (error) {
				//resolve([]);
				return [];
			});
	});
}
export const collectionOptions = (collectionData, collectionName) => {
	const { Option } = Select;
	if (collectionData && collectionData.length > 0) {
		let m = collectionData.find(item => item.name === collectionName)
		if (m) {
			let cData = m.collections.split(",")

			return cData.map(item => <Option key={item} value={item}>{item}</Option>)
		} else return <Option value=''>Not in Database</Option>

	}
};
export const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
};


export const encrypt = (textToEncrypt) => {
	let encryptionMethod = 'AES-256-CBC';
	let secret = "My32charPasswordAndInitVectorPro"; //must be 32 char length
	let iv = secret.substr(0, 16);
	var encryptor = crypto.createCipheriv(encryptionMethod, secret, iv);
	let encryptedMessage = encryptor.update(textToEncrypt, 'utf8', 'base64') + encryptor.final('base64');
	return encryptedMessage;
}
export const decrypt = (encryptedMessage) => {
	var encryptionMethod = 'AES-256-CBC';
	var secret = "My32charPasswordAndInitVectorPro"; //must be 32 char length
	var iv = secret.substr(0, 16);
	var decryptor = crypto.createDecipheriv(encryptionMethod, secret, iv);
	var decryptedMessage = decryptor.update(encryptedMessage, 'base64', 'utf8') + decryptor.final('utf8');
	return decryptedMessage;
}
export const getWhereClause = (filterColumns, withWhere = false) => {
	var whereClause = "";
	if (filterColumns) {
		var filterClause = [];
		//propvalue is array use = or in pending code
		Object.entries(filterColumns).forEach(([key, value], index) => {
			if (Array.isArray(filterColumns[key])) {
				if (value && value.length > 0) {
					//remove all empty entries
					let nonEmptyValues = [];
					value.forEach((item, index) => {
						if (item !== '') nonEmptyValues.push(value)
					})
					filterClause.push(key + " IN ('" + nonEmptyValues.join("','") + "')")
				}
			}
			else
				if (value && value !== '') {
					if (key.includes("date"))
						filterClause.push("DATE(" + key + ")='" + value + "'")
					else
						filterClause.push(key + "='" + value + "'")
				}
		})
		if (filterClause.length > 0) {
			if (withWhere)
				whereClause = " WHERE " + filterClause.join(" AND ");
			else
				whereClause = " AND " + filterClause.join(" AND ");
		}
	}

	return whereClause;
}
export const apiRequest = async (reqData, mode="prod",postForm=false) => {

	return new Promise((resolve, reject) => {
		var form = new FormData();
		var url = "";
		if (typeof reqData === 'string' || reqData instanceof String) {

			url = reqData;
		} 
		else {
			
			url = 'accounts/db_query';
		}
		if (postForm) {

			for (const [key, value] of postForm) {
				form.append(key,value)
              }
		} 
		else {
			form.append('queries', encrypt(JSON.stringify(reqData)));
		}
		if (mode === "dev")
			form.append('mode', "dev");

		API.post(url, form).then(res => {
			if (parseInt(res['data'].status) === 1) {
				resolve(res['data'].data);
			}
			else {

				reject(res['data'].message || 'Api Call Error');
			}

		});
	});
}
