import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { triggerFocus } from 'antd/lib/input/Input';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Spin } from 'antd';

const tdStyle = { border: '1px solid grey', borderCollapse: 'collapse' }

const EventReport = (props) => {

	const context = useContext(PsContext);
	const [loading, setIsLoading] = useState(false);
	const [data, changeData] = useState([]);
	const [eventData, setEventData] = useState([]);
	const [eventTypes, setEventTypes] = useState([]);
	const [fromdate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
	const [todate, setToDate] = useState(moment().format("YYYY-MM-DD"));

	const printDocument = () => {
		var content = document.getElementById("printdata");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}

	useEffect(() => {
		listdata();
		listeventtypes();
		listeventcount();
	}, []);

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/village').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const listeventtypes = () => {
		try {
			setIsLoading(true);
			API.get('v1/event/listtype').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setEventTypes(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const listeventcount = () => {
		try {
			setIsLoading(true);
			var form = new FormData();
			form.append('fromdate', fromdate);
			form.append('todate', todate);
			API.post('v1/event/eventcountbygroup', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setEventData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const getCount = (gid, tid) => {
		var d = eventData;
		var count = 0;

		if (gid == 'all') {
			var dat = d.filter(function (obj) {

				if (obj.eventtype == tid) {
					count += parseInt(obj.ecount);
				}
			});
		}
		else {

			/*var index = d.findIndex(function (obj) {
				return (obj.group_id == gid && obj.eventtype == tid);
			});
			if (index > -1) {
				count = d[index].ecount;
			}*/

			let m = d.filter(item => item.place == gid && item.eventtype == tid);
			return m.length;

		}

		return count;

	}

	const onFromChange = (event) => {

		var fromdate = $("#fromdate").val();
		setFromDate(fromdate);
		var toMinDate = moment(fromdate).format("YYYY-MM-DD");
		if (fromdate != '') {
			$("#todate").attr('min', toMinDate);
		}
	};

	const getRowTotal = (gid) => {
		var d = eventData;
		let total = 0;
		let m = d.filter(item => item.place == gid);
		return m.length;
	};

	const getTotalRecords = (gid) => {
		var d = eventData;
		let total = 0;
		d.map(item => total = parseFloat(total) + parseFloat(item.ecount));
		return parseFloat(total).toFixed(0);
	};

	return (
		<div className="container">
			<Navbar bg="white" expand="lg">
				<Container>
					<Navbar.Brand >Village Wise Report</Navbar.Brand>
				</Container>
			</Navbar>
			<Row style={{ margin: '10px' }}>
				<Col md={2}>
					<label>From Date:</label>
					<MDBInput
						type="date"
						id="fromdate"
						defaultValue={fromdate}
						onChange={onFromChange}
					/>
				</Col>
				<Col md={2}>
					<label>To Date:</label>
					<MDBInput
						type="date"
						id="todate"
						defaultValue={todate}
						onChange={(e) => { setToDate(e.target.value) }}
					/>
				</Col>
				<Col md={4}>
					<Button type="submit" className="mt-3" onClick={listeventcount}>Submit</Button>&nbsp;
					<Button type="button" variant="warning" className="mt-3" onClick={printDocument}>Print</Button>&nbsp;
					<ReactHTMLTableToExcel
						id="test-table-xls-button"
						className="download-table-xls-button btn btn-info mt-3"
						table="table-to-xls" 
						filename="Event Group Wise Report"
						sheet="eventgroupwise"
						buttonText="Export as XLS" />
				</Col>
				<Col md={12} id="printdata" className="table-responsive mt-3">
					<Spin spinning={loading} >
						<div style={{ width: '1200px', height: '600px', overflow: 'auto' }}>
							<table className="table mt-10 table-bordered" style={tdStyle} id="table-to-xls">
								<thead style={{ position: 'sticky', top: '0', backgroundColor: '#E5E4E2', color: 'black', zIndex: '1' }} className="thead-dark" >
									<tr>
										<th width="5%" style={{ border: '1px solid grey', borderCollapse: 'collapse', position: 'sticky', top: '0', zIndex: '1000' }} scope='col'><b>S.No</b></th>
										<th width="10%" style={{ border: '1px solid grey', borderCollapse: 'collapse', position: 'sticky', top: '0', zIndex: '1000' }} scope='col'><b>Village Name</b></th>
										{eventTypes.map((item, i) => {
											return <th width="10%" style={{ border: '1px solid grey', borderCollapse: 'collapse', position: 'sticky', top: '0' }} ><b>{item.name}</b></th>
										})}
										<th width="10%">Total</th>
									</tr>
								</thead>
								<tbody className="text-center">
									{data.map((item, i) => {
										return <tr>
											<td style={{ border: '1px solid grey', borderCollapse: 'collapse', position: 'sticky', left: '1', backgroundColor: '#E5E4E2' }} >{i + 1}</td>
											<td align="left" style={{ border: '1px solid grey', borderCollapse: 'collapse', position: 'sticky', left: '0', backgroundColor: '#E5E4E2' }} >{item.villagename}</td>
											{eventTypes.map((event, i) => {
												return <td width="10%" style={tdStyle}>{getCount(item.id, event.id)}</td>
											})}
											<td><b style={{ fontWeight: '600' }}>{getRowTotal(item.id)}</b></td>
										</tr>
									})}
									<tr>
										<td style={{ border: '1px solid grey', borderCollapse: 'collapse', position: 'sticky', left: '1', backgroundColor: '#E5E4E2', zIndex: '1' }}></td>
										<td style={{ border: '1px solid grey', borderCollapse: 'collapse', position: 'sticky', left: '0', backgroundColor: '#E5E4E2', zIndex: '1' }} align="right"><b>Total</b></td>
										{eventTypes.map((event, i) => {
											return <td width="10%" style={tdStyle}><b>{getCount('all', event.id)}</b></td>
										})}
										<td><b style={{ fontWeight: '600' }} >{getTotalRecords()}</b></td>
									</tr>
								</tbody>
							</table>
						</div>
					</Spin>
				</Col>
				<iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
			</Row>
		</div >
	);
}
export default EventReport;