import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';

const ViewGroup = (props) => {

	return (
		<>
			<div class="container">
				<Row>
					<Col md={{ span: 12, offset: 0 }}>
						<Table bordered>
							<tbody>
								<tr>
									<th><b>Group Name:</b></th><td colspan="3">{props.data.grp_name}</td>
									<th><b>Total Members:</b></th><td colspan="3">{props.data.total_members}</td>
								</tr>
								<tr>
									<th><b> Block: </b></th><td colspan="3">{props.data.block}</td>
									<th><b> Date Of Start: </b></th><td colspan="3">{props.data.date_of_start}</td>
								</tr>
								<tr>
									<th><b> President Name: </b></th><td colspan="3">{props.data.pre_name}</td>
									<th><b> President Mobile: </b></th><td colspan="3">{props.data.pre_mobile}</td>
								</tr>
								<tr>
									<th><b> Secretary Name: </b></th><td colspan="3">{props.data.sec_name}</td>
									<th><b> Secretary Mobile: </b></th><td colspan="3">{props.data.sec_mobile}</td>
								</tr>
								<tr>
									<th><b> Treasurer name: </b></th><td colspan="3">{props.data.trusure_name}</td>
									<th><b> Treasurer Mobile: </b></th><td colspan="3">{props.data.trusure_mobile}</td>
								</tr>
								<tr>
									<th><b> App Access1: </b></th><td colspan="3">{props.data.app_access1}</td>
									<th><b> Plastic Bank Active: </b></th><td colspan="3">{props.data.bank_active}</td>
								</tr>
								<tr>
									<th><b> Address: </b></th><td colspan="3">{props.data.address}</td>
									<th><b> Village: </b></th><td colspan="3">{props.data.village_name}</td>
								</tr>
								<tr>
									<th><b> Address: </b></th><td colspan="3">{props.data.bank_name}</td>
									<th><b> Village: </b></th><td colspan="3">{props.data.bank_ac_no}</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default ViewGroup;