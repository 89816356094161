import { Spin } from 'antd';
import moment from 'moment';
import React, { useContext, useState, useEffect} from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import { groupByMultiple, momentDate, getAscSortOrder } from '../../utils';
import API from '../../utils/api';


const styles = {
	table: {
		border: '1px solid black',
		borderCollapse: 'collapse'
	},
	tr: {
		border: '1px solid black',
		borderCollapse: 'collapse',
	},
	td: {
		border: '1px solid black',
		borderCollapse: 'collapse',
		padding: '5px',
		fontSize: '12px',
	},
	tdBg: {
		border: '1px solid black',
		borderCollapse: 'collapse',
		padding: '5px',
		backgroundColor: 'rgb(224 247 211)',
	},
	tdRed: {
		border: '1px solid black',
		borderCollapse: 'collapse',
		padding: '5px',
		backgroundColor: 'rgb(245 227 221)',
		fontStyle: 'italic',
		fontWeight: 'bold',
	},
};

const HbcgSurvey=(props)=>{

	const context = useContext(PsContext);
	const [loader, setLoader] = useState(false);
	
	const [fromDate, setFromDate] = useState();
	const [toDate, setToDate] = useState(momentDate(new Date(),'YYYY-MM-DD'));
	const [villageId, setVillageId] = useState(0);

	const [data, setData] = useState([]);
	const [dataView, setDataView] = useState([]);
	const [indicators, setIndicators] = useState([]);
	const [village, setVillage] = useState([]);

	useEffect(()=>{
		//loadData();
	},[]);

	const loadVillage=()=>{
		API.get('v1/group/village').then(res=>{
			if(res['data'].errorcode=='200'){
				let d = res['data'].data;
				setVillage(d.sort(getAscSortOrder('villagename')));
			}
		});
	}

	const loadData=()=>{

		setLoader(true);
		setData([]);
		setDataView([]);
		var form = new FormData();
		form.append('staff_id', 0);
		form.append('date_from', fromDate);
		form.append('date_to', toDate);

		API.post('v1/user/hbc_report',form).then(res=>{
			if(res['data'].errorcode=='200'){
				let d = res['data'].data;
				setData(res['data'].data);
				let m = groupByMultiple(d, function(obj){
					return [obj.idate];
				});
				setDataView(m);
				setIndicators(res['data'].dataquestion);
			}	
			else{
				toast.error(res['data'].content || 'Error');
			}
			setLoader(false);
		});
	}

	const getIndicatorScored=(indicator, idate)=>{
		let m = data.filter(item => item.indicator_id==indicator.id && moment(item.idate).isSame(idate));
		let total = 0;
		m.map(item => total += parseFloat(item.rateing));
		let rv = ((parseFloat(total) / (parseFloat(m.length))) * 100).toFixed(2);
		return !isNaN(rv) ? rv : 0;
	}

	const getPercentage=(indicator, idate)=>{
		let m = data.filter(item => item.indicator_id==indicator.id && moment(item.idate).isSame(idate));
		let total = 0;
		m.map(item => total += parseFloat(item.rateing));
		
		let rv = ((parseFloat(total) / (parseFloat(m.length)*5)) * 100).toFixed(2);
		return !isNaN(rv) ? rv : 0;
	};

	return(
		<div className='container'>
			<Card>
				<Card.Body>
					<Spin spinning={loader}>

					<h5>HBCG Survey</h5>
					<hr />
					
					<Row className="mb-3">						
						<Col md={2}>
							<label style={{fontSize: '11px'}}>Date</label>
							<Form.Control
								type="date"
								max={momentDate(new Date(),'YYYY-MM-DD')}
								value={fromDate}
								onChange={e=>setFromDate(e.target.value)}
								size="sm"
							/>
						</Col>

						<Col md={2}>
							<label style={{fontSize: '11px'}}>Village</label>
							<Form.Control
								type="date"
								max={momentDate(new Date(),'YYYY-MM-DD')}
								value={fromDate}
								onChange={e=>setFromDate(e.target.value)}
								size="sm"
							/>
						</Col>

						{/*<Col md={2}>
							<label style={{fontSize: '11px'}}>To Date</label>
							<Form.Control
								type="date"
								max={momentDate(new Date(),'YYYY-MM-DD')}
								min={fromDate}
								value={toDate}
								onChange={e=>setToDate(e.target.value)}
								size="sm"
							/>
						</Col>*/}
						<Col md={1}>
							<Button 
								type="button"
								size="sm"
								className="mt-4 btn-block"
								onClick={loadData}
							>
								View
							</Button>
						</Col>
					</Row>
					
					{data.length>0 && (<Row>
						<Col md={12}>
							<table width="100%" align="center" style={styles.table}>
								<thead>
									<tr style={styles.tr}>
										<th style={styles.td} >
											Indicator / Date
										</th>
										{dataView.map((item,i)=>{
											return <th colSpan={2} style={styles.td} >
												{momentDate(item[0].idate,'DD-MM-YYYY')}
											</th>
										})}
									</tr>
								</thead>
								<tbody>
									<tr style={styles.tr} >
										<td style={styles.td} >Report Format</td>
										{dataView.map((item,i)=>{
											return <React.Fragment>
												<td style={styles.td} width="50px" >SCORE</td>
												<td style={styles.td} width="50px">%</td>
											</React.Fragment>
										})}
									</tr>
									{indicators.map((item,i)=>{
										return <tr key={i} style={styles.tr} >
											<td style={styles.td} >{item.indicators}</td>
											{dataView.map((ind,i)=>{
												return <React.Fragment>
													<td style={styles.td} >{getIndicatorScored(item, ind[0].idate)}</td>
												 	<td style={styles.td} >{getPercentage(item, ind[0].idate)}</td>
												</React.Fragment>
											})}
										</tr>
									})}
								</tbody>
							</table>
						</Col>
					</Row>)}

					</Spin>
				</Card.Body>
			</Card>
		</div>
	)
};

export default HbcgSurvey;
