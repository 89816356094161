import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { decimalKeyPress, integerIndMobile, baseUrl } from '../../utils';
import axios from 'axios';
import moment from 'moment';

const MaterialCollection = () => {

	const context = useContext(PsContext);
	const [data, changeData] = useState([]);
	const [eventData, setEventData] = useState([]);
	const [groupData, setGroupData] = useState([]);
	const [vendorData, setVendorData] = useState([]);
	const [buyingData, setBuyingData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [type, setType] = useState('event');

	useEffect(() => {
		//listdata();
		listgroup();
		listevent();
		listvendor();
	}, []);

	const listvendor = () => {
		try {
			setIsLoading(true);
			API.get('v1/vendor/listvendor').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setVendorData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {

		}
	};

	const listgroup = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listgroup').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setGroupData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {

		}
	};

	const listevent = () => {
		try {
			setIsLoading(true);
			API.get('v1/event/listevent').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setEventData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {

		}
	};

	const listdata = (e) => {
		try {
			setIsLoading(true);
			var form = new FormData();
			form.append('acyear', context.acyear.id);
			form.append('materialType', e);
			API.post('v1/plastic/listplastic', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {

		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			if (buyingData.length > 0) {
				setBtnLoading(true);
				console.log($("#addform").serialize());
				API.post('v1/plastic/addmaterial', $("#addform").serialize()).then(res => {
					if (res['data'].status == '1') {
						toast.success("Material Collection Added Successfully..!", "success");
						document.getElementById("addform").reset();
						setBtnLoading(false);
						setBuyingData([]);
						const vrl = baseUrl + '/v1/log/addlog';
						let formData = new FormData();
						formData.append('createon', '2021/12/10');   //append the values with key, value pair
						formData.append('context', 'Add Plastic Material');
						formData.append('e_type', 'User_log');
						formData.append('action_by', 'admin');
						formData.append('addby', 1);
						formData.append('addip', 'localhost');

						var config = {
							method: 'post',
							url: vrl,
							data: formData
						};

						axios(config)
							.then(function (response) {
								console.log(JSON.stringify(response.data));
							})
							.catch(function (error) {
								console.log(error);
							});
					}
					else {
						toast.error("Something Went Wrong..!", "danger");
						document.getElementById("addform").reset();
					}
					setIsLoading(false);
				});
			} else {

				toast.error("Please input the Material kg", "success");
			}
		}
		catch (error) {

		}
	};

	const changeAmt = (item, e) => {
		var d = [];
		var index = buyingData.findIndex(function (obj) {
			return (obj.id == item.id);
		});
		let skgid = "#buy_" + item.id;
		let kg = $(skgid).val();

		var buyingAmt = (item.current_price * item.buying_rate) / 100;

		if (index > -1) {
			if (parseFloat(e) > parseFloat(buyingAmt)) {
				let sid = "#buyamt_" + item.id;
				$(sid).val(buyingData[index].bamt);
				alert('Enter minimum of buying amount ( Rs.' + buyingAmt + ' )');
			} else {
				var total = parseFloat(kg * e);
				d.push({ "id": item.id, "kg": kg, "amount": item.current_price, "bamt": e, "total": total });
				const arr = [...buyingData.filter((item, i) => i != index), ...d];
				setBuyingData(arr);
				let sid = "#buyamt_" + item.id;
				$(sid).val(e);
			}
		}
	}

	const changeKg = (item, bAmt, e) => {
		var d = [];
		var index = buyingData.findIndex(function (obj) {
			return (obj.id == item.id);
		});
		if (index > -1) {
			d.push({ "id": item.id, "kg": e, "amount": item.current_price, "bamt": bAmt, "total": e * bAmt });
			console.log(item.current_price);
			console.log(bAmt);
			const arr = [...buyingData.filter((item, i) => i != index), ...d];
			setBuyingData(arr);
			let sid = "#buy_" + item.id;
			$(sid).val(e);
		} else {
			var s = [];

			s.push({ "id": item.id, "kg": e, "amount": item.current_price, "bamt": bAmt, "total": e * bAmt });
			const arr = [...buyingData.filter((item, i) => i != index), ...s];
			setBuyingData(arr);
		}
	}

	const changeType = (e) => {
		listdata(e);
	}

	let amt = 0;
	let total = 0;
	let kgtotal = 0;
	let stotal = 0;
	let amtTotal = 0;

	console.log(data);

	return (
		<>
			<div className="container">
				<Navbar bg="white" expand="md">
					<Container>
						<Navbar.Brand >Material Collection&emsp;&emsp;&emsp;
							<div >
								<div className="form-check form-check-inline">
									<input type="radio" name="type" value="event" className="form-check-input" defaultChecked={type == 'event' ? true : false} onChange={(e) => { setType(e.target.value) }} />
									<label for="type">
										Event
									</label>
								</div>
								<div className="form-check form-check-inline">
									<input type="radio" name="type" value="vendor" className="form-check-input" defaultChecked={type == 'vendor' ? true : false} onChange={(e) => { setType(e.target.value) }} />
									<label for="type">
										Vendor/Public
									</label>
								</div>
							</div>
						</Navbar.Brand>
					</Container>
				</Navbar>
				<Row className='mt-1'>
					<Col md={8}>

					</Col>
					<Col md={2}>
						<h6 style={{ float: 'right' }} className='mt-2'>Select Type :</h6>
					</Col>
					<Col md={2}>
						<select className="form-control" required onChange={(e) => changeType(e.target.value)}>
							<option value="">Select Type</option>
							<option value="plastic kg">Plastic Kg</option>
							<option value="others kg">Non Plastic Kg</option>
							<option value="others pcs">Non Plastic Piece</option>
						</select>
					</Col>
				</Row>
				{data.length > 0 ? <Card className='mt-1'>
					<Card.Body>
						<Form action="" method="post" id="addform" onSubmit={handleSubmit}>
							<input type="hidden" name="collections" value={JSON.stringify(buyingData)} />
							<input type="hidden" name="type" value={type} />
							<input type="hidden" name="acyear" value={context.acyear.id} />
							<Row className="mt-3">
								<Col md={1}><label>Group</label></Col>
								<Col md={3}>
									<select name="group" className="form-control" required onChange={(e) => { listdata(e.target.value) }}>
										<option value="">Select Group</option>
										{groupData.map((user, i) => {
											return <option value={user.id}>{user.grp_name}</option>
										})}
									</select>
								</Col>
								{type == 'event' ?
									<>
										<Col md={1}><label>Event</label></Col>
										<Col md={3}>
											<select name="event" className="form-control" required>
												<option value="">Select Event</option>
												{eventData.map((user, i) => {
													return <option value={user.id}>{user.eventname}</option>
												})}
											</select>
										</Col>
									</>
									: type == 'vendor' ?
										<>
											<Col md={1}><label>Vendor</label></Col>
											<Col md={3}>
												<select name="vendor" className="form-control" required>
													<option value="">Select Vendor</option>
													{vendorData.map((user, i) => {
														return <option value={user.id}>{user.customername}</option>
													})}
												</select>
											</Col>
										</>
										: ''}
								<Col md={1}><label>Date</label></Col>
								<Col md={3}>
									<input type="date" name="cdate" className="form-control" defaultValue={moment().format("YYYY-MM-DD")} required />
								</Col>
								<Col md={12} className="mt-3">
									<Table bordered className="mt-10">
										<thead className="text-center">
											<tr>
												<th width="25%"><b>Type</b></th>
												<th width="10%"><b>Kg</b></th>
												<th width="15%"><b>Amount</b></th>
												<th width="15%"><b>Total</b></th>
											</tr>
										</thead>
										<tbody className="text-center">
											{data.map((item, i) => {
												let id = "#buy_" + item.id;
												let sid = "#buyamt_" + item.id;
												let buying = $(id).val();
												let bamt = $(sid).val();
												amt = buying != '' ? parseFloat(buying * bamt) : 0;
												//total+=parseFloat(amt);
												stotal += bamt != '' ? parseFloat(bamt) : 0;
												kgtotal += buying != '' ? parseFloat(buying) : 0;
												let buyingAmt = (item.current_price * item.buying_rate) / 100;
												amtTotal += parseFloat(buyingAmt);
												total += parseFloat(buying * buyingAmt);
												return <tr>
													<td align="left">{item.plastic_name}</td>
													<td>
														<input type="text" name="buying_kg" onKeyPress={decimalKeyPress} id={"buy_" + item.id} onChange={(e) => { changeKg(item, buyingAmt, e.target.value) }} />
													</td>
													<td>{parseFloat(buyingAmt).toFixed(2)}</td>
													{/*<td>
													<input type="text" name="buying_amt" onKeyPress={decimalKeyPress} id={"buyamt_"+item.id}onChange={(e)=> {changeAmt(item,e.target.value)}} />
												</td>*/}
													<td>{buying > 0 ? parseFloat(buying * buyingAmt).toFixed(2) : ''}</td>
												</tr>
											}
											)}
											<tr>
												<td align="right" colSpan="1"><b>Total</b></td>
												<td width="15%"><b>{kgtotal > 0 ? parseFloat(kgtotal).toFixed(2) : ''}</b></td>
												<td width="15%"></td>
												{/* <td width="15%"><b>{amtTotal > 0 ? parseFloat(amtTotal).toFixed(2) : ''}</b></td> */}
												<td width="15%"><b>{total > 0 ? parseFloat(total).toFixed(2) : ''}</b></td>
											</tr>
											<tr>
												<td align="right" colSpan="3"><b>Amount Paid</b></td>
												<td align="right">
													<input type="text" onKeyPress={decimalKeyPress} name="p_amount" value={total > 0 ? parseFloat(total).toFixed(2) : ''} required />
												</td>
											</tr>
										</tbody>
									</Table>
								</Col>
								<Col md={12}>
									<button type="submit" className="btn btn-success btn-block btn-md" disabled={btnLoading}>Save</button>
								</Col>
							</Row>
						</Form>
					</Card.Body>
				</Card> : ''}
			</div>
		</>
	);
};
export default MaterialCollection;