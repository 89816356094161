import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import { imageUrl } from '../../utils';
import moment from 'moment';

const viewGroupMeetingReport = (props) => {

	const styles = {
		imgStyle: {
			border: '1px solid #d8d8d8', height: '120px'
		},
	}

	console.log(props.data);
	console.log(props.image);

	return (
		<>
			<div class="container">
				<Row>
					<Col md={{ span: 12, offset: 0 }}>
						<Table bordered>
							<tbody>
								<tr>
									<th><b>Group Name:</b></th><td colspan="3">{props.data.gname}</td>
									<th><b>Meeting Date:</b></th><td colspan="3">{moment(props.data.date_meeting).format('DD-MM-YYYY')}</td>
								</tr>
								<tr>
									<th><b> Start Time: </b></th><td colspan="3">{props.data.start_time}</td>
									<th><b> End Time: </b></th><td colspan="3">{props.data.end_time}</td>
								</tr>
								<tr>
									<th><b> Remark: </b></th><td colspan="3">{props.data.remark}</td>
									<th><b> No of Participants: </b></th><td colspan="3">{props.data.no_of_participants}</td>
								</tr>
								<tr>
									<th><b> Photo: </b></th><td colspan="3"><img src={imageUrl + props.data.photo} width="120px" style={styles.imgStyle} /></td>
									<th><b> Maximam Photo: </b></th><td colspan="3">{props.image.map((item) => {
										return (<Col md={2}>
											<img src={imageUrl + item.photo} width="120px" style={styles.imgStyle} />
										</Col>);
									})}</td>
								</tr>
								<tr>
									<th><b> Total Members: </b></th><td colspan="3">{props.data.total_participants}</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default viewGroupMeetingReport;