import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { imageUrl } from '../../utils';

const PrintData = (props) => {

	const context = useContext(PsContext);
	const styles = {
		imgStyle: {
			border: '1px solid #d8d8d8', height: '120px'
		},
	}

	return (
		<>
			{/*<div class="container">
				<Row>
					<Col md={{ span: 12, offset: 0 }}>
						<Table bordered responsive>
							<tbody>
								<tr>
									<th><b>Group Name:</b></th><td colspan="3">{props.edit.grp_name}</td>
									<th><b>Staff Name:</b></th><td colspan="3">{props.edit.staffname}</td>
								</tr>
								<tr>
									<th><b> Event Type: </b></th><td colspan="3">{props.edit.typename}</td>
									<th><b> Event Name: </b></th><td colspan="3">{props.edit.title}</td>
								</tr>
								<tr>
									<th><b> Place/Village: </b></th><td colspan="3">{props.edit.place}</td>
									<th><b> Venue: </b></th><td colspan="3">{props.edit.venue}</td>
								</tr>
								<tr>
									<th><b> Total Participants: </b></th><td colspan="3">{props.edit.participants}</td>
									<th><b> Male/Female: </b></th><td colspan="3">
										Male : {props.edit.male_participant}&emsp;
										Female : {props.edit.female_participant}
									</td>
								</tr>
								<tr>
									<th><b> Special Category: </b></th><td colspan="3">{props.edit.special_category}</td>
									<th><b> Banking: </b></th><td colspan="3">{props.edit.banking}</td>
								</tr>
								<tr>
									<th><b> Plastic Bank: </b></th><td colspan="3">{props.edit.plastic_bank}</td>
									<th><b> Organising: </b></th><td colspan="3">{props.edit.organising}</td>
								</tr>
								<tr>
									<th><b> Kap Study: </b></th><td colspan="3">{props.edit.kap_study}</td>
									<th><b> Door Conselling: </b></th><td colspan="3">{props.edit.door}</td>
								</tr>
								<tr>
									<th><b> Topic: </b></th><td colspan="3">{props.edit.topics}</td>
									<th><b> Report Date: </b></th><td colspan="3">{moment(props.edit.meeting_date).format('DD-MM-YYYY')}</td>
								</tr>
								<tr>
									<th><b> Reporting Time: </b></th><td colspan="3">{moment(props.edit.from_time, ['HH:mm']).format("hh:mm A")} to {moment(props.edit.to_time, ['HH:mm']).format("hh:mm A")}</td>
									<th><b> Resource person / guest: </b></th><td colspan="3">{props.edit.invitees}</td>
								</tr>
								<tr>
									<th><b> Content: </b></th><td colspan="5">
										<div dangerouslySetInnerHTML={{ __html: props.edit.feedback }}></div>
									</td>
								</tr>
								<tr>
									<th><b> Photos: </b></th><td colspan="5">
										{/*<Row>
											{props.images.map((item, i) => {
												return (<Col md={2}>
													<img src={imageUrl + item.image} width="120px" style={styles.imgStyle} />
												</Col>);
											})}
										</Row>
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</div>*/}

		</>
	);
};
export default PrintData;