import React, { useState, useContext } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import { baseUrl } from "../../utils";

import Layout from "./layout";

const HomeGroupAccount = (props) => {
  const context = useContext(PsContext);

  const menu = [
    { name: "Payment In", path: "payment_in" },
    { name: "Payment Out", path: "payment_out" },
    { name: "Daily Report", path: "day_report" },
    //{name: 'Date to Date Report', path: 'day_to_day_report'},
  ];

  const linkClick = (page) => {
    if (props.onAction) props.onAction(page);
  };

  const getNav = (item) => {
    return (
      <li
        className="list-group-item bg-transparent h5 py-3"
        onClick={(e) => linkClick(item.path)}
      >
        <a>{item.name}</a>
        <span className="float-end">
          <i className="fa fa-arrow-right"></i>
        </span>
      </li>
    );
  };

  return (
    <Row>
      <Col>
        {/*<a
				onClick={e=>linkClick('payment_in')}
					className="btn btn-info btn-block mb-3"
				>
					Payment In
				</a>

				<a
					onClick={e=>linkClick('payment_out')}
					className="btn btn-info btn-block mb-3"
				>
					Payment Out
				</a>

				<a
					onClick={e=>linkClick('reports')}
					className="btn btn-info btn-block mb-3"
				>
					Reports
	</a>*/}

        <ul className="list-group bg-transparent">
          {menu.map((item) => getNav(item))}
        </ul>
      </Col>
    </Row>
  );
};
export default withRouter(HomeGroupAccount);
