import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../../utils/api';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import PsContext from '../../../context';
import { PSListItem } from '../../../comp';
import { lettersOnly, decimalKeyPress, baseUrl } from '../../../utils';
import axios from 'axios';

const AddType = (props) => {

	const context = useContext(PsContext);
	const [btnLoading, setBtnLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [data, changeData] = useState([]);
	const [getData, setGetData] = useState([]);

	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			setBtnLoading(true);
			API.post('/v1/plastic/addplastic', $("#addform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Plastic Added Successfully", "success");
					document.getElementById("addform").reset();
					setBtnLoading(false);
					const vrl = baseUrl + 'v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'Plastic Type Added');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
						method: 'post',
						url: vrl,
						data: formData
					};

					axios(config)
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
				}
				else {
					toast.error("something went wrong", "danger");
					document.getElementById("addform").reset();
				}
				setIsLoading(false);
			});
		}
		catch (error) {

		}
	};


	const getChangeValue = (e) => {
		let s = e.target.value;
		setGetData(s);
	}

	return (
		<>
			<div className="container">
				<Navbar bg="white" expand="lg">
					<Container>
						<Navbar.Brand >Add Plastic</Navbar.Brand>
						<Nav className="justify-content-end">
							<Link to={{
								pathname: '/typemanagement'
							}}>Manage</Link>
						</Nav>
					</Container>
				</Navbar>
				<Row>
					<Col md={4}>
					</Col>
					<Col md={4}>
						<Card>
							<Card.Body>
								<h3>Add Plastic</h3><hr />
								<Form action="" method="post" id="addform" validated={validated} onSubmit={handleSubmit}>
									<Row>
										<Col md={12}>
											<Row>
												<Col md={12}>
													<MDBInput
														label="Plastic Name"
														type="text"
														name="plastic_name"
														size="sm"
														required
														onKeyPress={lettersOnly}
													/>
												</Col>
											</Row>
											<Row className="mt-3">
												<Col md={12}>
													<MDBInput
														label="Short Name"
														type="text"
														name="short_name"
														size="sm"
													/>
												</Col>
											</Row>
											<Row className="mt-3">
												<Col md={12}>
													<MDBInput
														label="HSN"
														type="text"
														name="hsn"
														size="sm"
													/>
												</Col>
											</Row>
											<Row className="mt-3">
												<Col md={12}>
													<MDBInput
														label="GST"
														type="text"
														name="gst"
														size="sm"
													/>
												</Col>
											</Row>
											<Row className="mt-3">
												<Col md={12}>
													<MDBInput
														label="Buying Rate (Maximum % of selling rate)"
														type="text"
														name="buying_rate"
														size="sm"
														onKeyPress={decimalKeyPress}
													/>
												</Col>
											</Row>
											<Row className='mt-3'>
												<Col md={12}>
													<select className='form-control' name="material_type" onChange={getChangeValue}>
														<option value="">-Select Material Type-</option>
														<option value="plastic">Plastic</option>
														<option value="others">Others</option>
													</select>
												</Col>
											</Row>
											<Row className='mt-3'>
												<Col md={12}>
													{getData == 'others' ? <select className='form-control' name="weight">
														<option value='kg'>Kg</option>
														<option value='pcs'>Pcs</option>
														<option value='liter'>Liter</option>
														<option value='meter'>Kg</option>
													</select> : getData == 'plastic' ? <MDBInput
														type="text"
														name="weight"
														size="sm"
														value='kg'
													/> : <MDBInput
														type="text"
														name="weight"
														size="sm"
														disabled
													/>}
												</Col>
											</Row>
											<Row className='mt-3'>
												<Col md={12}>
													<MDBInput
														type="number"
														name="order"
														size="sm"
														label="Plastic Order"
													/>
												</Col>
											</Row>
										</Col>
									</Row>
									<Button type="submit" className="mt-3" variant="success" size="md" style={{ float: 'right' }} disabled={btnLoading}>{btnLoading ? 'Please wait..' : 'Add'}</Button>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default AddType;