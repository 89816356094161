import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col,  Modal , Form} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import {Select} from 'antd';
import axios from 'axios';
import {integerIndMobile, lettersOnly} from '../../utils';
import {baseUrl} from '../../utils';
import ReactFileReader from 'react-file-reader';
import PLUSIMAGE from '../../assets/css/images/plus.png';
import {CKEditor} from 'ckeditor4-react';

const AddBank = (props) =>{

	const context = useContext(PsContext);
	const [submit, sethandleSubmit] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [feedback, setFeedback] = useState('');
	const [photos, setPhotos] = useState([]);
	const [photos1, setPhotos1] = useState([]);
	const [eventDate, setEvent] = useState('');
	const [eventTime, setEventTime] = useState('');
	const [data, changeData] = useState([]);
	const [stdata, stChangeData] = useState([]);
	const [staff, setStaff] = useState([]);
	const [typeData, setTypeData] = useState([]);
	const {Option}	= Select;
	const [selectedPersons , setSelectedPersons] = useState ([]);
	
	useEffect(() => {
        listLedgerHead(); 
        listGroupName(); 
		var date = new Date().getDate()+'-'+(new Date().getMonth()+1)+new Date().getFullYear();
		setEvent(date);
		setEventTime(date);
    } , [] );
	
	const listGroupName = () => {
		try {
			setIsLoading(true);
			API.get('v1/user/list/ledgergroup').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};
	
	
	const listLedgerHead = () => {
		try {
            setIsLoading(true);
			API.get('v1/user/list/ledgerthead').then(res => {
			if (res['data'].status == '1') {
                var d = res['data'].data;
				stChangeData(d);
                setIsLoading(false);
				}
			else {
				setIsLoading(false);
				}
			});
		}
		catch (error) {
            setIsLoading(false);
		}
	};
	
	const liststaff = () => {
		try {
			setIsLoading(true);
			API.get('v1/user/liststaff').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setStaff(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};
	
	const children = [];
    children.push(staff.map(item => <option value={item.id} > {item.name} </option>));
	
	const handleSubmit = (event) => {
		event.preventDefault();
		try{
			setBtnLoading(true);
			API.post('v1/user/add/bankaccounts',$("#meetform").serialize()).then(res=>{
				if (res['data'].status == '1') {
				  toast.success("Bank Saved Successfully..!", "success");
				  document.getElementById("meetform").reset();
				  setBtnLoading(false);
				  props.afterFinish();
				  props.afterList();
				}	
				else {
				  toast.error("Something Went Wrong..!", "danger");
				  document.getElementById("editform").reset();
				}
				setIsLoading(false);
			});
		}
		catch(error){
			toast.error("Something Went Wrong..!", "danger");
		}
	};
	
	const getFiles = (files) => {
		var d = [];
		var d1 = [];
		if(photos.length > 0){
			for(var i=0;i<(files.base64).length;i++){
				d.push(files.base64[i]);
			}
			const arr = [...photos, ...d];
			setPhotos(arr);
		}else{
			setPhotos(files.base64);
		}

		if(photos1.length > 0){
			for(var i=0;i<files.length;i++){
				d1.push(files);
			}
			const arr1 = [...photos1, ...d1];
			setPhotos1(arr1);
		}else{
			setPhotos1(files.base64);
		}
	}
	
	const handleRemove = (index) => {
		var d = photos.filter((item,i)=> i!=index);
		setPhotos(d);
		var d1 = photos1.filter((item1,i1)=> i1!=index);
		setPhotos1(d1);
	}
	
	const onFeedbackChange = (evt) => {
		setFeedback(evt.editor.getData());
	};
	
	const handleChecked = selectedPersons => {
        setSelectedPersons(selectedPersons);
    };

	return(
		<>
			<div className="container">
			<Form action="" method="post" id="meetform" validated={validated} onSubmit={handleSubmit} >
					<Row>
						<Col md={12} className="mt-3">
							<MDBInput
								label="Bank Name"
								type="text"
								name="ledger_head_name"
								size="sm"
								required
								onKeyPress={lettersOnly}
							/>
						</Col>
						<Col md={12} className="mt-3">
							<MDBInput
								label="Account No"
								type="text"
								name="account_no"
								size="sm"
								required
							/>
						</Col>
					</Row>
					<Col className="mt-3">
						<button  className="btn btn-outline-black btn-block" size="sm"  type="submit" disabled={btnLoading}>
							{btnLoading ? 'Please wait..' : 'Save'}
						</button>
					</Col>
			</Form>
			</div>
		</>
	);
};

export default AddBank;