import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { calculateAge } from '../../utils';
import moment from 'moment';

const ViewStaff = (props) => {

	const context = useContext(PsContext);
	const [data, changeData] = useState([]);


	return (
		<>
			<div>
				<Row>
					<Col md={{ span: 12 }}>
						<div>
							<Row>
								<Col md={12}>
									<b style={{ fontSize: '22px' }}>{props.data.name} - {props.data.designation}</b>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<div style={{ fontSize: '17px' }}>
										<Row>
											<Col md={5}>Date of birth</Col>
											<Col md={7}>: {moment(props.data.dob).format('DD-MM-YYYY')}</Col>
										</Row>
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<div style={{ fontSize: '17px' }}>
										<Row>
											<Col md={5}>Age</Col>
											<Col md={7}>: {calculateAge(props.data.dob)}</Col>
										</Row>
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<div style={{ fontSize: '17px' }}>
										<Row>
											<Col md={5}>Gender</Col>
											<Col md={7}>: {props.data.gender}</Col>
										</Row>
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={12}>

									<div style={{ fontSize: '17px' }}>
										<Row>
											<Col md={5}>Qualification</Col>
											<Col md={7}>: {props.data.edu_qualification}</Col>
										</Row>
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<div style={{ fontSize: '17px' }}>
										<Row>
											<Col md={5}>Date Of Appointment</Col>
											<Col md={7}>: {moment(props.data.doa).format('DD-MM-YYYY')}</Col>
										</Row>
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<div style={{ fontSize: '17px' }}>
										<Row>
											<Col md={5}>APP Access</Col>
											<Col md={7}>: {props.data.mobile_access}</Col>
										</Row>
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<div style={{ fontSize: '17px' }}>
										<Row>
											<Col md={5}>Address</Col>
											<Col md={7}>: {props.data.address}</Col>
										</Row>
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<div style={{ fontSize: '17px' }}>
										<Row>
											<Col md={5}>Email</Col>
											<Col md={7}>: {props.data.email}</Col>
										</Row>
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<div style={{ fontSize: '17px' }}>
										<Row>
											<Col md={5}>Staff Type</Col>
											<Col md={7}>: {props.data.staff_type == '1' ? 'Admin' : 'Staff'}</Col>
										</Row>
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<div style={{ fontSize: '17px' }}>
										<Row>
											<Col md={5}>Staff Status</Col>
											<Col md={7}>: {props.data.status == '1' ? 'Active' : 'Inactive'}</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</div>
						{/*<Table>
							<tbody>
								<tr>
									<th><b> Name </b></th><td>:</td><td>{props.data.name}</td>
								</tr>
								<tr>
									<th><b> DOB </b></th><td>:</td><td>{moment(props.data.dob).format('DD-MM-YYYY')}</td>
								</tr>
								<tr>
									<th><b> Age: </b></th><td>:</td><td>{calculateAge(props.data.dob)}</td>
								</tr>
								<tr>
									<th><b> Gender: </b></th><td>:</td><td>{props.data.gender}</td>
								</tr>
								<tr>
									<th><b> Qualification: </b></th><td>:</td><td>{props.data.edu_qualification}</td>
								</tr>
								<tr>
									<th><b> Designation: </b></th><td>:</td><td>{props.data.designation}</td>
								</tr>
							</tbody>
	</Table>*/}
					</Col>
				</Row>
			</div>
		</>
	);
};
export default ViewStaff;