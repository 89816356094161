import React from 'react';
import Routes from './routes/routes';
import PsContextProvider from './context/PsContextProvider';

function App() {
  return (
	<PsContextProvider> 
		<Routes />
	</PsContextProvider>
  );
}

export default App; 
