import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import { Select } from 'antd';
//import Select from 'react-select';
import { integerIndMobile, lettersOnly, baseUrl } from '../../utils';

const { Option } = Select;

const EditUser = (props) => {

	const context = useContext(PsContext);
	const [submit, sethandleSubmit] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [hashCode, setHashCode] = useState("");
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [data, changeData] = useState([]);
	const [selectValue, setSelectValue] = useState(props.data.group_type);
	const [selectedGroup, setSelectedGroup] = useState([]);

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		if (!form.checkValidity() === true) {
			event.stopPropagation();
			setValidated(true);
			return;
		}
		try {
			setBtnLoading(true);
			API.post('v1/user/createuser', $("#editform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("User Updated Successfully..!", "success");
					document.getElementById("editform").reset();
					setBtnLoading(false);
					props.afterFinish();
					props.afterList();
					const vrl = baseUrl + 'v1/log/addlog';
					let formData = new FormData();
					formData.append('createon', '2021/12/10');   //append the values with key, value pair
					formData.append('context', 'User Edit');
					formData.append('e_type', 'User_log');
					formData.append('action_by', 'admin');
					formData.append('addby', 1);
					formData.append('addip', 'localhost');

					var config = {
						method: 'post',
						url: vrl,
						data: formData
					};

					axios(config)
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
				}
				else {
					toast.error("Something Went Wrong..!", "danger");
					document.getElementById("editform").reset();
				}
				setIsLoading(false);
			});
		}
		catch (error) {
			toast.error("Something Went Wrong..!", "danger");
		}
	};

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listgroup').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const listCode = () => {
		try {
			let hashcode = props.data.pass_word;
			let formData = new FormData();
			formData.append('password', hashcode);
			API.post('v1/user/getpassword', formData).then(res => {
				var d = res['data'].data;
				setHashCode(d);
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		listCode();
		listdata();
		setSelectedGroup(props.data.group_type);
		//console.log(props.data.group_type.split(','));
	}, []);


	const handleDeleteClick = (id) => {
		try {
			if (window.confirm('Do you want to Remove?')) {
				setIsLoading(true);
				API.get('v1/user/removeuser?id=' + id).then(res => {
					if (res['data'].status == '1') {
						toast.success("User Deleted Successfully..!", "success");
						props.afterFinish();
						props.afterList();
					}
					else {
						toast.error("Something Went Wrong..!", "danger");
						setIsLoading(false);
					}
				});
			}

		}
		catch (error) {
			setIsLoading(false);
		}
	}

	const handleGroupChange = (v) => {
		setSelectedGroup(v);
	}

	return (
		<>
			<div class="container">
				<Form action="" method="post" id="editform" validated={validated} onSubmit={handleSubmit} >
					<input type="hidden" name="id" value={props.data.id} />
					<input type="hidden" name="group_type" value={selectedGroup} />
					{/*<input type="hidden" name="selectedGroup" value={JSON.stringify(selectedGroup)} />*/}
					<Row>
						<Col md={12}>
							{/*<Select className='mt-2' isClearable options={
								data.map((item, i) => {
									return { value: item.id, label: item.grp_name };
								})
							}
								placeholder="Select Group"
						onChange={handleGroupChange} name="group_type" size="sm" required defaultValue={selectedGroup} />*/}
							{/*<Select
								style={{ width: '100%' }}
								placeholder="Select Group"
								onChange={handleGroupChange}
								name="group_type"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								value={selectedGroup}
							>
								{data.map(item => <Option value={item.id}>{item.grp_name}</Option>)}
							</Select>*/}
							<MDBInput
								label="Name"
								type="text"
								name="name"
								className='mt-2'
								size="sm"
								defaultValue={props.data.name}
								onKeyPress={lettersOnly}
							/>
							<MDBInput
								label="User Name & Mobile"
								type="text"
								id="username"
								name="username"
								className='mt-2'
								size="sm"
								onKeyPress={integerIndMobile}
								defaultValue={props.data.user_name}
							/>
						</Col>
						<Col md={12}>
							<MDBInput
								label="Password"
								type="text"
								id="password"
								className='mt-2'
								name="password"
								size="sm"
								value={hashCode}
								onChange={e => setHashCode(e.target.value)}
								required
							/>
						</Col>
						<Col md={12} className="mt-3">
							<button className="btn btn-outline-black btn-block" type="submit" disabled={btnLoading}>
								{btnLoading ? 'Please wait..' : 'Update'}</button>
						</Col>
						<Col md={12} className="mt-3">
							<button type="button" className="btn btn-danger btn-block" disabled={btnLoading} onClick={() => { handleDeleteClick(props.data.id) }}>
								{btnLoading ? 'Please wait..' : 'Remove User'}</button>
						</Col>
					</Row>
				</Form>
			</div>
		</>
	);
};

export default EditUser;