import { Switch, Route, BrowserRouter } from 'react-router-dom';

import LoginPage from '../pages/loginPage';
import AttendancePage from '../pages/attendancePage';
import ReportPage from '../pages/reportPage';
import TopHeader from '../pages/layout/topHeader';

import Accounts from '../pages/mobileAccounts'; 

export default () => (

  <BrowserRouter>
    <Switch>

      <Route path="/acc/:type/:userid/:groupid" component={Accounts} />

      <Route path="/login" component={LoginPage} />
      <Route path="/attendance/:userid/:month" component={AttendancePage} />
      <Route path="/eventreport/:userid/:month" component={ReportPage} />
      <Route path="/" component={TopHeader} />

    </Switch>
  </BrowserRouter>

);
