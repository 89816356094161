import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Table,
  Navbar,
  Container,
  Nav,
  Form,
} from "react-bootstrap";
import toast from "react-hot-toast";
import PsContext from "../../context";
import API from "../../utils/api";
import { MDBInput } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { Switch, Spin } from "antd";
import moment from "moment";
import $ from "jquery";
import EditHouse from "./editHouse";
import EditStar from "./editStar";
import EditStar1 from "./editStar1";
import AddHouse from "./addHouse";
import { CardFixedTop, momentDate } from "../../utils";
import Pagination from "react-js-pagination";

const HBCOutcome = (props) => {
  const [data, changeData] = useState([]);
  const [indicatorsids, setIndicators] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searchHBCData, setSearchHBCData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [block, setBlock] = useState([]);
  const [visitUser, setVisitUser] = useState([]);
  const [village, setVillage] = useState([]);
  const [villageName, setVillageName] = useState([]);
  const [searchVillage, setSearchVillage] = useState([]);
  const [searchBlock, setSearchBlock] = useState([]);
  const [loader, setLoader] = useState(false);
  const [maxVisitCount, setMaxVisitCount] = useState(0);
  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState([]);
  const [listVisitReport, setListVisitReport] = useState([]);
  const [addModel, setAddModel] = useState(false);
  const [fromDate, setFromDate] = useState(
    momentDate(new Date(), "YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(momentDate(new Date(), "YYYY-MM-DD"));

  useEffect(() => {
    API.get("v1/group/getMaxVisitCount").then((res) => {
      if (res["data"].status == "1") {
        var d = parseInt(res["data"].data[0].visitcount.toString());

        setMaxVisitCount(d);

        const t = [];
        const t2 = [];
        for (let i = 0; i < d; i++) {
          t.push(
            <>
              <th align="center" colSpan={2}>
                <b>Visit-{i + 1}</b>
              </th>
            </>
          );
        }
        for (let i = 0; i < d; i++) {
          t2.push(
            <>
              <th>Home Visited</th>
              <th>Items</th>
            </>
          );
        }
        setItems(t);
        setItems2(t2);

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });

    const form = new FormData();
    form.append("fromdate", fromDate);
    form.append("todate", toDate);
    API.post("v1/group/listVisitReport", form).then((res) => {
      if (res["data"].status == "1") {
        var d = res["data"].data;
        /* console.log("listviewreport");
            console.log(d);*/
        setListVisitReport(d);

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });

    try {
      setIsLoading(true);
      API.get("v1/group/hbcOutcomeReport").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
          setIndicators(d);

          console.log("test", d);

          /*  setSearchData(d);
            setSearchHBCData(d);
            setSearchBlock(d);
            setSearchVillage(d);*/
          /*
          
          var tm=[];
          d.map((item,i) => {
              const t=[];    
          for(let i1=1; i1<=maxVisitCount; i1++)
          {
              t.push(<td>{cellloader1(item.indicator_id,i1)}</td>);
          }
          
          tm.push(<tr><td>{i+1}</td><td>{item.indicators}</td>{t}</tr>);
            });
          
            console.log(tm);
            changeData(tm);*/
          //  console.log(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }

    setLoader(false);
    listdata();
    listBlock();
    listVillage();
    //listQuestionReport();
    setLoader(true);
    setIsLoading(false);
  }, []);

  const listdata = () => {};

  // const cellloader1 = (id, visit, ch) => {
  //   //console.log("id+visit"+id+visit);
  //   // const res= listVisitReport.filter((item)=>{
  //   //     if(item.indicator_id===id && item.visit_count===visit)
  //   //         return item.items_sum;
  //   //     else
  //   //         return "N/A";
  //   // });
  //   const res = listVisitReport.filter((item) => (item.indicator_id === id && parseInt(item.visit_count) === parseInt(visit)));
  //   //console.log(res.length>0?res[0].items_sum:0);

  //   if (ch == 1)
  //     return res.length > 0 ? res[0].items_sum : 0;
  //   else if (ch == 2)
  //     return res.length > 0 ? res[0].visit_count : 0;

  //   //    return "ff";
  // }

  const cellloader1 = (id, visit) => {
    const res = listVisitReport.find(
      (item) =>
        item.indicator_id === id &&
        parseInt(item.visit_count) === parseInt(visit)
    );
    return res
      ? {
          member_count: res.member_count,
          items_sum: res.items_sum,
          idate: res.idate,
        }
      : { member_count: 0, items_sum: 0, idate: 0 };
  };

  const dateChange = () => {
    var fromDate = $("#fromdate").val();
    var toDate = moment(fromDate).format("YYYY-MM-DD");
    if (fromDate != "") {
      $("#todate").attr("min", toDate);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      API.post("v1/group/listVisitReport", $("#search").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            var d = res["data"].data;
            var ids = res["data"].data.map((item) => item.indicator_id);
            var filterData = indicatorsids.filter((items) => ids.includes(items.indicator_id));
            changeData(filterData);
            setListVisitReport(d);
            setSearchData(d);
            setSearchHBCData(d);
            setSearchBlock(d);
            setSearchVillage(d);
            setIsLoading(false);
          } else {
            toast.error(res["data"].message);
            setIsLoading(false);
          }
        }
      );
    } catch (er) {}
  };

  const listBlock = () => {
    try {
      setIsLoading(true);
      API.get("v1/group/block").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setBlock(d);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listVillage = () => {
    try {
      setIsLoading(true);
      API.get("v1/group/village").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          setVillage(d);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const filterVillage = (e) => {
    var d = village.filter(function (item, i) {
      return item.block_id == e.target.value;
    });
    setVillageName(d);
  };

  //console.log(block);
  //console.log(village);

  const getBlock = (e) => {
    let d = searchBlock;
    if (e.target.value == "") {
      return setSearchHBCData(d);
    }
    let nadata = d.filter(function (obj) {
      return obj.block_id == e.target.value;
    });
    setSearchHBCData(nadata);
  };

  const getVillage = (e) => {
    let d = searchVillage;
    if (e.target.value == "") {
      return setSearchHBCData(d);
    } else {
      let data = d.filter(function (obj) {
        return obj.village_id == e.target.value;
      });
      setSearchHBCData(data);
    }
  };

  const getValue = () => {
    var d = [];
    for (var i = 1; i <= 10; i++) {
      d.push(<option value={i}>visit - {i}</option>);
    }
    return d;
  };

  const showAddModel = () => {
    setAddModel(true);
  };

  const listVisitUser = (id, visit) => {
    try {
     setVisitUser();
  
     let d = listVisitReport.filter(
        (item) => item.indicator_id === id && parseInt(item.visit_count) === parseInt(visit)
      );
    
      if (d.length === 0) {
        console.error("No matching indicator found");
        setIsLoading(false);
        return; 
      }
  
      var form = new FormData();
      form.append("house_id", d[0].house_id);
      form.append("visit", d[0].visit_count);
      form.append("indicator_id", d[0].indicator_id);
      // form.append("userid", "100,101,99");
  
      API.post("v1/group/listVisitUser", form).then((res) => {
        if (res["data"].status == "1") {
         if (res["data"].data && res["data"].data.length > 0) {
            var filteredData = res["data"].data.filter(
              (item) =>
                item.indicator_id === d[0].indicator_id &&
                parseInt(item.visit_count) === parseInt(visit)
            );
           setVisitUser(filteredData);
          } else {
            console.error("No data in response");
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
      setIsLoading(false);
    }
  };
  
  return (
    <div className="container">
      <Navbar bg="white" expand="lg">
        <Container>
          <Navbar.Brand>HBC Outcome Report - </Navbar.Brand>
          <Nav className="justify-content-end"></Nav>
        </Container>
      </Navbar>
      <Form method="post" id="search" onSubmit={handleSearch}>
        <Row className="mt-2">
          <Col md={1}></Col>
          <Col md={2}>
            <label className="control-label">From</label>
            <Form.Control
              type="date"
              name="fromdate"
              id="fromdate"
              size="sm"
              // defaultValue={momentDate(new Date(), "YYYY-MM-DD")}
              // onChange={dateChange}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              max={momentDate(new Date(), "YYYY-MM-DD")}
            />
          </Col>
          <Col md={2}>
            <label className="control-label">To</label>
            <Form.Control
              type="date"
              name="todate"
              size="sm"
              // defaultValue={momentDate(new Date(), "YYYY-MM-DD")}
              id="todate"
              max={momentDate(new Date(), "YYYY-MM-DD")}
              min={fromDate}
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <label className="control-label">Block Search</label>
            <select
              className="form-control form-control-sm"
              name="block"
              onChange={filterVillage}
            >
              <option value="">-Select-</option>
              {block.map((item) => {
                return <option value={item.id}>{item.block}</option>;
              })}
            </select>
          </Col>
          <Col md={2}>
            <label className="control-label">Village Search</label>
            <select className="form-control form-control-sm" name="village">
              <option value="">-Select-</option>
              {villageName.map((item) => {
                return <option value={item.id}>{item.villagename}</option>;
              })}
            </select>
          </Col>
          <Col md={2}>
            <label className="control-label">Indicators</label>
            <select className="form-control form-control-sm" name="indicator">
              <option value="">-Select-</option>
              {indicatorsids.map((item) => {
                return <option value={item.indicator_id}>{item.indicators}</option>;
              })}
            </select>
          </Col>
          <Col md={1} className="mt-3">
            <Button
              variant="warning"
              size="sm"
              style={{ float: "left", marginTop: "4px" }}
              type="submit"
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>

      <Row className="text-center">
        <Col md={{ span: 2, offset: 10 }}></Col>
      </Row>
      <Spin spinning={loading}>
        <Row className="mt-2">
          <Col md={12}>
            {" "}
            <div className="table-responsive" style={{ height: "450px" }}>
              <table className="table mt-10 table-bordered">
                <thead
                  className="text-center"
                  style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#E5E4E2",
                  }}
                >
                  <tr>
                    <th width="5%">S.No</th>
                    <th width="10%">
                      <b>Indicators</b>
                    </th>
                    <th width="10%">
                      <b>Date Created</b>
                    </th>
                    {items}
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    {items2}
                  </tr>
                </thead>
                <tbody className="text-center">
                  {data && data.length > 0 ? (
                    data.map((item, i) => {
                      const t = [];
                      const idate =
                        cellloader1(item.indicator_id, 1).idate !== 0
                          ? cellloader1(item.indicator_id, 1).idate
                          : "-";
                      t.push(
                        <>
                          <td>
                            {idate !== "-"
                              ? moment(idate).format("DD/MM/YY")
                              : "-"}
                          </td>
                          {/* <td colSpan="2"></td> */}
                        </>
                      );
                      for (let i1 = 1; i1 <= maxVisitCount; i1++) {
                        const cellData = cellloader1(item.indicator_id, i1);
                        t.push(
                          <>
                          <td>
                              <Button
                                variant="white"
                                onClick={() => {
                                  showAddModel();
                                  listVisitUser(item.indicator_id, i1);
                                }}
                                size="sm"
                              >
                                {cellData.member_count}
                              </Button>
                            </td>
                            <td>{cellData.items_sum}</td>
                          </>
                        );
                      }
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.indicators}</td>

                          {t}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5">No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row className="text-center">
          <Col md={{ span: 2, offset: 10 }}>
            <Modal
              show={addModel}
              size="lg"
              onHide={() => {
                setAddModel(false);
              }}
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>Visitors Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {visitUser && visitUser.length>0 ? (
                  visitUser.map((item, index) => {
                    if (index % 2 === 0) {
                      // Check if it's the start of a new row
                      return (
                        <Row
                          key={index}
                          style={{
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "10px",
                          }}
                        >
                          {/* Render the first column */}
                          <Col>
                            <span
                              style={{
                                color: "blue",
                                fontSize: "19px",
                              }}
                            >
                              <b> {item.fam_head_name}</b>
                            </span>
                            <br />
                            Mobile:{" "}
                            <span
                              style={{
                                color: "blue",
                              }}
                            >
                              {item.mobile}
                            </span>
                            <br />
                            No of Family Members:{" "}
                            <span
                              style={{
                                color: "blue",
                              }}
                            >
                              {item.no_of_fam}
                            </span>
                            <br />
                            Date :{" "}
                              <span
                                style={{
                                  color: "blue",
                                }}
                              >
                                {moment(item.idate).format("DD/MM/YY")}
                              </span>
                              <br />
                            Street Name:{" "}
                            <span
                              style={{
                                color: "blue",
                              }}
                            >
                              {item.street_name}
                            </span>
                            <br />
                            Block:{" "}
                            <span
                              style={{
                                color: "blue",
                              }}
                            >
                              {item.block}
                            </span>
                            <br />
                            Village:{" "}
                            <span
                              style={{
                                color: "blue",
                              }}
                            >
                              {item.villagename}
                            </span>
                          </Col>
                          {/* Render the second column, if available */}
                          {visitUser[index + 1] && (
                            <Col>
                              <span
                                style={{
                                  color: "blue",
                                  fontSize: "19px",
                                }}
                              >
                                <b> {visitUser[index + 1].fam_head_name}</b>
                              </span>
                              <br />
                              Mobile:{" "}
                              <span
                                style={{
                                  color: "blue",
                                }}
                              >
                                {visitUser[index + 1].mobile}
                              </span>
                              <br />
                              No of Family Members:{" "}
                              <span
                                style={{
                                  color: "blue",
                                }}
                              >
                                {visitUser[index + 1].no_of_fam}
                              </span>
                              <br />
                              Date :{" "}
                              <span
                                style={{
                                  color: "blue",
                                }}
                              >
                                {moment(visitUser[index + 1].idate).format("DD/MM/YY")}
                              </span>
                              <br />
                              Street Name:{" "}
                              <span
                                style={{
                                  color: "blue",
                                }}
                              >
                                {visitUser[index + 1].street_name}
                              </span>
                              <br />
                              Block:{" "}
                              <span
                                style={{
                                  color: "blue",
                                }}
                              >
                                {visitUser[index + 1].block}
                              </span>
                              <br />
                              Village:{" "}
                              <span
                                style={{
                                  color: "blue",
                                }}
                              >
                                {visitUser[index + 1].villagename}
                              </span>
                            </Col>
                          )}
                        </Row>
                      );
                    }
                    return null; // Return null for odd-indexed items to avoid extra rows
                  })
                ) : (
                  <span
                    style={{
                      color: "blue",
                    }}
                  >
                    <b>No Data Found</b>
                  </span>
                )}
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
export default HBCOutcome;
