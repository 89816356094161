import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import AddUser from './addStaff';
import AddTrack from './addTrack';
import EditUser from './editStaff';
import ViewUser from './viewStaff';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Paginator from 'react-hooks-paginator';
import { triggerFocus } from 'antd/lib/input/Input';
import { baseUrl } from '../../utils';
import { Switch } from 'antd';


const StaffManagement = (props) => {

	const context = useContext(PsContext);
	const [AddUser, setAddUser] = useState(false);
	const [editModel, setEditModel] = useState(false);
	const [ViewData, setViewData] = useState(false);
	const [user, setUser] = useState([]);
	const [view, setView] = useState([]);
	const [data, changeData] = useState([]);
	const [loading, setIsLoading] = useState(false);
	const [searchData, setSearchData] = useState([]);
	const [offset, setOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		listdata();
	}, []);

	const showModel = (item) => {
		setUser(item);
		setEditModel(true);
	}

	const viewModel = (item) => {
		setView(item);
		setViewData(true);
	}

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/user/list').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setSearchData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	/* const onSearchChange = (event) => {
		event.preventDefault();
		try{
		setBtnLoading(true);
		API.post('v1/enquiry/search/enquiry',$("#src_Add").serialize()).then(res=>{
		if (res['data'].status == '1') {
		 var d = res['data'].data;
		 changeSearchData(d);
		 setIsLoading(false);
		 setBtnLoading(false);
		}
		else {
		 setAdminEnquiry(true);
		 setBtnLoading(false);
		}
		 setIsLoading(false);
		});
		}
		catch(error){

		}
		};
 */

	const onSearchChange = (e) => {
		var d = data;
		let ndata = d.filter(function (item) {
			const name = item.name.toUpperCase();
			const mobile = item.mobile;
			const designation = item.designation.toUpperCase();
			const s = e.target.value.toUpperCase();
			return (name.indexOf(s) > -1 || mobile.indexOf(s) > -1 || designation.indexOf(s) > -1);
		});
		setSearchData(ndata);
	}

	const handleDeleteClick = (item, id, sta) => {
		try {
			{
				setIsLoading(true);
				API.get('v1/user/delete?id=' + id + '&status=' + sta).then(res => {
					if (res['data'].status == '1') {
						//var d = res['data'].data;
						var d = data.filter(dt => dt.id != id);
						changeData(d);
						setIsLoading(false);
						listdata();
					}
					else {
						toast.error("Something Went Wrong..!", "danger");
						setIsLoading(false);
					}
				});
			}
		}
		catch (error) {
			setIsLoading(false);
		}
	}

	/*const handleDeleteClick = (item, id, stu) => {
		try {
			{
				setIsLoading(true);
				API.get('v1/user/delete?id=' + id + '&status=' + stu).then(res => {
					if (res['data'].status == '1') {
						toast.success("User Deleted Successfully..!", "success");

						var d = data.filter(dt => dt.id != id);
						changeData(d);
						setIsLoading(false);
						listdata();
					}

					else {
						toast.error("Something Went Wrong..!", "danger");
						setIsLoading(false);
					}
				});
			}

		}
		catch (error) {
			setIsLoading(false);
		}
	}*/

	const handleStatusChange = (item, id, e) => {

		let status = e ? '1' : '0';

		let msg = e ? 'Do you want to activate ?' : 'Do  you want to deactivate?';
		if (!window.confirm(msg)) {
			return;
		}

		handleDeleteClick(item, id, status)

	}
	return (
		<div className="container" >
			<Navbar bg="white" expand="lg">
				<Container>
					<Navbar.Brand >Manage Staff</Navbar.Brand>
					<Nav className="justify-content-end">
						<Row>
							<Col md={8}>
								<MDBInput
									label="Search "
									type="text"
									onChange={onSearchChange}
								/>
							</Col>
							<Col md={4}>
								<Link to={{
									pathname: '/addstaff'
								}}>Add New</Link>
							</Col>
						</Row>
					</Nav>
				</Container>
			</Navbar>

			{/*<Col md={2}>
				    <Button variant="success" onClick={()=>{setAddUser(true)}}><i class="fa fa-plus" aria-hidden="true"></i> Add New</Button>
				</Col> */}

			<Row className="mt-3">
				<Col md={8}>
				</Col>
				<Col md={4}>

				</Col>
			</Row>
			<br />

			<Row className="text-center">
				<Col md={{ span: 2, offset: 10 }}>

					<Modal show={editModel} size="lg" onHide={() => { setEditModel(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title >Edit Staff</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<EditUser data={user} afterFinish={() => { setEditModel(false) }} afterList={() => listdata()} />
						</Modal.Body>
						<Modal.Footer>

						</Modal.Footer>
					</Modal>

					<Modal show={ViewData} onHide={() => { setViewData(false) }} size='md' >
						<Modal.Header closeButton style={{ borderBottom: '0' }}>
							<Modal.Title style={{ height: '0' }} >View Staff</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ViewUser data={view} />
						</Modal.Body>
					</Modal>
				</Col>
			</Row>
			<Table bordered responsive className="table table-bordered">
				<thead className="text-center">
					<tr>
						<th width="5%">S.No</th>
						<th width="20%"><b>Name Of the Staff</b></th>
						<th width="10%"><b>Mobile</b></th>
						<th width="15%"><b>Designation</b></th>
						<th width="15%"><b>Email</b></th>
						{/*<th width="10%"><b>No Of Groups</b></th>*/}
						<th width="20%"><b>Manage</b></th>
					</tr>
				</thead>
				<tbody className="text-center">
					{(searchData.map((item, id) => {
						// let d = searchData.length;
						return <tr style={{ background: (item.status == '0' ? '#F1EA87' : '') }}>
							<td>{id + 1}</td>
							<td align="left">{item.name}</td>
							<td>{item.mobile}</td>
							<td align="left">{item.designation}</td>
							<td align="left">{item.email}</td>
							{/*<td>{item.groupcount}</td>*/}
							<td>{item.status == '1' ? <Button variant="success" onClick={() => { showModel(item) }} size="sm"><i class="fas fa-edit"></i>  </Button> : ''} {' '}
								<Button variant="warning" size="sm" onClick={() => viewModel(item, item.id)}><i class="fas fa-eye"></i>  </Button>{' '}
								<Switch checked={item.status == '1'} onChange={e => { handleStatusChange(item, item.id, e) }} />
								{/*<Button variant="warning" size="sm" onClick={() => handleDeleteClick(item, item.id)}><i class="fas fa-trash"></i>  </Button>{' '}*/}
							</td>
						</tr>
					}))}
				</tbody>
			</Table>
			{/*  <Paginator
					totalRecords={Hdata.length}
					pageLimit={4}
					pageNeighbours={1}
					setOffset={setOffset}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
				/>  */}
		</div >
	);
};
export default StaffManagement;