import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Table, Navbar, Nav, Container, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { integerIndMobile, lettersOnly, integerIndGst } from '../../utils';
import { baseUrl } from '../../utils';
import axios from 'axios';

const EditHouse = (props) => {

	const context = useContext(PsContext);
	const [btnLoading, setBtnLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [data, changeData] = useState([]);
	const [block, setBlock] = useState([]);
	const [village, setVillage] = useState([]);
	const [dob, setDob] = useState('');

	useEffect(() => {
		var date = new Date().getDate() + '-' + (new Date().getMonth() + 1) + new Date().getFullYear();
		setDob(date);
		listBlock();
		listVillage(props.data.block_id);
	}, []);

	console.log(props.data);

	const listBlock = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/houseblock').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setBlock(d);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const listVillage = (blockId) => {
		try {
			setIsLoading(true);
			API.get('v1/group/housevillage?blockId=' + blockId).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setVillage(d);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		if (!form.checkValidity() === true) {
			event.stopPropagation();
			setValidated(true);
			return;
		}
		try {
			setBtnLoading(true);
			API.post('v1/group/edithouse', $("#addform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("House Updated Successfully..!", "success");
					document.getElementById("addform").reset();
					setBtnLoading(false);
					props.afterFinish();
					props.afterList();
				}
				else {
					toast.error("Something Went Wrong..!", "danger");
					document.getElementById("addform").reset();
				}
				setIsLoading(false);
			});
		}
		catch (error) {

		}
	};

	const selectVillage = (e) => {
		listVillage(e);
	}

	return (
		<>

			<Form action="" method="post" id="addform" noValidate validated={validated} onSubmit={handleSubmit} >
				<input type='hidden' value={props.data.id} name="id" />
				<Row className="mt-2">
					<Col md={12}>
						<Row className="mt-2">
							<Col md={12}>
								<Row>
									<Col md={3}>
										<lable>Name of the Family Head<span style={{ color: 'red' }}>*</span></lable>
									</Col>
									<Col md={1}>
										:
									</Col>
									<Col md={2}>
										<select className='form-select form-select-sm' name="mr_and_mrs" required defaultValue={props.data.mr_and_mrs}>
											<option value=''>--Select--</option>
											<option value='Mr'>Mr</option>
											<option value='Mrs'>Mrs</option>
										</select>
									</Col>
									<Col md={6}>
										<MDBInput
											type="text"
											name="fam_head_name"
											size="sm"
											required
											onKeyPress={lettersOnly}
											defaultValue={props.data.fam_head_name}
										/>
									</Col>
								</Row>
								<Row className='mt-3'>
									<Col md={3}>
										<label>Mobile Number<span style={{ color: 'red' }}>*</span></label>
									</Col>
									<Col md={1}>
										:
									</Col>
									<Col md={8}>
										<MDBInput
											type="text"
											name="mobile"
											size="sm"
											required
											onKeyPress={integerIndMobile}
											defaultValue={props.data.mobile}
										/>
									</Col>
								</Row>
								<Row className='mt-3'>
									<Col md={3}>
										<label>Number of family Members<span style={{ color: 'red' }}>*</span></label>
									</Col>
									<Col md={1}>
										:
									</Col>
									<Col md={8}>
										<MDBInput
											type="number"
											name="no_of_fam"
											size="sm"
											required
											onKeyPress={lettersOnly}
											defaultValue={props.data.no_of_fam}
										/>
									</Col>
								</Row>

								<Row className='mt-3'>
									<Col md={3}>
										<label>Street name<span style={{ color: 'red' }}>*</span></label>
									</Col>
									<Col md={1}>
										:
									</Col>
									<Col md={8}>
										<MDBInput
											type="text"
											name="street_name"
											size="sm"
											required
											defaultValue={props.data.street_name}
										/>
									</Col>
								</Row>
								<Row className='mt-3'>
									<Col md={3}>
										<label>Select Block<span style={{ color: 'red' }}>*</span></label>
									</Col>
									<Col md={1}>
										:
									</Col>
									<Col md={8}>
										<select className='form-select form-select-sm' name="block_id" required onChange={e => selectVillage(e.target.value)}>
											<option value="">--Select--</option>
											{block.map((item) => {
												return <option value={item.id} selected={props.data.block_id == item.id ? props.data.block_id : ''}>{item.block}</option>
											})}
										</select>
									</Col>
								</Row>
								<Row className='mt-3'>
									<Col md={3}>
										<label>Select Village<span style={{ color: 'red' }}>*</span></label>
									</Col>
									<Col md={1}>
										:
									</Col>
									<Col md={8}>
										<select className='form-select form-select-sm' name="village_id" required defaultValue={props.data.village_id}>
											<option value="">--Select--</option>
											{village.map((item) => {
												return <option value={item.id} selected={props.data.village_id == item.id ? props.data.village_id : ''}>{item.villagename}</option>
											})}
										</select>
									</Col>
								</Row>
							</Col>
							<Col md={3}>

							</Col>
							<Col md={6} className='mt-3'>
								<button className="btn btn-success btn-block" type="submit" disabled={btnLoading}>
									{btnLoading ? 'Please wait..' : 'Update'}</button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</>
	);
};
export default EditHouse;