import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal,Table, Navbar ,Container, Nav, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';

import PsContext from '../../context';
import API from '../../utils/api';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import { Link }  from   'react-router-dom';
import axios from 'axios';
import { triggerFocus } from 'antd/lib/input/Input';

const EventReportDate = (props) =>{
	
	const context = useContext(PsContext);
    const[loading, setIsLoading ] = useState(false);
    
	return(
        <div className="container">
            <Navbar bg="white" expand="lg">
				<Container>
					<Navbar.Brand >Event Report Datewise</Navbar.Brand>
					<Nav  className="justify-content-end">{' '}
                    <Link
                        to={{
                            pathname:'/eventreport'
                        }}>
                        Groupwise Report
                    </Link>{' '}
                        |
                    <Link
                        to={{
                            pathname:''
                        }}>
                        Export
                    </Link>{' '}
                        |
                        {' '}
                    <Link
                        to={{
                            pathname:''
                        }}>
                        Print
                    </Link>
					</Nav>
				</Container>
			</Navbar>
            <Row className="mt-3">
				<Col md={3}>
                    <Form.Group >
                        <Form.Label>From Date</Form.Label>
                        <Form.Control type="date" name="duedate" placeholder="" />
                    </Form.Group>
				</Col>
                <Col md={3}>
                    <Form.Group >
                        <Form.Label>To Date</Form.Label>
                        <Form.Control type="date" name="duedate" placeholder="Due date" />
                    </Form.Group>
                    </Col>
                <Col md={3} className="mt-4">
                   <Button 
                        type="button"
                        variant="success"
                        size="md"
                    >Submit
                   </Button>
				</Col>
			</Row>
        </div>
    );

       
    }
export default EventReportDate;