import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { lettersOnly, integerIndMobile } from '../../utils';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const tdStyle = { border: '1px solid grey', borderCollapse: 'collapse', padding: '3px' }

const PlasticCollectionSourceReport = () => {

	const context = useContext(PsContext);
	const [loading, setIsLoading] = useState(false);
	const [groupData, setGroupData] = useState([]);
	const [plasticData, setPlasticData] = useState([]);
	const [collectionData, setCollectionData] = useState([]);
	const [fromdate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
	const [todate, setToDate] = useState(moment().format("YYYY-MM-DD"));

	const printDocument = () => {
		var content = document.getElementById("printdata");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}

	useEffect(() => {
		listgroupname();
		//listplastic();
		listcollection();
	}, []);

	const listcollection = () => {
		try {
			setIsLoading(true);
			var form = new FormData();
			form.append('acyear', context.acyear.id);
			form.append('fromdate', fromdate);
			form.append('todate', todate);
			form.append('source', $("#source").val());
			//form.append('material_type', $("#material_type").val());
			API.post('v1/plastic/listcollectionbysource', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setCollectionData(d);
					setIsLoading(false);
				}
				else {
					setCollectionData([]);
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const listplastic = (event) => {
		event.preventDefault();
		try {

			API.post('v1/plastic/listchartplastic', $('#list').serialize()).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setPlasticData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {

		}
	};

	// const listplastic = () => {
	// 	try {
	// 		setIsLoading(true);
	// 		var form = new FormData();
	// 		form.append('acyear', context.acyear.id);
	// 		API.post('v1/plastic/listplastic', form).then(res => {
	// 			if (res['data'].status == '1') {
	// 				var d = res['data'].data;
	// 				setPlasticData(d);
	// 				setIsLoading(false);
	// 			}
	// 			else {
	// 				setIsLoading(false);
	// 			}
	// 		});
	// 	}
	// 	catch (error) {
	// 		setIsLoading(false);
	// 	}
	// };

	const listgroupname = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listgroup').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setGroupData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const getStock = (grpid, pid) => {
		var d = collectionData;
		var kg = 0;
		var index = d.findIndex(function (obj) {
			return (obj.group_id == grpid && obj.plastic_id == pid);
		});
		if (index > -1) {
			kg = d[index].kg;
		}
		return kg;
	}

	const getTotalStock = (pid) => {
		var d = collectionData;
		var kg = 0;
		d.filter(function (obj) {
			if (obj.plastic_id == pid) {
				kg += parseFloat(obj.kg);
			}
		});
		return kg;
	}

	const onFromChange = (event) => {
		var fromdate = $("#fromdate").val();
		setFromDate(fromdate);
		var toMinDate = moment(fromdate).format("YYYY-MM-DD");
		if (fromdate != '') {
			$("#todate").attr('min', toMinDate);
		}
	};

	return (
		<>
			<div className="container">
				<Navbar bg="white" expand="md">
					<Container>
						<Navbar.Brand >Plastic Collection Source Wise Report</Navbar.Brand>
					</Container>
				</Navbar><br />
				<Row style={{ marginTop: '0px' }}>
					<Col md={12}>
						<Form onSubmit={listplastic} method='post' id="list">
							<Row>
								<Col md={9}>
								</Col>
								<Col md={2} className='ms-0'>
									<select className='form-control sm' name="type">
										<option value="">Select Type</option>
										<option value="plastic kg">Plastic Kg</option>
										<option value="others kg">Non Plastic Kg</option>
										<option value="others pcs">Non Plastic Piece</option>
									</select>
								</Col>
								<Col md={1}>
									<Button type="submit" size='sm' className='mt-1' variant="success">Search</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				{plasticData.length > 0 ? <Row style={{ margin: '10px' }}>
					<Col md={2}>
						<label>Source</label>
						<select name="source" id="source" className="form-control">
							<option value="event">Event</option>
							<option value="vendor">Vendor</option>
						</select>
					</Col>
					<Col md={3}>
						<label>From Date:</label>
						<MDBInput
							type="date"
							id="fromdate"
							defaultValue={fromdate}
							onChange={onFromChange}
						/>
					</Col>
					<Col md={3}>
						<label>To Date:</label>
						<MDBInput
							type="date"
							id="todate"
							defaultValue={todate}
							onChange={(e) => { setToDate(e.target.value) }}
						/>
					</Col>
					{/* <Col md={2}>
						<label>Material Type:</label>
						<select className='form-control sm' name="material_type" id="material_type" required>
							<option value="">Select Type</option>
							<option value="plastic kg">Plastic Kg</option>
							<option value="others kg">Non Plastic Kg</option>
							<option value="others pcs">Non Plastic Piece</option>
						</select>
					</Col> */}
					<Col md={4}>
						<Button type="submit" className="mt-3" onClick={listcollection}>Submit</Button>&nbsp;
						<Button type="button" className="mt-3" variant="warning" onClick={printDocument}>Print</Button>&nbsp;
						<ReactHTMLTableToExcel
							id="test-table-xls-button"
							className="download-table-xls-button btn mt-3 btn-info"
							table="table-to-xls"
							filename="Plastic Collection Source Wise Report"
							sheet="plasticcollectionsourcewise"
							buttonText="Export as XLS" />
					</Col>
					<Col md={12} className="mt-3" id="printdata">
						<Table bordered responsive style={tdStyle} id="table-to-xls">
							<thead className="text-center">
								<tr>
									<th style={tdStyle} colSpan={2 + plasticData.length}>
										<span style={{ fontSize: '18px' }}>Plastic Collection Source Wise Report ( {moment(fromdate).format("DD-MM-YYYY")} to {moment(todate).format("DD-MM-YYYY")} )</span>
									</th>
								</tr>
								<tr>
									<th width="5%" style={tdStyle}><b>S.No</b></th>
									<th width="10%" style={tdStyle}><b>Group Name</b></th>
									{plasticData.map((item, i) => {
										return <th width="10%" style={tdStyle}><b>{item.short_name}</b></th>
									})}
								</tr>
							</thead>
							<tbody className="text-center">
								{groupData.map((item, i) => {
									return <tr>
										<td style={tdStyle}>{i + 1}</td>
										<td style={tdStyle} align="left">{item.grp_name}</td>
										{plasticData.map((items, j) => {
											return <td style={tdStyle} align="right">{getStock(item.id, items.id)}</td>
										})}
									</tr>
								})}
								<tr>
									<td colSpan="2" align="right" style={tdStyle}><b>Total</b></td>
									{plasticData.map((items, j) => {
										return <td style={tdStyle} align="right"><b>{getTotalStock(items.id).toFixed(2)}</b></td>
									})}
								</tr>
							</tbody>
						</Table>
					</Col>
					<iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
				</Row> : ''}

				<Row style={{ display: 'none' }}>
					<Col md={12}>
						<div align="center" style={{ color: '#08788C', fontSize: '30px' }}><b>WOMEN PRENEURS 4 PLASTIC</b></div>&nbsp;
						<table className="table mt-10 table-bordered" style={tdStyle} id="table-to-xls">
							<thead className="text-center">
								<tr>
									<th style={tdStyle} colSpan={2 + plasticData.length}>
										<span style={{ fontSize: '18px' }}>Plastic Collection Source Wise Report ( {moment(fromdate).format("DD-MM-YYYY")} to {moment(todate).format("DD-MM-YYYY")} )</span>
									</th>
								</tr>
								<tr>
									<th width="5%" style={tdStyle}><b>S.No</b></th>
									<th width="10%" style={tdStyle}><b>Group Name</b></th>
									{plasticData.map((item, i) => {
										return <th width="10%" style={tdStyle}><b>{item.short_name}</b></th>
									})}
								</tr>
							</thead>
							<tbody className="text-center">
								{groupData.map((item, i) => {
									return <tr>
										<td style={tdStyle}>{i + 1}</td>
										<td style={tdStyle} align="left">{item.grp_name}</td>
										{plasticData.map((items, j) => {
											return <td style={tdStyle} align="right">{getStock(item.id, items.id)}</td>
										})}
									</tr>
								})}
								<tr>
									<td colSpan="2" align="right" style={tdStyle}><b>Total</b></td>
									{plasticData.map((items, j) => {
										return <td style={tdStyle} align="right"><b>{getTotalStock(items.id).toFixed(2)}</b></td>
									})}
								</tr>
							</tbody>
						</table>
						<iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default PlasticCollectionSourceReport;