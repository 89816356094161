import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Navbar, Container, Nav, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { lettersOnly, integerIndMobile, decimalKeyPress, momentDate } from '../../utils';
import { baseUrl } from '../../utils';
import axios from 'axios';
import Select from 'react-select';
import ReactFileReader from 'react-file-reader';
import PLUSIMAGE from '../../assets/css/images/plus.png';
import moment from 'moment';
import { TimePicker } from 'antd';
import { imageUrl } from '../../utils';

const styles = {
	imgStyle: {
		border: '1px solid #d8d8d8', margin: '10px 10px 0px 0px', height: '120px'
	},
	plusImgStyle: {
		padding: '10px', border: '1px solid #d8d8d8', margin: '10px', backgroundColor: '#f1f1f1', cursor: 'pointer'
	},
	btnStyle: {
		position: 'absolute',
		bottom: '175px',
		left: '132px',
		borderRadius: '32px',
		cursor: 'pointer'
	}
}

const EditGroupMeetingReport = (props) => {

	const context = useContext(PsContext);
	const [submit, sethandleSubmit] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [data, changeData] = useState([]);
	const [removeData, setRemoveData] = useState([]);
	const [villageData, setVillageData] = useState([]);
	const [imageData, setImageData] = useState(props.image);
	const [dob, setDob] = useState('');
	const [photos, setPhotos] = useState([]);
	const [photos1, setPhotos1] = useState([]);
	const [file, setFile] = useState([]);
	const [fileName, setFileName] = useState('');
	const [maxMembers, setMaxMembers] = useState([]);
	const [startTime, setStartTime] = useState('');

	const handleFiles = (picfiles) => {
		setFile(picfiles.base64);
		setFileName(picfiles.fileList[0].name);
	}

	const getFiles = (files) => {
		var d = [];
		var d1 = [];
		if (photos.length > 0) {
			for (var i = 0; i < (files.base64).length; i++) {
				d.push(files.base64[i]);
			}
			const arr = [...photos, ...d];
			setPhotos(arr);
		} else {
			setPhotos(files.base64);
		}

		if (photos1.length > 0) {
			for (var i = 0; i < files.length; i++) {
				d1.push(files);
			}
			const arr1 = [...photos1, ...d1];
			setPhotos1(arr1);
		} else {
			setPhotos1(files.base64);
		}
	}

	const handleRemove = (index) => {
		var d = photos.filter((item, i) => i != index);
		setPhotos(d);
		var d1 = photos1.filter((item1, i1) => i1 != index);
		setPhotos1(d1);
	}

	const handleDeleteClick = (id) => {
		try {
			var d = imageData.filter((item, i) => item.id != id);
			setImageData(d);
			var r = [];
			r.push(id);
			const arr = [...removeData, ...r];
			setRemoveData(arr);
		}
		catch (error) {
			setIsLoading(false);
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			setBtnLoading(true);
			console.log($("#addform").serialize());
			API.post('v1/group/editgroupmeetingreport', $("#addform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Report Updated Successfully..!", "success");
					document.getElementById("addform").reset();
					setBtnLoading(false);
					props.afterFinish();
					props.afterList();
				}
				else {
					toast.error(res['data'].message, "danger");
					setBtnLoading(false);
				}
				setIsLoading(false);
			});
		}
		catch (error) {

		}
	};

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listgroup').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d.filter(item => item.status == 1));
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		listdata();
	}, []);

	const handleGroupChange = (e) => {
		console.log(e.target.value);
		console.log(data);
		let m = data.find(item => item.id == e.target.value);
		setMaxMembers(m.total_members);
		console.log(maxMembers);
	};

	const getData = (field) => {
		return (props.data[field] || '');
	}

	return (
		<>
			<div className="container">
				<Form action="" method="post" id="addform" validated={validated} onSubmit={handleSubmit}>
					<input type="hidden" name="files" value={JSON.stringify(photos1)} />
					<input type="hidden" name="picfiles" value={JSON.stringify(file)} />
					<input type="hidden" name="removefiles" value={JSON.stringify(removeData)} />
					<input type="hidden" name="fileName" value={JSON.stringify(fileName)} />
					<input type="hidden" name="id" value={getData('id')} />
					<Row>
						<Col md={3}>
							<label>Group Name<span style={{ color: 'red' }}>*</span></label>
						</Col>
						<Col md={1}>
							:
						</Col>

						<Col md={8}>
							<select className="form-control form-control-sm" name="group_id" required onChange={e => handleGroupChange(e)} disabled>
								<option value="">Select</option>
								{data.map((user, i) => {
									return <option value={user.id} selected={getData('group_id') == user.id ? getData('group_id') : ''}>{user.grp_name}</option>
								})}
							</select>
						</Col>
					</Row>
					<Row className='mt-3'>
						<Col md={3}>
							<label>Date of Meeting<span style={{ color: 'red' }}>*</span></label>
						</Col>
						<Col md={1}>
							:
						</Col>
						<Col md={8}>
							<MDBInput
								type="date"
								size="md"
								name="date_meeting"
								max={momentDate(new Date(), 'YYYY-MM-DD')}
								defaultValue={getData('date_meeting')}
								required
							/>
						</Col>
					</Row>
					<Row className='mt-3'>
						<Col md={3}>
							<label>Starting Time<span style={{ color: 'red' }}>*</span></label>
						</Col>
						<Col md={1}>
							:
						</Col>
						<Col md={8}>
							<TimePicker type="time" name="start_time" use12Hours format="h:mm a"
								style={{ width: '100%' }}
								onChange={e => setStartTime(e)}
								defaultValue={moment(getData('start_time'), 'h:mm:ss A')}
							/>
						</Col>
					</Row>
					<Row className='mt-3'>
						<Col md={3}>
							<label>Ending Time<span style={{ color: 'red' }}>*</span></label>
						</Col>
						<Col md={1}>
							:
						</Col>
						<Col md={8}>
							<TimePicker type="time" name="end_time" use12Hours format="h:mm a"
								style={{ width: '100%' }}
								defaultValue={moment(getData('end_time'), 'h:mm:ss A')}
							/>
						</Col>
					</Row>
					<Row className='mt-3'>
						<Col md={3}>
							<label>No of participants<span style={{ color: 'red' }}>*</span></label>
						</Col>
						<Col md={1}>
							:
						</Col>
						<Col md={8}>
							<MDBInput
								type="number"
								size="md"
								name="no_of_participants"
								min="0"
								max={maxMembers}
								required
								value={getData('no_of_participants')}
							/>
						</Col>
					</Row>
					<Row className='mt-3'>
						<Col md={3}>
							<label>Total Members<span style={{ color: 'red' }}>*</span></label>
						</Col>
						<Col md={1}>
							:
						</Col>
						<Col md={8}>
							<MDBInput
								type="number"
								size="md"
								min='0'
								max={getData('no_of_participants')}
								name="total_participants"
								required
								defaultValue={getData('total_participants')}
							/>
						</Col>
					</Row>

					<Row className='mt-3'>
						<Col md={3}>
							<label>Photo (2)<span style={{ color: 'red' }}>*</span></label>
						</Col>
						<Col md={1}>
							:
						</Col>
						<Col md={8}>
							{imageData.map((item) => {
								return (<Col md={3}><img src={imageUrl + item.photo} width='150%' /><br /><button type="button" className="btn btn-danger btn-block btn-sm" style={{ marginLeft: '10px' }} onClick={() => { handleDeleteClick(item.id) }}>Remove</button></Col>);
							})}
							{photos.map((item, i) => {
								return (<Col md={2} style={{ maxWidth: '15%' }}>
									<img src={item} width="120px" style={styles.imgStyle} /><br />
									<button type="button" className="btn btn-danger btn-block btn-sm" style={{ marginLeft: '10px' }} onClick={() => { handleRemove(i) }}>Remove</button>
								</Col>);
							})}
							<ReactFileReader fileTypes={[".png", ".jpg", ".jpeg"]} multipleFiles={true} base64={true} handleFiles={getFiles}>
								<img src={PLUSIMAGE} width="120px" style={styles.plusImgStyle} />
							</ReactFileReader>
						</Col>
					</Row>
					<Row className='mt-4'>
						<Col md={3}>
							<label>Photo<span style={{ color: 'red' }}>*</span></label>
						</Col>
						<Col md={1}>
							:
						</Col>
						<Col md={8}>
							{file == '' ? <img src={imageUrl + getData('photo')} width='50%' /> : <img src={file} width="40%" />}
							<ReactFileReader base64={true} handleFiles={handleFiles}>
								<button type="button" className='btn btn-block' size="sm">{file == '' ? 'Upload' : 'change'}</button>
							</ReactFileReader>
						</Col>
					</Row>
					<Row className='mt-3'>
						<Col md={3}>
							<label>Remarks<span style={{ color: 'red' }}></span></label>
						</Col>
						<Col md={1}>
							:
						</Col>
						<Col md={8}>
							<MDBInput
								type="text"
								size="md"
								name="remark"
								defaultValue={getData('remark')}
							/>
						</Col>
					</Row>
					<Row className='mt-2'>
						<Button type="submit" variant="success" className='btn btn-block'>Update</Button>
					</Row>
				</Form>
			</div >
		</>
	);
};

export default EditGroupMeetingReport;